const loginUI = {
    default: {
        fields: [
            'email',
            'password',
            'agree_tos'
        ]
    }
}

export default loginUI;