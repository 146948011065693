//Load Data from LocalStorage
export const LOAD_USER_INFO_FROM_LOCAL_STORAGE_ACTION = "LOAD_USER_INFO_FROM_LOCAL_STORAGE_ACTION";

//Domain Info
export const SET_DOMAIN_INFO = "SET_DOMAIN_INFO";

//Login Actions
export const LOGIN_ACTION = "LOGIN_ACTION";
export const LOGOUT_ACTION = "LOGOUT_ACTION";

//Profile Actions
export const SET_PROFILE_ACTION = "SET_PROFILE_ACTION";
export const SET_ACCOUNT_BALANCE_ACTION = "SET_ACCOUNT_BALANCE_ACTION";

//Layout Action
export const TOGGLE_LEFT_MENU_ACTION = "TOGGLE_LEFT_MENU_ACTION";
export const SET_LEFT_MENU_ACTION = "SET_LEFT_MENU_ACTION";
export const CHANGE_USER_INFO_ON_HEADER = "CHANGE_USER_INFO_ON_HEADER";
export const SET_MENU_INFO_FROM_REQUEST = "SET_MENU_INFO_FROM_REQUEST";
export const SET_OPEN_SUB_MENU_ACTION = "SET_OPEN_SUB_MENU_ACTION";
export const SET_LOAD_STATUS_ACTION = "SET_LOAD_STATUS_ACTION";
export const TOGGLE_CHAT_PANEL_ACTION = "TOGGLE_CHAT_PANEL_ACTION";

//Modal Actions
export const OPEN_MODAL_ACTION = "OPEN_MODAL_ACTION";
export const CLOSE_MODAL_ACTION = "CLOSE_MODAL_ACTION";
export const SET_CONTENT_MODAL_ACTION = "SET_CONTENT_MODAL_ACTION";

//Request Actions
export const SET_PARAMS_HOOK_ACTION = "SET_PARAMS_HOOK_ACTION";
export const SET_URL_HOOK_ACTION = "SET_URL_HOOK_ACTION";
export const SET_CALLBACK_SUCCESS_ACTION = "SET_CALLBACK_SUCCESS_ACTION";
export const SET_CALLBACK_FAILED_ACTION = "SET_CALLBACK_FAILED_ACTION";
export const SET_CHANGE_METHOD_HOOK = "SET_CHANGE_METHOD_HOOK";

//Socket actions
export const SET_SOCKET_OBJECT = "SET_SOCKET_OBJECT";

//Game actions
export const SET_BET_COIN_AMOUNT = "SET_BET_COIN_AMOUNT";