import { t1 } from "translate";
import { composeValidators, required, emailValidator } from "validations/index";

const signupSchema = {
  user_name: {
    type: "text",
    label: t1("user_name"),
    validate: composeValidators(required),
    errorText: {
      required: "User name is require",
    },
  },
  email: {
    type: "text",
    label: t1("email"),
    validate: composeValidators(required, emailValidator),
    errorText: {
      required: "Email is require",
      emailInvalid: "Email is not valid",
    },
  },
  password: {
    type: "password",
    label: t1("password"),
    validate: composeValidators(required),
    errorText: {
      required: "Password is require",
    },
  },
  confirm_password: {
    type: "password",
    label: t1("confirm_password"),
    validate: composeValidators(required),
    errorText: {
      required: "Confirm password is require",
    },
  },
};

export default signupSchema;
