import React from 'react';
import BaseLayoutField from '../antd/BaseLayoutField';

const FormElementRender = ({ form, fieldData, style = 'antd', arrayMutators = {}, values = {} }) => {
  if (style === 'antd') {
    return BaseLayoutField({ form, fieldData: fieldData, arrayMutators: arrayMutators, values: values });
  } else {
    return <></>;
  }
};

export default FormElementRender;
