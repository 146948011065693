import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { Layout, Menu, theme, Row, Col } from "antd";
import { useState, useEffect } from "react";
import AuthWrapper from "components/AuthWrapper";
import UserInfoWrapper from "components/UserInfoWrapper";
import { connect } from "react-redux";
import { logoutAction } from "redux/actions";
import MENU_LIST from "constants/menus";
import { t1 } from "translate";
import { useNavigate, useLocation } from "react-router-dom";
import Drawer from "antd/lib/drawer";
import MenuIcon from '@mui/icons-material/Menu';
import Logo from 'assets/images/logo_big.png';
import Avatar from 'antd/lib/avatar';
import Typography from 'antd/lib/typography';
import Statistic from 'antd/lib/statistic';
import Divider from 'antd/lib/divider';
import FOOTER_LIST from "constants/footer";
import styles from './styles.module.scss';

const { Content } = Layout;
const { Title, Text } = Typography;

const addLogoutMenu = (menuList = [], dispatch) => {
    let output = menuList;

    if (!output.find((e) => e.key == 9999)) {
        output.push({
            key: 9999,
            icon: <LogoutOutlined />,
            label: t1("logout"),
            onClick: (e) => dispatch(logoutAction()),
        });
    }

    return output;
};

const convertMenuToLink = (menuList = [], navigate, location) => {
    let output = [];

    menuList.map((menuData) => {
        if (menuData?.children) {
            output.push({
                ...menuData,
                children: convertMenuToLink(menuData.children, navigate, location),
            });

            const checkActiveTab = (menuData?.children || [])?.findIndex(tab => tab?.link === location.pathname);
            if (checkActiveTab !== -1) {
                menuData.style = { background: '#2d3948', borderRadius: 0};
                menuData.children = menuData?.children?.map((tab, tabIndex) => ({
                    ...tab,
                    style: {
                        background: tabIndex === checkActiveTab ? '#383d43' : 'none'
                    }
                }))
            } else {
                menuData.style = {background: 'none'};
            }
        } else {
            output.push({
                ...menuData,
                onClick: (e) => {
                    if (location.pathname != menuData.link) {
                        navigate(menuData.link);
                    }
                },
            });
        }
    });

    return output;
};

const PageLayout = ({ children, dispatch, breadcrumList = [], profile, auth }) => {
    const [openMenu, setOpenMenu] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const {
        token: { colorBgContainer },
    } = theme.useToken();

    useEffect(() => {
        if (typeof profile?.reloadProfile === 'function') {
            profile.reloadProfile();
        }
    }, [location.pathname]);

    const handleRenderPrice = (price) => {
        if (price >= 1000000000) {
            return price / 1000000000;
        } else if (price >= 1000000) {
            return price / 1000000;
        } else {
            return price;
        }
    }

    const handleRenderPriceIcon = (price, concurrency) => {
        const icon = concurrency === 0
            ? <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px"}}/>
            : <img src="/goldicon.png" alt="sro money gold icon" style={{width:"14px"}}/>;
        let title = '';
        if (price >= 1000000000) {
            title = t1('billion');
        } else if (price >= 1000000) {
            title = t1('million');
        }
        return <>
            {!!title && (<span style={{fontSize: '15px', marginRight: '3px'}}>{title}</span>)}
            {icon}
        </>
    }

    return (
        <AuthWrapper>
            <Layout className={styles.layout}>
                <div span={24} className={styles.headerLayout}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <img src={Logo} alt="logo" style={{width: '180px'}}/>      
                        <MenuIcon onClick={() => setOpenMenu(true)}/>
                        <Drawer 
                            width={230}
                            closable={false}
                            placement="left"
                            open={openMenu}
                            onClose={() => setOpenMenu(false)}
                            bodyStyle={{
                                background: '#001529',
                                padding: '0px',
                            }}
                        >
                            <div className={styles.userInfo}>
                                <div className={styles.detail}>
                                    <Avatar className={styles.avatar} shape="circle" size={45} icon={<UserOutlined />} />
                                    <div>
                                        <Title level={4} className={styles.userName} ellipsis={true} style={{}}>{profile?.profile?.username}</Title>
                                        <Text className={styles.userType}>{t1('normal_user')}</Text>
                                    </div>
                                </div>
                                <Divider className="mt-3 mb-1" style={{borderColor: 'rgba(255,255,255,0.4)'}}/>
                                <div className={styles.userAccount}>
                                    <Row>
                                        <Col span={12}>
                                            <Statistic
                                                title={<span style={{color: "#ddd"}}>{t1('total_point')}</span>}
                                                value={handleRenderPrice(parseFloat(profile?.account?.balance))}
                                                valueStyle={{color: '#fff', fontSize: '17px'}}
                                                suffix={handleRenderPriceIcon(parseFloat(profile?.account?.balance), 0)}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title={<span style={{color: "#ddd"}}>{t1('gold_tk')}</span>}
                                                value={handleRenderPrice(parseInt(profile?.account?.gold_accounts[0]?.balance))}
                                                valueStyle={{color: '#fff', fontSize: '17px'}}
                                                suffix={handleRenderPriceIcon(parseFloat(profile?.account?.gold_accounts[0]?.balance), 1)}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title={<span style={{color: "#ddd"}}>{t1('gold_bk')}</span>}
                                                value={handleRenderPrice(parseInt(profile?.account?.gold_accounts[1]?.balance))}
                                                valueStyle={{color: '#fff', fontSize: '17px'}}
                                                suffix={handleRenderPriceIcon(parseFloat(profile?.account?.gold_accounts[1]?.balance), 1)}
                                            />
                                        </Col>
                                        <Col span={12}>
                                            <Statistic
                                                title={<span style={{color: "#ddd"}}>{t1('gold_hk')}</span>}
                                                value={handleRenderPrice(parseInt(profile?.account?.gold_accounts[2]?.balance))}
                                                valueStyle={{color: '#fff', fontSize: '17px'}}
                                                suffix={handleRenderPriceIcon(parseFloat(profile?.account?.gold_accounts[2]?.balance), 1)}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                                <Divider className="mt-3 mb-1" style={{borderColor: 'rgba(255,255,255,0.4)'}}/>
                            </div>
                            <Menu
                                theme="dark"
                                defaultSelectedKeys={[]}
                                mode="inline"
                                style={{ height: '100vh', overflow: 'auto' }}
                                items={addLogoutMenu(
                                    convertMenuToLink(MENU_LIST, navigate, location),
                                    dispatch
                                )}
                            />
                        </Drawer>
                    </div>
                </div>
                <Layout className={styles.contentLayout}>
                    <Content
                        style={{
                            padding: "0 16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "28px",
                        }}
                        className="pt-3"
                    >
                        {children}
                    </Content>
                </Layout>
                <div className={styles.footerLayout}>
                    <div className={styles.footerMenu}>
                        {FOOTER_LIST.map((link, index) => (
                            <div
                                key={index}
                                className={styles.footerLink}
                                onClick={() => navigate(link.link)}
                            >
                                <div className={styles.icon}>{link.icon}</div>
                                <div className={styles.title}>{link.title}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </Layout>
        </AuthWrapper>
    );
};

const mapStateToProps = (state,props) => {
    return {
        profile: state?.profile,
        auth: state?.auth?.token
    }
}

export default connect(mapStateToProps)(PageLayout);
