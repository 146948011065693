//this class using to create data for axios request
//import Store from 'redux/store';
import lGet from 'lodash/get';

class CommonURL {
    options = (userInfo = true) => {
        /*const user = lGet(Store.getState(),"localStorage.userInfo",{});

        let { info, ga } = user;
        info = info || {};
        ga = ga || {};*/

        //let domain = window.location.href.replace("http://","").replace("https://","").split(".")[0];//working

        //for local testing
        /*if (window.location.href.split(".").length < 3){
          domain = "unidemo1";//case for localhost la idiot
        }*/

        /*if (lGet(info, 'lname') === 'root' || lGet(info, 'code') === 'root') {
          domain = 'system';
        }*/

    const params = {
        // submit: 1,
        /*_sand_ajax: 1,
        _sand_platform: 3,
        _sand_readmin: 1,
        _sand_ga_sessionToken: ga.sessionToken || '',
        _sand_ga_browserToken: ga.browserToken || '',
        _sand_domain: window.APP_SCHOOL || domain,
        _sand_masked: '',
        _sand_web_url: window.location.href,*/
    };

    /*if (userInfo) {
       params._sand_token = info.token;
       params._sand_uiid = info.iid;
       params._sand_uid = info.id;
    }*/

     return params;
   };

   appendObjectToURL(url, params) {
     if (!params) {
       return url;
     }

     const urlObj = new URL(url);
     Object.keys(params).forEach((key) =>
       urlObj.searchParams.append(key, params[key]),
     );
     return urlObj;
   }

    getURL(url, params, headers) {
      let localUrl = url;
      let localParams = params;
      let localHeaders = headers;

      localParams = localParams || {};
      let userInfo = true;
      if (
        Object.prototype.hasOwnProperty.call(localParams, 'domainUrl') ||
        Object.prototype.hasOwnProperty.call(localParams, 'domain-url')
      ) {
        localUrl =
          localParams.domainUrl + localUrl.startsWith('/')
            ? localUrl
            : `/${localUrl}`;
        delete localParams.domainUrl;
      } else if (!localUrl.startsWith('http') && !localUrl.startsWith('https')) {
        localUrl =
          window.API_SERVER ? window.API_SERVER + (localUrl.startsWith('/') ? localUrl : `/${localUrl}`) : 'https://test-api.combatdigito.com' +
          (localUrl.startsWith('/') ? localUrl : `/${localUrl}`);
      }
      localParams = Object.assign({}, localParams, this.options(userInfo));

      if (!localHeaders) {
        localHeaders = {};
      }

      const myHeaders = new Headers();
      Object.keys(localHeaders).forEach((key) => {
        myHeaders.append(key, localHeaders[key]);
      });

      return {
        urlProcess: localUrl,
        allParams: localParams,
        headersData: myHeaders,
      };
    }

   createFrom(params, form) {
     let localForm = form;
     if (!localForm) {
       localForm = new FormData();
     }
     if (!params) {
       return localForm;
     }
     Object.keys(params).forEach((key) => {
       this.simplifyParams(localForm, key, params[key]);
     });
     return localForm;
   }

   simplifyParams(form, key, param) {
     // console.log(key, param);
     if (typeof param === 'undefined' || param === null) return;

     // console.log('param: ', param, typeof param);
     if (typeof param !== 'object' || param instanceof File) {
       form.append(key, param);
       // console.log('appending', key, param);
       return;
     }

     if (Array.isArray(param)) {
       param.forEach((value, i) => {
         this.simplifyParams(form, `${key}[${i}]`, value);
       });
     } else {
       Object.keys(param).forEach((subKey) => {
         this.simplifyParams(form, `${key}[${subKey}]`, param[subKey]);
       });
     }
   }
 }

 export default new CommonURL();
