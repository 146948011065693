import React, { useState } from "react";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import Select from "antd/lib/select";
import { t1 } from "translate";
import Button from "antd/lib/button";
import DatePicker from 'antd/lib/date-picker';
import { TRANSACTION_TYPE } from "constants";

const { RangePicker } = DatePicker;

const OPTION_LIST = [
    {
        label: t1("init_status"),
        value: "init",
    },
    {
        label: t1("success_status"),
        value: "success",
    },
];

const columns = [
    {
        title: t1('created_at'),
        dataIndex: 'created_at',
        key: 'created_at',
        render: (data,record,index) => {
            return dayjs(data).format('DD/MM/YYYY HH:mm')
        }
    },{
        title: t1('point_to_add'),
        dataIndex: 'amount',
        key: 'amount'
    },{
        title: t1('offer_code'),
        dataIndex: 'code',
        key: 'code'
    },{
        title: t1('transaction_status'),
        dataIndex: 'status',
        key: 'status'
    },{
        title: t1('last_updated_at'),
        dataIndex: 'updated_at',
        key: 'updated_at',
        render: (data,record,index) => {
            return dayjs(data).format('DD/MM/YYYY HH:mm')
        }
    },
];

const DepositTransactionList = (props) => {
    const [transactionList, setTransactionList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [status, setStatus] = useState("init");
    const [startDate, setStartDate] = useState(dayjs().add(-1, "months"));
    const [endDate, setEndDate] = useState(dayjs());
    const [data, UpEnpoints, setParams] = useFetch(
        endpoints.get_transaction_list,
        {
            type: TRANSACTION_TYPE.DEPOSIT,
            status: status,
            start_date: startDate.format("YYYY-MM-DD"),
            end_date: endDate.format("YYYY-MM-DD"),
            limit: pageSize,
            page: currentPage,
        },
        "get",
        (result, params) => {
            setTransactionList(result?.data || []);
            setCurrentPage(result?.meta?.current_page);
            setPageSize(result?.meta?.per_page);
            setTotal(result?.meta?.total);
        }
    );

    return (
        <Row gutter={[16,16]}>
            <Col span="24">
                <Row gutter={[16,16]}>
                    <Col span={24} md={8} lg={8}>
                        <p>{t1("status")}</p>
                        <Select
                            defaultValue={"init"}
                            options={OPTION_LIST}
                            value={status}
                            onChange={(e) => setStatus(e)}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col span={24} md={10} lg={10}>
                        <p>{t1("time_range")}</p>
                        <RangePicker
                            value={[startDate, endDate]}
                            onChange={(dayStart, dayEnd) => {
                                setStartDate(dayStart);
                                setEndDate(dayEnd);
                            }}
                            style={{ width: "100%" }}
                        />
                    </Col>
                    <Col span={24} md={6} lg={6}>
                        <Button type="primary" onClick={(e) => {
                            setParams("");
                            setParams({
                                type: TRANSACTION_TYPE.DEPOSIT,
                                status: status,
                                start_date: startDate.format("YYYY-MM-DD"),
                                end_date: endDate.format("YYYY-MM-DD"),
                                limit: pageSize,
                                page: 1,
                            });
                        }}>{t1("click_to_search")}</Button>
                    </Col>
                </Row>
            </Col>
            <Col span="24">
                <Table
                    dataSource={transactionList}
                    columns={columns}
                    pagination={{
                        current: currentPage,
                        pageSize: pageSize,
                        total: total,
                        showTotal: (totalNumber,range) => t1('total_%s_transaction',[totalNumber]),
                        onChange: (pageNumber, pageSizeNumber) => {
                            setParams("");
                            setParams({
                                type: TRANSACTION_TYPE.DEPOSIT,
                                status: status,
                                start_date: startDate.format("YYYY-MM-DD"),
                                end_date: endDate.format("YYYY-MM-DD"),
                                limit: pageSizeNumber,
                                page: pageNumber,
                            });
                        },
                    }}
                />
            </Col>
        </Row>
    );
};

export default DepositTransactionList;
