import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";

const { Title, Paragraph, Text } = Typography;

const Step2 = (props) => {
  const { setCurrentPercent, cancelWithdrawProcess } = props;

  return (
    <Row gutter={[8,8]}>
      <Col span={24}>
        <Typography>
          <Title>{t1("end_of_withdraw_process")}</Title>
          <Paragraph>
            {t1("click_button_below_to_confirm_that_you_are_ready_to_withdraw_your_items")}
          </Paragraph>

          <Text type="danger">
            {t1(
              "failed_to_confirm_in_time_will_make_you_blocked_from_deposit_function_for_at_least_5_mins"
            )}
          </Text>
        </Typography>
      </Col>
      <Col span={24} className="text-center">
        <Button
          className="me-2"
          onClick={cancelWithdrawProcess}
        >
          {t1("i_cancelled_the_deal")}
        </Button>
        <Button
          onClick={(e) => setCurrentPercent(0)}
        >
          {t1("i_am_ready")}
        </Button>
      </Col>
    </Row>
  );
};

export default Step2;
