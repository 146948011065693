import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

//This page will list all ticket support request that they create
const SupportTicketLayout = (props) => {
    return <PageLayout>
        {t1('support_ticket_layout')}
    </PageLayout>
}

export default SupportTicketLayout;