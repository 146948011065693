import { t1 } from "translate";

export const orderGenerator = (listOrder = []) => {
    let output = listOrder;

    output = listOrder.map((orderPrice, indexPrice) => {
        const quantity = orderPrice?.quantity ? Number(orderPrice.quantity) : 1;
        const price = orderPrice?.price_each_unit
            ? Number(orderPrice.price_each_unit)
            : (orderPrice?.price_per_unit
                ? Number(orderPrice.price_per_unit)
                : 0.0000);
        return [price , quantity];
    });

    if (output.length === 0){
        output = [[0.0000, 1]];
    }

    return output;
};

export const generatePricePoint = (priceSellMax = 0, priceBuyMax = 0) => {
    return Math.floor(((priceBuyMax + priceSellMax) * 10000) / 2) / 10000;
};

export const optionGenerator = (listSellOrder = [], listBuyOrder = []) => {
    let output = {
        chart: {
            type: "area",
            zoomType: "xy",
        },
        title: {
            text: t1("gold_price_order_depth_chart"),
        },
        xAxis: {
            minPadding: 0,
            maxPadding: 0,
            plotLines: [
                {
                    color: "#888",
                    value: generatePricePoint(
                        listSellOrder.length ? (listSellOrder[0]?.price_per_unit ? Number(listSellOrder[0].price_per_unit) : 0) : 0,
                        listBuyOrder.length ? (listBuyOrder[0]?.price_each_unit ? Number(listBuyOrder[0].price_each_unit) : 0) : 0
                    ),
                    width: 1,
                    label: {
                        text: t1("average_price_match"),
                        rotation: 90,
                    },
                },
            ],
            title: {
                text: t1("gold_price_per_mil"),
            },
        },
        yAxis: [
            {
                lineWidth: 1,
                gridLineWidth: 1,
                title: null,
                tickWidth: 1,
                tickLength: 5,
                tickPosition: "inside",
                labels: {
                    align: "left",
                    x: 8,
                },
            },
            {
                opposite: true,
                linkedTo: 0,
                lineWidth: 1,
                gridLineWidth: 0,
                title: null,
                tickWidth: 1,
                tickLength: 5,
                tickPosition: "inside",
                labels: {
                    align: "right",
                    x: -8,
                },
            },
        ],
        legend: {
            enabled: false,
        },
        plotOptions: {
            area: {
                fillOpacity: 0.2,
                lineWidth: 1,
                step: "center",
            },
        },
        tooltip: {
            headerFormat: `<span style="font-size=10px;">${t1(
                "price_order"
            )}: {point.key}</span><br/>`,
            valueDecimals: 4,
        },
        series: [
            {
                name: t1("sell_order_quantity"),
                data: orderGenerator(listSellOrder),
                color: "#03a7a8",
            },
            {
                name: t1("buy_order_quantity"),
                data: orderGenerator(listBuyOrder),
                color: "#fc5857",
            },
        ],
    };

    return output;
};
