import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const signupLayout = (formComponent) => {
  return (
    <Row gutter={[20, 16]}>
      <Col span={24}>{formComponent?.user_name}</Col>
      <Col span={24}>{formComponent?.email}</Col>
      <Col span={24}>{formComponent?.password}</Col>
      <Col span={24}>{formComponent?.confirm_password}</Col>
      <Col span={24}>{formComponent?.SubmitButton}</Col>
    </Row>
  );
};

export default signupLayout;
