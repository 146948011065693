import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { MENU_LINK_USER_INFO_EDIT } from 'constants/menu-link'

const WHITE_LIST_PAGE = ['/login','/register','/signup'];
const DASHBOARD_PAGE = MENU_LINK_USER_INFO_EDIT;
const LOGIN_PAGE = '/login';

const AuthWrapper = ({isAuthenticated, children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (isAuthenticated){
            if (WHITE_LIST_PAGE.includes(location.pathname)){
                navigate(DASHBOARD_PAGE)
            }
        }else{
            if (!location.pathname.includes('/offer/')) {
                if (!WHITE_LIST_PAGE.includes(location.pathname)){
                    navigate(LOGIN_PAGE);
                }
            }
        }
    },[isAuthenticated])

    return <>{children}</>
}

const mapStatesToProps = (state,props) => {
    return {
        isAuthenticated: state?.auth?.token || false
    }
}

export default connect(mapStatesToProps)(AuthWrapper);