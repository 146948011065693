import {
    SET_ACCOUNT_BALANCE_ACTION,
    SET_SOCKET_OBJECT,
    SET_MENU_INFO_FROM_REQUEST,
    SET_CONTENT_MODAL_ACTION,
    SET_PARAMS_HOOK_ACTION,
    SET_URL_HOOK_ACTION,
    SET_CALLBACK_SUCCESS_ACTION,
    SET_CALLBACK_FAILED_ACTION,
    SET_CHANGE_METHOD_HOOK,
    OPEN_MODAL_ACTION,
    CLOSE_MODAL_ACTION,
    SET_PROFILE_ACTION,
    LOGIN_ACTION,
    LOGOUT_ACTION,
    LOAD_USER_INFO_FROM_LOCAL_STORAGE_ACTION,
    SET_LEFT_MENU_ACTION,
    TOGGLE_LEFT_MENU_ACTION,
    SET_OPEN_SUB_MENU_ACTION,
    SET_DOMAIN_INFO,
    CHANGE_USER_INFO_ON_HEADER,
    SET_BET_COIN_AMOUNT,
    SET_LOAD_STATUS_ACTION,
    TOGGLE_CHAT_PANEL_ACTION,
} from "redux/action-types";

export const loadUserInfoFromLocalStorageAction = () => ({
    type: LOAD_USER_INFO_FROM_LOCAL_STORAGE_ACTION,
    payload: {},
});


//Layout
export const toggleLeftMenuAction = () => ({
    type: TOGGLE_LEFT_MENU_ACTION,
    payload: {},
});

export const setLeftMenuAction = (state) => ({
    type: SET_LEFT_MENU_ACTION,
    payload: { state: state },
});

export const setOpenSubMenuAction = (openKeys) => ({
    type: SET_OPEN_SUB_MENU_ACTION,
    payload: { openKeys },
});

export const changeUserInfoOnHeaderAction = () => ({
    type: CHANGE_USER_INFO_ON_HEADER,
    payload: {},
});

export const setMenuInfoFromRequest = (data) => ({
    type: SET_MENU_INFO_FROM_REQUEST,
    payload: data,
});

export const setLoadStatusAction = (data) => {
    return {
        type: SET_LOAD_STATUS_ACTION,
        payload: data,
    };
};

export const toggleChatPanelAction = () => {
    return {
        type: TOGGLE_CHAT_PANEL_ACTION,
        payload: {},
    };
};

export const setDomainInfoAction = (domainInfo) => ({
    type: SET_DOMAIN_INFO,
    payload: domainInfo,
});

//login
export const loginAction = (tokenData) => ({
    type: LOGIN_ACTION,
    payload: tokenData,
});

export const logoutAction = () => ({
    type: LOGOUT_ACTION,
    payload: {},
});

//profileInfo
export const setProfileAction = (data) => {
    return {
        type: SET_PROFILE_ACTION,
        payload: data,
    };
};

export const setAccountBalanceActions = (data) => {
    return {
        type: SET_ACCOUNT_BALANCE_ACTION,
        payload: data,
    };
};

//Modal
/**
 *
 * @param {*} data
 * form {
 *      id: 'modal_no_1',
 *      visible: true
 * }
 */
export const openModalAction = (data) => {
    return {
        type: OPEN_MODAL_ACTION,
        payload: data,
    };
};

export const closeModalAction = (data) => {
    return {
        type: CLOSE_MODAL_ACTION,
        payload: data,
    };
};

export const setContentModalAction = (data) => {
    return {
        type: SET_CONTENT_MODAL_ACTION,
        payload: data,
    };
};

//Request methods

export const setParamsHookAction = (hook) => {
    return {
        type: SET_PARAMS_HOOK_ACTION,
        payload: hook,
    };
};

export const setUrlHookAction = (hook) => {
    return {
        type: SET_URL_HOOK_ACTION,
        payload: hook,
    };
};

export const setCallbackSuccessAction = (hook) => {
    return {
        type: SET_CALLBACK_SUCCESS_ACTION,
        payload: hook,
    };
};

export const setCallbackFailedAction = (hook) => {
    return {
        type: SET_CALLBACK_FAILED_ACTION,
        payload: hook,
    };
};

export const setChangeMethodHook = (hook) => {
    return {
        type: SET_CHANGE_METHOD_HOOK,
        payload: hook,
    };
};

//Socket Methods --------------------------------
export const setSocketObject = (socket) => {
    return {
        type: SET_SOCKET_OBJECT,
        payload: socket,
    };
};

//Game Methods
export const setBetCoinAmount = (betCoinAmount) => {
    return {
        type: SET_BET_COIN_AMOUNT,
        payload: betCoinAmount
    }
    
}
