import React from "react";
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';

const { Text } = Typography;
const PasswordFieldAntd = ({input, meta, errorText = {}, ...rest}) => {
    return <div>
      <Input.Password
        {...input}
        {...rest}
        status={meta.error && meta.touched ? 'error' : ''}
        onFocus={(e) => input.onChange(e?.target?.value)}
        onBlur={(e) => {
          input.onChange(e?.target?.value);
        }}
      />
      {meta.error && meta.touched ? (
        <Text type="danger" style={{ float: 'left' }}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : (
        ''
      )}
    </div>
}

export default PasswordFieldAntd;
