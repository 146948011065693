import React from 'react';
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';
import { Popover, Tooltip } from 'antd';
import { Field } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import ReviewDataFieldAntd from './ReviewDataField';
import ReportFieldAntd from './ReportFieldAntd';
import TextFieldAntd from './TextFieldAntd';
import TextAreaFieldAntd from './TextAreaFieldAntd';
import PasswordFieldAntd from './PasswordFieldAntd';
import NumberFieldAntd from './NumberFieldAntd';
import RadioFieldAntd from './RadioFieldAntd';
import CheckboxFieldAntd from './CheckboxFieldAntd';
import DatePickerFieldAntd from './DatePickerFieldAntd';
import RangePickerFieldAntd from './RangePickerFieldAntd';
import TimePickerFieldAntd from './TimePickerFieldAntd';
import TimeRangePickerFieldAntd from './TimeRangePickerFieldAntd';
import RateFieldAntd from './RateFieldAntd';
import SwitchFieldAntd from './SwitchFieldAntd';
import SelectFieldAntd from './SelectFieldAntd';
import SelectFieldAsyncAntd from './SelectFieldAsyncAntd';
import UploadFieldAntd from './UploadFieldAntd';
import DropdownFieldAntd from './DropdownFieldAntd';
import { HeartOutlined } from '@ant-design/icons';
import Button from 'antd/lib/button';
import DefaultLayout from '../core/DefaultLayout';
import DefaultLayoutReview from '../core/DefaultLayoutReview';
import styles from './styles.module.scss';
import { isMobile } from 'react-device-detect';
import { useNavigate  } from 'react-router-dom';
import { modifySchemaArrayChildReviewField } from '../utils';

const defaultAllow = () => undefined;

const tooltipIcon = (labelIcon = null, tooltip = undefined) => {
  if (labelIcon && labelIcon.icon && labelIcon.tooltip) {
    return (
      <Popover
        placement={isMobile ? 'top' : 'rightTop'}
        content={<span style={{ whiteSpace: 'pre-line' }}>{labelIcon.tooltip}</span>}
      >
        <img src={labelIcon?.icon} style={{ marginLeft: 4, width: isMobile ? '23px' : '20px' }} />
      </Popover>
    );
  } else if (labelIcon && labelIcon.icon) {
    return <img src={labelIcon?.icon} style={{ marginLeft: 4, width: isMobile ? '23px' : '20px' }} />;
  } else if (tooltip) {
    return (
      <Popover
        placement={isMobile ? 'top' : 'rightTop'}
        content={<span style={{ whiteSpace: 'pre-line' }}>{tooltip}</span>}
      >
        <img src="/assets/icons/info-icon.svg" style={{ marginLeft: 4, width: isMobile ? '23px' : '20px' }} />
      </Popover>
    );
  }
  return <></>;
};

const BaseLayoutField = ({ form, fieldData, arrayMutators = {}, values = {} }) => {
  if (fieldData?.modifySchema && typeof fieldData?.modifySchema === 'function') {
    fieldData = fieldData.modifySchema(values, fieldData);
  }
  const history = useNavigate();
  const currentPath = 'data';
  
  switch (fieldData.type) {
    case 'review_data':
      return (
        <Row gutter={16}>
          {fieldData?.hideLabel ? <></> : <Col span={24} md={8}>
            <b>{fieldData.label}</b>
          </Col>}
          <Col span={24} md={fieldData?.hideLabel ? 24 : 16}>
            <Field
              name={fieldData.name}
              component={ReviewDataFieldAntd}
              style={fieldData.style || {}}
              valuesData={values || {}}
              value={fieldData?.value}
              reviewRender={fieldData?.reviewRender || undefined}
              oldType={fieldData?.old_type || undefined}
              schemaIn={fieldData || undefined}
            />
          </Col>
        </Row>
      );
    case 'link':
      return (
        <Row gutter={16}>
          <Col span={24}>
            <div dangerouslySetInnerHTML={{ __html: fieldData.label }}></div>
          </Col>
        </Row>
      );
    case 'report':
      return (
        <Row gutter={16}>
          <Col span={8}>{fieldData.label}</Col>
          <Col span={16}>
            <Field
              name={fieldData.name}
              component={ReportFieldAntd}
              style={fieldData.style || {}}
              valuesData={values || {}}
            />
          </Col>
        </Row>
      );
    case 'text':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={TextFieldAntd}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              suffix={fieldData.suffix || undefined}
              style={fieldData.style || {}}
              size={fieldData?.size || 'normal'}
              maxLength={fieldData?.maxLength || undefined}
              regexPattern={fieldData?.regexPattern || undefined}
              note={fieldData.note || undefined}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              defaultValue={fieldData.defaultValue || undefined}
              placeholder={fieldData.placeholder !== undefined ? fieldData.placeholder : fieldData.label}
              valuesData={values || {}}
              textNote={fieldData.textNote || undefined}
              renderFields={fieldData.renderFields || undefined}
              onFocus={fieldData.onFocus || undefined}
              onBlur={fieldData.onBlur || undefined}
              key={fieldData?.key || undefined}
              value={fieldData?.value}
              handleInputChange={fieldData.handleInputChange || undefined}
              forceAutoFill={fieldData.forceAutoFill || undefined}
            />
          </Col>
        </Row>
      );
    case 'textarea':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={TextAreaFieldAntd}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              allowClear={fieldData.allowClear || false}
              autoSize={fieldData.autoSize || true}
              showCount={fieldData.showCount || false}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              defaultValue={fieldData.defaultValue || undefined}
              placeholder={fieldData.placeholder !== undefined ? fieldData.placeholder : fieldData.label}
              valuesData={values || {}}
              textNote={fieldData.textNote || undefined}
              renderFields={fieldData.renderFields || undefined}
              key={fieldData?.key}
              forceAutoFill={fieldData.forceAutoFill || undefined}
            />
          </Col>
        </Row>
      );
    case 'password':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={PasswordFieldAntd}
              size={fieldData?.size || 'normal'}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              placeholder={fieldData.placeholder !== undefined ? fieldData.placeholder : fieldData.label}
              valuesData={values || {}}
            />
          </Col>
        </Row>
      );
    case 'number':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={NumberFieldAntd}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              suffix={fieldData.suffix || undefined}
              size={fieldData?.size || 'normal'}
              maxLength={fieldData?.maxLength || undefined}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              placeholder={fieldData.placeholder !== undefined ? fieldData.placeholder : fieldData.label}
              valuesData={values || {}}
              renderFields={fieldData.renderFields || undefined}
              onFocus={fieldData.onFocus || undefined}
              onBlur={fieldData.onBlur || undefined}
              min={fieldData.min}
              max={fieldData.max || undefined}
              step={fieldData.step || 1}
              addonAfter={fieldData.addonAfter || undefined}
              regexPattern={fieldData?.regexPattern || undefined}
              handleInputChange={fieldData?.handleInputChange || undefined}
            />
          </Col>
        </Row>
      );
    case 'radio':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={RadioFieldAntd}
              options={fieldData.options || []}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              buttonStyle={fieldData.buttonStyle}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              onChange={fieldData.onChange}
              renderFields={fieldData.renderFields || undefined}
            />
          </Col>
        </Row>
      );
    case 'checkbox':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={CheckboxFieldAntd}
              mode={fieldData.mode || 'single'}
              option={fieldData.option || {}}
              options={fieldData.options || []}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
            />
          </Col>
        </Row>
      );
    case 'datepicker':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={DatePickerFieldAntd}
              form={form}
              dateFormat={fieldData.dateFormat}
              picker={fieldData.picker}
              format={fieldData.format}
              disabledDate={fieldData.disabledDate || undefined}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              size={fieldData?.size || 'normal'}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              placeholder={fieldData.placeholder || fieldData.dateFormat || fieldData.label}
              onChange={fieldData.onChange || undefined}
              renderFields={fieldData.renderFields || undefined}
              key={fieldData.key || undefined}
              forceAutoFill={fieldData.forceAutoFill || undefined}
              keyData={fieldData.keyData || undefined} //use to auto clear old value
            />
          </Col>
        </Row>
      );
    case 'monthpicker':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={DatePickerFieldAntd}
              form={form}
              dateFormat={fieldData.dateFormat}
              picker={fieldData.picker}
              format={fieldData.format}
              disabledDate={fieldData.disabledDate || undefined}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              size={fieldData?.size || 'normal'}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              placeholder={fieldData.placeholder || fieldData.dateFormat || fieldData.label}
              onChange={fieldData.onChange || undefined}
              renderFields={fieldData.renderFields || undefined}
              key={fieldData.key || undefined}
              forceAutoFill={fieldData.forceAutoFill || undefined}
            />
          </Col>
        </Row>
      );
    case 'rangepicker':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={RangePickerFieldAntd}
              format={fieldData.format}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              size={fieldData?.size || 'normal'}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              placeholder={fieldData.placeholder || [fieldData.label, fieldData.label]}
              renderFields={fieldData.renderFields || undefined}
            />
          </Col>
        </Row>
      );
    case 'timepicker':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={TimePickerFieldAntd}
              timeFormat={fieldData.format || 'HH:mm'}
              disabledTime={fieldData.disabledTime || null}
              hourStep={fieldData.hourStep || 1}
              minuteStep={fieldData.minuteStep || 1}
              allowClear={fieldData.allowClear || true}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              size={fieldData?.size || 'normal'}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              placeholder={fieldData.placeholder || fieldData.timeFormat || fieldData.label}
              className={fieldData.className || ''}
              popupClassName={fieldData.popupClassName || ''}
            />
          </Col>
        </Row>
      );
    case 'time_rangepicker':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={TimeRangePickerFieldAntd}
              timeFormat={fieldData.timeFormat}
              allowClear={fieldData.allowClear || true}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              size={fieldData?.size || 'normal'}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              placeholder={fieldData.placeholder || undefined}
              disabledTime={fieldData.disabledTime || null}
              hourStep={fieldData.hourStep || 1}
              minuteStep={fieldData.minuteStep || 1}
              className={fieldData.className || ''}
              popupClassName={fieldData.popupClassName || ''}
              textNote={fieldData.textNote || undefined}
            />
          </Col>
        </Row>
      );
    case 'rate':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={RateFieldAntd}
              count={fieldData.count || 5}
              allowHalf={fieldData.allowHalf || false}
              character={fieldData.character || <HeartOutlined />}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
            />
          </Col>
        </Row>
      );
    case 'switch':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={SwitchFieldAntd}
              size={fieldData.size || 'default'}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
            />
          </Col>
        </Row>
      );
    case 'select':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {/* TODO: check text note for select field of create request kehi */}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={SelectFieldAntd}
              mode={fieldData.mode || 'single'}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              placeholder={fieldData.placeholder ? fieldData.placeholder : fieldData.label || undefined}
              loading={fieldData.loading || false}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              showSearch={fieldData.showSearch || false}
              allowClear={fieldData.allowClear || false}
              filterOption={fieldData.filterOption || false}
              options={fieldData.options || []}
              async={fieldData.async || false}
              size={fieldData?.size || 'normal'}
              maxLength={fieldData.maxLength || undefined}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              asyncOptions={
                fieldData.asyncOptions || {
                  endpoints: '',
                  params: '',
                  transformDataToOptions: (dataOutput) => dataOutput,
                }
              }
              className={fieldData.className || ''}
              forceAutoFill={fieldData.forceAutoFill || undefined}
            />
          </Col>
        </Row>
      );
    case 'select_async':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={SelectFieldAsyncAntd}
              mode={fieldData.mode || 'single'}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              filterOption={fieldData.filterOption || false}
              loading={fieldData.loading || false}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              showSearch={fieldData.showSearch || false}
              allowClear={fieldData.allowClear || false}
              options={fieldData.options || undefined}
              async={fieldData.async || false}
              size={fieldData.size || 'normal'}
              maxLength={fieldData.maxLength || undefined}
              regexPattern={fieldData?.regexPattern || undefined}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              asyncOptions={
                fieldData.asyncOptions || {
                  endpoints: '',
                  params: '',
                  method: 'get',
                  transformDataToOptions: (dataOutput) => dataOutput,
                  transformDataWhenFail: (dataOutput) => dataOutput,
                }
              }
              placeholder={fieldData.placeholder ? fieldData.placeholder : fieldData.label || undefined}
              key={fieldData.key || 766453}
              onBlur={fieldData.onBlur || undefined}
              className={fieldData.className || ''}
              renderFields={fieldData.renderFields || undefined}
              forceAutoFill={fieldData.forceAutoFill || undefined}
            />
          </Col>
        </Row>
      );
    case 'upload':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={`${styles.label} ${fieldData.classNameLabel}`}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={UploadFieldAntd}
              multi={fieldData.multiple}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              uploadSettings={fieldData.uploadSettings || {}}
              progress={fieldData.progress || {}}
              maxCount={fieldData.maxCount || 1}
              beforeUpload={fieldData.beforeUpload}
              handleAfterUpload={fieldData.handleAfterUpload}
              UploadButton={fieldData.UploadButton || <Button>Upload</Button>}
              onRemove={fieldData.onRemove}
              hideUploadWhenMaxCount={fieldData.hideUploadWhenMaxCount}
              convertDefaultFile={fieldData.convertDefaultFile}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              showUploadList={fieldData?.showUploadList || true}
              customFormList={fieldData?.customFormList || false}
              key={fieldData.key || 0}
              textNote={fieldData.textNote || undefined}
              className={fieldData.className || ''}
              accept={fieldData.accept || ''}
              forceUnListFile={fieldData?.forceUnListFile || false}
            />
          </Col>
        </Row>
      );
    case 'image-dropdown':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={`${styles.label} ${fieldData.classNameLabel}`}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
                {tooltipIcon(fieldData.labelIcon, fieldData.tooltip)}
              </h4>
            )}
            <Field
              name={fieldData.name}
              component={DropdownFieldAntd}
              validate={typeof fieldData.validate === 'function' ? fieldData.validate : defaultAllow}
              hintText={fieldData.label}
              label={fieldData.label}
              style={fieldData.style || {}}
              errorText={fieldData.errorText || {}}
              disabled={fieldData.disabled || false}
              valuesData={values || {}}
              showUploadList={fieldData?.showUploadList || false}
              customFormList={fieldData?.customFormList || false}
              key={fieldData.key || 0}
              textNote={fieldData.textNote || undefined}
              className={fieldData.className || ''}
              items={fieldData?.items || []}
              dropdownRender={fieldData?.dropdownRender || ((e) => {console.log(e)})}
              renderField={fieldData?.renderField || ((e) => <></>)}
              placement={fieldData?.placement || 'bottom'}
              customOpenButton={fieldData?.customOpenButton || <></>}
            />
          </Col>
        </Row>
      );
    case 'array':
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            {fieldData.hideLabel ? (
              <></>
            ) : (
              <h4 className={styles.label}>
                {fieldData.label}
                {fieldData.labelRequired && <label className="label-required"></label>}
              </h4>
            )}
            <FieldArray name={fieldData.name}>
              {({ fields }) => {
                if (fieldData?.autoFill && fields.value === undefined) {
                  // When render this function will render twice and it is async function so if do not use pop function, data will be push twice
                  fields.pop();
                  fields.push(fieldData?.initialValues || {});
                }

                return (
                  <>
                    {fields.map((name, index) => {
                      var ComponentsArrayObject = {};
                      Object.keys(fieldData.children).map((fieldArrayChild, indexChild) => {
                        var fieldDataChild = fieldData.children[fieldArrayChild];
                        fieldDataChild['name'] = name + '.' + fieldArrayChild;
                        if (fieldData.hideLabelFromSecondRow !== undefined) {
                          if (fieldData.hideLabelFromSecondRow && index > 0) {
                            fieldDataChild['hideLabel'] = true;
                          } else {
                            fieldDataChild['hideLabel'] = false;
                          }
                        }

                        //this will be use to modify schema of children, this will have highest priority because it set for children when rendering it
                        if (typeof fieldData.modifyChildSchema === 'function') {
                          fieldDataChild = fieldData.modifyChildSchema(
                            fieldDataChild,
                            { fields, name, index },
                            fieldArrayChild
                          );
                        }

                        var FieldComponent = BaseLayoutField({
                          fieldData: {
                            disabled: fieldData.disabledChild,
                            ...fieldDataChild,
                            renderFields: fieldData.renderFields || undefined,
                          },
                          arrayMutators: arrayMutators,
                          values: values,
                        });

                        ComponentsArrayObject[fieldArrayChild] = FieldComponent;
                        //return FieldComponent;
                      });

                      if (!fieldData.disabled) {
                        if (typeof fieldData.RemoveButton === 'function') {
                          ComponentsArrayObject['RemoveButton'] = fieldData.RemoveButton(
                            () => fields.remove(index),
                            index
                          );
                        } else {
                          ComponentsArrayObject['RemoveButton'] = (
                            <span onClick={() => fields.remove(index)} style={{ cursor: 'pointer' }}>
                              ❌
                            </span>
                          );
                        }

                        //hide remove button if this is the only one value
                        if (fields.length === 1) {
                          ComponentsArrayObject['RemoveButton'] = <></>;
                        }
                      }
                      return (
                        <div key={name} className="form-array-item">
                          {fieldData.showLabel ? (
                            <label>
                              {fieldData.name} #{index + 1}
                            </label>
                          ) : (
                            <></>
                          )}
                          {typeof fieldData.layout === 'function'
                            ? fieldData.layout(ComponentsArrayObject, index, fields)
                            : DefaultLayout(ComponentsArrayObject)}

                          {ComponentsArrayObject['RemoveButton'] && (
                            <div
                              className="icon-remove"
                              style={{
                                position: 'absolute',
                                left: 0,
                                bottom: 8,
                                width: '100%',
                              }}
                            >
                              {ComponentsArrayObject['RemoveButton']}
                            </div>
                          )}
                        </div>
                      );
                    })}
                    {fieldData.disabled || fields.length === fieldData.maxLengthItem ? (
                      <></>
                    ) : typeof fieldData.AddButton === 'function' ? (
                      fieldData.AddButton(() => fields.push(fieldData?.initialValues || undefined), fields?.length)
                    ) : (
                      <Button type="button" onClick={() => fields.push(fieldData?.initialValues || undefined)}>
                        Add Child Array
                      </Button>
                    )}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
      );
    case 'array_review_data':
      return (
        <Row gutter={16}>
          <Col span={24} md={8}>
            <b>{fieldData.label}</b>
          </Col>
          <Col span={24} md={16}>
            <FieldArray name={fieldData.name}>
              {({ fields }) => {
                let modifiedSchema = modifySchemaArrayChildReviewField(fieldData);

                return (
                  <>
                    {fields.map((name, index) => {
                      var ComponentsArrayObject = {};
                      Object.keys(modifiedSchema.children).map((fieldArrayChild, indexChild) => {
                        var fieldDataChild = modifiedSchema.children[fieldArrayChild];
                        fieldDataChild['name'] = name + '.' + fieldArrayChild;

                        var FieldComponent = BaseLayoutField({
                          fieldData: {
                            ...fieldDataChild,
                          },
                          arrayMutators: arrayMutators,
                          values: values,
                        });

                        ComponentsArrayObject[fieldArrayChild] = FieldComponent;
                      });

                      return (
                        <Row gutter={[2,2]}>
                          <Col span={24} md={24} style={{marginBottom: '10px'}}>
                            {typeof fieldData.layoutReview === 'function'
                              ? fieldData.layoutReview(ComponentsArrayObject, index, fields)
                              : DefaultLayoutReview(modifiedSchema)(ComponentsArrayObject)}
                          </Col>
                        </Row>
                      );
                    })}
                  </>
                );
              }}
            </FieldArray>
          </Col>
        </Row>
      );
    default:
      return (
        <Row gutter={16}>
          <Col className="gutter-row" span={24}>
            <h4 className={styles.label}>{fieldData.label}</h4>
          </Col>
        </Row>
      );
  }
};

export default BaseLayoutField;
