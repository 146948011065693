import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Table from "antd/lib/table";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import notification from 'antd/lib/notification';
import Pagination from "antd/lib/pagination";
import Tabs from "antd/lib/tabs";
import FormCreator from "commons/final-form/core/FormCreator";
import schema from "./schema-form/schema";
import ui from "./schema-form/ui";
import layout from "./schema-form/layout";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import Typography from "antd/lib/typography";
import MonsterInfo from './components/MonsterInfo'
import Select from "antd/lib/select";

const { Title, Paragraph, Text, Link } = Typography;

//This will list all the item that are selling on the market, allow user to make a deal here
const QuestInfoLayout = ({profile}) => {
	const location = useLocation();

    const [monsterList, setMonsterList] = useState([]);
    const [monsterSelectedIndex, setMonsterSelectedIndex] = useState(0);

    const [dataMonster, setEndpointMonster, setParamsMonster] = useFetch(
		endpoints.object_list,
		{
            code_include: 'MOB_TQ_',
            object_type: 1
        },
		"GET",
		(result, params) => {
            if (result?.data){
                const getMonsterList = result?.data?.object.sort((a,b) => (parseInt(a.level) > parseInt(b.level)) ? 1 : -1);
                setMonsterList(getMonsterList);
                if (getMonsterList?.length > 0) {
                    setMonsterSelectedIndex(0);
                }
            }
		},
		(result, params) => {
            console.log({fail: result})
		}
	);

    const onSubmit = (dataValue) => {
        setParamsMonster('');
        setParamsMonster({
            ...dataValue,
            object_type: 1
        });
    }

	return (
		<PageLayout breadcrumList={[{ title: t1("dashboard"), link: '/dashboard' },{ title: t1("quest_info_tool"), link: location.pathname }]}>
            <Row gutter={[8, 8]}>
                <Col span={24} className="mt-2">
                    <Card title={null} style={{ borderRadius: "5px"}}>
                        <Row gutter={[16, 32]}>
                            <Col span={24}>
                                <FormCreator initialValues={{
                                    code_include: 'MOB_TQ_'
                                }} schema={schema} ui={ui()} layout={layout} onSubmit={onSubmit} SubmitButton={
                                    <button className="btn btn-primary" type="submit">
                                        {t1('search')}
                                    </button>
                                }/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title={null}>
                        <Row gutter={[8, 8]}>
                            <Col span={24}>
                                <Select
                                    style={{width: '100%', marginBottom: '20px'}}
                                    defaultValue={monsterSelectedIndex}
                                    options={
                                        monsterList.map((monster, i) => {
                                            return {
                                                label: <p style={{marginBottom: 0}}><b>{t1('lv %s',[monster['level']])}</b><span className="ms-1">{monster?.object_name?.vietnamese ?? monster?.code}</span></p>,
                                                value: i,
                                            }
                                        })
                                    }
                                    onChange={(e) => setMonsterSelectedIndex(e)}
                                />
                                {monsterSelectedIndex !== -1 && monsterList.length > 0 &&  <MonsterInfo monster={monsterList[monsterSelectedIndex]}/>}
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
		</PageLayout>
	);
}

const mapStateToProps = (state,props) => {
	return {
		profile: state?.profile
	}
}

export default connect(mapStateToProps)(QuestInfoLayout);
