import React, { useState, useEffect } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from "translate";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import notification from "antd/lib/notification";
import { useLocation, useNavigate } from "react-router-dom";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { SERVER_LIST } from "constants/game";
import {
    getImageLinkFromItemModel,
    getItemType,
  } from "components/ItemDetail/utils";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import Typography from "antd/lib/typography";
import Button from "antd/lib/button";
import DetailContent from "components/ItemDetail/DetailContent";
import dayjs from "dayjs";
import Modal from 'antd/lib/modal';
import { EyeOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

const OfferTradeLayout = (props) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [fetchData, setFetchData] = useState(false);
    const [itemDetail, setItemDetail] = useState(null);
    const [price, setPrice] = useState(null);
    const [offerId, setOfferId] = useState(null);
    const [itemType, setItemType] = useState({});
    const [rareType, setRareType] = useState("");
    const [paramsFilter, setParamsFilter] = useState({
        server_id: 3
    });
    const [showModal, setShowModal] = useState(false);
    const [showItemModal, setShowItemModal] = useState(false);

    const [offerData, setEndpointGetOffer, setParamsGetOffer] = useFetch(
        endpoints.public_offer,
        {},
        "GET",
        (result, params) => {
            if (fetchData) {
                if (result?.item) {
                    setItemDetail(result?.item);
                    setPrice(result?.price);
                } else {
                    notification.warning({
                        message: t1('warning_message'),
                        description: result?.message
                    })
                    setOfferId(null);
                    return navigate('/market');
                }
            }
        },
        (result, params) => {
            if (fetchData) {
                if (result?.item) {
                    setItemDetail(result?.item);
                    setPrice(result?.price);

                    // Get Item Type
                    const itemTypeData = getItemType(
                        result?.item?.item_model_info,
                        result?.item?.item_info
                    );
                    setItemType(itemTypeData);

                    // Get Rare Type
                    let rareType = "";
                    if (itemTypeData?.type === ITEM_RARITY.RARE){
                      if (result?.item?.item_model_info?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOS}_RARE`)){
                        rareType = 'SOS';
                      }else{
                        if (result?.item?.item_model_info?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOM}_RARE`)){
                          rareType = "SOM";
                        }else{
                          rareType = "SUN";
                        }
                      }
                    }
                    setRareType(rareType);
                } else {
                    notification.warning({
                        message: t1('warning_message'),
                        description: result?.message
                    })
                    setOfferId(null);
                    return navigate('/market');
                }
            }
        }
    );

    useEffect(() => {
        const offers = location.pathname.replace('/offer-trade/', '');
        const getOfferId = offers.split('-')[0];
        if (!!getOfferId) {
            setParamsGetOffer('');
            setParamsGetOffer({id: getOfferId})
            setFetchData(true);
            setOfferId(getOfferId);
        } else {
            notification.warning({
                message: t1('warning_message'),
                description: t1('item_is_not_exist')
            })
            return navigate('/market');
        }
    }, [location, offerId]);

    let isLock = itemDetail?.status === "lock";

    let fullItemName = itemDetail?.item_info?.plus && parseInt(itemDetail?.item_info?.plus) > 0 ? `${itemDetail?.item_model_info?.name?.vi} (+${itemDetail?.item_info?.plus})` : itemDetail?.item_model_info?.name?.vi;

    if (fullItemName?.length > 1){
      
    }else{
      fullItemName = t1('no_name_item');
    }
  
    if (itemType?.type == ITEM_RARITY.RARE){
      fullItemName = `[${rareType}] ${fullItemName}`;
    }
  
    let quantity = 1;
  
    if (itemDetail?.item_info?.count){
      quantity = itemDetail?.item_info?.count;
    }

    const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

    const renderItemStatus = (itemData) => {
        switch (itemData?.status){
            case 'lock':
                return <span style={{color: '#d3f261'}}>{t1(`status_${itemData?.status}`)}</span>
            case 'active':
                return <span style={{color: '#389e0d'}}>{t1(`status_${itemData?.status}`)}</span>
            case 'inactive':
                return <span style={{color: '#ff7875'}}>{t1(`status_${itemData?.status}`)}</span>
            case 'selling':
                return <span style={{color: '#85a5ff'}}>{t1(`status_${itemData?.status}`)}</span>
            default:
                return <></>
        }
    }

    const [data, setEndpoint, setParams] = useFetch(
		endpoints.purchase_item,
		'',
		"POST",
		(result, params) => {
            setParamsGetOffer('');
            setParamsGetOffer({
                submit: 1, page: 1, limit: 10, ...paramsFilter
            });
            notification.success({
                message: t1('success_message'),
                description: t1('buy_item_success'),
            });
		},
		(result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

    const handlePurchase = () => {
        setParams('');
        setParams({
            item_id: itemDetail?.id,
            open_price_id: price?.id,
            quantity,
        })
        setShowModal(false);
    }

    const renderItemImage = () => {
        return (
            <div style={{marginBottom: '10px'}}>
                <img
                    src={getImageLinkFromItemModel(
                        itemDetail?.item_model_info
                    )}
                    alt={itemDetail?.item_model_info?.name?.vi}
                    style={{ width: "100%" }}
                    onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        if (currentTarget.src === getImageLinkFromItemModel(itemDetail?.item_model_info)) {
                            currentTarget.src =getImageLinkFromItemModel(itemDetail?.item_model_info, true);
                        }
                    }}
                />
                {itemType?.type === ITEM_RARITY.RARE ? (
                    <img
                        className="rare-star ps-1"
                        style={{ width: "100%" }}
                        src="https://sro.money/images/rare_star.gif"
                        alt={itemDetail?.item_model_info?.name?.vi}
                    />
                ) : (
                    <></>
                )}
            </div>
        );
    };

    return <PageLayout>
        <Col span={24}>
            <Card title={t1('offer_trade_layout')} style={{ borderRadius: "5px"}}>
				<Row gutter={[16, 8]}>
                    <Col span={24}>
                        <Typography>
                            <Title
                                ellipsis={{ rows: 2}}
                                level={5}
                                className={`m-0 item-name-${itemType?.type} reverse`}
                            >
                                {(itemDetail?.item_info?.plus && parseInt(itemDetail?.item_info?.plus) > 0) ? `${itemDetail?.item_model_info?.name?.vi} (+${itemDetail?.item_info?.plus})` : itemDetail?.item_model_info?.name?.vi}
                            </Title>
                        </Typography>
                    </Col>
                    <Col span={9} style={{textAlign: 'center'}}>
                        {renderItemImage()}
                        {itemDetail && (
                            <Button
                                icon={<EyeOutlined />}
                                onClick={() => setShowItemModal(true)}
                                style={{width: '60%'}}
                                size="small"
                            />
                        )}
                    </Col>
                    <Col span={15}>
                        <Typography>
                            <Text style={{fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                {t1("server_:_%s", [getServer(itemDetail?.server_id)])}
                            </Text>
                            <br/>
                            <Text style={{fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                {t1("bot_id_:_%s", [itemDetail?.bot_id])}
                            </Text>
                            <br/>
                            <Text style={{fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                {t1("seller_name_:_%s", [itemDetail?.last_owner?.username])}
                            </Text>
                            <br/>
                            <Text style={{fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>{t1("status_:_")}</Text>
                            {renderItemStatus(itemDetail)}
                            <br/>
                            {price?.created_at && (
                                <>
                                    <Text style={{fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                        {t1("sell_at:_%s", [dayjs(price.created_at).format('DD/MM/YYYY')])}
                                    </Text>
                                    <br/>
                                </>
                            )}
                            <br/>
                            <Text style={{fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>
                                {t1("quantity_:_%s", [quantity])}
                            </Text>
                            <br/>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Text style={{marginRight: '3px', fontSize: '13px', whiteSpace: 'nowrap', fontWeight: 'bold'}}>{t1("price_:_%s", [parseInt(price?.price) || 0])}</Text>
                                {price?.concurrency === 0
                                    ? <img src="/cashicon.png" alt="sro money point icon" style={{width:"24px"}}/>
                                    : <img src="/goldicon.png" alt="sro money gold icon" style={{width:"18px"}}/>
                                }
                            </div>
                        </Typography>
                        {price && (
                            <Button
                                type="primary"
                                onClick={() => setShowModal(true)}
                                style={{marginTop: '10px', padding: '0px 50px'}}
                            >
                                {t1('buy')}
                            </Button>
                        )}
                    </Col>
                </Row>
            </Card>
        </Col>
        <Modal
            title={t1('confirm_purchase')}
            open={showModal}
            onOk={handlePurchase}
            onCancel={(e) => setShowModal(false)}
        >
            <p>{t1('click_ok_to_purchase_item_to_your_inventory')}</p>
        </Modal>
        <Modal
            title={''}
            open={showItemModal}
            footer={null}
            onCancel={(e) => setShowItemModal(false)}
        >
            {itemDetail && (
                <DetailContent
                    itemType={itemType}
                    itemModel={itemDetail?.item_model_info}
                    itemInfo={itemDetail?.item_info}
                    rareType={rareType}
                />
            )}
        </Modal>
    </PageLayout>
}

export default OfferTradeLayout;
