import React from 'react';
import Typography from "antd/lib/typography";
import { t1 } from 'translate';

const { Title, Paragraph, Text } = Typography;

const MAPPING_BLUE = [
    {
        id: 0,
        is_allow_input: true,
        name: (input) => t1('unknow_blue_info_%s',[input])
    },
    {
        id: 5,
        is_allow_input: true,
        name: (input) => t1('inteligent_%s_increase',[input])
    },
    {
        id: 6,
        name: t1('immortal_%s_times',[6])
    },
    {
        id: 11,
        is_allow_input: true,
        name: (input) => t1('strength_%s_increase',[input])
    },
    {
        id: 29,
        is_allow_input: true,
        name: (input) => t1('burning_%s_increase',[input + "%"])
    },
    {
        id: 32,
        name: t1('steady_(%s)',[4])
    },{
        id: 80,
        is_allow_input: true,
        name: (input) => t1('durability_%s_increase',[input + "%"])
    },{
        id: 92,
        is_allow_input: true,
        name: (input) => t1('attack_rate_%s_increase',[input + "%"])
    },
    {
        id: 98,
        is_allow_input: true,
        name: (input) => t1('attack_rate_%s_increase',[input + "%"])
    },
    {
        id: 110,
        is_allow_input: true,
        name: (input) => t1('bypass_block_%s',[input])
    },
    {
        id: 128,
        is_allow_input: true,
        name: (input) => t1('defense_rate_%s',[input + "%"])
    },
    {
        id: 134,
        is_allow_input: true,
        name: (input) => t1('defense_rate_%s_increase',[input + "%"])
    },{
        id: 146,
        is_allow_input: true,
        name: (input) => t1('HP_%s_increase',[input])
    },{
        id: 152,
        is_allow_input: true,
        name: (input) => t1('MP_%s_increase',[input])
    },{
        id: 158,
        is_allow_input: true,
        name: (input) => t1('MP_%s_increase',[input])
    },{
        id: 320,
        name: t1('lucky_(%s)',[5])
    }
]


const BlueDetail = ({blueList = []}) => {
    return <Paragraph className="item-name-blue reverse">
        {blueList.map((blueInfo) => {
            var blueTemplate = MAPPING_BLUE.find((blueBase) => blueBase.id == blueInfo.blue_id);

            if (!blueTemplate){
                return <>
                    {t1('unknow_blue_id_%s_with_value_%s',[blueInfo.blue_id,blueInfo.blue_value])}
                    <br/>
                </>
            }else{
                return <>
                    {blueTemplate?.is_allow_input ? blueTemplate?.name(blueInfo?.blue_value) : blueTemplate?.name}
                    <br/>
                </>
            }
        })}
    </Paragraph>
}

export default BlueDetail;