import React from 'react';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';

const { Text } = Typography;

const ReportFieldAntd = ({ input, meta, errorText = {}, ...rest }) => {
  return <div className={styles.textField}>{input?.value}</div>;
};

export default ReportFieldAntd;
