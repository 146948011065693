import React from "react";
import PreLoginLayout from "components/PageLayout/PreloginLayout";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import message from "antd/lib/message";
import { t1 } from "translate";
import FormCreator from "commons/final-form/core/FormCreator";
import registerLayout from "schema-form/layout/registerLayout";
import registerSchema from "schema-form/schema/registerSchema";
import registerUI from "schema-form/ui/registerUI";
import Button from "antd/lib/button";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { loginAction } from "redux/actions";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const RegisterLayout = ({ dispatch }) => {
  const navigate = useNavigate();
    const [messageApi, contextHolder] =
        message.useMessage();

    const [data, UpEnpoints, UpParams] = useFetch(
        endpoints.register,
        "",
        "post",
        (result, params) => {
            message.success("Signup successful");
            navigate('/login');
        },
        (result, params) => {
            if (!result?.success) {
                message.error(result?.message || t1('register_new_account_failed'));
            } else {
                message.error(t1('register_new_account_failed'));
            }
        }
    );

    return (
        <PreLoginLayout>
            <Row>
                <Col
                    span={24}
                    md={{ span: 8, offset: 8 }}
                    xs={{ span: 22, offset: 1 }}
                >
                    <Card
                        title={t1("register_page")}
                        bordered={true}
                        style={{
                            width: "100%",
                            marginTop: "70px",
                        }}
                    >
                        <FormCreator
                            debugMode={false}
                            schema={registerSchema}
                            ui={registerUI}
                            layout={registerLayout}
                            onSubmit={(value) => {
                                if (
                                    value?.password !==
                                    value?.confirm_password
                                ) {
                                    message.error(t1('password_must_be_identical'));
                                } else {
                                    UpParams("");
                                    UpParams(value);
                                }
                            }}
                            SubmitButton={(form) => {
                                return (
                                    <Button
                                        type="primary"
                                        style={{
                                            width: "100%",
                                        }}
                                        onClick={(e) =>
                                            form.submit()
                                        }
                                    >
                                        {t1(
                                            "click_to_resgister"
                                        )}
                                    </Button>
                                );
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </PreLoginLayout>
    );
};

export default connect()(RegisterLayout);
