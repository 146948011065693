import React, { useState } from "react";
import Steps from 'antd/lib/steps';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { t1 } from 'translate';
import { stepTwoSteps } from 'pages/deposit/constants/stepDetailSteps';
import dayjs from "dayjs";

/**
 * This components will work like description below:
 * 
 * 1/ Show text: You need to accept trade offer from bot#123345 - Security chat text: Id-1923475 - countdown 2 mins
 * 2/ Bot check that then show successful and stop countdown when that step finished - cancel mean fail => block from deposit function for 5 mins
 * 3/ Move to step 3
 * 
 * Any step fail will resulted in block from deposit function for 5 mins
 */
const StepTwo = ({nextStep, setCurrentPercent, currentTransactionData,offerItemList}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [timeStartStep,setTimeStartStep] = useState(dayjs().unix()*1000);

    return <Row gutter={[16,16]}>
        <Col span={24} className="text-center">
            <h5 onClick={(e) => {
                setCurrentStep(currentStep + 1);
                setTimeStartStep(dayjs().unix()*1000);
                }}>{t1('step_2')}</h5>
        </Col>
        <Col span={24}>
            <Steps progressDot current={currentStep} items={stepTwoSteps(timeStartStep,currentStep,nextStep,setCurrentPercent)}/>
        </Col>
        <Col span={24}>
            {stepTwoSteps(timeStartStep,currentStep,nextStep,setCurrentPercent,offerItemList)[currentStep]?.component ? stepTwoSteps(timeStartStep,currentStep,nextStep,setCurrentPercent,offerItemList)[currentStep]?.component : <></>}
        </Col>
    </Row>
}

export default StepTwo;