import { createStore } from "redux";
//import createSagaMiddleware from 'redux-saga';
import rootReducer from "redux/reducers";
//import mySaga from 'redux/middleware/saga';

//const sagaMiddleware = createSagaMiddleware();

//export default createStore(rootReducer, applyMiddleware(sagaMiddleware));
export default createStore(rootReducer);
//sagaMiddleware.run(mySaga);
