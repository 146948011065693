import { ITEM_ACC_CATEGORY_II, ITEM_ARMOR_CATEGORY_II, ITEM_CATEGORY_I_MAPPING, ITEM_SHIELD_CATEGORY_II, ITEM_WEAPON_CATEGORY_II } from "constants/item";
import lGet from "lodash/get";
import { Buffer } from "buffer";

export const convertWhiteSeedTextToLong = (whiteSeed) => {
	let byteList = whiteSeed.split("-");

	//convert each byteList to hexa
	byteList = byteList.map((hexString) => {
		return 0xff & parseInt(hexString, 16);
	});

	return parseInt(Buffer.from(byteList).readBigInt64LE());
};

export const readSlotData = (
	slot,
	whiteSeed,
	slotSize = 5
) => {
	if (slot > 6) {
		return 0;
	}

	var offset = slot * slotSize;
	var mask =
		((0x00000001 << slotSize) - 0x00000001) << offset;

	var output =
		(convertWhiteSeedTextToLong(whiteSeed) & mask) >>
		offset;

	return output;
};

export const getPercentage = (slotData) => {
	var value = Math.floor((slotData / 31) * 100);
	return value;
};

export const getValueFromSlotAndItemModel = (slotId, itemModel, itemInfo, fieldMin, fieldMax, fieldPlus = '') => {
	let output = parseFloat(lGet(itemModel,`data.${fieldMin}`,0));
	let percentage = getPercentage(
		readSlotData(
			slotId,
			lGet(
				itemInfo,
				"white_seed",
				"00-00-00-00-00-00-00-00"
			)
		)
	)/100;

	if (percentage > 0){
		output += Math.floor((parseFloat(lGet(itemModel,`data.${fieldMax}`,0)) - parseFloat(lGet(itemModel,`data.${fieldMin}`,0))) * percentage);
	}

	var plusNumber = parseInt(lGet(itemInfo,'plus',0));

	if (plusNumber > 0 && fieldPlus != ''){
		output += plusNumber * lGet(itemModel,`data.${fieldPlus}`,0);
	}

	return {
		value: Math.floor(output * 10)/10,
		percentage: Math.floor(percentage * 100)
	};
}

//ưe will have to split information here
export const getFullItemInformation = (
	itemModel,
	itemInfo
) => {
	let output = {};
	//case for weapon
	if (
		itemModel.item_slot_type ==
		ITEM_CATEGORY_I_MAPPING.WEAPON
	) {
		output["layout_type"] = 'weapon';
		switch (itemModel.item_slot_sub_type){
			case ITEM_WEAPON_CATEGORY_II.SWORD:
				output["race"] = 'china';
				output["type_name"] = 'sword';
				break;
			case ITEM_WEAPON_CATEGORY_II.BLADE:
				output["race"] = 'china';
				output["type_name"] = 'blade';
				break;
			case ITEM_WEAPON_CATEGORY_II.TBLADE:
				output["race"] = 'china';
				output["type_name"] = 'tblade';
				break;
			case ITEM_WEAPON_CATEGORY_II.SPEAR:
				output["race"] = 'china';
				output["type_name"] = 'spear';
				break;
			case ITEM_WEAPON_CATEGORY_II.BOW:
				output["race"] = 'china';
				output["type_name"] = 'bow';
				break;
			case ITEM_WEAPON_CATEGORY_II.AXE:
				output["race"] = 'europe';
				output["type_name"] = 'axe';
				break;
			case ITEM_WEAPON_CATEGORY_II.DAGGER:
				output["race"] = 'europe';
				output["type_name"] = 'dagger';
				break;
			case ITEM_WEAPON_CATEGORY_II.DARKSTAFF:
				output["race"] = 'europe';
				output["type_name"] = 'dark_staff';
				break;
			case ITEM_WEAPON_CATEGORY_II.HARP:
				output["race"] = 'europe';
				output["type_name"] = 'harp';
				break;
			case ITEM_WEAPON_CATEGORY_II.STAFF:
				output["race"] = 'europe';
				output["type_name"] = 'staff';
				break;
			case ITEM_WEAPON_CATEGORY_II.SWORD_EU:
				output["race"] = 'europe';
				output["type_name"] = 'sword_eu';
				break;
			case ITEM_WEAPON_CATEGORY_II.TSTAFF:
				output["race"] = 'europe';
				output["type_name"] = 'tstaff';
				break;
			case ITEM_WEAPON_CATEGORY_II.XBOW:
				output["race"] = 'europe';
				output["type_name"] = 'xbow';
				break;
		}

		//slot 0 = durablity max
		output["durability_max"] = getValueFromSlotAndItemModel(0,itemModel,itemInfo,'durability_0','durability_100');
		//slot 1 = additional phys dam
		output["additional_phys_min"] = getValueFromSlotAndItemModel(1,itemModel,itemInfo,'additional_phys_min_0','additional_phys_min_100');
		output["additional_phys_max"] = getValueFromSlotAndItemModel(1,itemModel,itemInfo,'additional_phys_max_0','additional_phys_max_100');
		//slot 2 = additional mag dam
		output["additional_mag_min"] = getValueFromSlotAndItemModel(2,itemModel,itemInfo,'additional_mag_min_0','additional_mag_min_100');
		output["additional_mag_max"] = getValueFromSlotAndItemModel(2,itemModel,itemInfo,'additional_mag_max_0','additional_mag_max_100');
		//slot 3 = attack rate
		output["attack_rate"] = getValueFromSlotAndItemModel(3,itemModel,itemInfo,'attack_rate_0','attack_rate_100');
		//slot 4 = dam phys
		output["dam_phys_min"] = getValueFromSlotAndItemModel(4,itemModel,itemInfo,'dam_phys_min_0','dam_phys_min_100','dam_phys_each_plus');
		output["dam_phys_max"] = getValueFromSlotAndItemModel(4,itemModel,itemInfo,'dam_phys_max_0','dam_phys_max_100','dam_phys_each_plus');
		//slot 5 = dam mag
		output["dam_mag_min"] = getValueFromSlotAndItemModel(5,itemModel,itemInfo,'dam_mag_min_0','dam_mag_min_100','dam_mag_each_plus');
		output["dam_mag_max"] = getValueFromSlotAndItemModel(5,itemModel,itemInfo,'dam_mag_max_0','dam_mag_max_100','dam_mag_each_plus');
		//slot 6 = critical
		output["crit"] = getValueFromSlotAndItemModel(6,itemModel,itemInfo,'crit_0','crit_100');
	}

	//case for armor
	if (
		[
			ITEM_CATEGORY_I_MAPPING.CLOTHES_ARMOR,
			ITEM_CATEGORY_I_MAPPING.CLOTHES_EU_ARMOR,
			ITEM_CATEGORY_I_MAPPING.HEAVY_ARMOR,
			ITEM_CATEGORY_I_MAPPING.HEAVY_EU_ARMOR,
			ITEM_CATEGORY_I_MAPPING.LIGHT_ARMOR,
			ITEM_CATEGORY_I_MAPPING.LIGHT_EU_ARMOR,
		].includes(itemModel.item_slot_type)
	) {
		output["layout_type"] = 'armor';
		//slot 0 = durablity max
		output["durability_max"] = getValueFromSlotAndItemModel(0,itemModel,itemInfo,'durability_0','durability_100');
		//slot 1 = additional_phys_def
		output["additional_phys_def"] = getValueFromSlotAndItemModel(1,itemModel,itemInfo,'additional_phys_def_0','additional_phys_def_100');
		//slot 2 = additional_mag_def
		output["additional_mag_def"] = getValueFromSlotAndItemModel(2,itemModel,itemInfo,'additional_mag_def_0','additional_mag_def_100');
		//slot 3 = defense_physical
		output["defense_physical"] = getValueFromSlotAndItemModel(3,itemModel,itemInfo,'defense_physical_0','defense_physical_100','defense_physical_each_plus');
		//slot 4 = defense_magical
		output["defense_magical"] = getValueFromSlotAndItemModel(4,itemModel,itemInfo,'defense_magical_0','defense_magical_100','defense_magical_each_plus');
		//slot 5 = defense_rate
		output["defense_rate"] = getValueFromSlotAndItemModel(5,itemModel,itemInfo,'defense_rate_0','defense_rate_100');

		switch (itemModel.item_slot_type){
			case ITEM_CATEGORY_I_MAPPING.CLOTHES_ARMOR:
				output['race'] = 'china';
				output['type_name'] = 'clothes_armor';
				break;
			case ITEM_CATEGORY_I_MAPPING.LIGHT_ARMOR:
				output['race'] = 'china';
				output['type_name'] = 'light_armor';
				break;
			case ITEM_CATEGORY_I_MAPPING.HEAVY_ARMOR:
				output['race'] = 'china';
				output['type_name'] = 'heavy_armor';
				break;
			case ITEM_CATEGORY_I_MAPPING.CLOTHES_EU_ARMOR:
				output['race'] = 'europe';
				output['type_name'] = 'clothes_armor';
				break;
			case ITEM_CATEGORY_I_MAPPING.LIGHT_EU_ARMOR:
				output['race'] = 'europe';
				output['type_name'] = 'light_armor';
				break;
			case ITEM_CATEGORY_I_MAPPING.HEAVY_EU_ARMOR:
				output['race'] = 'europe';
				output['type_name'] = 'heavy_armor';
				break;
		}

		switch (itemModel.item_slot_sub_type){
			case ITEM_ARMOR_CATEGORY_II.ARM_ARMOR:
				output['piece_name'] = 'arm_armor';
				break;
			case ITEM_ARMOR_CATEGORY_II.BODY_ARMOR:
				output['piece_name'] = 'body_armor';
				break;
			case ITEM_ARMOR_CATEGORY_II.FOOT_ARMOR:
				output['piece_name'] = 'foot_armor';
				break;
			case ITEM_ARMOR_CATEGORY_II.HEAD_ARMOR:
				output['piece_name'] = 'head_armor';
				break;
			case ITEM_ARMOR_CATEGORY_II.LEG_ARMOR:
				output['piece_name'] = 'leg_armor';
				break;
			case ITEM_ARMOR_CATEGORY_II.SHOULDER_ARMOR:
				output['piece_name'] = 'shoulder_armor';
				break;
		}
	}

	//case for shield
	if (
		itemModel.item_slot_type ==
		ITEM_CATEGORY_I_MAPPING.SHIELD
	) {
		output["layout_type"] = 'shield';
		output['type_name'] = 'shield';
		switch (itemModel.item_slot_sub_type){
			case ITEM_SHIELD_CATEGORY_II.CHINA_SHIELD:
				output['race'] = 'china';
				break;
			case ITEM_SHIELD_CATEGORY_II.EU_SHIELD:
				output['race'] = 'europe';
				break;
		}
		//slot 0 = durability
		output["durability_max"] = getValueFromSlotAndItemModel(0,itemModel,itemInfo,'durability_0','durability_100');
		//slot 1 = additional_phys_def
		output["additional_phys_def"] = getValueFromSlotAndItemModel(1,itemModel,itemInfo,'additional_phys_def_0','additional_phys_def_100');
		//slot 2 = additional_mag_def
		output["additional_mag_def"] = getValueFromSlotAndItemModel(2,itemModel,itemInfo,'additional_mag_def_0','additional_mag_def_100');
		//slot 3 = block ratio
		output["blocking"] = getValueFromSlotAndItemModel(3,itemModel,itemInfo,'blocking_0','blocking_100');
		//slot 4 = phys_def
		output["defense_physical"] = getValueFromSlotAndItemModel(4,itemModel,itemInfo,'defense_physical_0','defense_physical_100','defense_physical_each_plus');
		//slot 5 = mag_def
		output["defense_magical"] = getValueFromSlotAndItemModel(5,itemModel,itemInfo,'defense_magical_0','defense_magical_100','defense_magical_each_plus');
	}

	//case for acc
	if (
		[ITEM_CATEGORY_I_MAPPING.ACC, ITEM_CATEGORY_I_MAPPING.EU_ACC].includes(itemModel.item_slot_type)
	) {
		output["layout_type"] = 'acc';
			//slot 0 = physical absorb
			output["phys_absorb"] = getValueFromSlotAndItemModel(0,itemModel,itemInfo,'phys_absorb_0','phys_absorb_100','phys_absorb_each_plus');
			//slot 1 = magical absorb
			output["mag_absorb"] = getValueFromSlotAndItemModel(1,itemModel,itemInfo,'mag_absorb_0','mag_absorb_100','marg_absorb_each_plus');

		if (ITEM_ACC_CATEGORY_II.RING == itemModel?.item_slot_sub_type){
			output["type_name"] = 'ring';
		}else{
			if (ITEM_ACC_CATEGORY_II.EARRING == itemModel?.item_slot_sub_type){
				output["type_name"] = 'earring';
			}else{
				output["type_name"] = 'necklace';
			}
		}

		if (ITEM_CATEGORY_I_MAPPING.ACC == itemModel.item_slot_type){
			output['race'] = 'china';
		}else{
			output['race'] = 'europe';
		}
	}

	return output;
};
