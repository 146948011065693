import React, { useState } from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Input from "antd/lib/input";

const { Title, Paragraph, Text } = Typography;

const Step2 = ({setCurrentPercent}) => {
  const [inputCapcha, setInputCapcha] = useState("");

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Typography>
          <Title>{t1("input_capcha")}</Title>
          <Paragraph>
            {t1(
              "you_will_need_to_type_in_the_capcha_below_in_order_to_continue._This_capcha_will_allow_bot_to_login_without_any_problem"
            )}
          </Paragraph>
          <Text type="danger">
            {t1(
              "failed_to_type_correct_capcha_will_make_you_blocked_from_deposit_function_for_at_least_5_mins"
            )}
          </Text>
        </Typography>
      </Col>
      <Col span={24} className="text-center">
        <p>{t1("capcha_image_goes_here")}</p>
        <Input
          defaultValue={inputCapcha}
          onChange={(e) => setInputCapcha(e?.target?.value)}
        />
      </Col>
      <Col span={24} className="text-center">
        <Button onClick={(e) => {
          setCurrentPercent(60);
        }}>{t1("submit_capcha")}</Button>
      </Col>
    </Row>
  );
};

export default Step2;
