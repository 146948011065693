import { endpoints } from "constants/endpoints";
import { t1 } from "translate";

const getProfileEndpoint = {
    url: endpoints.get_profile,
    outputData: (params) => {
        //output data here
        if (params){
            if (params?.updated){
                return {
                    success: true,
                    result: {
                        id: 1,
                        ...params
                    },
                };
            }else{
                return {
                    success: true,
                    result: {
                        id: 1,
                        username: 'testaccount',
                        email: 'testaccount1@gmail.com',
                        avatar: 'https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png'
                    },
                };
            }
        }else{
            return {
                success: false,
                message: t1("invalid_params"),
            }
        }
    },
};

export default getProfileEndpoint;
