import React from "react";
import { t1 } from "translate";
import {
  PlusCircleOutlined,
  DownloadOutlined,
  UploadOutlined,
  HddOutlined,
  ShopOutlined,
} from "@ant-design/icons";
import {
  MENU_LINK_INVENTORY,
  MENU_LINK_MARKET,
  MENU_LINK_DEPOSIT,
  MENU_LINK_WITHDRAW,
  MENU_LINK_ADD_POINT,
} from "constants/menu-link";

const FOOTER_LIST = [
  {
    title: t1('add_point'),
    icon: <PlusCircleOutlined style={{fontSize: '20px'}}/>,
    link: MENU_LINK_ADD_POINT
  },
  {
    title: t1('inventory'),
    icon: <HddOutlined style={{fontSize: '20px'}}/>,
    link: MENU_LINK_INVENTORY
  },
  {
    title: t1('market'),
    icon: <ShopOutlined style={{fontSize: '20px'}}/>,
    link: MENU_LINK_MARKET
  },
  {
    title: t1('deposit'),
    icon: <UploadOutlined style={{fontSize: '20px'}}/>,
    link: MENU_LINK_DEPOSIT
  },
  {
    title: t1('withdraw'),
    icon: <DownloadOutlined style={{fontSize: '20px'}}/>,
    link: MENU_LINK_WITHDRAW
  },
];

export default FOOTER_LIST;
