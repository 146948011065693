import { t1 } from 'translate';
import { composeValidators, maxLength } from 'validations/index';

const bankAccountSchema = {
    bank_name: {
        type: 'text',
        label: t1('bank_name'),
        maxLength: 30,
        validate: composeValidators((value) => maxLength(value, 30)),
        errorText: {
            invalidValue: t1('max_length:_%s', [30])
        },
    },
    bank_short_name: {
        type: 'text',
        label: t1('bank_short_name'),
        maxLength: 30,
        validate: composeValidators((value) => maxLength(value, 30)),
        errorText: {
            invalidValue: t1('max_length:_%s', [30])
        },
    },
    account: {
        type: 'text',
        label: t1('bank_account'),
    },
    name: {
        type: 'text',
        label: t1('account_holder'),
    }
}

export default bankAccountSchema