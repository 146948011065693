const ui = () => {
    return {
        default: {
            fields: [
                'level_start',
                'level_end'
            ]
        }
    }
}

export default ui;