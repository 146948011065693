import InventoryLayout from ".";
import { MENU_LINK_INVENTORY } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_INVENTORY,
        exact: true,
        component: <InventoryLayout />,
    }
];

export default routeSchema;