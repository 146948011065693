import DepositLayout from ".";
import DepositBotStatusLayout from './status';
import DepositAllowedLayout from './allowed';
import DepositHistoryLayout from './history';
import { MENU_LINK_DEPOSIT_BOT_STATUS, MENU_LINK_DEPOSIT, MENU_LINK_DEPOSIT_HISTORY, MENU_LINK_DEPOSIT_ALLOWED } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_DEPOSIT,
        exact: true,
        component: <DepositLayout />,
    },
    {
        path: MENU_LINK_DEPOSIT_BOT_STATUS,
        exact: true,
        component: <DepositBotStatusLayout />,
    },
    {
        path: MENU_LINK_DEPOSIT_ALLOWED,
        exact: true,
        component: <DepositAllowedLayout />,
    },
    {
        path: MENU_LINK_DEPOSIT_HISTORY,
        exact: true,
        component: <DepositHistoryLayout />,
    }
];

export default routeSchema;