import { t1 } from 'translate';
import { composeValidators, required} from 'validations/index';

const otpCharacterSchema = {
    otp: {
        type: 'text',
        label: t1('please_type_in_otp_from_our_ingame_bot'),
        validate: composeValidators(required),
        errorText: {
            required: t1('otp_is_require')
        },
    }
}

export default otpCharacterSchema