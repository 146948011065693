import GoldMarketLayout from ".";
import { MENU_LINK_GOLD_MARKET_HK, MENU_LINK_GOLD_MARKET_BK, MENU_LINK_GOLD_MARKET_TK } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_GOLD_MARKET_HK,
        exact: true,
        component: <GoldMarketLayout />,
    },{
        path: MENU_LINK_GOLD_MARKET_BK,
        exact: true,
        component: <GoldMarketLayout />,
    },{
        path: MENU_LINK_GOLD_MARKET_TK,
        exact: true,
        component: <GoldMarketLayout />,
    }
];

export default routeSchema;