import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

//This page should have our main information for trusting
const BlogAboutUsLayout = (props) => {
    return <PageLayout>
        {t1('blog_about_us_layout')}
    </PageLayout>
}

export default BlogAboutUsLayout;