import { t1 } from 'translate';
import StepOne from '../components/StepOne'
import StepTwo from '../components/StepTwo'

const withdrawSteps = (descriptionList = [], nextStep, setCurrentPercent, setCurrentStep, startWithdraw, currentTransactionData, offerItemList) => {
    return [
        {
            id: 0,
            key: 0,
            title: t1('step_one'),
            description: descriptionList.find((element) => element.id == 0) || t1('description_step_one'),
            content: (
                <StepOne
                    nextStep={nextStep}
                    setCurrentPercent={setCurrentPercent}
                    setCurrentStep={setCurrentStep}
                    startWithdraw={startWithdraw}
                    currentTransactionData={currentTransactionData}
                    offerItemList={offerItemList}
                />
            ),
            //totalTime: 300,//5 minutes
        },
        {
            id: 1,
            key: 1,
            title: t1('step_two'),
            description: descriptionList.find((element) => element.id == 1) || t1('description_step_two'),
            content: <StepTwo nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>,
            totalTime: 120,//2 minutes
        }
    ];
}

export default withdrawSteps;