import React, { useEffect, useState } from 'react';
import Upload from 'antd/lib/upload';
import Button from 'antd/lib/button';
import lGet from 'lodash/get';
//import { deleteFile } from 'api/files';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';
import { Spin } from 'antd';
import { DeleteOutlined } from "@ant-design/icons";

const deleteFile = (e) => console.log(e);

const { Text } = Typography;

const UploadFieldAntd = ({
  input,
  meta,
  uploadSettings = {},
  beforeUpload = (e) => true,
  maxCount = 1,
  progress = {},
  handleAfterUpload = (e) => e,
  convertDefaultFile = (e) => [],
  multi = false,
  UploadButton = <Button>Upload</Button>,
  onRemove = {},
  hideUploadWhenMaxCount = true,
  limitCapacity = 5000000,
  showUploadList = true,
  customFormList = false,
  errorText = {},
  textNote = undefined,
  accept,
  ...rest
}) => {
  const [fileCount, setFileCount] = useState(
    typeof convertDefaultFile === 'function' ? convertDefaultFile(input).length : 0
  );
  const [errorMessage, setErrorMessage] = useState('');
  const [statusError, setStatusError] = useState(false);
  const [files, setFiles] = useState(typeof convertDefaultFile === 'function' ? convertDefaultFile(input) : []);
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    // ON REMOVE ONE ELEMENT IN FORM ARRAY
    if (!input.value) {
      setFiles([]);
      setFileCount(0);
    }
  }, [input.value]);

  useEffect(() => {
    setStatusError(false);
  }, [meta.touched]);

  useEffect(() => {
    if (rest?.forceUnListFile) {
      input.onChange(undefined);
      setFiles([]);
      setFileCount(0);
    }
  }, [rest?.forceUnListFile]);

  const handleCustomDeleteFile = (file) => {
    if (typeof onRemove === 'function') {
      onRemove(file, input);
    } else {
      const valueData = input.value;
      if (valueData === file?.response?.data?.file_url) {
        input.onChange('');
        const fileName = file?.response?.data?.file_name;
        if (fileName) {
          deleteFile(fileName);
        }
      } else {
        //handle case multiple
        input.onChange('');
      }
    }

    const newFiles = files?.filter((fileInfo) => fileInfo?.uid !== file?.uid);
    setFileCount(newFiles?.length ?? 0);
    setFiles(newFiles);
  };

  const checkShowUpload = () => {
    if (!customFormList || (customFormList && maxCount > fileCount)) {
      return true;
    }

    return false;
  };

  const fieldTextNote = () => {
    return textNote ? (
      <div
        className={`w-100 ${styles.textNote} ${styles.whiteSpace}`}
        dangerouslySetInnerHTML={{ __html: textNote }}
      ></div>
    ) : (
      <></>
    );
  };

  const handleProgress = (evt) => {
    if (progress && typeof progress == 'function') {
      progress(evt);
    }
  };

  return (
    <div className={styles.uploadFile}>
      <div className={checkShowUpload() ? 'uploadShow' : 'uploadHide'}>
        <Upload
          {...input}
          {...rest}
          // accept={accept}
          action={uploadSettings.endpoint || ''}
          headers={uploadSettings.headers || {}}
          data={uploadSettings?.params || {}}
          maxCount={maxCount}
          progress={handleProgress}
          beforeUpload={(file, fileList) => {
            if (file?.size > limitCapacity) {
              setErrorMessage('5MB以下のファイル容量をアップロードしてください。');
              setStatusError(true);
              setFileCount(fileCount - 1);
              file['uploaded'] = false;
              setFiles([]);
              return false;
            }
            setUploading(true);
            setErrorMessage('');
            setFileCount(fileCount + 1);
            beforeUpload(file);
            return true;
          }}
          multiple={multi}
          showUploadList={showUploadList && !customFormList}
          defaultFileList={convertDefaultFile(input)}
          onChange={({ file, fileList }) => {
            if (file?.status === 'uploading' && uploading !== true) {
              setUploading(true);
            } else if (file?.status === 'done' && uploading !== false) {
              setUploading(false);
            } else if (file?.status === 'error') {
              setUploading(false);
              setErrorMessage('失敗しました');
            }
            if (file?.size > limitCapacity) return false;
            setFileCount(fileList.length);
            if (typeof handleAfterUpload === 'function') {
              input.onChange(handleAfterUpload({ file, fileList }));
            } else {
              input.onChange(
                fileList.map((fileInfo) => {
                  return lGet(fileInfo, 'response.url', '');
                })
              );
            }
            setFiles([file]);
          }}
          onRemove={onRemove}
        >
          {hideUploadWhenMaxCount ? maxCount > fileCount ? UploadButton : <></> : UploadButton}
        </Upload>
        {fieldTextNote()}
        {((meta.error && meta.touched) || statusError) &&
        (errorText[meta.error] || errorMessage == '5MB以下のファイル容量をアップロードしてください。') ? (
          <Text type="danger" className={styles.messageError}>
            {statusError ? errorMessage : errorText[meta.error] ? errorText[meta.error] : meta.error}
          </Text>
        ) : null}
      </div>
      {customFormList &&
        files?.map((file, index) => (
          <div key={index} className="uploadItemCustom">
            <span>{file?.name ? (file.name?.length > 16 ? `${file.name?.slice(0, 16)}...` : file.name) : ''}</span>
            {uploading ? (
              <Spin />
            ) : (
              <button type="button" onClick={() => handleCustomDeleteFile(file)}>
                <DeleteOutlined/>
              </button>
            )}
          </div>
        ))}
    </div>
  );
};

export default UploadFieldAntd;
