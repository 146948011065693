import React from "react";
import { QRNormal } from "react-qrbtf";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { t1 } from "translate";

const QRRender = ({ input, nextStep, codeTransaction }) => {
    return (
        <Row>
            <Col span={24}>
                <div>
                    <Row>
                        <Col span={24} md={10} lg={10} className="text-center">
                            <QRNormal
                                id="myqrcode"
                                value={input}
                                className="my-qrcode"
                                styles={{ svg: { width: "300px" } }}
                                //type="round"
                                size={80}
                                opacity={80}
                                posType="round"
                                otherColor="#33CCCC"
                                posColor="#0693E3"
                            />
                        </Col>
                        <Col span={24} md={14} lg={14} className="pt-5">
                            <h5>{t1("account_name:_%s", ["TRAN MINH NHAT"])}</h5>
                            <h5>{t1("account_number:_%s", [1014736971])}</h5>
                            <p className="mb-1">{t1("bank_name:_%s", ["VIETCOMBANK"])}</p>
                            <p>{t1("security_code:_%s", [codeTransaction])}</p>
                            <Button type="primary" className="ms-2" onClick={(e) => nextStep(e)}>
                                {t1("click_to_next_step")}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default QRRender;
