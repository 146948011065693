export const TIMEFORMAT = 'HH:mm';
export const DATEFORMAT2 = "DD/MM/YYYY";
export const DATEFORMAT3 = "DD-MM-YYYY";
export const EXCHANGE_RATE = 1000;

export const CURRENCY_OPTIONS = [
  {
    label: 'point',
    value: 0
  },
  {
    label: 'gold',
    value: 1
  }
];

export const ITEM_GOLD_MODEL_ID = 999999;
export const TRANSACTION_TYPE = {
  DEPOSIT: 1,
  WITHDRAW: 2
}