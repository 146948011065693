import { SET_PROFILE_ACTION } from "redux/action-types";
//import lGet from 'lodash/get';

const initialState = {};

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PROFILE_ACTION: {
            return {... state, ...action.payload};
        }
        default:
            return state;
    }
}

export default ProfileReducer;
