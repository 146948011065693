import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const { Title, Paragraph, Text } = Typography;

const Step2 = ({nextStep, setCurrentPercent}) => {
  return (
    <Row gutter={[8,8]}>
      <Col span={24}>
        <Typography>
          <Title>{t1("withdraw_offer_finished")}</Title>
          <Paragraph>
            {t1("this_withdraw_offer_has_been_finished")}
          </Paragraph>
        </Typography>
      </Col>
    </Row>
  );
};

export default Step2;