import WithdrawLayout from ".";
import WithdrawGoldLayout from "./gold";
import WithdrawBotStatusLayout from './status';
import WithdrawHistoryLayout from './history';
import { MENU_LINK_WITHDRAW, MENU_LINK_WITHDRAW_GOLD, MENU_LINK_WITHDRAW_BOT_STATUS, MENU_LINK_WITHDRAW_HISTORY } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_WITHDRAW,
        exact: true,
        component: <WithdrawLayout />,
    },
    {
        path: MENU_LINK_WITHDRAW_GOLD,
        exact: true,
        component: <WithdrawGoldLayout />,
    },
    {
        path: MENU_LINK_WITHDRAW_BOT_STATUS,
        exact: true,
        component: <WithdrawBotStatusLayout />,
    },
    {
        path: MENU_LINK_WITHDRAW_HISTORY,
        exact: true,
        component: <WithdrawHistoryLayout />,
    }
];

export default routeSchema;