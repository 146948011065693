import React from "react";
import { useNavigate } from "react-router-dom";
import PreLoginLayout from "components/PageLayout/PreloginLayout";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import { t1 } from "translate";
import FormCreator from "commons/final-form/core/FormCreator";
import signupLayout from "schema-form/layout/signupLayout";
import signupSchema from "schema-form/schema/signupSchema";
import signupUI from "schema-form/ui/signupUI";
import Button from "antd/lib/button";
import message from "antd/lib/message";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { connect } from "react-redux";

const SignupLayout = ({ dispatch }) => {
  const navigate = useNavigate();

  const [data, UpEnpoints, setLoginParams] = useFetch(
    endpoints.signup,
    "",
    "post",
    (result, params) => {
      message.success("Signup successful");
      navigate('/login');
    },
    (result, params) => {
      if (!result?.success) {
        message.error(result?.message || "Signup failed");
      }
    }
  );

  return (
    <PreLoginLayout>
      <Row>
        <Col span={24} md={{ span: 6, offset: 9 }} xs={{ span: 22, offset: 1 }}>
          <Card
            title={t1("signup_page")}
            bordered={true}
            style={{ width: "100%", marginTop: "200px" }}
          >
            <FormCreator
              debugMode={true}
              schema={signupSchema}
              ui={signupUI}
              layout={signupLayout}
              onSubmit={(value) => {
                setLoginParams(value);
              }}
              SubmitButton={(form) => {
                return (
                  <Button
                    type="primary"
                    style={{ width: "100%" }}
                    onClick={(e) => form.submit()}
                  >
                    {t1("click_to_login")}
                  </Button>
                );
              }}
            />
          </Card>
        </Col>
      </Row>
    </PreLoginLayout>
  );
};

export default connect()(SignupLayout);
