import CharacterListTabContent from "components/GameCharacter/CharacterListTabContent";
import {
    START_MODEL_ID_MALE_CHINA,
    START_MODEL_ID_FEMALE_CHINA,
    START_MODEL_ID_MALE_EU,
    START_MODEL_ID_FEMALE_EU,
    SERVER_LIST,
} from "constants/game";

export const getCharacterImageFromModelId = (modelId) => {
    let isCn = false;
    let isMale = false;
    let diff = 0;
    if (modelId < START_MODEL_ID_MALE_EU) {
        isCn = true;
    }

    if (isCn) {
        if (modelId < START_MODEL_ID_FEMALE_CHINA) {
            isMale = true;
            diff = modelId - START_MODEL_ID_MALE_CHINA + 1;
        } else {
            diff =
                modelId - START_MODEL_ID_FEMALE_CHINA + 1;
        }
    } else {
        if (modelId < START_MODEL_ID_FEMALE_EU) {
            isMale = true;
            diff = modelId - START_MODEL_ID_MALE_EU + 1;
        } else {
            diff = modelId - START_MODEL_ID_FEMALE_EU + 1;
        }
    }

    return `${window.IMAGE_SERVER}/char_${isCn ? "ch" : "eu"}_${
        isMale ? "man" : "woman"
    }${diff}.gif`;
};

export const generateCharacterEachServer = (
    gameCharacterList = []
) => {
    let output = [];

    SERVER_LIST.map((serverInfo) => {
        let serverCharacter = [];
        if (gameCharacterList.length > 0) {
            serverCharacter = gameCharacterList.filter(
                (character) =>
                    character?.server_id == serverInfo.id
            );
        }
        output.push({
            ...serverInfo,
            list_character: serverCharacter,
        });
    });

    return output;
};

export const generateServerGameCharacterListTab = ({
    serverListInfo,
    onRemoveCharacter,
    onSelectCharacter,
    currentSelected,
    isAbleToAddCharacter,
    onClickAddCharacter,
}) => {
    let output = [];

    serverListInfo.map((serverInfo) => {
        output.push({
            label: <span style={{fontSize: '13px'}}>{`${serverInfo.name} ( ${serverInfo?.list_character.length} )`}</span>,
            key: serverInfo.id,
            children: (
                <CharacterListTabContent
                    key={serverInfo.id}
                    serverInfo={serverInfo}
                    onRemoveCharacter={onRemoveCharacter}
                    onSelectCharacter={onSelectCharacter}
                    currentSelected={currentSelected}
                    isAbleToAddCharacter={
                        isAbleToAddCharacter
                    }
                    onClickAddCharacter={
                        onClickAddCharacter
                    }
                />
            ),
        });
    });

    return output;
};
