import { t1 } from 'translate';

const userIdentifySchema = {
    identify_card_number: {
        type: 'text',
        label: t1('identify_card_number'),
    },
    identify_card_type: {
        type: 'select',
        label: t1('type_of_card'),
        style: {width: '100%'},
        options: [
            {
                label: 'CCCD/CMNTD',
                value: 1,
            },{
                label: 'GPLX',
                value: 2,
            },{
                label: 'Passport',
                value: 3,
            },
        ]
    },
    identify_name: {
        type: 'text',
        label: t1('name_on_identify_card')
    }
}

export default userIdentifySchema