const bankAccountUI = {
    default: {
        fields: [
            'bank_name',
            'bank_short_name',
            'account',
            'name'
        ]
    }
}

export default bankAccountUI;