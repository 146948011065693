import { t1 } from 'translate';

const createListingGoldSchema = {
    price: {
        type: 'number',
        label: t1('point_number'),
        handleInputChange: (e) => console.log(e),
    },
}

export default createListingGoldSchema