import { t1 } from 'translate';
import { DATEFORMAT2 } from 'constants';
import Image from 'antd/lib/image';
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { avatarList } from "constants/avatar";

const renderImage = (src) => {
    return (
        <Image
        src={src}
        width={100}
        height={100}
        style={{ borderRadius: '50px' }}
        />
    );
};

const userInfoSchema = {
    email: {
        type: 'text',
        label: t1('email'),
    },
    username: {
        type: 'text',
        label: t1('username')
    },
    avatar: {
        type: 'image-dropdown',
        hideLabel: true,
        label: t1('avatar'),
        mode: 'single',
        name: 'avatar',
        items: avatarList.map((avatar, index) => ({
            key: index + 1,
            label: avatar
        })),
        reviewRender: (value, schema) => {
            return renderImage(value);
        },
        renderField: (value, schema) => {
            return renderImage(value);
        },
        customOpenButton: () => (
            <Button type="default">
                {t1("change_avatar")}
            </Button>
        ),
        dropdownRender: (menu, input, closeDropdown) => {
            const items = menu?.props?.items || [];

            return (
                <Row
                    gutter={[16, 16]}
                    style={{
                        background: '#c2c2c2',
                        borderRadius: '8px',
                        padding: '10px 0'
                    }}
                >
                    {items.map((item, index) => (
                        item?.label && (
                            <Col
                                span={8}
                                key={index}
                                onClick={(e) => {
                                    input?.onChange(item?.label);
                                    closeDropdown();
                                }}
                                className='text-center'
                            >
                                <img
                                    src={item.label}
                                    width={60}
                                    height={60}
                                    style={{ cursor: 'pointer' }}
                                    alt='avatar-selection'
                                />
                            </Col>
                        )
                    ))}
                </Row>
          )}
    },
    total_sell: {
        type: 'number',
        label: t1('total_sell')
    },
    total_purchase: {
        type: 'number',
        label: t1('total_purchase')
    },
    total_auction_sell: {
        type: 'number',
        label: t1('total_auction_sell')
    },
    total_auction_bid: {
        type: 'number',
        label: t1('total_auction_bid')
    },
    total_auction_won: {
        type: 'number',
        label: t1('total_auction_won')
    },
    reputation_score: {
        type: 'number',
        label: t1('reputation_score')
    },
    total_review: {
        type: 'number',
        label: t1('total_review')
    },
    identify_name: {
        type: 'text',
        label: t1('identify_name'),
    },
    identify_card_number: {
        type: 'text',
        label: t1('identify_card_number'),
    },
    identify_card_type: {
        type: 'select',
        label: t1('identify_card_type'),
        options: [
            {
                value: 1,
                label: t1('cccd')
            },
            {
                value: 2,
                label: t1('passport')
            },
        ]
    },
    dob: {
        type: 'datepicker',
        label: t1('date_of_birth'),
        dateFormat: DATEFORMAT2,
        style: {width: '100%'}
    },
    phone_number: {
        type: 'text',
        label: t1('phone_number'),
    },
    identify_card_front_image: {
        type: 'image-upload',
        hideLabel: true,
        label: t1('identify_card_front_image'),
        mode: 'single',
    },
    identify_card_back_image: {
        type: 'image-upload',
        hideLabel: true,
        label: t1('identify_card_back_image'),
        mode: 'single',
    },
    identify_verified: {
        type: 'review',
        label: t1('identify_verified'),
    },
    bank_id: {
        type: 'select',
        label: t1('bank_id'),
        options: [
            {
                value: 1,
                label: 'bank1'
            },
            {
                value: 2,
                label: 'bank2'
            },
        ]
    },
    account_number:{
        type: 'text',
        label: t1('account_number'),
    },
    branch: {
        type: 'text',
        label: t1('branch'),
    },
    account_name: {
        type: 'text',
        label: t1('account_name'),
    },
    account_status: {
        type: 'review',
        label: t1('account_status'),
    },
    referal_code: {// will not allow to modify, will be autogenerated - only allow admin manual modify
        type: 'review',
        label: t1('referal_code'),
    }
}

export default userInfoSchema