import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { t1 } from "translate";
import Tabs from "antd/lib/tabs";
import DepositTransactionList from "../components/DepositTransactionList";
import WithdrawTransactionList from "../components/WithdrawTransactionList";

const TabList = [
    {
        key: "1",
        label: t1("deposit_transaction_history"),
        children: <DepositTransactionList/>,
    },
    {
        key: "2",
        label: t1("withdraw_transaction_history"),
        children: <WithdrawTransactionList/>,
    },
];

//This page will be use to list all point request in system
const PointHistoryLayout = (props) => {
    const [activeTab, setActiveTab] = useState("1");

    return (
        <PageLayout>
            <Row>
                <Col span={24}>
                    <Tabs defaultActiveKey={activeTab} items={TabList} />
                </Col>
            </Row>
        </PageLayout>
    );
};

export default PointHistoryLayout;
