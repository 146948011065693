import React, { useEffect } from 'react';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';

const { Text } = Typography;
const whiteListKeys = ['Backspace', 'Enter'];

const TextFieldAntd = ({ input, meta, errorText = {}, note = undefined, ...rest }) => {
  useEffect(() => {
    if (rest?.renderFields) {
      rest.renderFields(input, rest?.valuesData);
    }
  }, [rest?.valuesData]);

  useEffect(() => {
    if (rest?.forceAutoFill) {
      if (rest.forceAutoFill === 'empty_data') {
        input.onChange(null);
      } else {
        input.onChange(rest?.forceAutoFill);
      }
    }
  }, [rest?.forceAutoFill]);

  const fieldTextNote = () => {
    return rest?.textNote ? <span className={`w-100`}>{rest?.textNote}</span> : <></>;
  };

  return (
    <div>
      <Input
        {...input}
        {...rest}
        status={meta.error && meta.touched ? 'error' : ''}
        onFocus={(e) => {
          input.onChange(e?.target?.value);
          if (rest.onFocus) {
            rest.onFocus(input, e);
          }
        }}
        onBlur={(e) => {
          input.onChange(e?.target?.value);
          if (rest.onBlur) {
            rest.onBlur(input, e);
          }
        }}
        autoComplete="off"
        onKeyDown={(e) => {
          // Hold Command/Control/Option Button
          if (!e.metaKey && !e.ctrlKey && !e.altKey && !whiteListKeys.includes(e.key)) {
            if (!new RegExp(rest?.regexPattern).test(e.key)) {
              e.preventDefault();
            }
          }
        }}
        onInput={rest.handleInputChange}
      />
      {fieldTextNote()}
      {note ? <span>{note}</span> : <></>}
      {meta.error && meta.touched ? (
        <Text type="danger" style={{ float: 'left' }}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : (
        ''
      )}
    </div>
  );
};

export default TextFieldAntd;
