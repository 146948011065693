import React from 'react';
import dayjs from 'dayjs';
import { Typography } from 'antd';
import { TimePicker } from 'antd';
import styles from './styles.module.scss';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Text } = Typography;

//rest here will need options value
//option should in this format: {label: A, value: B}

const TimeRangePickerFieldAntd = ({ input, meta, timeFormat, ...rest }) => {
  const fieldTextNote = () => {
    return rest?.textNote ? <span className={`w-100 ${styles.textNote}`}>{rest?.textNote}</span> : <></>;
  };
  return (
    <>
      <TimePicker.RangePicker
        separator={<ArrowRightOutlined className={styles.iconRangePicker} />}
        {...input}
        {...rest}
        value={
          input.value
            ? input.value.length == 2
              ? [dayjs(input.value[0]), dayjs(input.value[1])]
              : [dayjs(input.value[0]), '']
            : ['', '']
        }
        format={timeFormat || 'HH:mm'}
        placeholder={rest?.placeholder ? rest?.placeholder : timeFormat ? [timeFormat, timeFormat] : undefined}
        onChange={(valueChange) => input.onChange(valueChange)}
      />
      {fieldTextNote()}
      {meta.error && meta.touched ? (
        <Text type="danger" className={styles.messageError}>
          {rest.errorText[meta.error] ? rest.errorText[meta.error] : meta.error}
        </Text>
      ) : null}
    </>
  );
};

export default TimeRangePickerFieldAntd;
