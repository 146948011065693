import { TOGGLE_CHAT_PANEL_ACTION, SET_LOAD_STATUS_ACTION, SET_MENU_INFO_FROM_REQUEST, CHANGE_USER_INFO_ON_HEADER, TOGGLE_LEFT_MENU_ACTION, SET_LEFT_MENU_ACTION, SET_OPEN_SUB_MENU_ACTION } from 'redux/action-types';
import lGet from 'lodash/get';
import {isMobile} from 'react-device-detect';

const initialState = {
    leftMenu: {
        collapsed: isMobile ? true : false,

        //defaultOpenKeys: [],
    },
    chatDisplay: {
        collapsed: isMobile ? true : false
    },
    menuInfo: {
        approve_request_number: 0,
    },
    loaded: false,
    //userInfoHeader: JSON.parse(localStorage.getItem('userInfoHeader'))
};

const LayoutReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_LEFT_MENU_ACTION: {
            return {
                ...state,
                leftMenu: {
                    collapsed: !lGet(state,"leftMenu.collapsed",true)
                },
            };
        }
        case TOGGLE_CHAT_PANEL_ACTION: {
            return {
                ...state,
                chatDisplay: {
                    collapsed: !lGet(state,"chatDisplay.collapsed",true)
                },
            };
        }
        case SET_LEFT_MENU_ACTION: {
            return {
                ...state,
                leftMenu: Object.assign({}, state.leftMenu, {collapsed: action.payload.state}),
            }
        }
        case CHANGE_USER_INFO_ON_HEADER: {
            return {
                ...state,
                userInfoHeader: JSON.parse(localStorage.getItem('userInfoHeader'))
            }
        }
        case SET_OPEN_SUB_MENU_ACTION: {
            let newLeftMenu = Object.assign({}, state.leftMenu, {defaultOpenKeys: lGet(action,"payload.openKeys",[])})
            return {
                ...state,
                leftMenu: newLeftMenu,
            }
        }
        case SET_MENU_INFO_FROM_REQUEST: {
            return {
                ...state,
                menuInfo: action.payload
            }
        }
        case SET_LOAD_STATUS_ACTION: {
            return {
                ...state,
                loaded: action.payload
            }
        }
        default:
            return state;
    }
}

export default LayoutReducer;
