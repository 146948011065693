const userInfoUI = {
    default: {
        fields: [
            'username',
            'email',
        ]
    },
    general_info: {
        fields: [
            'username',
            'email',
            'avatar',
            'dob',
            'phone_number',
        ]
    },
}

export default userInfoUI;