import PointAddLayout from "./add";
import PointRefundLayout from './refund';
import PointHistoryLayout from './history';
import { MENU_LINK_ADD_POINT, MENU_LINK_REFUND, MENU_LINK_POINT_HISTORY } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_ADD_POINT,
        exact: true,
        component: <PointAddLayout />,
    },
    {
        path: MENU_LINK_REFUND,
        exact: true,
        component: <PointRefundLayout />,
    },{
        path: MENU_LINK_POINT_HISTORY,
        exact: true,
        component: <PointHistoryLayout />,
    }
];

export default routeSchema;