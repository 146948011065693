import React, { useState } from 'react';
import { t1 } from 'translate';
import { cloneDeep } from 'lodash';
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import Typography from "antd/lib/typography";
import { SERVER_LIST } from "constants/game";
import { ITEM_CATEGORY_I_MAPPING } from 'constants/item';
import searchIcon from 'assets/icons/common/searchIcon.svg';
import clearIcon from 'assets/icons/common/clearIcon.svg';
import { CURRENCY_OPTIONS } from 'constants';
import { SearchOutlined, UndoOutlined } from '@ant-design/icons';

const filterList = [
    {
        key: 'concurrency',
        placeholder: t1('currency'),
        options: CURRENCY_OPTIONS
    },
    {
        key: 'server_id',
        placeholder: t1('select_server'),
        options: SERVER_LIST.map(server => ({ value: server.id, label: server.name }))
    },
    {
        key: 'item_level',
        placeholder: t1('select_level'),
        options: [
            { value: 1, label: 'Level 1' },
            { value: 2, label: 'Level 2' },
            { value: 3, label: 'Level 3' },
            { value: 4, label: 'Level 4' },
            { value: 5, label: 'Level 5' },
            { value: 6, label: 'Level 6' },
            { value: 7, label: 'Level 7' },
            { value: 8, label: 'Level 8' },
            { value: 9, label: 'Level 9' },
        ]
    },
    {
        key: 'type',
        placeholder: t1('select_type'),
        options: Object.keys(ITEM_CATEGORY_I_MAPPING).map((key) => {
            return {
                value: ITEM_CATEGORY_I_MAPPING[key],
                label: t1(key.toLowerCase())
            }
        })
    },
    {
        key: 'source',
        placeholder: t1('race'),
        options: [
            { value: 0, label: t1('china_race') },
            { value: 1, label: t1('eu_race')},
        ]
    },{
        key: 'is_rare',
        placeholder: t1('is_rare'),
        options: [
            { value: 0, label: t1('normal') },
            { value: 1, label: t1('sox')},
        ]
    },{
        key: 'sex',
        placeholder: t1('gender'),
        options: [
            { value: 0, label: t1('all') },
            { value: 1, label: t1('male')},
            { value: 2, label: t1('female')},
        ]
    },
];

const { Title } = Typography;

const SearchItem = (props) => {
    const { styles, params, updateParams } = props;

    const [filterParams, setFilterParams] = useState(params);
    console.log({params});

    const handleChangeParams = (key, value) => {
        const newParams = cloneDeep(filterParams);
        newParams[key] = value;
        setFilterParams(newParams);
    };

    const handleClearParams = () => {
        updateParams({
            q: '',
            server_id: 3,
            level: null,
            type: null
        });
    }

    const handleSearch = () => {
        updateParams(filterParams);
    }

    return (
        <div className={styles.searchItem}>
            <div className={styles.filterOptions}>
                <div className={styles.filterOption}>
                    <Title level={5} style={{marginBottom: '2px', marginLeft: '3px'}}>{t1('find_item')}</Title>
                    <div className={styles.searchWrapper}>
                        <div className={styles.wrapper}>
                            <div className={styles.iconSearch}>
                                <img src={searchIcon} />
                            </div>
                            <input
                                type="text"
                                placeholder={t1('find_item')}
                                defaultValue={filterParams.q}
                                onBlur={e => handleChangeParams('q', e.target.value)}
                            />
                            <div className={styles.clearSearch}>
                                <button
                                    className={styles.clearBtn + ` ${!!filterParams.q ? styles.show : styles.hide}`}
                                    onClick={() => handleChangeParams('q', '')}
                                >
                                    <img src={clearIcon} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {filterList.map((filter, index) => (
                    <div key={index} className={styles.filterOption}>
                        <Title level={5} style={{marginBottom: '2px', marginLeft: '3px'}}>{filter.placeholder}</Title>
                        <Select
                            key={index}
                            placeholder={filter.placeholder}
                            value={filterParams[filter.key]}
                            options={filter.options}
                            onChange={(e) => handleChangeParams(filter.key, e)}
                            style={{ width: "100%" }}
                        />
                    </div>
                ))}
                <div className={styles.filterOption}>
                    <div className={styles.refreshBtn}>
                        <Button type='primary' onClick={handleSearch}>
                            <SearchOutlined style={{ fontSize: '15px' }} />
                        </Button>
                        <Button type='default' onClick={handleClearParams}>
                            <UndoOutlined style={{ fontSize: '15px' }} />
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SearchItem;
