import React, { useState } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import DataFetchHook from 'commons/hook/fetchDataHook';
import { endpoints } from 'constants/endpoints';
import Table from 'antd/lib/table';
import { SERVER_LIST } from 'constants/game';
import { t1 } from 'translate';
import Tag from 'antd/lib/tag';
import Progress from 'antd/lib/progress';
import TableCustom  from '../TableCustom';

const BotInfo = () => {
    const [ totalBotSlot, setTotalBotSlot ] = useState(0);
    const [ botList, setBotList ] = useState([]);

    const [data,updateEndpointBot,updateParamsBot] = DataFetchHook(endpoints.bot_list,{submit: 1, limit: 99999}, 'GET', (result,params) => {
        setBotList(result?.data);
    })

    const [dataSlot,updateEndpointSlot,updateParamsSlot] = DataFetchHook(endpoints.number_bot_free,{submit: 1, limit: 99999}, 'GET', (result,params) => {
        setTotalBotSlot(result?.data?.free);
    })

    const renderDetailTemplate = (data) => {
        return (
            <div>
                <p>
                    <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('bot_name')}:</span>
                    <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>{data?.char_name}</span>
                </p>
                <p>
                    <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('server')}:</span>
                    <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>{getServer(data?.server_id)}</span>
                </p>
                <p>
                    <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('active_status')}:</span>
                    <Tag color={data?.active_status ? 'green' : 'volcano'}>
                        {data?.active_status ? t1('active') : t1('inactive')}
                    </Tag>
                </p>
                <p>
                    <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('online_status')}:</span>
                    <Tag color={data?.online_status ? 'green' : 'volcano'}>
                        {data?.online_status ? t1('online') : t1('offline')}
                    </Tag>
                </p>
                <p>
                    <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('storage')}:</span>
                    {<Progress steps={25} percent={Math.floor((data?.character_total_storage_slot - data?.empty_storage_number) * 100/data?.character_total_storage_slot)} size={[5,10]}/>}
                </p>
            </div>
        );
    };

    const getServer = (serverId) =>
        SERVER_LIST.find(
            (server) => server.id === Number(serverId)
        )?.name || "";

        const columns = [
            {
              title: t1('bot_name'),
              dataIndex: 'char_name',
              key: 'char_name',
              isShortCut: true,
              render: (text) => <a>{text}</a>,
              style: {
                fontSize: '12px'
              }
            },
            {
              title: t1('server'),
              dataIndex: 'server_id',
              key: 'server_id',
              isShortCut: true,
              render: (data) => {
                return getServer(data)
              }
            },
            {
              title: t1('active_status'),
              dataIndex: 'active_status',
              key: 'active_status',
              render: (status) => (
                <Tag color={status ? 'green' : 'volcano'}>
                  {status ? 'Active' : 'Inactive'}
                </Tag>
              ),
            },
            {
              title: t1('online_status'),
              dataIndex: 'online_status',
              key: 'online_status',
              isShortCut: true,
              render: (status) => (
                <Tag color={status ? 'green' : 'volcano'}>
                  {status ? 'Online' : 'Offline'}
                </Tag>
              ),
            },{
              title: t1('storage'),
              dataIndex: 'character_total_storage_slot',
              key: 'character_total_storage_slot',
              render: (data,record,index) => {
                return <Progress steps={25} percent={Math.floor((record?.character_total_storage_slot - record?.empty_storage_number) * 100/record?.character_total_storage_slot)} size={[5,10]}/>
              },
            }
          ];

    return <Row>
        <Col span={24}>
            <p>{t1('currently_server_can_serve_%s_more_deposit_at_the_same_time',[totalBotSlot])}</p>
        </Col>
        <Col span={24}>
            <TableCustom
                isShortCut={true}
                columns={columns}
                data={botList}
                pagination={{
                    pageSize: 5
                }}
                detailTemplate={renderDetailTemplate}
            />
        </Col>
    </Row>
};

export default BotInfo;
