import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { CloseOutlined } from '@mui/icons-material';

const MobilePopup = ({children, open = false, onClose = () => {}, title = ''}) => {
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <div className={styles.mobilePopupContainer}>
      {isOpen && (
        <div className={styles.mobilePopupBox}>
          <div className={styles.mobilePopupHeader}>
            <div className={styles.headerTitle}>{title}</div>
            <button className={styles.closeBtn} onClick={onClose}>
              <CloseOutlined />
            </button>
          </div>
          <div style={{height: '100%', overflowY: 'auto'}}>
            {children}
          </div>
        </div>
      )}
    </div>
  )
};

export default MobilePopup;
