import React, { useEffect } from 'react';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';

const { Text } = Typography;

const TextAreaFieldAntd = ({ input, meta, errorText = {}, ...rest }) => {
  useEffect(() => {
    if (rest?.renderFields) {
      rest.renderFields(input, rest?.valuesData);
    }
  }, [rest?.valuesData]);

  useEffect(() => {
    if (rest?.forceAutoFill) {
      if (rest.forceAutoFill === 'empty_data') {
        input.onChange(null);
      } else {
        input.onChange(rest?.forceAutoFill);
      }
    }
  }, [rest?.forceAutoFill]);

  const fieldTextNote = () => {
    return rest?.textNote ? <span className={`w-100 ${styles.textNote}`}>{rest?.textNote}</span> : <></>;
  };

  return (
    <div className={styles.textField}>
      <Input.TextArea
        {...input}
        {...rest}
        status={meta.error && meta.touched ? 'error' : ''}
        onFocus={(e) => input.onChange(e?.target?.value)}
        onBlur={(e) => {
          input.onChange(e?.target?.value);
        }}
        autoComplete="off"
      />
      {fieldTextNote()}
      {meta.error && meta.touched ? (
        <Text type="danger" style={{ float: 'left' }}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : (
        ''
      )}
    </div>
  );
};

export default TextAreaFieldAntd;
