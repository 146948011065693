import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from "translate";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col"
import Statistic from 'antd/lib/statistic'
import Progress from 'antd/lib/progress'
import CharacterDetail from "components/ConnectCharacter/CharacterDetail";
import InventoryList from "components/Inventory/ItemList";
import { connect } from 'react-redux';
import GoldAccountDetail from 'components/GoldAccount';
import BotInfo from "components/BotInfo";

//This page will show all the statistic that people needed
const DashboardLayout = ({accountInfo,...pages}) => {
  return (
    <PageLayout
      breadcrumList={[{ title: t1("dashboard_page") }]}
    >
      <h5 className="mt-2 mb-3">{t1("dashboard_page")}</h5>
      <Row gutter={[16,16]} className="mb-5">
        <Col span={24}>
          <Card
            headStyle={{minHeight: '40px', padding: '0 10px'}}
            title={<p className="fs-6 mb-0">{t1("summary_your_trading_action")}</p>}
            bordered={false}
            extra={<a style={{ fontSize: '11px', marginLeft: '2px' }}href="/point/add">{t1('click_to_add_point')}</a>}
          >
            <Row>
              <Col lg={6} md={6} sm={12} xs={24}>
                <Statistic title={t1("current_point")} value={accountInfo?.balance} valueStyle={{ color: '#3f8600' }}/>
              </Col>
              <Col lg={18} md={18} sm={12} xs={24}>
                <GoldAccountDetail/>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24} style={{height: '100%'}}>
          <Card
            headStyle={{minHeight: '40px', padding: '0 10px'}}
            title={<p className="fs-6 mb-0">{t1("summary_your_trading_action")}</p>}
            bordered={false}
            extra={<a style={{ fontSize: '11px', marginLeft: '2px' }} href="/market">{t1('click_to_market')}</a>}
          >
            <Row>
              <Col lg={8} md={8} sm={12} xs={24}>
                <Statistic title={t1("total_listing")} value={Math.floor(Math.random() * 100)} valueStyle={{ color: '#3f8600' }}/>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <Statistic title={t1("success_listing")} value={Math.floor(Math.random() * 100)} valueStyle={{ color: '#cf1322' }}/>
              </Col>
              <Col lg={8} md={8} sm={12} xs={24}>
                <Statistic title={t1("total_income")} value={Math.floor(Math.random() * 1000000)} valueStyle={{ color: '#3f8600' }} suffix={'đ'}/>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={12} md={12} sm={24} xs={24}>
          <Card
            headStyle={{minHeight: '40px', padding: '0 10px'}}
            title={<p className="fs-6 mb-0">{t1("dedicated_bot_information")}</p>}
            bordered={false}
            extra={<a style={{ fontSize: '11px', marginLeft: '2px' }} href="/deposit">{t1('click_to_deposit')}</a>}
          >
            <Row>
              <Col span={20}>
                <BotInfo/>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <Card
            headStyle={{minHeight: '40px', padding: '0 10px'}}
            title={<p className="fs-6 mb-0">{t1("character_connected")}</p>}
            bordered={false}
            extra={<a style={{ fontSize: '11px', marginLeft: '2px' }} href="/user-info/character">{t1('click_to_add_character')}</a>}
          >
            <Row>
              <Col span={24}>
                <CharacterDetail/>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col lg={6} md={6} sm={24} xs={24}>
          <Card
            headStyle={{minHeight: '40px', padding: '0 10px'}}
            title={<p className="fs-6 mb-0">{t1("current_item_in_inventory")}</p>}
            bordered={false}
            extra={<a style={{ fontSize: '11px', marginLeft: '2px' }} href="/withdraw">{t1('click_to_withdraw')}</a>}
          >
            <Row>
              <Col span={24}>
                <InventoryList/>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </PageLayout>
  );
};

const mapStateToProps = (state,props) => {
    return {
        accountInfo: state?.profile?.account
    }
}

export default connect(mapStateToProps)(DashboardLayout);
