import React, { useState } from "react";
import Steps from "antd/lib/steps";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { t1 } from "translate";
import { stepOneSteps } from "pages/deposit/constants/stepDetailSteps";
import dayjs from "dayjs";

/**
 * This components will work like description below:
 *
 * 1/ Show text: Your account must show up at downhang (show countdown 5 minutes - stop when character show up
 * - chat bot will update this data and notify all socket all at once - when search and see account name around the area (must be really close to location because
 * bot cannot trade too far)), also force them to have enough space, otherwise they will get block 5 mins from withdraw if bot cannot make trade due to full of inventory
 * 2/ User type capcha to allow bot login without any problem (run countdown 1 min)
 * 3/ Bot ready in => move to step 2
 * Bot cannot login => block this account from withdraw for 5 mins (also release thier item to unlock state) (will need to go through voiding trade transaction
 * 1st, remember to check case when somehow item listed in 2 withdraw trade)
 *
 */

const StepOne = ({
  nextStep,
  setCurrentPercent,
  startDeposit,
  currentTransactionData,
  offerItemList = [],
}) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [timeStartStep, setTimeStartStep] = useState(
    dayjs().unix() * 1000
  );

  return (
    <Row gutter={[16, 16]}>
      <Col span={24} className="text-center">
        <h5>{t1("step_1")}</h5>
      </Col>
      <Col span={24}>
        <Steps
          progressDot
          current={currentStep}
          items={stepOneSteps(
            timeStartStep,
            currentStep,
            nextStep,
            setCurrentPercent,
            startDeposit,
            currentTransactionData,
            offerItemList
          )}
        />
      </Col>
      <Col span={24}>
        {stepOneSteps(
          timeStartStep,
          currentStep,
          nextStep,
          setCurrentPercent,
          setCurrentStep,
          startDeposit,
          currentTransactionData,
          offerItemList
        )[currentStep]?.component ? (
          stepOneSteps(
            timeStartStep,
            currentStep,
            nextStep,
            setCurrentPercent,
            setCurrentStep,
            startDeposit,
            currentTransactionData,
            offerItemList
          )[currentStep]?.component
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

export default StepOne;
