import { t1 } from 'translate';
import { composeValidators, required, emailValidator } from 'validations/index';

const loginSchema = {
    email: {
        type: 'text',
        label: t1('email'),
        validate: composeValidators(required, emailValidator),
        errorText: {
            required: t1('email_is_require'),
            emailInvalid: t1('email_is_not_valid')
        },
    },
    password: {
        type: 'password',
        label: t1('password'),
        validate: composeValidators(required),
        errorText: {
            required: t1('password_is_require'),
        },
    },
    agree_tos: {
        type: 'checkbox',
        hideLabel: true,
        label: t1('agree_to_our_terms_of_service'),
        mode: 'single',
        option: {
            label: t1('agree_to_our_terms_of_service'),//will need to be open modal global here
        },
        validate: composeValidators(required),
        errorText: {
            required: t1('agree_tos_is_require'),
        },
    }
}

export default loginSchema