import React, { useState, useMemo } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Collapse from 'antd/lib/collapse';
import { GUIDANCE_LIST } from "constants/guidance";

import searchIcon from '../../../assets/icons/common/searchIcon.svg';
import styles from './styles.module.scss';

//This page will list all video to guide user how to use the system
const BlogGuidanceVideoLayout = (props) => {
  const [guidanceVideoKeySearch, setGuidanceVideoKeySearch] = useState('');

  const onChange = (key) => {
    console.log({key});
  };

  const guidanceVideoShowList = useMemo(() => {
    if (!!guidanceVideoKeySearch) {
      return GUIDANCE_LIST.filter((item) => item.label.includes(guidanceVideoKeySearch));
    }

    return GUIDANCE_LIST;
  }, [guidanceVideoKeySearch]);

  return <PageLayout>
      <div className={styles.guidanceContainer}>
        <div className={styles.header}>
          <span className={styles.title}>
            {t1('blog_guidance_video_layout')}
          </span>
        </div>
        <div className={styles.content}>
          <div className={styles.searchBox}>
            <input
              type="text"
              className={styles.search}
              placeholder={t1('question_title')}
              onChange={(e) => setGuidanceVideoKeySearch(e.target.value)}
            />
            <img src={searchIcon} alt="search icon" />
          </div>
          <div className={styles.guidanceVideos}>
            <Collapse
              items={guidanceVideoShowList}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
  </PageLayout>
}

export default BlogGuidanceVideoLayout;