import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import Table from "antd/lib/table";
import { t1 } from "translate";
import DataFetchHook from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { SERVER_LIST } from "constants/game";

import styles from './styles.module.scss';

//This page will list all the bot currently status, thier available, how many queue they are still in or are they all maintained
const WithdrawBotStatusLayout = (props) => {
  const [ totalBotSlot, setTotalBotSlot ] = useState(0);
  const [ botList, setBotList ] = useState([]);

  const [data,updateEndpointBot,updateParamsBot] = DataFetchHook(endpoints.bot_list,{submit: 1, limit: 99999}, 'GET', (result,params) => {
    setBotList(result?.data);
  })

  const [dataSlot,updateEndpointSlot,updateParamsSlot] = DataFetchHook(endpoints.number_bot_free,{submit: 1, limit: 99999}, 'GET', (result,params) => {
    setTotalBotSlot(result?.data?.free);
  })

  const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

  const columns = [
    {
      title: <span style={{fontSize: '13px'}}>{t1('bot_name')}</span>,
      dataIndex: 'char_name',
      key: 'char_name',
      render: (text,record) => {
        return (
          <div style={{
            display: 'flex',
            flexDirection: 'column',
          }}>
            <div style={{position: 'relative', fontSize: '15px', fontWeight: '700', width: 'fit-content'}}>
              {text}
              <div
                style={{
                  position: 'absolute',
                  top: '8px',
                  right: '-10px',
                  width: '7px',
                  height: '7px',
                  background: record.online_status ? '#9ee06c' : '#ed3300',
                  borderRadius: '20px'
                }}
              />
            </div>
            <span>Server: {getServer(record.server_id)}</span>
          </div>
        )
      },
    },
    {
      title: <span style={{fontSize: '13px'}}>{t1('status')}</span>,
      dataIndex: 'active_status',
      key: 'active_status',
      render: (status) => (
        <div style={{textAlign: 'center'}}>
          <Tag color={status ? 'green' : 'volcano'} style={{fontSize: '10px', fontWeight: '600', padding: `${status ? '0px 10px' : '0px 3px'}`}}>
            {status ? t1('active') : t1('inactive')}
          </Tag>
        </div>
      ),
    },
    {
      title: <span style={{fontSize: '13px'}}>{t1('storage')}</span>,
      dataIndex: 'character_total_storage_slot',
      key: 'character_total_storage_slot',
      render: (data,record,index) => {
        return <div style={{textAlign: 'center'}}>{`${record?.empty_storage_number}/${record?.character_total_storage_slot}`}</div>
      },
    }
  ];

  return (
    <PageLayout breadcrumList={[{ title: t1("withdraw_bot_status") , link: '/withdraw/status'}]}>
      <Row className={`mt-4 ${styles.botTable}`}>
        <Col span={24}>
          <Card title={t1("withdraw_bot_status_layout_(%s)",[totalBotSlot])} style={{ borderRadius: "5px" }}>
            <Row>
              <Col span={24}>
                <Table
                  style={{ width: '100%' }}
                  columns={columns}
                  dataSource={botList}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default WithdrawBotStatusLayout;
