import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const { Title, Paragraph, Text } = Typography;

const Step1 = ({setCurrentPercent}) => {
  return (
    <Row>
      <Col span={24}>
        <Typography>
          <Title>{t1("submit_item_to_offer")}</Title>
          <Paragraph>
            {t1(
              "you_need_to_put_enough_items_that_you_want_to_deposit_from_start"
            )}
            {t1(
              "after_that_you_need_to_confirm_the_trade_1_click_only,_bot_will_automatically_finished_this_step_for_you"
            )}
          </Paragraph>
          <Text type="danger">
              {t1(
                "failed_to_come_to_location_in_time_will_make_you_blocked_from_deposit_function_for_at_least_2_mins"
              )}
            </Text>
        </Typography>
      </Col>
    </Row>
  );
};

export default Step1;
