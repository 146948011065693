import { t1 } from 'translate';

const schema = {
    level_start: {
        type: 'number',
        label: t1('level_start'),
        handleInputChange: (e) => {}
    },
    level_end: {
        type: 'number',
        label: t1('level_end'),
        handleInputChange: (e) => {}
    }
}

export default schema;