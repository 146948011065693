import React, { useState } from "react";
import { connect } from "react-redux";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import FormCreator from "commons/final-form/core/FormCreator";
import userIdentifySchema from "schema-form/schema/userIdentifySchema";
import userIdentifyUI from "schema-form/ui/userIdentifyUI";
import userIdentifyLayout from "schema-form/layout/userIdentifyLayout";
import {t1 } from 'translate';
import Button from 'antd/lib/button';
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Message from "antd/lib/message";

const UserIdentifyBlock = ({ profile, reloadProfile }) => {
    const [editGeneral, setEditGeneral] = useState(false);

    const [data, UpEnpoints, setUpdateParams] = useFetch(
        endpoints.user_update,
        "",
        "post",
        (result, params) => {
            Message.success("Update successful");
            setEditGeneral(false);
            reloadProfile();
        },
        (result, params) => {
            if (!result?.success) {
                Message.error(result?.message || "Update failed");
            }
        }
    );

    const onSubmitUpdate = (values) => {
        const updateParams = {
            identify_card_type: values?.identify_card_type,
            identify_card_number: values?.identify_card_number,
            identify_name: values?.identify_name
        };
        setUpdateParams(updateParams);
    };

    return (
        <Card title={null} style={{ borderRadius: "5px" }}>
            <Row gutter={[16, 24]}>
                <Col span={24} md={12}>
                    <FormCreator
                        debugMode={false}
                        initialValues={profile}
                        schema={userIdentifySchema}
                        ui={userIdentifyUI}
                        uiId={"default"}
                        layout={userIdentifyLayout}
                        mode={editGeneral ? "edit" : "view"}
                        SubmitButton={
                            editGeneral ? (
                                (form) => {
                                    return (
                                        <div>
                                            <Button
                                                className="me-2"
                                                onClick={(e) => {
                                                    form.reset();
                                                    setEditGeneral(false);
                                                }}
                                            >
                                                {t1("cancel")}
                                            </Button>
                                            <Button
                                                type="primary"
                                                onClick={(e) => {
                                                    form.submit();
                                                    setEditGeneral(false);
                                                }}
                                            >
                                                {t1("save")}
                                            </Button>
                                        </div>
                                    );
                                }
                            ) : (
                                <></>
                            )
                        }
                        onSubmit={(e) => onSubmitUpdate(e)}
                    />
                </Col>
                <Col span={24} md={2}>
                    {editGeneral ? (
                        <></>
                    ) : (
                        <div>
                            <Button type="primary" onClick={(e) => setEditGeneral(true)}>
                                {t1("update_info")}
                            </Button>
                        </div>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

const mapStatesToProps = (state, props) => {
    return {
        profile: state?.profile?.profile || {},
        reloadProfile: state?.profile?.reloadProfile,
    };
};

export default connect(mapStatesToProps)(UserIdentifyBlock);
