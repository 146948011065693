import React from "react";
import PageLayout from "components/PageLayout";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import { t1 } from 'translate';
import { DATEFORMAT2 } from 'constants';
import TableCustom from "components/TableCustom";

const MarketHistoryLayout = (props) => {
  const actionType = [
    {
      key: 1,
      title: 'Market',
      color: 'green'
    },
    {
      key: 2,
      title: 'Auction',
      color: 'blue'
    }
  ];

  const marketStatus = [
    {
      key: 1,
      title: 'Success',
      color: 'green'
    },
    {
      key: 2,
      title: 'Not Complete',
      color: 'red'
    },
    {
      key: 3,
      title: 'Deposit',
      color: 'blue'
    },
  ];

  const columns = [
    {
      title: t1('date'),
      dataIndex: 'date',
      key: 'date',
      isShortCut: true,
      render: (text) => (
        <span>{!!text ? dayjs(text).format(DATEFORMAT2) : ''}</span>
      ),
    },
    {
      title: t1('action_type'),
      dataIndex: 'action_type',
      key: 'action_type',
      isShortCut: true,
      render: (action_type) => {
        const actionTypeDetail = actionType.find((action) => action.key === action_type) ?? '';
        if (!actionTypeDetail) return '';

        return (
          <Tag color={actionTypeDetail?.color}>
            {actionTypeDetail?.title}
          </Tag>
        )
      },
    },
    {
      title: t1('item_name'),
      dataIndex: 'item_name',
      key: 'item_name',
      isShortCut: true,
    },
    {
      title: t1('pricing_gold'),
      dataIndex: 'pricing_gold',
      key: 'pricing_gold',
    },
    {
      title: t1('pricing_point'),
      dataIndex: 'pricing_point',
      key: 'pricing_point',
    },
    {
      title: t1('status'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const actionTypeDetail = marketStatus.find((action) => action.key === status) ?? '';
        if (!actionTypeDetail) return '';

        return (
          <Tag color={actionTypeDetail?.color}>
            {actionTypeDetail?.title}
          </Tag>
        )
      },
    }
  ];

  const data = [
    {
      key: '1',
      date: dayjs(),
      action_type: 1,
      item_name: 'item 1',
      pricing_gold: '100000',
      pricing_point: '10',
      status: 1,
    },
    {
      key: '2',
      date: dayjs(),
      action_type: 2,
      item_name: 'item 2',
      pricing_gold: '20000',
      pricing_point: '20',
      status: 1,
    },
    {
      key: '3',
      date: dayjs(),
      action_type: 2,
      item_name: 'item 3',
      pricing_gold: '1000000',
      pricing_point: '100',
      status: 2,
    },
    {
      key: '4',
      date: dayjs(),
      action_type: 1,
      item_name: 'item 4',
      pricing_gold: '',
      pricing_point: '100',
      status: 3,
    },
    {
      key: '5',
      date: dayjs(),
      action_type: 1,
      item_name: 'item 5',
      pricing_gold: '100000',
      pricing_point: '10',
      status: 3,
    },
  ];

  const renderDetailTemplate = (data) => {
    return (
      <div>
        <p>
          <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('date')}:</span>
          <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>
            {!!data?.date ? dayjs(data?.date).format(DATEFORMAT2) : ''}
          </span>
        </p>
        <p>
          <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('action_type')}:</span>
          {() => {
            const actionTypeDetail = actionType.find((action) => action.key === data?.action_type) ?? '';
            if (!actionTypeDetail) return '';
    
            return (
              <Tag color={actionTypeDetail?.color}>
                {actionTypeDetail?.title}
              </Tag>
            )
          }}
        </p>
        <p>
          <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('item_name')}:</span>
          <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>{data?.item_name}</span>
        </p>
        <p>
          <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('pricing_gold')}:</span>
          <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>{data?.pricing_gold}</span>
        </p>
        <p>
          <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('pricing_point')}:</span>
          <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>{data?.pricing_point}</span>
        </p>
        <p>
          <span style={{fontSize: '15px', fontWeight: 'bold', marginRight: '5px'}}>{t1('status')}:</span>
          <span style={{fontSize: '15px', fontWeight: '500', color: "#8e8282"}}>{
            () => {
              const actionTypeDetail = marketStatus.find((action) => action.key === data?.status) ?? '';
              if (!actionTypeDetail) return '';
        
              return (
                <Tag color={actionTypeDetail?.color}>
                  {actionTypeDetail?.title}
                </Tag>
              )
            }
          }</span>
        </p>
      </div>
    );
  };
  
  return <PageLayout>
    <Col span={24}>
      <Card title={t1('market_history')} style={{ borderRadius: "5px" }}>
        <Row>
          <Col span={24}>
            <TableCustom
              isShortCut={true}
              columns={columns}
              data={data}
              pagination={{pageSize: 10}}
              detailTemplate={renderDetailTemplate}
            />
          </Col>
        </Row>
      </Card>
    </Col>
  </PageLayout>
}

export default MarketHistoryLayout;
