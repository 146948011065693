import { t1 } from "translate";

export const columnsSell = [
    {
        title: t1("point_price_per_mil"),
        dataIndex: "price_per_unit",
        key: "price_per_unit",
    },
    {
        title: t1("total_gold_amount_in_mil"),
        dataIndex: "quantity",
        key: "quantity",
        render: (data, row, index) => {
            return Math.floor(data).toLocaleString();
        },
    },
];

export const columnsBuy = [
    {
        title: t1('point_price_per_mil'),
        dataIndex: 'price_each_unit',
        key: 'price_each_unit',
    },
    {
        title: t1('total_gold_amount_in_mil'),
        dataIndex: 'quantity',
        key: 'quantity',
        render: (data,row,index) => {
            return Math.floor(data).toLocaleString()
        }
    },
];
