export const getQueryParams = (query, params) => {
  const outputParams = {}
  const queryParams = new URLSearchParams(query)

  params.forEach(field => {
    outputParams[field] = queryParams.get(field)
  });
  return outputParams;
}

export const copyText = (text) => navigator.clipboard.writeText(text);

export const formatPrice = (price) => price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
