const createGoldItemMarketUI = {
  default: {
    fields: [
      'amount',
      'preViewAmount',
      'price',
      'preViewPriceEachUnit'
    ],
  },
};

export default createGoldItemMarketUI;
