import { t1 } from 'translate';

const MONSTER_AREA_TEXT_FIND = [
    {
        label: t1('china'),
        value: 'MOB_CH_'
    },{
        label: t1('dondoang'),
        value: 'MOB_WC_'
    },{
        label: t1('oasis_area'),
        value: 'MOB_OA_'
    },{
        label: t1('hoadien_area'),
        value: 'MOB_KT_'
    },{
        label: t1('kalakoram_area'),
        value: 'MOB_KK_'
    },{
        label: t1('europe_area'),
        value: 'MOB_EU_'
    },{
        label: t1('asia_minor_area'),
        value: 'MOB_AM_'
    },{
        label: t1('center_asia_area'),
        value: 'MOB_CA_'
    },{
        label: t1('center_asia_area'),
        value: 'MOB_CA_'
    },{
        label: t1('dh_cave_area'),
        value: 'MOB_DH_'
    },{
        label: t1('talamakan_area'),
        value: 'MOB_TK_'
    },{
        label: t1('roc_mountain_area'),
        value: 'MOB_RM_'
    },{
        label: t1('tomb_qin_area'),
        value: 'MOB_TQ_'
    },{
        label: t1('forgotten_world_area'),
        value: 'MOB_GOD_'
    }
];

const schema = {
    code_include: {
        type: 'select',
        label: t1('monster_area'),
        options: MONSTER_AREA_TEXT_FIND
    },
    level_start: {
        type: 'number',
        label: t1('level_start'),
        handleInputChange: (e) => {}
    },
    level_end: {
        type: 'number',
        label: t1('level_end'),
        handleInputChange: (e) => {}
    }
}

export default schema;