const registerUI = {
    default: {
        fields: [
            'user_name',
            'email',
            'password',
            'confirm_password',
            'agree_tos'
        ]
    }
}

export default registerUI;