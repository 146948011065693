import { endpoints } from "constants/endpoints";
import { t1 } from "translate";

const loginEndpoint = {
    url: endpoints.login,
    outputData: (params) => {
        //output data here
        if (params?.email && params?.password) {
            return {
                success: true,
                token: "token-fake",
            };
        } else {
            return {
                success: false,
                message: t1("invalid_params"),
            };
        }
    },
};

export default loginEndpoint;
