import MarketLayout from ".";
import MarketDealLayout from './deal';
import MarketHistoryLayout from './history';
import { MENU_LINK_MARKET, MENU_LINK_MARKET_DEAL, MENU_LINK_MARKET_HISTORY } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_MARKET,
        exact: true,
        component: <MarketLayout />,
    },{
        path: MENU_LINK_MARKET_DEAL,
        exact: true,
        component: <MarketDealLayout />,
    },{
        path: MENU_LINK_MARKET_HISTORY,
        exact: true,
        component: <MarketHistoryLayout />,
    }
];

export default routeSchema;