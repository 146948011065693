import SettingsPersonalLayout from "./personal";
import SettingsNotificationLayout from './notification';
import { MENU_LINK_SETTINGS_PERSONAL, MENU_LINK_SETTINGS_NOTIFICATION } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_SETTINGS_PERSONAL,
        exact: true,
        component: <SettingsPersonalLayout />,
    },
    {
        path: MENU_LINK_SETTINGS_NOTIFICATION,
        exact: true,
        component: <SettingsNotificationLayout />,
    }
];

export default routeSchema;