import React from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { MENU_LINK_USER_INFO_EDIT } from 'constants/menu-link'
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { setProfileAction } from 'redux/actions';

const UserInfoWrapper = ({profile, dispatch , children}) => {
    const navigate = useNavigate();
    const location = useLocation();

    if (profile && !profile?.updated){
        if (location.pathname !== MENU_LINK_USER_INFO_EDIT){
            navigate(MENU_LINK_USER_INFO_EDIT)
        }
    }else{
        //keep it this way
    }

    return <>{children}</>
}

const mapStatesToProps = (state,props) => {
    return {
        profile: state?.profile?.profile || false
    }
}

export default connect(mapStatesToProps)(UserInfoWrapper);