import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Pagination from 'antd/lib/pagination';
import ItemDetail from "components/ItemDetail";
import notification from "antd/lib/notification";
import Modal from "antd/lib/modal";
import FormCreator from "commons/final-form/core/FormCreator";
import createListingSchema from "schema-form/schema/createListingSchema";
import createListingUI from "schema-form/ui/createListingUI";
import createListingLayout from "schema-form/layout/createListingLayout";
import createGoldItemLayout from "schema-form/layout/createGoldItemLayout";
import createGoldItemSchema from "schema-form/schema/createGoldItemSchema";
import createGoldItemUI from "schema-form/ui/createGoldItemUI";
import createListingGoldLayout from "schema-form/layout/createListingGoldLayout";
import createListingGoldSchema from "schema-form/schema/createListingGoldSchema";
import createListingGoldUI from "schema-form/ui/createListingGoldUI";
import Button from "antd/lib/button";
import GoldAccount from "components/GoldAccount";
import { connect } from 'react-redux';
import ItemGoldDetail from "components/ItemDetail/ItemGoldDetail";
import { ITEM_GOLD_MODEL_ID } from "constants";

//This page will show all current item, gold, point that user currently have
const InventoryLayout = ({profile}) => {
    const [itemList, setItemList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [modalOpen,setModalOpen] = useState(false);
    const [modalGoldOpen,setModalGoldOpen] = useState(false);
    const [currentItem, setCurrentItem] = useState({});

    const [indexServer,setIndexServer] = useState(0);
    const [goldModal,setGoldModal] = useState(false);

    const [dataCreateGold, setEndpointCreateGold, setParamsCreateGold] = useFetch(
        endpoints.create_gold_item,
        '',
        "POST",
        (result, params) => {
            if (profile?.reloadProfile){
                profile?.reloadProfile();
            }
            setGoldModal(false);
            setParamsInventory('');
            setParamsInventory({
                submit: 1,
                page: page,
                limit: pageSize,
                status: 'active' 
            })
        },
        (result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
        }
    )

    const [dataInventory, setEndpointInventory, setParamsInventory] = useFetch(
        endpoints.my_items,
        { submit: 1, page: page, limit: pageSize, status: 'active' },
        "GET",
        (result, params) => {
            setItemList(result?.data);
            setPage(result?.meta?.current_page);
            setTotal(result?.meta?.total);
            setPageSize(result?.meta?.per_page);
        },
        (result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
        }
    )

    const [data, setEndpoint, setParams] = useFetch(
        endpoints.set_price,
        '',
        "POST",
        (result, params) => {
            setModalOpen(false);
            setModalGoldOpen(false);
            setParamsInventory('');
            setParamsInventory({
                submit: 1,
                page: page,
                limit: pageSize,
                status: 'active' 
            })
        },
        (result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
        }
    )

    const [dataCancel, setEndpointCancel, setParamsCancel] = useFetch(
        endpoints.close_price,
        '',
        "POST",
        (result, params) => {
            setModalOpen(false);
            setParamsInventory('');
            setParamsInventory({
                submit: 1,
                page: page,
                limit: pageSize,
                status: 'active' 
            })
        },
        (result, params) => {
            setModalOpen(false);
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
        }
    )
    
    const [dataCancelGold, setEndpointCancelGold, setParamsCancelGold] = useFetch(
        endpoints.cancel_gold_listing,
        '',
        "POST",
        (result, params) => {
            if (profile?.reloadProfile){
                profile?.reloadProfile();
            }
            setModalGoldOpen(false);
            setParamsInventory('');
            setParamsInventory({
                submit: 1,
                page: page,
                limit: pageSize,
                status: 'active' 
            })
        },
        (result, params) => {
            setModalGoldOpen(false);
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
        }
    )



    const onSubmit = (data) => {
        setParams('');
        setParams({
            item_id: currentItem?.id,
            ...data
        })
    }

    const onSubmitGold = (data) => {
        setParams('');
        setParams({
            item_id: currentItem?.id,
            concurrency: 0,
            ...data
        })
    }

    const onSubmitCreateGold = (data) => {
        setParamsCreateGold('');
        setParamsCreateGold({
            amount: data?.amount,
            server_id: indexServer + 1
        });
    }

    return <PageLayout>
        {t1('inventory_info')}
        <Card className="mb-2">
            <Row>
                <Col span={24}>
                    <GoldAccount showCreateListingButton={true} action={(sIndex) => {
                        setIndexServer(sIndex);
                        setGoldModal(true);
                    }}/>
                </Col>
            </Row>
        </Card>
        <Card>
            <Row>
                <Col span={24}>
                    <h6 className="mb-3">{t1('item_list_in_inventory_(%s)',[total])}</h6>
                </Col>
                <Col span={24}>
                    <Row gutter={[16,8]}>
                        {itemList?.map((item) => {
                            return <Col span={24}>
                                {
                                    item?.model_id == ITEM_GOLD_MODEL_ID ? <ItemGoldDetail data={item} action={(itemInfo) => {
                                        if (['active','selling'].includes(itemInfo?.status)){
                                            setCurrentItem(itemInfo);
                                            setModalGoldOpen(true);
                                        }
                                    }} /> : (
                                        <ItemDetail
                                            isSmallPic={true}
                                            data={item}
                                            action={(itemInfo, callBack) => 
                                                <Button type="primary" danger={item?.status === 'selling' ? true : false} color="red" onClick={() => {
                                                    if (['active','selling'].includes(itemInfo?.status)){
                                                        setCurrentItem(itemInfo);
                                                        setModalOpen(true);
                                                        callBack(false);
                                                    }
                                                }}>
                                                    {item?.status === 'selling' ? t1('cancel_listing') : t1('sell_item')}
                                                </Button>
                                            }
                                        />
                                    )
                                }
                            </Col>
                        })}
                    </Row>
                </Col>
                <Col className="mt-2" span={24} style={{ textAlign: 'right' }}>
                    <Pagination defaultCurrent={page} total={total} pageSize={pageSize} onChange={(pageNumber,pageSizeNumber) => {
                        setParamsInventory('');
                        setParamsInventory({
                            submit: 1,
                            page: pageNumber,
                            limit: pageSizeNumber,
                            status: 'active' 
                        })
                    }}/>
                </Col>
            </Row>
        </Card>
        <Modal title={currentItem?.latest_open_price_id ? t1('cancel_listing') : t1('set_selling_price')} open={modalGoldOpen} footer={null} onCancel={(e) => setModalGoldOpen(false)}>
            {currentItem?.latest_open_price_id ? <Row>
                <Col span={24} className="text-center">
                    <p>{t1('do_you_want_to_cancel_listing_of_this_gold_item')}</p>
                    <Button className="me-2" onClick={(e) => {
                        setModalGoldOpen(false);
                    }}>{t1('click_to_close_modal')}</Button>
                    <Button type="primary" danger onClick={(e) => {
                        setParamsCancelGold('');
                        setParamsCancelGold({
                            item_id: currentItem?.id
                        })
                    }}>{t1('click_to_cancel_listing')}</Button>
                </Col>
            </Row>: <FormCreator
                schema={createListingGoldSchema}
                ui={createListingGoldUI}
                layout={createListingGoldLayout}
                onSubmit={onSubmitGold}
                initialValues={{price: 10}}
                SubmitButton={(form) => {
                    return <Button onClick={(e) => form.submit()}>{t1('click_to_create_listing')}</Button>
                }}
            />}
        </Modal>
        <Modal title={t1('create_gold_item')} open={goldModal} footer={null} onCancel={(e) => setGoldModal(false)}>
            <FormCreator
                schema={createGoldItemSchema(profile?.account?.gold_accounts[indexServer])}
                ui={createGoldItemUI}
                layout={createGoldItemLayout}
                onSubmit={onSubmitCreateGold}
                initialValues={{amount: 0}}
                SubmitButton={(form) => {
                    return <Button onClick={(e) => form.submit()}>{t1('click_to_create_gold_item_for_selling')}</Button>
                }}
            />
        </Modal>
        <Modal title={currentItem?.latest_open_price_id ? t1('cancel_listing') : t1('set_selling_price')} open={modalOpen} footer={null} onCancel={(e) => setModalOpen(false)}>
            {currentItem?.latest_open_price_id ? <Row>
                <Col span={24} className="text-center">
                    <p>{t1('do_you_want_to_cancel_listing_of_this_item')}</p>
                    <Button
                        type="primary"
                        danger
                        className="mb-2"
                        style={{width: '80%'}}
                        onClick={(e) => {
                            setParamsCancel('');
                            setParamsCancel({
                                item_id: currentItem?.id
                            })
                        }}
                    >
                        <span style={{fontSize: '13px'}}>{t1('click_to_cancel_listing')}</span>
                    </Button>
                    <Button onClick={(e) => {setModalOpen(false)}} style={{width: '80%'}}>
                        <span style={{fontSize: '13px'}}>{t1('click_to_close_modal')}</span>
                    </Button>
                </Col>
            </Row>: <FormCreator
                schema={createListingSchema}
                ui={createListingUI}
                layout={createListingLayout}
                onSubmit={onSubmit}
                initialValues={{concurrency: 0, price: 10}}
                SubmitButton={(form) => {
                    return <Button onClick={(e) => form.submit()}>{t1('click_to_create_listing')}</Button>
                }}
            />}
        </Modal>
    </PageLayout>
}

const mapStateToProps = (state,props) => {
    return {
        profile: state?.profile 
    }
}

export default connect(mapStateToProps)(InventoryLayout);
