import React from "react";
import Rate from 'antd/lib/rate';
import { HeartOutlined } from '@ant-design/icons';

const RateFieldAntd = ({input, meta, count = 5, allowHalf = false, character = <HeartOutlined/>,...rest}) => {
    return <Rate
        {...input}
        {...rest}
        count={count}
        allowHalf={allowHalf}
        character={character}
        onChange={(valueChange) => input.onChange(valueChange)}
    />
}

export default RateFieldAntd;