import { t1 } from 'translate';
import StepOne from '../components/StepOne'
import StepTwo from '../components/StepTwo'
import StepThree from '../components/StepThree'
import StepFour from '../components/StepFour'
import StepFive from '../components/StepFive'

const depositSteps = (descriptionList = [], nextStep, setCurrentPercent,startDeposit,currentTransactionData,offerItemList=[]) => {
    return [
        {
            id: 0,
            key: 0,
            title: t1('step_one'),
            description: descriptionList.find((element) => element.id == 0) || t1('description_step_one'),
            content: <StepOne nextStep={nextStep} setCurrentPercent={setCurrentPercent} startDeposit={startDeposit} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>,
            //totalTime: 480,//500 seconds
        },
        {
            id: 1,
            key: 1,
            title: t1('step_two'),
            description: descriptionList.find((element) => element.id == 1) || t1('description_step_two'),
            content: <StepTwo nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>,
            totalTime: 100,//500 seconds
        },
        /*{
            id: 2,
            key: 2,
            title: t1('step_three'),
            description: descriptionList.find((element) => element.id == 2) || t1('description_step_three'),
            content: <StepThree nextStep={nextStep} setCurrentPercent={setCurrentPercent}/>,
            totalTime: 300,//500 seconds
        },
        {
            id: 3,
            key: 3,
            title: t1('step_four'),
            description: descriptionList.find((element) => element.id == 3) || t1('description_step_four'),
            content: <StepFour nextStep={nextStep} setCurrentPercent={setCurrentPercent}/>
        },
        {
            id: 4,
            key: 4,
            title: t1('step_five'),
            description: descriptionList.find((element) => element.id == 4) || t1('description_step_five'),
            content: <StepFive nextStep={nextStep} setCurrentPercent={setCurrentPercent}/>
        }*/
    ];
}

export default depositSteps;