import React from "react";

export const CHARACTER_LIST = [{
    label: <><img src="/images/char_ch_woman11.jpg" style={{width: '50px'}}/><span className="ps-2">StopperNo1</span> - asian server</>,
    value: 1,
    name: "StopperNo1",
    icon: "/images/char_ch_woman11.jpg"
},{
    label: <><img src="/images/char_ch_man11.jpg" style={{width: '50px'}}/><span className="ps-2">StopperNo2</span> - asian_eu server</>,
    value: 2,
    name: "StopperNo2",
    icon: "/images/char_ch_man11.jpg"
}];