import { SET_DOMAIN_INFO } from "redux/action-types";
import lGet from 'lodash/get';

const initialState = {
    isStaff: false
};

const LocalStorageReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DOMAIN_INFO: {
            return Object.assign({},state,lGet(action,"payload",{}));
        }
        default:
            return state;
    }
}

export default LocalStorageReducer;
