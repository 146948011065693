import React, { useState } from "react";
import { connect } from "react-redux";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import FormCreator from "commons/final-form/core/FormCreator";
import bankAccountSchema from "schema-form/schema/bankAccountSchema";
import bankAccountUI from "schema-form/ui/bankAccountUI";
import bankAccountLayout from "schema-form/layout/bankAccountLayout";
import { t1 } from "translate";
import Button from "antd/lib/button";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Message from "antd/lib/message";

const BankAccountBlock = ({ profile, reloadProfile, bank }) => {
    const [currentBankAccount, setCurrentBankAccount] = useState(false);

    const [data, UpEnpoints, setUpdateParams] = useFetch(
        endpoints.create_bank_account,
        "",
        "post",
        (result, params) => {
            Message.success("Created successful");
            reloadProfile();
        },
        (result, params) => {
            if (!result?.success) {
                Message.error(result?.message || "Created failed");
            }
        }
    );

    const onSubmitUpdate = (values) => {
        const updateParams = {
            bank_name: values?.bank_name,
            bank_short_name: values?.bank_short_name,
            account: values?.account,
            name: values?.name,
        };
        setUpdateParams(updateParams);
    };

    return (
        <Card title={null} style={{ borderRadius: "5px" }}>
            <Row gutter={[16, 24]}>
                <Col span={24}>
                    <h5>{t1("current_bank_account_list")}</h5>
                    {bank.length == 0 ? (
                        <p>{t1("you_havent_added_bank_account_yet")}</p>
                    ) : (
                        <Row gutter={[16, 16]}>
                            {bank.map((bankAccount, index) => (
                                <Col span={12} key={index}>
                                    <Button
                                        className="fw-bolder"
                                        type={
                                            currentBankAccount.id === bankAccount.id
                                                ? "primary"
                                                : "dashed"
                                        }
                                        style={{ width: "100%" }}
                                        onClick={() => setCurrentBankAccount(bankAccount)}
                                    >
                                        {bankAccount?.bank_short_name}
                                        <span className="ms-2 fw-400">{bankAccount?.bank_account}</span>
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    )}
                </Col>
                <Col span={24} md={12}>
                    <FormCreator
                        debugMode={false}
                        initialValues={{}}
                        schema={bankAccountSchema}
                        ui={bankAccountUI}
                        uiId={"default"}
                        layout={bankAccountLayout}
                        mode={"edit"}
                        SubmitButton={(form) => {
                            return (
                                <div>
                                    <Button
                                        className="me-2"
                                        onClick={(e) => {
                                            form.reset();
                                        }}
                                    >
                                        {t1("cancel")}
                                    </Button>
                                    <Button
                                        type="primary"
                                        onClick={(e) => {
                                            form.submit();
                                        }}
                                    >
                                        {t1("save")}
                                    </Button>
                                </div>
                            );
                        }}
                        onSubmit={(e) => onSubmitUpdate(e)}
                    />
                </Col>
            </Row>
        </Card>
    );
};

const mapStatesToProps = (state, props) => {
    return {
        profile: state?.profile?.profile || {},
        bank: state?.profile?.banks || [],
        reloadProfile: state?.profile?.reloadProfile,
    };
};

export default connect(mapStatesToProps)(BankAccountBlock);
