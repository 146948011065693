import QuestInfoLayout from "./quest";
import MonsterInfoLayout from "./monster";
import { MENU_LINK_HELPER_QUEST, MENU_LINK_HELPER_MONSTER } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_HELPER_QUEST,
        exact: true,
        component: <QuestInfoLayout />,
    },{
        path: MENU_LINK_HELPER_MONSTER,
        exact: true,
        component: <MonsterInfoLayout />,
    }
];

export default routeSchema;