import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

//This page will list all item that eligible for deposit, out of this list won't show when deposit
const DepositAllowedLayout = (props) => {
    return <PageLayout>
        {t1('deposit_allowed_layout')}
    </PageLayout>
}

export default DepositAllowedLayout;