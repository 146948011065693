import React from "react";
import { SERVER_LIST } from "constants/game";
import { t1 } from "translate";
import {
  getImageLinkFromItemModel,
  getItemType,
} from "./utils";
import styles from "./styles.module.scss";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import ItemStatus from "./ItemStatus";
import Popover from 'antd/lib/popover';
import DetailContent from "./DetailContent";
import { CURRENCY_OPTIONS } from "constants";

const { Title, Paragraph, Text } = Typography;

const MarketGoldListingBlock = ({listingInfo, isSelected = false, isSmallPic = false,...props}) => {
  const { action, isFocus } = props;
  let data = listingInfo;

  const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

  return (
      <Row
        align="middle"
        className={`${styles.itemContainer} ${
          isFocus ? "item-selected" : ""
        } enabled-item`}
        gutter={8}
        onClick={(e) => {
          if (typeof(action) === 'function') {
            action(listingInfo);
          }
        }}
      >
        <Col span={24}>
          <Typography>
            <Title
              ellipsis={{ rows: 2}}
              level={5}
              className={`m-0 item-name-rare`}
            >
              {t1('gold_item')}
            </Title>
            <Text className="text-white">
              {t1("server_:_%s", [
                getServer(data?.server_id),
              ])}
            </Text>
			<Text className="text-white">
				<br/>
				{t1('quantity:_%s',[listingInfo?.item?.quantity])}
				<br/>
				{t1('%s_needed:_',[CURRENCY_OPTIONS[listingInfo?.concurrency].label])}<span>{parseInt(listingInfo?.price)}</span>
            </Text>
          </Typography>
        </Col>
      </Row>
  );
};

export default MarketGoldListingBlock;

