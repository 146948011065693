import React, { useState } from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Input from "antd/lib/input";

const { Title, Paragraph, Text } = Typography;

const Step2 = ({setCurrentPercent}) => {
  const [inputCapcha, setInputCapcha] = useState("");

  return (
    <Row gutter={[8, 8]}>
      <Col span={24}>
        <Typography>
          <Title>{t1("confirm_item_that_bot_recognize")}</Title>
          <Paragraph>
            {t1(
              "the_items_below_has_been_confirmed_by_our_bot_is_the_item_that_you_deposited._hover_over_the_picture_or_click_it_to_show_item_detail._click_button_to_confirm"
            )}
          </Paragraph>
          <Text type="danger">
            {t1(
              "failed_to_confirm_this_will_block_you_from_deposit_function_for_at_least_3_mins"
            )}
          </Text>
        </Typography>
      </Col>
      <Col span={24} className="text-center">
        <p>{t1("item_image_goes_here")}</p>
        {
          ['/images/spear_16.png','/images/sword_12.png','/images/sword_13.png','/images/sword_14.png'].map(itemUrl => {
            return <img src={itemUrl} alt='' style={{display: 'inline-block'}} className="me-2"/>
          })
        }
      </Col>
      <Col span={24} className="text-center">
        <Button onClick={(e) => {
          setCurrentPercent(60);
        }}>{t1("confirm")}</Button>
      </Col>
    </Row>
  );
};

export default Step2;
