import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Steps from "antd/lib/steps";
import Statistic from "antd/lib/statistic";
import Typography from "antd/lib/typography";
import withdrawSteps from '../constants/withdrawSteps';
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import SelectCharacter from "pages/deposit/components/SelectCharacter";
import notification from "antd/lib/notification";
import InputNumber from "antd/lib/input-number";
import { connect } from "react-redux";

//This page will be use to withdraw item in system
/**
	* This components will work like description below:
	* 
	* 1/ Connect to socket (from redux)
	* 2/ Choose character that linked to account + select item (that currently free in inventory) that want to withdraw => click request
	* 3/ Item that in this list will get blocked status prevent them from withdraw and listing onto market
	* 3/ Move to step 1
	* (bot will automatically sign out when trade finished fail somehow)
	*/

const { Countdown } = Statistic;
const { Title } = Typography;

const WithdrawLayout = ({profile}) => {
	const [isWithdraw, setIsWithdraw] = useState(false);

	const [currentStep, setCurrentStep] = useState(0);
	const [currentPercent, setCurrentPercent] = useState(0);
	const [timeStartStep, setTimeStartStep] = useState(0);

	const [character, setCharacter] = useState(false);
	
	const [withdrawTransaction, setWithdrawTransaction] = useState({});
	const [goldWithdraw,setGoldWithdraw] = useState(0);

	const [dataWithdraw, setEndpointWithdraw, setParamsWithdraw] = useFetch(
		endpoints.start_withdraw_gold,
		'',
		"POST",
		(result, params) => {
			setWithdrawTransaction(result?.data);
		},
		(result, params) => {
			notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	}

	const startWithdraw = () => {
		setParamsWithdraw('');
		setParamsWithdraw({
			game_character_id: character?.id,
			amount: goldWithdraw,
			server_id: character?.server_id
		})
	}

	//just simply split it into 2 stage - inventory mode and withdraw queue
	return <PageLayout>
		<h5>{t1('withdraw_layout')}</h5>
		{isWithdraw ? <Row>
			<Col span={24}>
				<Card title={t1("withdraw_queue")}>
					<Row>
						<Col span={6}>
							<Row gutter={[20, 16]}>
								<Col span={24}>
									<Steps
										percent={currentPercent}
										current={currentStep}
										direction="vertical"
										items={withdrawSteps([], nextStep, setCurrentPercent, setCurrentStep, startWithdraw, withdrawTransaction).map((stepData, index) => {
											if (index != currentStep) {
												return {
													title: stepData?.title,
													description: stepData?.description
												}
											} else {
												return {
													title: stepData?.title,
													description: stepData?.description,
													subTitle: stepData?.totalTime ? <Countdown format="mm:ss" value={timeStartStep + stepData?.totalTime * 1000} valueStyle={{ color: '#1677ff' }} /> : '',
												}
											}
										})}
									/>
								</Col>
							</Row>
						</Col>
						<Col span={18}>
							{withdrawSteps([], nextStep, setCurrentPercent, setCurrentStep, startWithdraw, withdrawTransaction).find((element) => element.id == currentStep)?.content || <></>}
						</Col>
					</Row>
				</Card>
			</Col>
		</Row> : <Row>
			<Col span={24}>
				<SelectCharacter
					updateCharacter={(e) => {
						setCharacter(JSON.parse(e))
					}}
					setFullInfo={true}
				/>
				<Card
					title={t1("select_gold_amount_to_withdraw")}
					bordered={true}
				>
					<Row>
						<Col span={24} className="pb-5">
							<Row gutter={[16,8]}>
								<Col span={24} className="text-center">
									<p>
										{t1('gold_amount')}	
									</p>
									<InputNumber min={1} max={character?.id ? profile?.account?.gold_accounts[character?.server_id - 1]?.balance : 0} step={1} onChange={(e) => setGoldWithdraw(e)}/>
								</Col>
							</Row>
						</Col>
						<Col span={24} className={'mt-5 text-center'}>
							<Button type="primary" disabled={!(goldWithdraw > 0 && character)} onClick={(e) => setIsWithdraw(true)}>{t1('start_withdraw_gold')}</Button>
						</Col>
					</Row>
				</Card>
			</Col>
		</Row>}
	</PageLayout>
}

const mapStatesToProps = (state,props) => {
	return {
		profile: state?.profile
	}
}

export default connect(mapStatesToProps)(WithdrawLayout);
