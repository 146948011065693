import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const bankAccountLayout = (formComponent) => {
    return (
        <Row gutter={[20, 20]} align="middle">
            <Col span={22} offset={1}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>{formComponent?.bank_name}</Col>
                    <Col span={24}>{formComponent?.bank_short_name}</Col>
                    <Col span={24}>{formComponent?.account}</Col>
                    <Col span={24}>{formComponent?.name}</Col>
                    <Col span={24} className="text-center mt-2">{formComponent?.SubmitButton}</Col>
                </Row>
            </Col>
        </Row>
    );
};

export default bankAccountLayout;
