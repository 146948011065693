import React, { useState } from "react";
import { SERVER_LIST } from "constants/game";
import { t1 } from "translate";
import {
  getImageLinkFromItemModel,
  getItemType,
} from "./utils";
import styles from "./styles.module.scss";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import ItemStatus from "./ItemStatus";
import Popover from 'antd/lib/popover';
import DetailContent from "./DetailContent";
import Modal from "antd/lib/modal";
import { CURRENCY_OPTIONS } from "constants";

const { Title, Paragraph, Text } = Typography;

const MarketListingBlock = ({listingInfo, isSelected = false, isSmallPic = false, onClick=false, ...props}) => {
  const { action = () => {}, isFocus } = props;
  let data = listingInfo;
  let itemModelInfo = data?.model;
  let itemInfo = data?.item;
  const [showDetail, setShowDetail] = useState(false);

  const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

  let itemType = getItemType(
    itemModelInfo,
    itemInfo?.item_info
  );
  
  let rareType = "";
  if (itemType?.type == ITEM_RARITY.RARE){
    if (itemModelInfo?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOS}_RARE`)){
      rareType = 'SOS';
    }else{
      if (itemModelInfo?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOM}_RARE`)){
        rareType = "SOM";
      }else{
        rareType = "SUN";
      }
    }
  }

  let isLock = data?.status == "lock";
  
  return (
    <>
      <Row
        align="middle"
        className={`${styles.itemContainer} ${
          isFocus ? "item-selected" : ""
        } ${isLock ? "disabled-item" : "enabled-item"}`}
        gutter={8}
        onClick={(e) => {
          onClick && typeof(onClick) === 'function' ? onClick(listingInfo) : setShowDetail(true);
        }}
      >
        <ItemStatus itemData={data} />
        <Col span={isSmallPic ? 3 : 6} className="text-center mt-1 mb-1">
          <img
            src={getImageLinkFromItemModel(
              itemModelInfo
            )}
            alt={itemModelInfo?.name?.vi}
            style={{ width: "90%" }}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              if (
                currentTarget.src ==
                getImageLinkFromItemModel(
                  itemModelInfo
                )
              ) {
                currentTarget.src =
                  getImageLinkFromItemModel(
                    itemModelInfo,
                    true
                  );
              }
            }}
          />
          {itemType?.type == ITEM_RARITY.RARE ? (
            <img
              className="rare-star ps-1"
              style={{ width: "90%" }}
              src="https://sro.money/images/rare_star.gif"
              alt={itemModelInfo?.name?.vi}
            />
          ) : (
            <></>
          )}
        </Col>
        <Col span={isSmallPic ? 21 : 18}>
          <Typography>
            <Title
              ellipsis={{ rows: 2}}
              level={5}
              className={`m-0 item-name-${itemType?.type}`}
            >
              {itemInfo?.item_info?.plus && parseInt(itemInfo?.item_info?.plus) > 0 ? `${itemModelInfo?.name?.vi} (+${itemInfo?.item_info?.plus})` : itemModelInfo?.name?.vi}
            </Title>
            {itemType?.type == ITEM_RARITY.RARE ? <><Text className="item-name-rare">{rareType}</Text><br/></> : <></>}
            <Text className="text-white">
              {t1("server_:_%s", [
                getServer(data?.server_id),
              ])}
            </Text>
            <Text className="text-white">
              <br/>
              {t1('quantity:_%s',[listingInfo?.item?.quantity])}
              <br/>
              {t1('%s_needed:_',[CURRENCY_OPTIONS[listingInfo?.concurrency].label])}
              {listingInfo?.sale_off ? (
                <span>
                  <span
                    style={{
                      fontWeight: 'bold',
                      color: 'red',
                      marginRight: '5px'
                    }}
                  >{listingInfo?.price_after_sale}</span>
                  <span
                    style={{
                      color: '#afafaf',
                      textDecoration: 'line-through',
                      textDecorationThickness: '2px'
                    }}
                  >{t1(`${listingInfo?.price}`)}</span>
                </span>
              ) : (
                <span>{parseInt(listingInfo?.price)}</span>
              )}
            </Text>
          </Typography>
        </Col>
      </Row>
      {listingInfo?.price ? (
        <div style={{
          position: 'absolute',
          bottom: '3px',
          left: '5px',
          color: 'white',
          backgroundColor: 'rgba(0,0,0,0.7)',
          padding: '1px 3px',
          borderRadius: '4px',
          border: '1px solid #fff'
        }}>
          <span className="me-1">
            {listingInfo?.concurrency === 0
              ? <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px"}}/>
              : <img src="/goldicon.png" alt="sro money gold icon" style={{width:"15px"}}/>
            }
          </span>
          {listingInfo?.sale_off ? (
            <span>
              <span
                style={{
                  fontWeight: 'bold',
                  color: 'red',
                  marginRight: '5px'
                }}
              >{listingInfo.price_after_sale}</span>
              <span
                style={{
                  color: '#afafaf',
                  textDecoration: 'line-through',
                  textDecorationThickness: '1px'
                }}
              >{t1(`${listingInfo?.price}`)}</span>
            </span>
          ) : (
            <span style={{fontSize: '12px'}}>{parseInt(listingInfo?.price)}</span>
          )}
        </div>
        ) : <></>
      }
      <Modal
        centered
        title={null}
        open={showDetail}
        onCancel={() => setShowDetail(false)}
        footer={null}
      >
        <DetailContent
          itemType={itemType}
          itemModel={itemModelInfo}
          itemInfo={itemInfo?.item_info}
          rareType={rareType}
          action={action(listingInfo, setShowDetail)}
        />
      </Modal>
    </>
  );
};

export default MarketListingBlock;

