import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

//This page will list all settings layout that user can modify, or some secret keyword that this user want to config
const SettingsPersonalLayout = (props) => {
    return <PageLayout>
        {t1('settings_personal_layout')}
    </PageLayout>
}

export default SettingsPersonalLayout;