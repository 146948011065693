import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import { t1 } from "translate";
import logoFB from 'assets/icons/common/logo-facebook.svg';
import logoGG from 'assets/icons/common/logo-google.svg';

const loginLayout = (formComponent) => {
    return (
        <Row gutter={[20, 8]}>
            <Col span={24} className="mb-1">{formComponent?.email}</Col>
            <Col span={24} className="mb-1">{formComponent?.password}</Col>
            <Col span={24} className="mb-4">{formComponent?.agree_tos}</Col>
            <Col span={24} className="mb-1">
                {formComponent?.SubmitButton}
            </Col>
            <Col span={24}>
                <Button
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '4px' }}
                    onClick={(e) => window.location = "https://sro.money/login/google"}
                >
                    <img src={logoGG} alt="logo-google" width={23} height={23} style={{marginRight: '5px'}}/>
                    <span>{t1('login_with') + ' google'}</span>
                </Button>
            </Col>
            {/* <Col span={24}>
                <Button
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: '4px' }}
                    onClick={(e) => window.location = "https://sro.money/login/facebook"}
                >
                    <img src={logoFB} alt="logo-google" width={23} height={23} style={{marginRight: '5px'}}/>
                    <span>{t1('login_with') + ' facebook'}</span>
                </Button>
            </Col> */}
        </Row>
    );
};

export default loginLayout;
