import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const userIdentifyLayout = (formComponent) => {
    return (
        <Row gutter={[20, 20]} align="middle">
            <Col span={22} offset={1}>
                <Row gutter={[10, 10]}>
                    <Col span={24}>{formComponent?.identify_card_type}</Col>
                    <Col span={24}>{formComponent?.identify_card_number}</Col>
                    <Col span={24}>{formComponent?.identify_name}</Col>
                    <Col span={24} className="text-center mt-2">{formComponent?.SubmitButton}</Col>
                </Row>
            </Col>
        </Row>
    );
};

export default userIdentifyLayout;
