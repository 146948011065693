import React from 'react';
import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { ohlcdata } from '../fakedata/price'

const options =  {
    title: {
        text: 'Price chart'
    },
    plotOptions: {
        candlestick: {
            color: 'pink',
            lineColor: 'red',
            upColor: 'lightgreen',
            upLineColor: 'green'
        }
    },

    rangeSelector: {
        selected: 1
    },

    series: [{
        type: 'candlestick',
        name: 'USD to EUR',
        data: ohlcdata
    }]
}

const PriceChart = () => {
    return <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>
}

export default PriceChart;