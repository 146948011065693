import React, { useState } from "react";
import Modal from "antd/lib/modal";
import { SERVER_LIST } from "constants/game";
import { t1 } from "translate";
import { getImageLinkFromItemModel, getItemType } from "./utils";
import styles from "./styles.module.scss";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import ItemStatus from "./ItemStatus";
import Popover from "antd/lib/popover";
import DetailContent from "./DetailContent";

const { Title, Paragraph, Text } = Typography;

const ItemGoldDetail = ({ isSmallPic = false, data, action = (e) => console.log(e), isFocus }) => {
    const getServer = (serverId) =>
        SERVER_LIST.find((server) => server.id === Number(serverId))?.name || "";

    let isLock = data?.status == "lock";

    return (
        <Row
            align="middle"
            className={`${styles.itemContainer} ${isFocus ? "item-selected" : ""} ${
                isLock ? "disabled-item" : "enabled-item"
            }`}
            gutter={8}
            onClick={(e) => {
                action(data);
            }}
        >
            <ItemStatus itemData={data} />
            <Col span={24}>
                <Typography>
                    <Title
                        ellipsis={{ rows: 2 }}
                        level={5}
                        className={`m-0 item-name-rare`}
                    >
                        {t1('gold_item')}
                    </Title>
                    <>
                            <Text className="item-name-rare">{t1('%s_gold',[data?.quantity])}</Text>
                            <br />
                        </>
                    
                    <Text className="text-white">
                        {t1("server_:_%s", [getServer(data?.server_id)])}
                    </Text>
                </Typography>
            </Col>
        </Row>
    );
};

export default ItemGoldDetail;
