import React from "react";
import { t1 } from "translate";
import dayjs from "dayjs";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Select from "antd/lib/select";
import Message from "antd/lib/message";

const { Title, Paragraph, Text } = Typography;

const Step1 = (props) => {
  const {
    currentStep,
    setCurrentStepDetail,
    setTimeStartStep,
    gameCharactersDisplay,
    selectedCharacter,
    updateCharacter,
  } = props;

  const nextStep = () => {
    if (!selectedCharacter) {
      return Message.error(t1('you_must_select_the_character_to_withdraw'));
    }

    setCurrentStepDetail(currentStep + 1);
    setTimeStartStep(dayjs().unix() * 1000);
  }

  return (
    <Row>
      <Col span={24}>
        <Typography>
          <Title level={5}>{t1("select_the_character_you_want_to_withdraw_to")}</Title>
        </Typography>
      </Col>
      <Col span={24}>
        <Select
          size="large"
          options={gameCharactersDisplay}
          onChange={(e) => updateCharacter(e)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={24}>
        <Button
          className="mt-4"
          onClick={nextStep}
        >
          {t1('continue')}
        </Button>
      </Col>
    </Row>
  );
};

export default Step1;
