import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const createGoldItemMarketLayout = (formComponent) => {
  return (
    <Row gutter={[20, 20]} align="middle">
      <Col span={24}>
        <Row gutter={[10, 10]}>
          <Col span={24}>{formComponent?.amount}</Col>
          <Col span={24}>{formComponent?.preViewAmount}</Col>
          <Col span={24}>{formComponent?.price}</Col>
          <Col span={24}>{formComponent?.preViewPriceEachUnit}</Col>
          <Col span={24} className="text-center">
            {formComponent?.SubmitButton}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default createGoldItemMarketLayout;
