import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Tag from "antd/lib/tag";
import Table from "antd/lib/table";
import dayjs from "dayjs";
import { DATEFORMAT2 } from 'constants';

//This page will list all deposit request history, its status, timming, what character for
const DepositHistoryLayout = (props) => {
    const columns = [
        {
          title: 'Date',
          dataIndex: 'date',
          key: 'date',
          render: (text) => (
            <span>{!!text ? dayjs(text).format(DATEFORMAT2) : ''}</span>
          ),
        },
        {
          title: 'Status',
          dataIndex: 'status',
          key: 'status',
          render: (status) => (
            <Tag color={status ? 'green' : 'volcano'}>
              {status ? 'Success' : 'Not Complete'}
            </Tag>
          ),
        },
        {
          title: 'Item name',
          dataIndex: 'item_name',
          key: 'item_name',
        },
        {
          title: 'Completion time',
          dataIndex: 'completion_time',
          key: 'completion_time',
          render: (text) => (
            <span>{!!text ? dayjs(text).format(DATEFORMAT2) : ''}</span>
          ),
        },
        {
          title: 'Transaction code',
          dataIndex: 'transaction_code',
          key: 'transaction_code',
        },
      ];
    
      const data = [
        {
          key: '1',
          date: dayjs(),
          status: true,
          item_name: "Item 1",
          completion_time: dayjs(),
          transaction_code: (Math.random() + 1).toString(36).substring(7)
        },
        {
          key: '2',
          date: dayjs(),
          status: true,
          item_name: "Item 2",
          completion_time: dayjs(),
          transaction_code: (Math.random() + 1).toString(36).substring(7)
        },
        {
          key: '3',
          date: dayjs(),
          status: false,
          item_name: "Item 3",
          completion_time: '',
          transaction_code: (Math.random() + 1).toString(36).substring(7)
        },
        {
          key: '4',
          date: dayjs(),
          status: true,
          item_name: "Item 4",
          completion_time: dayjs(),
          transaction_code: (Math.random() + 1).toString(36).substring(7)
        },
      ];

    return <PageLayout>
        <Col span={24}>
            <Card title={t1('deposit_history_layout')} style={{ borderRadius: "5px" }}>
                <Row>
                <Col span={24}>
                    <Table
                    style={{ width: '100%' }}
                    columns={columns}
                    dataSource={data}
                    />
                </Col>
                </Row>
            </Card>
            </Col>
    </PageLayout>
}

export default DepositHistoryLayout;