import React, { useState } from "react";
import { t1 } from "translate";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import InputNumber from "antd/lib/input-number";
import Button from "antd/lib/button";
import { POINT_LIST } from "constants/paymentMethod";
import { connect } from 'react-redux'
import Select from 'antd/lib/select';
import RefundPointReceipt from "../RefundPointReceipt";

const RefundPointInput = (props) => {
    const { setParams, setCurrentStep, nextStepId, userProfile, account, bank } = props;
    const [amount, setAmount] = useState(0);
    const [bankIndex, setBankIndex] = useState(0);

    return (
        <Row gutter={[16, 16]}>
            <Col span={24} md={18} lg={18}>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <h6>{t1("select_account_to_refund_to")}</h6>
                    </Col>
                    <Col span={24}>
                        <Select defaultValue={bankIndex} onChange={(e) => {
                            setBankIndex(e)
                        }} style={{width: '100%'}} options={bank.map((bankInfo,indexBank) => {
                            return {
                                label: `${bankInfo?.bank_name} - ${bankInfo?.bank_account} - ${bankInfo?.name}`,
                                value: indexBank
                            }
                        })}/>
                    </Col>
                    <Col span={24}>
                        <h6>{t1("enter_amount_of_point_you_want_to_refund")}</h6>
                    </Col>
                    <Col span={24}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                padding: "5px 20px 5px 0px",
                                border: "1px solid #d9d9d9",
                                borderRadius: "8px",
                            }}
                        >
                            <InputNumber
                                min={0}
                                max={account?.balance || 0}
                                bordered={false}
                                controls={false}
                                placeholder="0"
                                value={amount}
                                style={{ width: "100%", color: "#1677ff" }}
                                onChange={(e) => setAmount(e)}
                            />
                            <img src="/cashicon.png" alt="sro money point icon" style={{width:"24px"}}/>
                        </div>
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            {POINT_LIST.map((point, index) => (
                                <Col span={12} key={index}>
                                    <Button
                                        className="fw-bolder"
                                        type={point.value === amount ? "primary" : "dashed"}
                                        style={{ width: "150px" }}
                                        onClick={() => setAmount(point.value)}
                                    >
                                        {t1("_%s", [point.value])}
                                        <img src="/cashicon.png" alt="sro money point icon" style={{width:"24px", marginLeft: '3px'}}/>
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                    </Col>
                </Row>
            </Col>
            <Col span={24} md={6} lg={6}>
                <RefundPointReceipt pointAmount={amount} bankAccount={bank.length > 0 ? bank[bankIndex] : {}} onCreateRefundRequest={(amountPoint,bankAccount) => {
                    setParams('');
                    setParams({
                        amount: amountPoint,
                        bank_id: bankAccount?.id
                    })
                    setCurrentStep(nextStepId);
                }}/>
            </Col>
        </Row>
    );
};

const mapStateToProps = (state, props) => {
    return {
        userProfile: state?.profile?.profile,
        account: state?.profile?.account,
        bank: state?.profile?.banks,
    };
};

export default connect(mapStateToProps)(RefundPointInput);
