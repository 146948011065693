import React, { useEffect } from 'react';
import DatePicker from 'antd/lib/date-picker';
import dayjs from 'dayjs';
import Typography from 'antd/lib/typography';

const { Text } = Typography;
//rest here will need options value
//option should in this format: {label: A, value: B}
const DatePickerFieldAntd = ({ input, form, meta, dateFormat, picker, errorText, ...rest }) => {
  useEffect(() => {
    if (rest?.renderFields) {
      rest.renderFields(input, rest?.valuesData);
    }
  }, [rest?.valuesData]);

  useEffect(() => {
    if (rest?.forceAutoFill) {
      if (rest.forceAutoFill === 'empty_data') {
        input.onChange(undefined);
      }else{
        input.onChange(rest?.forceAutoFill);
      }
    }
  }, [rest?.forceAutoFill]);

  useEffect(() => {
    if (rest?.keyData) {
      input.onChange(dayjs(rest?.keyData));
    }
  }, [rest?.keyData]);

  return (
    <>
      <DatePicker
        {...input}
        {...rest}
        format={dateFormat}
        value={input.value ? (typeof input.value === 'object' ? input.value : dayjs(input.value)) : ''}
        onChange={(valueChange) => {
          input.onChange(valueChange);
          if (rest?.onChange) {
            rest.onChange(input, form);
          }
        }}
        picker={picker}
      />
      {meta.error && meta.touched ? (
        <Text type="danger">
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : null}
    </>
  );
};

export default DatePickerFieldAntd;
