export const START_MODEL_ID_MALE_CHINA = 1907;
export const START_MODEL_ID_FEMALE_CHINA = 1920;
export const START_MODEL_ID_MALE_EU = 14717;
export const START_MODEL_ID_FEMALE_EU = 14730;

export const SERVER_LIST = [
    {
        id: 1,
        name: 'Thiên Kim'
    },{
        id: 2,
        name: 'Bạch Kim'
    },{
        id: 3,
        name: 'Hoàng Kim'
    }
]