import React from "react";
import { getFullItemInformation } from "commons/data-handle";
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Typography from "antd/lib/typography";
import { t1 } from "translate";
import { ITEM_RARITY } from "constants/item";
import BlueDetail from "./BlueDetail";

const { Title, Paragraph, Text } = Typography;

const AccessoryDetail = ({itemModel, itemInfo, itemStats, itemType, rareType}) => {
	return <Row>
		<Col span={24}>
		<Typography>
			<Title
				level={5}
				className={`m-0 item-name-${itemType?.type} reverse`}
			>
				{(itemInfo?.plus && parseInt(itemInfo?.plus) > 0) ? `${itemModel?.name?.vi} (+${itemInfo?.plus})` : itemModel?.name?.vi}
			</Title>
			<br/>
			<Paragraph>
                {itemType?.type == ITEM_RARITY.RARE ? <><Text className="item-name-rare reverse">{rareType}</Text><br/></> : <></>}
				{t1('item_type:_%s',[itemStats?.type_name])}
				<br/>
				{t1('degree:_%s',[itemModel?.code?.match(new RegExp(/_([0-9]*)_/g))[0]?.replaceAll("_","")])}
			</Paragraph>
			<Paragraph>
				{t1('phys_absorb_rate:_%s',[`${itemStats?.phys_absorb?.value}% (${itemStats?.phys_absorb?.percentage}%)`])}
				<br/>
				{t1('magic_absorb_rate:_%s',[`${itemStats?.mag_absorb?.value}% (${itemStats?.mag_absorb?.percentage}%)`])}
			</Paragraph>
			<Paragraph>
				{t1('level_can_use:_%s',[itemModel?.level_can_use])}
				<br/>
				{t1('race:_%s',[itemStats?.race])}
			</Paragraph>
            <br/>
            <BlueDetail blueList={itemInfo?.blue_list}/>
			</Typography>
		</Col>
	</Row>
}

const ArmorDetail = ({itemModel, itemInfo, itemStats, itemType, rareType}) => {
	return <Row>
        <Col span={24}>
            <Typography>
                <Title
                    level={5}
                    className={`m-0 item-name-${itemType?.type} reverse`}
                >
                    {(itemInfo?.plus && parseInt(itemInfo?.plus) > 0) ? `${itemModel?.name?.vi} (+${itemInfo?.plus})` : itemModel?.name?.vi}
                </Title>
                <br/>
                <Paragraph>
                    {itemType?.type == ITEM_RARITY.RARE ? <><Text className="item-name-rare reverse">{rareType}</Text><br/></> : <></>}
                    {t1('item_type:_%s',[itemStats?.type_name])}
                    <br/>
                    {t1('item_place:_%s',[itemStats?.piece_name])}
                    <br/>
                    {t1('degree:_%s',[itemModel?.code?.match(new RegExp(/_([0-9]*)_/g))[0]?.replaceAll("_","")])}
                </Paragraph>
                <Paragraph>
                    {t1('phys_defense:_%s',[`${itemStats?.defense_physical?.value} (${itemStats?.defense_physical?.percentage}%)`])}
                    <br/>
                    {t1('magic_defense:_%s',[`${itemStats?.defense_magical?.value} (${itemStats?.defense_magical?.percentage}%)`])}
                    <br/>
                    {t1('durability:_%s',[`${itemInfo?.durablity}/${itemStats?.durability_max?.value} (${itemStats?.durability_max?.percentage}%)`])}
                    <br/>
                    {t1('defense_rate:_%s',[`${itemStats?.defense_rate?.value} (${itemStats?.defense_rate?.percentage}%)`])}
                    <br/>
                    {t1('additional_phys_def:_%s',[`${itemStats?.additional_phys_def?.value/10}% (${itemStats?.additional_phys_def?.percentage}%)`])}
                    <br/>
                    {t1('additional_mag_def:_%s',[`${itemStats?.additional_mag_def?.value/10}% (${itemStats?.additional_mag_def?.percentage}%)`])}
                </Paragraph>
                <Paragraph>
                    {t1('level_can_use:_%s',[itemModel?.level_can_use])}
                    <br/>
                    {t1('gender:_%s',[itemModel?.code?.includes("_M_") ? t1('male') : t1('female')])}
                    <br/>
                    {t1('race:_%s',[itemStats?.race])}
                </Paragraph>
                <br/>
                <BlueDetail blueList={itemInfo?.blue_list}/>
            </Typography>
        </Col>
    </Row>
}

const ShieldDetail = ({itemModel, itemInfo, itemStats, itemType, rareType}) => {
	return <Row>
        <Col span={24}>
            <Typography>
                <Title
                    level={5}
                    className={`m-0 item-name-${itemType?.type} reverse`}
                >
                    {(itemInfo?.plus && parseInt(itemInfo?.plus) > 0) ? `${itemModel?.name?.vi} (+${itemInfo?.plus})` : itemModel?.name?.vi}
                </Title>
                <br/>
                <Paragraph>
                    {itemType?.type == ITEM_RARITY.RARE ? <><Text className="item-name-rare reverse">{rareType}</Text><br/></> : <></>}
                    {t1('item_type:_%s',[itemStats?.type_name])}
                    <br/>
                    {t1('degree:_%s',[itemModel?.code?.match(new RegExp(/_([0-9]*)_/g))[0]?.replaceAll("_","")])}
                </Paragraph>
                <Paragraph>
                    {t1('phys_defense:_%s',[`${itemStats?.defense_physical?.value} (${itemStats?.defense_physical?.percentage}%)`])}
                    <br/>
                    {t1('magic_defense:_%s',[`${itemStats?.defense_magical?.value} (${itemStats?.defense_magical?.percentage}%)`])}
                    <br/>
                    {t1('durability:_%s',[`${itemInfo?.durablity}/${itemStats?.durability_max?.value} (${itemStats?.durability_max?.percentage}%)`])}
                    <br/>
                    {t1('blocking:_%s',[`${itemStats?.blocking?.value} (${itemStats?.blocking?.percentage}%)`])}
                    <br/>
                    {t1('additional_phys_def:_%s',[`${itemStats?.additional_phys_def?.value/10}% (${itemStats?.additional_phys_def?.percentage}%)`])}
                    <br/>
                    {t1('additional_mag_def:_%s',[`${itemStats?.additional_mag_def?.value/10}% (${itemStats?.additional_mag_def?.percentage}%)`])}
                </Paragraph>
                <Paragraph>
                    {t1('level_can_use:_%s',[itemModel?.level_can_use])}
                    <br/>
                    {t1('race:_%s',[itemStats?.race])}
                </Paragraph>
                <br/>
                <BlueDetail blueList={itemInfo?.blue_list}/>
            </Typography>
        </Col>
    </Row>
}

const WeaponDetail = ({itemModel, itemInfo, itemStats, itemType, rareType}) => {
	return <Row>
        <Col span={24}>
            <Typography>
                <Title
                    level={5}
                    className={`m-0 item-name-${itemType?.type} reverse`}
                >
                    {(itemInfo?.plus && parseInt(itemInfo?.plus) > 0) ? `${itemModel?.name?.vi} (+${itemInfo?.plus})` : itemModel?.name?.vi}
                </Title>
                <br/>
                <Paragraph>
                    {itemType?.type == ITEM_RARITY.RARE ? <><Text className="item-name-rare reverse">{rareType}</Text><br/></> : <></>}
                    {t1('item_type:_%s',[itemStats?.type_name])}
                    <br/>
                    {t1('degree:_%s',[itemModel?.code?.match(new RegExp(/_([0-9]*)_/g))[0]?.replaceAll("_","")])}
                </Paragraph>
                <Paragraph>
                    {t1('dam_phys:_%s',[`${itemStats?.dam_phys_min?.value} ~ ${itemStats?.dam_phys_max?.value} (${itemStats?.dam_phys_max?.percentage}%)`])}
                    <br/>
                    {t1('dam_magic:_%s',[`${itemStats?.dam_mag_min?.value} ~ ${itemStats?.dam_mag_max?.value} (${itemStats?.dam_mag_max?.percentage}%)`])}
                    <br/>
                    {t1('durability:_%s',[`${itemInfo?.durablity}/${itemStats?.durability_max?.value} (${itemStats?.durability_max?.percentage}%)`])}
                    <br/>
                    {t1('attack_rate:_%s',[`${itemStats?.attack_rate?.value} (${itemStats?.attack_rate?.percentage}%)`])}
                    <br/>
                    {t1('crit:_%s',[`${itemStats?.crit?.value} (${itemStats?.crit?.percentage}%)`])}
                    <br/>
                    {t1('additional_phys_dam:_%s',[`${itemStats?.additional_phys_min?.value/10}% ~ ${itemStats?.additional_phys_max?.value/10}% (${itemStats?.additional_phys_max?.percentage}%)`])}
                    <br/>
                    {t1('additional_mag_dam:_%s',[`${itemStats?.additional_mag_min?.value/10}% ~ ${itemStats?.additional_mag_max?.value/10}% (${itemStats?.additional_mag_max?.percentage}%)`])}
                </Paragraph>
                <Paragraph>
                    {t1('level_can_use:_%s',[itemModel?.level_can_use])}
                    <br/>
                    {t1('race:_%s',[itemStats?.race])}
                </Paragraph>
                <br/>
                <BlueDetail blueList={itemInfo?.blue_list}/>
            </Typography>
        </Col>
    </Row>
}

const DetailContent = ({itemModel, itemInfo, itemType, rareType, action}) => {
    if (itemModel['code'] === "GOLD") {
        return <></>;
    }

    if (itemModel['level_can_use'] == 0){
        if (itemInfo["count"] > 0){
            return <>
                <><p>{t1('quantity:_%s',[itemInfo["count"]])}</p></>
                {action}
            </>;
        }else{
            return <>
                <></>
                {action}
            </>;
        }
    }
	let itemStats = getFullItemInformation(itemModel,itemInfo);
    let content = <></>;
	switch (itemStats?.layout_type){
		case 'acc':
			content = <AccessoryDetail itemModel={itemModel} itemInfo={itemInfo} itemStats={itemStats} itemType={itemType} rareType={rareType} />;
            break;
		case 'armor':
			content = <ArmorDetail itemModel={itemModel} itemInfo={itemInfo} itemStats={itemStats} itemType={itemType} rareType={rareType} />;
            break;
        case 'shield':
            content = <ShieldDetail itemModel={itemModel} itemInfo={itemInfo} itemStats={itemStats} itemType={itemType} rareType={rareType} />;
            break;
        case 'weapon':
            content = <WeaponDetail itemModel={itemModel} itemInfo={itemInfo} itemStats={itemStats} itemType={itemType} rareType={rareType} />;
            break;
		default:
			content = <></>
            break;
	}

    return <>
        {content}
        {action}
    </>;
}

export default DetailContent;
