import React, { useEffect } from 'react';
import Select from 'antd/lib/select';
import { Typography } from 'antd';
import styles from './styles.module.scss';

const { Text } = Typography;

const whiteListKeys = ['Backspace', 'Enter'];

const SelectFieldAntd = ({ input, meta, errorText, options = [], ...rest }) => {
  useEffect(() => {
    if (rest?.forceAutoFill) {
      input.onChange(rest?.forceAutoFill);
    }
    if (rest.forceAutoFill === 'empty_data') {
      input.onChange(null);
    }
  }, [rest?.forceAutoFill]);

  return (
    <>
      <Select
        {...input}
        {...rest}
        options={options}
        onChange={(valueChange) => input.onChange(valueChange)}
        value={input?.value || undefined}
        onInputKeyDown={(event) => {
          if (!event.metaKey && !event.ctrlKey && !event.altKey && !whiteListKeys.includes(event.key)) {
            if (!new RegExp(rest?.regexPattern).test(event.key)) {
              event.preventDefault();
            }
            if (rest.maxLength !== undefined && event.target.value.length >= rest.maxLength) {
              event.preventDefault();
            }
          }
        }}
      />
      {meta.error && meta.touched ? (
        <Text type="danger" className={styles.messageError}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : null}
    </>
  );
};

export default SelectFieldAntd;
