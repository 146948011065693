import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from "translate";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Card from "antd/lib/card";
import SelectCharacter from "./components/SelectCharacter";
import dayjs from "dayjs";
import Statistic from 'antd/lib/statistic';
import Steps from 'antd/lib/steps';
import depositSteps from './constants/depositSteps';
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import notification from 'antd/lib/notification';

const { Countdown } = Statistic;

//This page will be use to deposit item in system
/**
	* This components will work like description below:
	*
	* 1/ Connect to socket (from redux)
	* 2/ Choose character that linked to account + item number that want to deposit => click request trade (remember to show list item eligible to deposit)
	* 3/ Move to step 1
	* (bot will automatically sign out when trade finished fail somehow)
	*/
const DepositLayout = (props) => {
	const [character, setCharacter] = useState(false);
	const [currentStep, setCurrentStep] = useState(0);
	const [timeStartStep, setTimeStartStep] = useState(0);
	const [currentPercent,setCurrentPercent] = useState(0);
	const [currentTransactionData,setCurrentTransactionData] = useState({});
  const [offerItemList, setOfferItemList] = useState([]);

	const [dataOffer,updateEndpointOffer,updateParamsOffer] = useFetch(
		endpoints.items_in_transaction,
		'',
		"GET",
		(result,params) => {
			console.log(result);
			//after finsihed => change to step 2
			setOfferItemList(result?.data)
		},
		(result,params) => {
			notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

	const [data,updateEndpoint,updateParams] = useFetch(
		endpoints.start_deposit,
		'',
		"POST",
		(result,params) => {
			//after finsihed => change to step 2
			setCurrentTransactionData(result?.data);
			//queue to refresh item in transaction data after have information
      setInterval(() => {
        const loadTransactionData = (transactionId) => {
          updateParamsOffer('');
          updateParamsOffer({
            inventory_transaction_id: transactionId,
          })
        }

        return loadTransactionData(result?.data?.transaction?.id);
      },10000)
		},
		(result,params) => {
			notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
		}
	)

	const startDeposit = () => {
		updateParams('');
		updateParams({
			game_character_id: character
		})
	}

	const nextStep = () => {
		setCurrentStep(currentStep + 1);
	}

	return (
		<PageLayout>
			<h5>{t1("deposit_layout")}</h5>
			<Row>
				<Col span={24}>
					{!character ? (
						<Card
							title={t1("select_character_that_you_want_to_deposit_item_from")}
							bordered={true}
						>
							<SelectCharacter
								updateCharacter={(e) => {
									setCharacter(e);
									setTimeStartStep(dayjs().unix() * 1000);
								}}
							/>
						</Card>
					) : (
						<></>
					)}
				</Col>
				{character ? (
					<Col span={24}>
						<Card title={t1("deposit_queue")}>
							<Row>
								{/* <Col span={6}>
									<Row gutter={[20,16]}>
										<Col span={24}>
											<Steps
												percent={currentPercent}
												current={currentStep}
												direction="vertical"
												items={depositSteps([],nextStep, setCurrentPercent,startDeposit,currentTransactionData,offerItemList).map((stepData, index) => {
													if (index != currentStep){
														return {
															title: stepData?.title,
															description: stepData?.description
														}
													}else{
														return {
															title: stepData?.title,
															description: stepData?.description,
															subTitle: stepData?.totalTime ? <Countdown format="mm:ss" value={timeStartStep + stepData?.totalTime * 1000} valueStyle={{color: '#1677ff'}}/> : '',
														}
													}
												})}
											/>
										</Col>
									</Row>
								</Col> */}
								<Col span={24}>
									{depositSteps([],nextStep, setCurrentPercent,startDeposit,currentTransactionData,offerItemList).find((element) => element.id == currentStep)?.content || <></>}
								</Col>
							</Row>
						</Card>
					</Col>
				) : (
					<></>
				)}
			</Row>
		</PageLayout>
	);
};

export default DepositLayout;
