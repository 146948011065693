import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";

export const getImageLinkFromItemModel = (itemModel, needToConvert = false) => {
    if (needToConvert){
        var oldLink = `https://sro.money/images/${itemModel?.image_url?.replace(".ddj",".jpg").split("\\").join('/')}`;
        var ListUrlComponent = oldLink.split("/");
        //replace the last one then join
        ListUrlComponent[ListUrlComponent.length - 1] = convertLastNameImg(ListUrlComponent[ListUrlComponent.length - 1]);
        return ListUrlComponent.join('/');
    }else{
        return `https://sro.money/images/${itemModel?.image_url?.toLowerCase().replace(".ddj",".jpg").split("\\").join('/')}`;
    }
}

export const convertLastNameImg = (lastName) => {
    var output = lastName.toUpperCase();
    output = output.replace(".JPG",".jpg").replace("_HA","_ha").replace("_SA","_sa").replace("_BA","_ba").replace("_LA","_la").replace("_AA","_aa").replace("_FA","_fa").replace("_CA","_ca");    
    return output;
}

export const getItemType = (itemModel,itemInfo = {}) => {
    let output = {
        type: ITEM_RARITY.NORMAL,
        special: ''
    }

    //check if rare or not
    if (itemModel.code?.includes("_RARE")){
        output['type'] = ITEM_RARITY.RARE;
        if (itemModel.code?.includes("A_RARE")){
            output['special'] = ITEM_RARE_SPECIAL.SOS;
        }else{
            if (itemModel.code?.includes("B_RARE")){
                output['special'] = ITEM_RARE_SPECIAL.SOM;
            }else{
                output['special'] = ITEM_RARE_SPECIAL.SUN;
            }
        }
    }else{
        if (itemInfo?.blue_list && itemInfo?.blue_list?.length > 0){
            output['type'] = ITEM_RARITY.BLUE;
        }else{
            output['type'] = ITEM_RARITY.NORMAL;
        }
    }
    //if not rare => check if it has blue or not

    return output;
}