import React from "react";
import Switch from 'antd/lib/switch';

const SwitchFieldAntd = ({input, meta, size = "default",...rest}) => {
    return <Switch
        {...input}
        {...rest}
        size={size}
        checked={input.value}
        onChange={(valueChange) => input.onChange(valueChange)}
    />
}

export default SwitchFieldAntd;