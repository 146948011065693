import React, { useState } from "react";
import { t1 } from "translate";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Pagination from "antd/lib/pagination";
import ItemDetail from "components/ItemDetail";
import notification from "antd/lib/notification";
import { ITEM_GOLD_MODEL_ID } from "constants";
import ItemGoldDetail from "components/ItemDetail/ItemGoldDetail";

//This page will show all current item, gold, point that user currently have
const InventoryList = (props) => {
    const [itemList, setItemList] = useState([]);
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);

    const [dataInventory, setEndpointInventory, setParamsInventory] = useFetch(
        endpoints.my_items,
        {
            submit: 1,
            page: page,
            pageSize: pageSize,
            status: "active",
        },
        "GET",
        (result, params) => {
            setItemList(result?.data);
            setPage(result?.meta?.current_page);
            setPageSize(result?.meta?.per_page);
            setTotal(result?.meta?.total);
        },
        (result, params) => {
            notification.warning({
                message: t1("warning_message"),
                description: result?.message,
            });
        }
    );

    return (
        <Row gutter={[16, 8]}>
            {itemList?.map((item, indexItem) => {
                return (
                    <Col span={24}>
                        {item?.model_id == ITEM_GOLD_MODEL_ID ? <ItemGoldDetail data={item} isSmallPic={true}/> : <ItemDetail data={item} isSmallPic={true} />}
                    </Col>
                );
            })}
            <Col span={24} style={{ textAlign: "right" }}>
                <Pagination
                    defaultCurrent={page}
                    current={page}
                    total={total}
                    pageSize={pageSize}
                    onChange={(pageNumber, pageSizeNumber) => {
                        setParamsInventory("");
                        setParamsInventory({
                            submit: 1,
                            page: pageNumber,
                            pageSize: pageSizeNumber,
                            status: "active",
                        });
                    }}
                />
            </Col>
        </Row>
    );
};

export default InventoryList;
