import UserInfoLayout from ".";
import CharacterInfoLayout from "./character";
import UserInfoHistoryLayout from './history';
import { MENU_LINK_USER_INFO_EDIT, MENU_LINK_CHARACTER_INFO, MENU_LINK_USER_INFO_HISTORY } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_USER_INFO_EDIT,
        exact: true,
        component: <UserInfoLayout />,
    },{
        path: MENU_LINK_CHARACTER_INFO,
        exact: true,
        component: <CharacterInfoLayout />,
    },{
        path: MENU_LINK_USER_INFO_HISTORY,
        exact: true,
        component: <UserInfoHistoryLayout />,
    }
];

export default routeSchema;