import React, { useState, useEffect } from 'react';
import Select from 'antd/lib/select';
import useFetch from 'commons/hook/fetchDataHook';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';

const { Text } = Typography;
const whiteListKeys = ['Backspace', 'Enter'];

const SelectFieldAsyncAntd = ({
  input,
  meta,
  errorText,
  options = undefined,
  valuesData = {},
  asyncOptions = {
    endpoints: '',
    params: '',
    method: 'get',
    transformDataToOptions: (dataOutput) => dataOutput,
    transformDataWhenFail: (dataOutput) => dataOutput,
  },
  ...rest
}) => {
  useEffect(() => {
    if (rest?.renderFields) {
      rest.renderFields(input, rest?.valuesData);
    }
  }, [rest?.valuesData]);

  useEffect(() => {
    if (rest?.forceAutoFill) {
      input.onChange(rest?.forceAutoFill);
    }
    if (rest.forceAutoFill === 'empty_data') {
      input.onChange(null);
    }
  }, [rest?.forceAutoFill]);

  const [optionsData, setOptions] = useState(options);
  const [dataOptions, updateEndpointOptions, updateParamsOptions] = useFetch(
    asyncOptions.endpoints,
    asyncOptions.params,
    asyncOptions.method,
    (result, paramsIn) => {
      if (typeof asyncOptions.transformDataToOptions === 'function') {
        setOptions(asyncOptions.transformDataToOptions(result, paramsIn, valuesData) || []);
      } else {
        setOptions(result);
      }
    },
    (result, paramsIn) => {
      if (typeof asyncOptions.transformDataWhenFail === 'function') {
        setOptions(asyncOptions.transformDataWhenFail(result, paramsIn) || []);
      } else {
        setOptions(result);
      }
    }
  );

  useEffect(() => {
    if (options !== undefined) {
      setOptions(options);
    } else {
      setOptions([]);
    }
  }, [options]);

  return (
    <>
      <Select
        {...input}
        {...rest}
        value={input?.value || undefined}
        options={optionsData}
        onSearch={(stringSearch) => {
          if (typeof asyncOptions.onSearch === 'function') {
            asyncOptions.onSearch(stringSearch, updateParamsOptions, input, updateEndpointOptions, valuesData);
          }
        }}
        onChange={(valueChange) => {
          if (typeof asyncOptions.onChange === 'function') {
            asyncOptions.onChange(valueChange, input, valuesData, updateParamsOptions);
          } else {
            input.onChange(valueChange);
          }
        }}
        onInputKeyDown={(event) => {
          if (!event.metaKey && !event.ctrlKey && !event.altKey && !whiteListKeys.includes(event.key)) {
            if (!new RegExp(rest?.regexPattern).test(event.key)) {
              event.preventDefault();
            }
            if (rest.maxLength !== undefined && event.target.value.length >= rest.maxLength) {
              event.preventDefault();
            }
          }
        }}
        onBlur={(e) => {
          if (typeof asyncOptions.onSearch === 'function') {
            if (asyncOptions.clearResultAfterSearch) {
              setOptions([]);
            }
            if (asyncOptions.recallCurrentValue) {
              asyncOptions.recallCurrentValue(input, updateParamsOptions,valuesData);
            }
          }
          if (rest.onBlur) {
            rest.onBlur(input, e);
          }
        }}
      />
      {meta.error && meta.touched ? (
        <Text type="danger" className={styles.messageError}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : null}
    </>
  );
};

export default SelectFieldAsyncAntd;
