import React from "react";
import Col from 'antd/lib/col';
import Row from 'antd/lib/row';

const layout = (ComponentObjects) => {
    return <Row gutter={8}>
        <Col span={9}>
            {ComponentObjects?.level_start}
        </Col>
        <Col span={9}>
            {ComponentObjects?.level_end}
        </Col>
        <Col span={6}>
            {ComponentObjects?.SubmitButton}
        </Col>
    </Row>
}

export default layout;