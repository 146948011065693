import React from "react";
import {
    SendOutlined,
    QuestionCircleOutlined,
    SolutionOutlined,
    YoutubeOutlined,
    DesktopOutlined,
    NotificationOutlined,
    SettingOutlined,
    PlusCircleOutlined,
    CheckCircleOutlined,
    MinusCircleOutlined,
    DollarOutlined,
    DownloadOutlined,
    CloudDownloadOutlined,
    UploadOutlined,
    RobotOutlined,
    ProfileOutlined,
    InfoOutlined,
    UserOutlined,
    HistoryOutlined,
    DashboardOutlined,
    HddOutlined,
    BankOutlined,
    CloudUploadOutlined,
    BookOutlined,
    ShopOutlined,
    ReadOutlined,
} from "@ant-design/icons";
import { t1, t2 } from "translate";
import {
    MENU_LINK_USER_INFO_EDIT,
    MENU_LINK_CHARACTER_INFO,
    MENU_LINK_USER_INFO_HISTORY,
    MENU_LINK_DASHBOARD,
    MENU_LINK_INVENTORY,
    MENU_LINK_MARKET,
    MENU_LINK_MARKET_HISTORY,
    MENU_LINK_MARKET_DEAL,
    MENU_LINK_DEPOSIT_BOT_STATUS,
    MENU_LINK_DEPOSIT,
    MENU_LINK_DEPOSIT_HISTORY,
    MENU_LINK_DEPOSIT_ALLOWED,
    MENU_LINK_WITHDRAW_BOT_STATUS,
    MENU_LINK_WITHDRAW,
    MENU_LINK_WITHDRAW_GOLD,
    MENU_LINK_WITHDRAW_HISTORY,
    MENU_LINK_ADD_POINT,
    MENU_LINK_REFUND,
    MENU_LINK_POINT_HISTORY,
    MENU_LINK_SETTINGS_PERSONAL,
    MENU_LINK_SETTINGS_NOTIFICATION,
    MENU_LINK_ABOUT_US,
    MENU_LINK_GUIDANCE_VIDEO,
    MENU_LINK_QA,
    MENU_LINK_TICKET_SUPPORT,
    MENU_LINK_GOLD_MARKET_HK,
    MENU_LINK_GOLD_MARKET_BK,
    MENU_LINK_GOLD_MARKET_TK,
    MENU_LINK_HELPER_QUEST,
    MENU_LINK_HELPER_MONSTER
} from "constants/menu-link";

const MENU_LIST = [
    {
        key: 1,
        icon: <ProfileOutlined />,
        label: t1("profile_panel"),
        children: [
            {
                key: 11,
                icon: <InfoOutlined />,
                label: t1("info_edit"),
                link: MENU_LINK_USER_INFO_EDIT,
            },
            {
                key: 12,
                icon: <UserOutlined />,
                label: t1("character_menu"),
                link: MENU_LINK_CHARACTER_INFO,
            },
            {
                key: 13,
                icon: <HistoryOutlined />,
                label: t1("profile_change_history"),
                link: MENU_LINK_USER_INFO_HISTORY,
            },
        ],
    },
    {
        key: 2,
        icon: <DashboardOutlined />,
        label: t1("dashboard_detail"),
        children: [
            {
                key: 20,
                icon: <DashboardOutlined />,
                label: t1('dashboard_page'),
                link: MENU_LINK_DASHBOARD
            },
            {
                key: 21,
                icon: <HddOutlined />,
                label: t1("inventory"),
                link: MENU_LINK_INVENTORY,
            },
            {
                key: 22,
                icon: <ShopOutlined />,
                label: t1("market_infomation"),
                link: MENU_LINK_MARKET,
            },
            {
                key: 23,
                icon: <BankOutlined />,
                label: t1("deal_highlight"),
                link: MENU_LINK_MARKET_DEAL,
            },
            {
                key: 24,
                icon: <HistoryOutlined />,
                label: t1("market_history"),
                link: MENU_LINK_MARKET_HISTORY,
            },
        ],
    },
    {
        key: 9,
        icon: <DashboardOutlined />,
        label: t1("gold_market"),
        children: [
            {
                key: 90,
                icon: <ShopOutlined />,
                label: t1('gold_hk'),
                link: MENU_LINK_GOLD_MARKET_HK
            },
            {
                key: 91,
                icon: <HddOutlined />,
                label: t1("gold_bk"),
                link: MENU_LINK_GOLD_MARKET_BK,
            },
            {
                key: 92,
                icon: <ShopOutlined />,
                label: t1("gold_tk"),
                link: MENU_LINK_GOLD_MARKET_TK,
            }
        ],
    },
    {
        key: 3,
        icon: <CloudUploadOutlined />,
        label: t1("deposit_panel"),
        children: [
            {
                key: 31,
                icon: <RobotOutlined />,
                label: t1("bot_status"),
                link: MENU_LINK_DEPOSIT_BOT_STATUS,
            },
            {
                key: 32,
                icon: <UploadOutlined />,
                label: t1("deposit_queue"),
                link: MENU_LINK_DEPOSIT,
            },
            {
                key: 33,
                icon: <HistoryOutlined />,
                label: t1("deposit_history"),
                link: MENU_LINK_DEPOSIT_HISTORY,
            },
            {
                key: 34,
                icon: <CheckCircleOutlined />,
                label: t1("deposit_allowed"),
                link: MENU_LINK_DEPOSIT_ALLOWED,
            },
        ],
    },
    {
        key: 4,
        icon: <CloudDownloadOutlined />,
        label: t1("withdraw_panel"),
        children: [
            {
                key: 41,
                icon: <RobotOutlined />,
                label: t1("bot_status"),
                link: MENU_LINK_WITHDRAW_BOT_STATUS,
            },
            {
                key: 42,
                icon: <DownloadOutlined />,
                label: t1("withdraw_queue"),
                link: MENU_LINK_WITHDRAW,
            },
            {
                key: 43,
                icon: <DownloadOutlined />,
                label: t1("withdraw_gold_queue"),
                link: MENU_LINK_WITHDRAW_GOLD,
            },
            {
                key: 44,
                icon: <HistoryOutlined />,
                label: t1("withdraw_history"),
                link: MENU_LINK_WITHDRAW_HISTORY,
            },
        ],
    },
    {
        key: 5,
        icon: <DollarOutlined />,
        label: t1("point_add_and_refund"),
        children: [
            {
                key: 51,
                icon: <PlusCircleOutlined />,
                label: t1("add_point"),
                link: MENU_LINK_ADD_POINT,
            },
            {
                key: 52,
                icon: <MinusCircleOutlined />,
                label: t1("refund"),
                link: MENU_LINK_REFUND,
            },
            {
                key: 53,
                icon: <HistoryOutlined />,
                label: t1("point_history"),
                link: MENU_LINK_POINT_HISTORY,
            }
        ],
    },
    {
        key: 6,
        icon: <SettingOutlined />,
        label: t1("settings"),
        children: [
            {
                key: 61,
                icon: <UserOutlined />,
                label: t1("personnal"),
                link: MENU_LINK_SETTINGS_PERSONAL,
            },
            {
                key: 62,
                icon: <NotificationOutlined />,
                label: t1("notification"),
                link: MENU_LINK_SETTINGS_NOTIFICATION,
            },
        ],
    },
    {
        key: 7,
        icon: <BookOutlined />,
        label: t1("about_and_guidance"),
        children: [
            {
                key: 71,
                icon: <DesktopOutlined />,
                label: t1("about"),
                link: MENU_LINK_ABOUT_US,
            },
            {
                key: 72,
                icon: <YoutubeOutlined />,
                label: t1("guidance"),
                link: MENU_LINK_GUIDANCE_VIDEO,
            },
        ],
    },
    {
        key: 8,
        icon: <SolutionOutlined />,
        label: t1("support_center"),
        children: [
            {
                key: 81,
                icon: <QuestionCircleOutlined />,
                label: t1("Q&A"),
                link: MENU_LINK_QA,
            },
            {
                key: 82,
                icon: <SendOutlined />,
                label: t1("ticket_support"),
                link: MENU_LINK_TICKET_SUPPORT,
            },
        ],
    },
    {
        key: 10,
        icon: <ReadOutlined />,
        label: t1("sro_origin_tools"),
        children: [
            {
                key: 101,
                icon: <ReadOutlined />,
                label: t1("quest_information"),
                link: MENU_LINK_HELPER_QUEST,
            },
            {
                key: 102,
                icon: <ReadOutlined />,
                label: t1("monster_information"),
                link: MENU_LINK_HELPER_MONSTER,
            }
        ],
    },
];

export default MENU_LIST;
