import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

//This page will list all deal that currently on the market (aution or special promote deal)
const MarketDealLayout = (props) => {
    return <PageLayout>
        {t1('market_deal_layout')}
    </PageLayout>
}

export default MarketDealLayout;