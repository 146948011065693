export const endpoints = {
    login: '/user/login',
    get_profile: '/user/me',
    register: '/user/register',
    signup: '/user/register',
    user_update: '/user/profile/update',

    game_character: '/user/game-account',
    unlink_game_character: '/user/game-account/delete',
    /*reg: '/api/Authenticate/register',
    register_test: '/api/register',
    login_test: '/api/login',
    get_profile: '/ping',
    get_balance: '/account/get-accounts',
    update_profile: '/api/profile',
    get_available_account: '/account/get-play-accounts',
    get_deposit: '/account/get-deposit',
    reg_wallet: '/account/register-wallet',
    withdraw: '/account/withdraw',*/
    


    /* ========== User ========== */
    link_account: '/user/link-account',
    confirm_connect_account: '/user/confirm-connect-account',
    /* ========== Character ========== */
    search_character: '/character/get',
    /*========point==============*/
    get_qr_code: '/account/deposit',
    create_refund_request: '/account/withdraw',
    create_bank_account: '/account/bank',
    get_transaction_list: '/account/list',
    //start deposit queue
    start_deposit: "/inventory/start-deposit",
    items_in_transaction: "/inventory/items-in-transaction",
    //inventory
    my_items: "user/items",
    //start withdraw queue
    start_withdraw: "inventory/start-withdraw",
    start_withdraw_gold: "account/withdraw-gold",
    //listing queue
    create_gold_item: "inventory/create-gold-item",
    set_price: "marketplace/open-price",
    close_price: "marketplace/close-price",
    cancel_gold_listing: "marketplace/cancel-gold-listing",
    search_item: "https://sro.money/public/marketplace/search",
    purchase_item: "marketplace/trade",
    //bot
    bot_list: "https://sro.money/public/bot/list",
    number_bot_free: "https://sro.money/public/bot/number-bot-free",
    // offer
    public_offer: "https://sro.money/public/marketplace/offer",
    pre_order: "marketplace/pre-order",
    list_pre_order: "marketplace/pre-order",
    delete_pre_order: "marketplace/pre-order",

    //tool
    quest_list: "quest",
    object_list: "game-object",

    //gold market api
    public_gold_market_buy: "https://sro.money/public/market/buy",
    public_gold_market_sell: "https://sro.money/public/market/sell",
    sell_gold_instant: "marketplace/sell-gold",

    // Coupon api
    calculate_price: "marketplace/calculate-price",
}