import PublicOfferLayout from "./index";

const routeSchema = [
    {
        path: '/offer/:id',
        exact: true,
        component: <PublicOfferLayout />,
    }
];

export default routeSchema;
 