import IMG1 from 'assets/images/avatar/char_ch_man1.png';
import IMG2 from 'assets/images/avatar/char_ch_man2.png';
import IMG3 from 'assets/images/avatar/char_ch_man3.png';
import IMG4 from 'assets/images/avatar/char_ch_man4.png';
import IMG5 from 'assets/images/avatar/char_ch_man5.png';

export const avatarList = [
  IMG1,
  IMG2,
  IMG3,
  IMG4,
  IMG5,
];
