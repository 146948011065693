import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { t1, t2, t3 } from "translate";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { connect } from "react-redux";
import UserInfoWrapper from "components/UserInfoWrapper";
import Tabs from "antd/lib/tabs";
import UserInfoBlock from "./components/UserInfoBlock";
import UserIdentifyBlock from "./components/IdentifyInfoBlock";
import BankAccountBlock from "./components/BankAccountBlock";

const TabList = () => {
    return [
        {
            key: "1",
            label: <span style={{fontSize: '12px'}}>{t1("user_info")}</span>,
            children: <UserInfoBlock/>,
        },
        {
            key: "2",
            label: <span style={{fontSize: '12px'}}>{t1("identify_information")}</span>,
            children: <UserIdentifyBlock/>,
        },
        {
            key: "3",
            label: <span style={{fontSize: '12px'}}>{t1("bank_account_information")}</span>,
            children: <BankAccountBlock/>,
        },
    ];
};

//This page will show userprofile Data
//Also allow people to edit it.
//Need to add a layout to check if this part is UserInfo check or not (add in PageLayout or make a component then add it in page layout)
//Will always redirect to this page until they complated update thier info here
const UserInfoLayout = ({ profile, dispatch }) => {
    const [activeTab,setActiveTab] = useState('1');

    return (
        <UserInfoWrapper>
            <PageLayout>
                <Row className="mt-3" style={{ height: '100%' }}>
                    <Col span={24}>
                        <Tabs type="card" size="small" defaultActiveKey={activeTab} items={TabList()} tabBarStyle={{margin: 0}}/>
                    </Col>
                </Row>
            </PageLayout>
        </UserInfoWrapper>
    );
};

const mapStatesToProps = (state, props) => {
    return {
        profile: state?.profile?.profile || {},
    };
};

export default connect(mapStatesToProps)(UserInfoLayout);
