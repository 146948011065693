import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

// This page will show all the history of userinfo update user has made (including character link update - event if it fail)
const UserInfoHistoryLayout = (props) => {
    return <PageLayout>
        {t1('user_info')}
    </PageLayout>
}

export default UserInfoHistoryLayout;