import { SET_SOCKET_OBJECT } from "redux/action-types";
//import lGet from 'lodash/get';

const initialState = {
    socket: null
};

const SocketReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_SOCKET_OBJECT: {
            return Object.assign({},state,{ socket: action.payload });
        }
        default:
            return state;
    }
}

export default SocketReducer;
