import DashboardPageLayout from "pages/dashboard";

const routeSchema = [
    {
        path: "/",
        exact: true,
        component: <DashboardPageLayout />,
    },
];

export default routeSchema;