import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { connect } from "react-redux";
import { t1, t2 } from "translate";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import AddPointInput from "../components/AddPointInput";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import Steps from "antd/lib/steps";
import { SmileOutlined, QrcodeOutlined, PlusOutlined } from "@ant-design/icons";
import QRRender from "../components/QrRender";
import Alert from "antd/lib/alert";
import { formatPrice } from "helper/helper";

const stepList = ({ setCurrentStep, setGetQRParams, qrString, codeTransaction }) => {
    return [
        {
            title: t1("choose_amount_to_add"),
            icon: <PlusOutlined />,
            content: (
                <AddPointInput
                    setParams={setGetQRParams}
                    setCurrentStep={setCurrentStep}
                    nextStepId={1}
                />
            ),
        },
        {
            title: t1("pay_by_qr_code"),
            icon: <QrcodeOutlined />,
            content: <QRRender input={qrString} nextStep={(e) => setCurrentStep(2)} codeTransaction={codeTransaction}/>,
        },
        {
            title: t1("wait_for_offer_to_finished"),
            icon: <SmileOutlined />,
            content: (
                <h5 className="mt-5 text-center">
                    {t1(
                        "thank_you_for_your_payment,_please_wait_for_system_to_process,_you_will_get_email_notification_after_transaction_confirmed"
                    )}
                </h5>
            ),
        },
    ];
};

//This page will be use to add point in system
const PointAddLayout = ({ userProfile, account }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [qrString, setQrString] = useState(false);
    const [offerId, setOfferId] = useState(false);
    const [codeTransaction,setCodeTransaction] = useState(false);

    const [data, UpEnpoints, setGetQRParams] = useFetch(
        endpoints.get_qr_code,
        "",
        "post",
        (result, params) => {
            setQrString(result?.data?.qr);
            setOfferId(result?.data?.id);
            setCodeTransaction(result?.data?.code);
        }
    );

    return (
        <PageLayout>
            <h5>{t1("add_point_to_account")}</h5>
            <Alert
                className="mb-2"
                message={
                    <div>
                        {t1("your_current_point_number_is_%s", [formatPrice(Number(account?.balance))])}
                        <img src="/cashicon.png" alt="sro money point icon" style={{ width:"20px", marginLeft: '3px'}}/>
                    </div>
                }
                type="success"
            />
            <Card title={null} bordered={true}>
                <Row gutter={[32, 16]}>
                    <Col span={24}>
                        <Steps
                            current={currentStep}
                            items={stepList({
                                setCurrentStep,
                                setGetQRParams,
                                qrString,
                            })}
                        />
                    </Col>
                    <Col span={24}>
                        {
                            stepList({ setCurrentStep, setGetQRParams, qrString, codeTransaction })[currentStep]
                                ?.content
                        }
                    </Col>
                </Row>
            </Card>
        </PageLayout>
    );
};

const mapStateToProps = (state, props) => {
    return {
        userProfile: state?.profile?.profile,
        account: state?.profile?.account,
    };
};

export default connect(mapStateToProps)(PointAddLayout);
