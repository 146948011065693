import { combineReducers } from "redux";
import layout from "./layout";
import domainInfo from "./domain-info";
import auth from "./auth";
import profile from "./profile";
import modal from './modal';
import request from './request';
import socket from './socket';
import game from './game';

export default combineReducers({ layout, domainInfo, auth, profile, modal, request, socket, game });
