import { t1 } from 'translate';
import { CURRENCY_OPTIONS } from 'constants';

const createGoldItemSchema = (accountServer) => ({
    amount: {
        type: 'number',
        options: CURRENCY_OPTIONS,
        min: 0,
        step: 1000000,
        max: accountServer?.balance,
        label: t1('amount_of_gold_from_server_that_you_want_to_sell'),
        style: {width: '100%'},
        handleInputChange: (e) => console.log(e)
    },
})

export default createGoldItemSchema