import React from "react";
import { t1 } from "translate";
import Statistic from "antd/lib/statistic";
import Step11 from 'pages/deposit/components/StepOne/steps/Step1';
import Step12 from 'pages/deposit/components/StepOne/steps/Step2';
import Step13 from 'pages/deposit/components/StepOne/steps/Step3';
import Step21 from 'pages/deposit/components/StepTwo/steps/Step1';
import Step22 from 'pages/deposit/components/StepTwo/steps/Step2';
import Step31 from 'pages/deposit/components/StepThree/steps/Step1';
import Step32 from 'pages/deposit/components/StepThree/steps/Step2';
import Step33 from 'pages/deposit/components/StepThree/steps/Step3';

const { Countdown } = Statistic;

export const stepOneSteps = (timeStartStep, currentStep = 0, nextStep, setCurrentPercent, setCurrentStep,startDeposit, currentTransactionData,offerItemList=[]) => {
  return [
    {
      title: <span style={{fontSize: '14px'}}>{t1("move_to_location")}</span>,
      description: '',//currentStep == 0 ? <Countdown value={timeStartStep + 300*1000} format="mm:ss"/> : '',
      //totalTime: 300, //5 mins
      component: <Step11 setCurrentPercent={setCurrentPercent} startDeposit={startDeposit} setCurrentStep={setCurrentStep} currentStep={currentStep}/>
    },
    {
      title: <span style={{fontSize: '14px'}}>{t1("end_of_step_1")}</span>,
      description: '',//currentStep == 2 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
      //totalTime: 120, //1 mins
      component: <Step13 nextStep={nextStep} setCurrentPercent={setCurrentPercent} currentTransactionData={currentTransactionData} offerItemList={offerItemList}/>
    },
  ];
};

/**
 * This components will work like description below:
 * 
 * 1/ Show text: You need to accept trade offer from bot#123345 - Security chat text: Id-1923475 - countdown 2 mins
 * 2/ Bot check that then show successful and stop countdown when that step finished - cancel mean fail => block from withdraw function for 5 mins
 * 3/ Move to step 3
 * 
 * Any step fail will resulted in block from withdraw function for 5 mins
 */
export const stepTwoSteps = (timeStartStep, currentStep = 0, nextStep, setCurrentPercent,offerItemList) => {
  return [
    {
      title: t1("trade_offer_detail"),
      description: t1('offer_detail'),//currentStep == 0 ? <Countdown value={timeStartStep + 120*1000} format="mm:ss"/> : '',
      //totalTime: 120, //2 mins
      component: <Step21 setCurrentPercent={setCurrentPercent} offerItemList={offerItemList}/>
    },
    {
      title: t1("trade_offer_finished"),
      description: t1('offer_result'),
      //totalTime: 120, //1 mins
      component: <Step22 nextStep={nextStep} setCurrentPercent={setCurrentPercent} offerItemList={offerItemList}/>
    },
  ];
};

/**
 * This components will work like description below:
 * 
 * 1/ Show text: You need to put enough item here then click accept - list of item that eligible to deposit (countdown 3 min)
 * 2/ Bot check that then show successful and stop countdown, show text to screen
 * We found these items in the trade => show item (cross item if not eligible) => if have not eligible item => cancel trade => block this user from trade for 2 mins
 * show link to item eligible to deposit
 * If number of item also not match => cancel trade => show message: wrong amount of items found - trade cancelled => block this user from trade for 2 mins
 * 
 * 3/ If all item eligible and number of item are correct => show promt to user: Is that correct => user confirm (countdown 3 mins) - if cancel => block this user from
 * trade for 3 mins
 * 
 * 4/ user confirm => move to step 4
 * Any step fail will resulted in block from deposit function for 5 mins
 */
export const stepThreeSteps = (timeStartStep, currentStep = 0, nextStep, setCurrentPercent) => {
  return [
    {
      title: t1("submit_item_to_trade_offer"),
      description: currentStep == 0 ? <Countdown value={timeStartStep + 180*1000} format="mm:ss"/> : '',
      totalTime: 180, //5 mins
      component: <Step31 setCurrentPercent={setCurrentPercent}/>
    },
    {
      title: t1("confirm_item"),
      description: currentStep == 1 ? <Countdown value={timeStartStep + 180*1000} format="mm:ss"/> : '',
      totalTime: 180, //3 mins
      component: <Step32 setCurrentPercent={setCurrentPercent}/>
    },
    {
      title: t1("end_of_step_3"),
      description: currentStep == 2 ? <Countdown value={timeStartStep + 60*1000} format="mm:ss"/> : '',
      totalTime: 60, //1 mins
      component: <Step33 nextStep={nextStep} setCurrentPercent={setCurrentPercent}/>
    },
  ];
};