import dashboardRouteSchema from "pages/dashboard/routes";
import depositRouteSchema from "pages/deposit/routes";
import inventoryRouteSchema from "pages/inventory/routes";
import loginRouteSchema from "pages/login/routes";
import marketRouteSchema from "pages/market/routes";
import userRouteSchema from "pages/user-info/routes";
import withdrawRouteSchema from "pages/withdraw/routes";
import pointRouteSchema from 'pages/point/routes';
import settingsRouteSchema from 'pages/settings/routes';
import blogRouteSchema from 'pages/blog/routes'
import supportRouteSchema from 'pages/support/routes';
import SignupRouteSchema from 'pages/signup/routes';
import PublicOfferSchema from "pages/public-offer/routes";
import OfferTradeSchema from "pages/offer-trade/routes";
import GoldMarketSchema from "pages/gold-market/routes";
import HelperSchema from "pages/helper/routes";

const routeSchema = [
    ...dashboardRouteSchema,
    ...depositRouteSchema,
    ...inventoryRouteSchema,
    ...loginRouteSchema,
    ...marketRouteSchema,
    ...userRouteSchema,
    ...withdrawRouteSchema,
    ...pointRouteSchema,
    ...settingsRouteSchema,
    ...blogRouteSchema,
    ...supportRouteSchema,
    ...SignupRouteSchema,
    ...PublicOfferSchema,
    ...OfferTradeSchema,
    ...GoldMarketSchema,
    ...HelperSchema,
];

export default routeSchema;
