export const GUIDANCE_LIST = [
  {
    label: 'Kết nối nhân vật như thế nào?',
    children: <div>
      <div style={{fontSize: '15px', color: '#fff', marginBottom: '10px'}}>Để kết nối nhân vật vui lòng làm theo clip hướng dẫn dưới đây</div>
      <iframe
        width="100%"
        height="280"
        src="https://www.youtube.com/embed/Fc_3ah_ngR0?si=DWW283mTFT0eJ3Ia&amp;start=184"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
      ></iframe>
    </div>,
  },
  {
    label: 'Nạp vật phẩm như thế nào?',
    children: <div>
      <div style={{fontSize: '15px', color: '#fff', marginBottom: '10px'}}>Để nạp vật phẩm vui lòng làm theo clip hướng dẫn dưới đây</div>
      <iframe
        width="100%"
        height="280"
        src="https://www.youtube.com/embed/ZfTXn-WyIoQ?si=vVFx36HBpZDppvNT&amp;start=530"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>,
  },
  {
    label: 'Rút vật phẩm như thế nào?',
    children: <div>
      <div style={{fontSize: '15px', color: '#fff', marginBottom: '10px'}}>Để rút vật phẩm vui lòng làm theo clip hướng dẫn dưới đây</div>
      <iframe
        width="100%"
        height="280"
        src="https://www.youtube.com/embed/Fc_3ah_ngR0?si=C8B9tHZG0SuiRpgJ&amp;start=354"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>,
  },
  {
    label: 'Nạp point như thế nào?',
    children: <div>
      <div style={{fontSize: '15px', color: '#fff', marginBottom: '10px'}}>Để nạp point vui lòng làm theo clip hướng dẫn dưới đây</div>
      <iframe
        width="100%"
        height="280"
        src="https://www.youtube.com/embed/Fc_3ah_ngR0?si=cm6qMt8zTRZpk4Rw&amp;start=55"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>,
  },
  {
    label: 'Mua vật phẩm như thế nào?',
    children: <div>
      <div style={{fontSize: '15px', color: '#fff', marginBottom: '10px'}}>Để mua vật phẩm vui lòng làm theo clip hướng dẫn dưới đây</div>
      <iframe
        width="100%"
        height="280"
        src="https://www.youtube.com/embed/Fc_3ah_ngR0?si=JXMKRjBS8ZQmbHWI&amp;start=48"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>,
  },
  {
    label: 'Bán vật phẩm như thế nào?',
    children: <div>
      <div style={{fontSize: '15px', color: '#fff', marginBottom: '10px'}}>Để bán vật phẩm vui lòng làm theo clip hướng dẫn dưới đây</div>
      <iframe
        width="100%"
        height="280"
        src="https://www.youtube.com/embed/Fc_3ah_ngR0?si=UOpcpRAj8XeqBgOp&amp;start=140"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen></iframe>
    </div>,
  },
]
