import React from 'react';
import Checkbox from 'antd/lib/checkbox';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';

const { Text } = Typography;

//rest here will need options value
//option should in this format: {label: A, value: B}
const CheckboxFieldAntd = ({ input, meta, mode = 'single', errorText = '', option = {}, options = [], ...rest }) => {
  switch (mode) {
    case 'single':
      return (
        <>
          <Checkbox {...input} {...rest} checked={input.value} onChange={(valueChange) => input.onChange(valueChange)}>
            {option.label}
          </Checkbox>
          {meta.error && meta.touched ? (
            <p style={{ marginBottom: 0 }}>
              <Text type="danger" className="error-msg">
                {errorText[meta.error] ? errorText[meta.error] : meta.error}
              </Text>
            </p>
          ) : (
            ''
          )}
        </>
      );
    case 'multi':
    case 'multiple':
      return (
        <>
          <Checkbox.Group
            {...input}
            {...rest}
            onChange={(valueChange) => input.onChange(valueChange)}
            options={options}
          />
          {meta.error && meta.touched ? (
            <p style={{ marginBottom: 0 }}>
              <Text type="danger" className={styles.messageError}>
                {errorText[meta.error] ? errorText[meta.error] : meta.error}
              </Text>
            </p>
          ) : (
            ''
          )}
        </>
      );
    default:
      return <></>;
  }

  return {};
};

export default CheckboxFieldAntd;
