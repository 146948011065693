const userIdentifyUI = {
    default: {
        fields: [
            'identify_card_number',
            'identify_card_type',
            'identify_name'
        ]
    }
}

export default userIdentifyUI;