import SignupLayout from "pages/signup";

const routeSchema = [
  {
    path: "/signup",
    exact: true,
    component: <SignupLayout />,
  },
];

export default routeSchema;
