import { CLOSE_MODAL_ACTION, OPEN_MODAL_ACTION, SET_CONTENT_MODAL_ACTION } from "redux/action-types";
import lGet from 'lodash/get';

const initialState = {};

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case OPEN_MODAL_ACTION: {
            let newState = Object.assign({}, state,);
            
            if (lGet(newState,lGet(action,'payload.id','no_id_form'))){
                newState[lGet(action,'payload.id','no_id_form')].visible = true;
            }else{
                newState[lGet(action,'payload.id','no_id_form')] = {
                    id: lGet(action,'payload.id','no_id_form'),
                    visible: true,
                }
            }

            return newState;
        }
        case CLOSE_MODAL_ACTION: {
            let newState = Object.assign({}, state,);
            
            if (lGet(newState,lGet(action,'payload.id','no_id_form'))){
                newState[lGet(action,'payload.id','no_id_form')].visible = false;
            }else{
                newState[lGet(action,'payload.id','no_id_form')] = {
                    id: lGet(action,'payload.id','no_id_form'),
                    visible: false,
                }
            }

            return newState;
        }
        case SET_CONTENT_MODAL_ACTION: {
            let newState = Object.assign({}, state,);
            
            if (lGet(newState,lGet(action,'payload.id','no_id_form'))){
                newState[lGet(action,'payload.id','no_id_form')].content = lGet(action,'payload.content',<></>);
            }else{
                newState[lGet(action,'payload.id','no_id_form')] = {
                    id: lGet(action,'payload.id','no_id_form'),
                    visible: false,
                    content: lGet(action,'payload.content',<></>)
                }
            }

            return newState;
        }
        default:
            return state;
    }
}

export default ProfileReducer;
