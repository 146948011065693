import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import ItemDetail from "components/ItemDetail";

const { Title, Paragraph, Text } = Typography;

const Step1 = ({setCurrentPercent, offerItemList = []}) => {
	return (
		<Row>
			<Col span={24}>
				<Typography>
					<Title className="text-center">{t1("trade_offer_detail")}</Title>
					<Paragraph>
						{t1(
							'items_below_should_be_included_by_our_bot_in_your_withdraw_offer._'
						)}
						{t1(
							"you_can_decline_the_offer_if_you_think_something_is_missing"
						)}
					</Paragraph>
				</Typography>
			</Col>
			<Col span={24}>
				<Row gutter={16}>
					{offerItemList.map((itemInfo) => {
						return <Col span={6}>
							<ItemDetail
			          data={itemInfo}
			          isSmallPic={true} 
			        />
				    </Col>
				})}
				</Row>
			</Col>
		</Row>
	);
};

export default Step1;