import React from "react";
import Row from "antd/lib/row";
import Col from "antd/lib/col";

const otpCharacterLayout = (formComponent) => {
    return (
        <Row gutter={[20, 16]}>
            <Col span={24}>{formComponent?.otp}</Col>
            <Col span={24} className="text-center">
                {formComponent?.SubmitButton}
            </Col>
        </Row>
    );
};

export default otpCharacterLayout;
