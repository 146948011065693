import { t1 } from 'translate';
import { CURRENCY_OPTIONS } from 'constants';

const createListingSchema = {
    concurrency: {
        type: 'select',
        options: CURRENCY_OPTIONS,
        label: t1('type_of_currency'),
        style: {width: '100%'}
    },
    price: {
        type: 'number',
        label: t1('point_number'),
        handleInputChange: (e) => console.log(e),
    },
}

export default createListingSchema