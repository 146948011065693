import React, {useEffect} from 'react';
import InputNumber from 'antd/lib/input-number';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';

const { Text } = Typography;
const whiteListKeys = ['Backspace', 'Enter'];

const NumberFieldAntd = ({ input, meta, errorText, note = undefined, ...rest }) => {
  useEffect(() => {
    if (rest?.renderFields) {
      rest.renderFields(input, rest?.valuesData);
    }
  }, [rest?.valuesData]);

  useEffect(() => {
    if (rest?.forceAutoFill) {
      input.onChange(rest?.forceAutoFill);
    }
  }, [rest?.forceAutoFill]);

  const fieldTextNote = () => {
    return rest?.textNote ? <span className={`w-100 ${styles.textNote}`}>{rest?.textNote}</span> : <></>;
  };

  return (
    <div className={styles.numberField}>
      <InputNumber
        {...input}
        {...rest}
        status={meta.error && meta.touched ? 'error' : ''}
        onFocus={(e) => {
          input.onChange(e?.target?.value);
          if (rest.onFocus) {
            rest.onFocus(input, e);
          }
        }}
        onBlur={(e) => {
          input.onChange(e?.target?.value);
          if (rest.onBlur) {
            rest.onBlur(input, e);
          }
        }}
        onKeyDown={(e) => {
          // Hold Command/Control/Option Button
          if (!e.metaKey && !e.ctrlKey && !e.altKey && !whiteListKeys.includes(e.key)) {
            if (!new RegExp(rest?.regexPattern).test(e.key)) {
              e.preventDefault();
            }
          }
        }}
        onInput={(value) => {
          rest.handleInputChange(value,input)
        }}
      />
      {fieldTextNote()}
      {note ? <span className={`w-100 ${styles.textNote}`}>{note}</span> : <></>}
      {/* {(meta.error && meta.touched) ? <Text type="danger" className={styles.messageError}>{errorText[meta.error] ? errorText[meta.error] : meta.error}</Text> : ''} */}
      {meta.error && meta.touched ? (
        <Text type="danger" style={{ float: 'left' }} className={styles.messageError}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : (
        ''
      )}
    </div>
  );
};

export default NumberFieldAntd;
