import { t1 } from 'translate';
import { composeValidators, required} from 'validations/index';

const searchGameCharacterSchema = {
    name: {
        type: 'text',
        label: t1('character_name'),
        validate: composeValidators(required),
        errorText: {
            required: t1('character_name_is_require')
        },
    }
}

export default searchGameCharacterSchema