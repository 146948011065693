import starIcon from '../assets/icons/common/starIcon.svg';
import moonIcon from '../assets/icons/common/moonIcon.svg';
import sunIcon from '../assets/icons/common/sunIcon.svg';
import cometIcon from '../assets/icons/common/cometIcon.svg';
import threeStarIcon from '../assets/icons/common/threeStarIcon.svg';

export const ITEM_DETAIL_MAPPING = [
  {
    key: 'gold_price_npc',
    label: 'Giá tham khảo (Vàng)'
  },
  {
    key: 'level_can_use',
    label: 'Cấp độ có thể sử dụng'
  },
  {
    key: 'durably_min_0',
    label: 'Độ bền tối thiểu'
  },
  {
    key: 'durably_max_100',
    label: 'Độ bền tối đa'
  },
  {
    key: 'crit_0',
    label: 'Chí mạng (Cường hoá 0%)'
  },
  {
    key: 'crit_100',
    label: 'Chí mạng (Cường hoá 100%)'
  },
  {
    key: 'parry_blows_attack_0',
    label: 'Đỡ đòn tấn công (Cường hoá 0%)'
  },
  {
    key: 'parry_blows_attack_100',
    label: 'Đỡ đòn tấn công (Cường hoá 100%)'
  },
  {
    key: 'magic_damage_min_0',
    label: 'Sát thương phép tối thiểu (Cường hoá 0%)'
  },
  {
    key: 'magic_damage_min_100',
    label: 'Sát thương phép tối thiểu (Cường hoá 100%)'
  },
  {
    key: 'magic_damage_max_0',
    label: 'Sát thương phép tối đa (Cường hoá 0%)'
  },
  {
    key: 'magic_damage_max_100',
    label: 'Sát thương phép tối đa (Cường hoá 100%)'
  },
  {
    key: 'magic_defense_0',
    label: 'Phòng thủ phép (Cường hoá 0%)'
  },
  {
    key: 'magic_defense_100',
    label: 'Phòng thủ phép (Cường hoá 100%)'
  },
  {
    key: 'increase_magic_attack_0',
    label: 'Tăng cường tấn công phép (Cường hoá 0%)'
  },
  {
    key: 'increase_magic_attack_10',
    label: 'Tăng cường tấn công phép (Cường hoá 100%)'
  },
  {
    key: 'increase_magic_attack_min_0',
    label: 'Tăng cường tấn công phép tối thiểu (Cường hoá 0%)'
  },
  {
    key: 'increase_magic_attack_min_100',
    label: 'Tăng cường tấn công phép tối thiểu (Cường hoá 100%)'
  },
  {
    key: 'increase_magic_attack_max_0',
    label: 'Tăng cường tấn công phép tối đa (Cường hoá 0%)'
  },
  {
    key: 'increase_magic_attack_max_100',
    label: 'Tăng cường tấn công phép tối đa (Cường hoá 100%)'
  },
  {
    key: 'physical_damage_min_0',
    label: 'Sát thương vật lý tối thiểu (Cường hoá 0%)'
  },
  {
    key: 'physical_damage_min_100',
    label: 'Sát thương vật lý tối thiểu (Cường hoá 100%)'
  },
  {
    key: 'physical_damage_max_0',
    label: 'Sát thương vật lý tối đa (Cường hoá 0%)'
  },
  {
    key: 'physical_damage_max_100',
    label: 'Sát thương vật lý tối đa (Cường hoá 100%)'
  },
  {
    key: 'physical_defense_0',
    label: 'Phòng thủ vật lý (Cường hoá 0%)'
  },
  {
    key: 'physical_defense_100',
    label: 'Phòng thủ vật lý (Cường hoá 100%)'
  },
  {
    key: 'increase_physical_attack_0',
    label: 'Tăng cường tấn công vật lý (Cường hoá 0%)'
  },
  {
    key: 'increase_physical_attack_100',
    label: 'Tăng cường tấn công vật lý (Cường hoá 100%)'
  },
  {
    key: 'increase_physical_attack_min_0',
    label: 'Tăng cường tấn công vật lý tối thiểu (Cường hoá 0%)'
  },
  {
    key: 'increase_physical_attack_min_100',
    label: 'Tăng cường tấn công vật lý tối thiểu (Cường hoá 100%)'
  },
  {
    key: 'increase_physical_attack_max_0',
    label: 'Tăng cường tấn công vật lý tối đa (Cường hoá 0%)'
  },
  {
    key: 'increase_physical_attack_max_100',
    label: 'Tăng cường tấn công vật lý tối đa (Cường hoá 100%)'
  }
];

export const ITEM_LEVEL = [
  {
    level: 'sos',
    icon: starIcon,
    style: null
  },
  {
    level: 'som',
    icon: moonIcon,
    style: null
  },
  {
    level: 'sun',
    icon: sunIcon,
    style: null
  },
  {
    level: 'son',
    icon: cometIcon,
    style: null
  },
  {
    level: 'honor',
    icon: threeStarIcon,
    style: {
      top: '-35px',
      left: 'calc(50% - 85px)',
      width: '90px',
      height: '65px'
    }
  }
]

export const ITEM_RARITY = {
  NORMAL: 'normal',
  BLUE: 'blue',
  RARE: 'rare'
}

export const ITEM_RARE_SPECIAL = {
  SOS: 'A',
  SOM: 'B',
  SUN: 'C'
}

export const ITEM_CATEGORY_I_MAPPING = {
  WEAPON: 6,
  ACC: 5,
  SHIELD: 4,
  HEAVY_ARMOR: 3,
  LIGHT_ARMOR: 2,
  CLOTHES_ARMOR: 1,
  HEAVY_EU_ARMOR: 11,
  LIGHT_EU_ARMOR: 10,
  CLOTHES_EU_ARMOR: 9,
  EU_ACC: 12
}

export const ITEM_SHIELD_CATEGORY_II = {
  CHINA_SHIELD: 1,
  EU_SHIELD: 2
}

export const ITEM_WEAPON_CATEGORY_II = {
  SWORD: 2,
  BLADE: 3,
  SPEAR: 4,
  TBLADE: 5,
  BOW: 6,
  SWORD_EU: 7,
  TSWORD: 8,
  AXE: 9,
  DARKSTAFF: 10,//phap bong
  TSTAFF: 11,//phap truong
  XBOW: 12,
  DAGGER: 13,
  HARP: 14,
  STAFF: 15,//nhat bong
}

export const ITEM_ARMOR_CATEGORY_II = {
  HEAD_ARMOR: 1,//include both hat and invisible hat
  SHOULDER_ARMOR: 2,
  BODY_ARMOR: 3,
  LEG_ARMOR: 4,
  ARM_ARMOR: 5,
  FOOT_ARMOR: 6,
}

export const ITEM_ACC_CATEGORY_II = {
  EARRING: 1,
  NECKLACE: 2,
  RING: 3
}