import React, { useState } from "react";
import Select from "antd/lib/select";
import Button from "antd/lib/button";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { t1 } from "translate";
import { CHARACTER_LIST } from "pages/deposit/fake";
import Typography from "antd/lib/typography";
import DataFetchHook from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { getCharacterImageFromModelId } from "commons/game";
import { SERVER_LIST } from "constants/game";

const { Title, Paragraph, Text, Link } = Typography;

const SelectCharacter = ({ updateCharacter = (e) => console.log(e), setFullInfo = false }) => {
  const [gameCharacterList, setGameCharacterList] = useState([]);

  const [
    dataGameCharacter,
    setEndpointGameCharacter,
    setParamsGameCharacter,
  ] = DataFetchHook(
    endpoints.game_character,
    { submit: 1 },
    "GET",
    (result, params) => {
      if (result?.data) {
        const newCharacterList = (result?.data || [])?.map(character => {
          const getServer = SERVER_LIST.find(server => server.id === Number(character?.server_id))?.name || '';
          const avatar = (
            <img
              src={getCharacterImageFromModelId(
                character?.char_id
              )}
              alt={character?.name}
              style={{ width: "50px" }}
            />
          );

          return {
            label: (
              <>
                {avatar}
                <span className="ps-2">{character?.name}</span> - {getServer}
              </>
            ),
            value: setFullInfo ? JSON.stringify(character) : character?.id,
            name: character?.name,
            icon: avatar
          }
        });
        setGameCharacterList(newCharacterList);
      }
    },
    (result, params) => {
      console.log({ result });
    }
  );

  return (
    <Row>
      <Col span={24}>
        <p className="fw-bold">{t1("select_your_character_to_countinue")}</p>
        <Select
          options={gameCharacterList}
          onChange={(e) => updateCharacter(e)}
          style={{ width: "100%" }}
        />
      </Col>
      <Col span={24}>
        <Paragraph>
          {t1(
            "you_can_move_to_profile_character_menu_to_connect_your_character_with_your_sro_money_account._after_connected_your_character_will_show_here"
          )}
        </Paragraph>
      </Col>
    </Row>
  );
};

export default SelectCharacter;
