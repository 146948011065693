import React, { useEffect } from 'react';
import Typography from 'antd/lib/typography';
import Radio from 'antd/lib/radio';
import styles from './styles.module.scss';

const { Text } = Typography;

//rest here will need options value
//option should in this format: {label: A, value: B}
const RadioFieldAntd = ({ input, meta, errorText, options = [], ...rest }) => {
  useEffect(() => {
    if (rest?.renderFields) {
      rest.renderFields(input, rest?.valuesData);
    }
  }, [rest?.valuesData]);
  if (rest?.buttonStyle) {
    return (
      <div className={styles.radioField}>
        <Radio.Group
          {...input}
          {...rest}
          onChange={(valueChange) => input.onChange(valueChange)}
          //options={options}
        >
          {options.map((optionsData, index) => {
            return (
              <Radio.Button value={optionsData.value} key={`radio-button-${index}`}>
                {optionsData.label}
              </Radio.Button>
            );
          })}
        </Radio.Group>
        {meta.error && meta.touched ? (
          <Text type="danger" className="error-msg">
            {errorText[meta.error] ? errorText[meta.error] : meta.error}
          </Text>
        ) : (
          ''
        )}
      </div>
    );
  } else {
    return (
      <div className={styles.radioField}>
        <Radio.Group {...input} {...rest} onChange={(valueChange) => input.onChange(valueChange)} options={options} />
        {meta.error && meta.touched ? (
          <p style={{ marginBottom: 0 }}>
            <Text type="danger" className="error-msg">
              {errorText[meta.error] ? errorText[meta.error] : meta.error}
            </Text>
          </p>
        ) : (
          ''
        )}
      </div>
    );
  }
};

export default RadioFieldAntd;
