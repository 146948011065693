import React, { useState, useMemo } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Collapse from 'antd/lib/collapse';

import searchIcon from '../../../assets/icons/common/searchIcon.svg';
import styles from './styles.module.scss';

const items = [
  {
    key: '1',
    label: 'This is panel header 1',
    children: <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum cumque similique itaque amet modi maiores placeat pariatur nam reprehenderit, eveniet dolore aut. Cumque, praesentium! Natus harum minus ratione magni. Harum 1?</p>,
  },
  {
    key: '2',
    label: 'This is panel header 2',
    children: <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum cumque similique itaque amet modi maiores placeat pariatur nam reprehenderit, eveniet dolore aut. Cumque, praesentium! Natus harum minus ratione magni. Harum 2?</p>,
  },
  {
    key: '3',
    label: 'This is panel header 3',
    children: <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. Cum cumque similique itaque amet modi maiores placeat pariatur nam reprehenderit, eveniet dolore aut. Cumque, praesentium! Natus harum minus ratione magni. Harum 3?</p>,
  },
];

//This page will list all question and answer that helpful for people
const SupportQALayout = (props) => {
  const [qaKeySearch, setQAKeySearch] = useState('');
  const [qaList, setQAList] = useState(items);

  const onChange = (key) => {
    console.log({key});
  };

  const qaShowList = useMemo(() => {
    if (!!qaKeySearch) {
      return items.filter((item) => item.label.includes(qaKeySearch));
    }

    return items;
  }, [qaKeySearch]);

  return (
    <PageLayout>
      <div className={styles.faqContainer}>
        <div className={styles.header}>
          <span className={styles.title}>
            {t1('support_qa_layout')}
          </span>
        </div>
        <div className={styles.content}>
          <div className={styles.searchBox}>
            <input
              type="text"
              className={styles.search}
              placeholder={t1('question_title')}
              onChange={(e) => setQAKeySearch(e.target.value)}
            />
            <img src={searchIcon} alt="search icon" />
          </div>
          <div className={styles.questions}>
            <Collapse
              items={qaShowList}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </PageLayout>
  )
}

export default SupportQALayout;
