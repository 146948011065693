export const MENU_LINK_USER_INFO_EDIT = '/user-info';
export const MENU_LINK_CHARACTER_INFO = '/user-info/character';
export const MENU_LINK_USER_INFO_HISTORY = '/user-info/history';

export const MENU_LINK_DASHBOARD = '/';
export const MENU_LINK_INVENTORY = '/inventory';
export const MENU_LINK_MARKET = '/market';
export const MENU_LINK_MARKET_DEAL = '/market/deal';
export const MENU_LINK_MARKET_HISTORY = '/market/history';

export const MENU_LINK_DEPOSIT_BOT_STATUS = '/deposit/status';
export const MENU_LINK_DEPOSIT = '/deposit';
export const MENU_LINK_DEPOSIT_HISTORY = '/deposit/history';
export const MENU_LINK_DEPOSIT_ALLOWED = '/deposit/allowed';

export const MENU_LINK_WITHDRAW_BOT_STATUS = '/withdraw/status';
export const MENU_LINK_WITHDRAW = '/withdraw';
export const MENU_LINK_WITHDRAW_GOLD = '/withdraw/gold';
export const MENU_LINK_WITHDRAW_HISTORY = '/withdraw/history';
//should have menu link to kyc here

export const MENU_LINK_ADD_POINT = '/point/add';
export const MENU_LINK_REFUND = '/point/refund';
export const MENU_LINK_POINT_HISTORY = '/point/history';

export const MENU_LINK_SETTINGS_PERSONAL = '/settings/personal';
export const MENU_LINK_SETTINGS_NOTIFICATION = '/settings/notification';

export const MENU_LINK_ABOUT_US = '/blog/about-us';
export const MENU_LINK_GUIDANCE_VIDEO = '/blog/guidance'

export const MENU_LINK_QA = '/support/qa'
export const MENU_LINK_TICKET_SUPPORT = '/support/ticket'

export const MENU_LINK_OFFER_TRADE = '/offer-trade/:id';
export const MENU_LINK_GOLD_MARKET_HK = '/gold-market/hoang-kim'
export const MENU_LINK_GOLD_MARKET_BK = '/gold-market/bach-kim'
export const MENU_LINK_GOLD_MARKET_TK = '/gold-market/thien-kim'

export const MENU_LINK_HELPER_QUEST = '/helper/quest';
export const MENU_LINK_HELPER_MONSTER = '/helper/monster';
