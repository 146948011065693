import { SET_BET_COIN_AMOUNT } from "redux/action-types";
//import lGet from 'lodash/get';

const initialState = {};

const GameReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BET_COIN_AMOUNT: {
            return {... state, ...action.payload};
        }
        default:
            return state;
    }
}

export default GameReducer;
