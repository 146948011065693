import BankTransferIcon from 'assets/icons/common/payment_bank_transfer.svg';
import CardIcons from 'assets/icons/common/payment_cards.svg';
import { t1 } from 'translate';

export const PAYMENT_METHODS = [
    {
        value: 1,
        label: 'bank_transfer',
        icon: BankTransferIcon,
        description: t1('using_e_bankink_for_transfer_via_qr_code')
    },
    // {
    //     label: 'cards',
    //     icon: CardIcons,
    //     value: 2,
    //     description: 'MasterCard, VISA, etc.'
    // },
];

export const POINT_LIST = [
    {
        label: '50',
        value: 50,
    },
    {
        label: '100',
        value: 100,
    },
    {
        label: '200',
        value: 200,
    },
    {
        label: '500',
        value: 500,
    },
    {
        label: '1000',
        value: 1000,
    },
    {
        label: '1500',
        value: 1500,
    },
];
