import React, { useEffect, useState } from 'react';
import Table from 'antd/lib/table';
import Space from 'antd/lib/space';
import Modal from 'antd/lib/modal';
import { t1 } from 'translate';
import { EyeOutlined } from '@ant-design/icons';

const TableCustom = ({isShortCut = false, columns, data, pagination, style, detailTemplate}) => {
    const [columnData, setColumnData] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [detailData, setDetailData] = useState(null);

    useEffect (() => {
        let newColumns = [...columns];
        if (isShortCut) {
            const action = {
                title: t1('_'),
                key: 'action',
                render: (_, record) => (
                  <Space size="middle" onClick={() => {
                    setShowDetail(true);
                    setDetailData(record);
                  }}>
                    <EyeOutlined />
                  </Space>
                ),
            };
            newColumns = [...newColumns.filter(column => column?.isShortCut), action];
        }

        setColumnData(newColumns);
    }, [columns, isShortCut]);

    return (
        <>
            <Table
                style={style}
                columns={columnData}
                dataSource={data}
                pagination={{
                    ...pagination,
                    size: isShortCut ? 'small' : 'default'
                }}
            />
            {typeof(detailTemplate) === 'function' && (
                <Modal
                    centered
                    title={t1('_')}
                    open={showDetail}
                    onCancel={() => setShowDetail(false)}
                    footer={null}
                >
                    {detailTemplate(detailData)}
                </Modal>
            )}
        </>
    );
};

export default TableCustom;
