import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Divider from "antd/lib/divider";
import Table from "antd/lib/table";
import useFetch from "commons/hook/fetchDataHook";
//import SearchItem from './components/SearchItem';
import { endpoints } from "constants/endpoints";
import MarketListingBlock from "components/ItemDetail/MarketListingBlock";
//import styles from './styles.module.scss';
import Modal from 'antd/lib/modal';
import notification from 'antd/lib/notification';
import Pagination from "antd/lib/pagination";
import { ITEM_GOLD_MODEL_ID } from "constants";
import MarketGoldListingBlock from "components/ItemDetail/MarketGoldListingBlock";
import { InputNumber } from "antd/lib";
import { connect } from "react-redux";
import { useLocation } from 'react-router-dom';
import { getQueryParams } from 'helper/helper';
import Typography from "antd/lib/typography";
import GoldAccountDetail from 'components/GoldAccount';
import DepthChart from './components/DepthChart';
import PriceChart from './components/PriceChart';
import { SERVER_LIST } from 'constants/game';
import FormCreator from 'commons/final-form/core/FormCreator';
import createGoldItemMarketSchema from "schema-form/schema/createGoldItemMarketSchema";
import createGoldItemMarketUI from "schema-form/ui/createGoldItemMarketUI";
import createGoldItemMarketLayout from "schema-form/layout/createGoldItemMarketLayout";
import preOrderGoldItemMarketSchema from "schema-form/schema/preOrderGoldItemMarketSchema";
import preOrderGoldItemMarketUI from "schema-form/ui/preOrderGoldItemMarketUI";
import preOrderGoldItemMarketLayout from "schema-form/layout/preOrderGoldItemMarketLayout";
import Button from "antd/lib/button";
import dayjs from "dayjs";
import { formatPrice } from "helper/helper";

import { fetchOrder } from "./controller";
import { columnsSell, columnsBuy } from "./table/columns";
import { optionGenerator } from "./generator/depthChartGenerator";
import { generateTotalQuantityAndPriceStart } from "./generator";
const { Title, Paragraph, Text, Link } = Typography;

//This will list all the item that are selling on the market, allow user to make a deal here
const MarketLayout = ({profile}) => {
	const location = useLocation();
    // const { server = 3, model = '' } = getQueryParams(location.search, ['server', 'model']);

    const [server, setServer] = useState(SERVER_LIST[2]);
    const [goldBuyOrder, setGoldBuyOrder] = useState([]);
    const [goldSellOrder, setGoldSellOrder] = useState([]);
    const [goldModal, setGoldModal] = useState(false);
    const [indexServer, setIndexServer] = useState(2);
    const [sellData, setSellData] = useState({ amount: 0, price: 0 });
    const [goldSellPage, setGoldSellPage] = useState(1);
    const [goldSellPageSize, setGoldSellPageSize] = useState(12);
    const [goldSellData, setGoldSellData] = useState([]);
    const [goldSellList, setGoldSellList] = useState([]);
    const [goldSellOwnList, setGoldSellOwnList] = useState([]);
    const [modalDeleteGoldOpen, setModalDeleteGoldOpen] = useState({
        status: false,
        selectedItemId: -1,
    });
    const [preOrderGoldModal, setPreOrderGoldModal] = useState(false);
    const [preOrderGoldList, setPreOrderGoldList] = useState([]);
    const [modalDeletePreOrderGoldOpen, setModalDeletePreOrderGoldOpen] = useState({
        status: false,
        selectedItemId: -1,
    });
    const [currentPreOrderListPage, setCurrentPreOrderListPage] = useState(1);
    const [preOrderListPageSize, setPreOrderListPageSize] = useState(10);
    const [preOrderListTotal, setPreOrderListTotal] = useState(0);
    // const [currentSellGoldListPage, setCurrentSellGoldListPage] = useState(1);
    // const [sellGoldListPageSize, setSellGoldListPageSize] = useState(5);
    // const [sellGoldListTotal, setSellGoldListTotal] = useState(0);

    useEffect(() => {
        const getServer = location.pathname.replace('/gold-market/', '');
        let currentServer = server; 
        switch (getServer) {
            case 'thien-kim':
                currentServer = SERVER_LIST[0];
                setIndexServer(0);
                break;
            case 'bach-kim':
                currentServer = SERVER_LIST[1];
                setIndexServer(1);
                break;
            default:
                currentServer = SERVER_LIST[2];
                setIndexServer(2);
                break;
        }
        setServer(currentServer);
        fetchOrder(setParamsGoldBuyOrder, server, currentServer.id);
        fetchOrder(setParamsGoldSellOrder, server, currentServer.id);
        fetchGoldSellList(currentServer.id);
        fetPreOrderGoldList(currentServer.id, 1);
    }, [location]);

    useEffect(() => {
        if (profile?.profile) {
            getGoldSellList(goldSellData);
        }
    }, [goldSellData, profile]);

    const fetchGoldSellList = (serverId = null) => {
        setParamsGoldSellListParams('');
        setParamsGoldSellListParams({
			server_id: serverId ? serverId : server.id,
			model_id: 999999,
            submit: 1,
			page: goldSellPage,
			limit: goldSellPageSize
        })
    };

    const fetPreOrderGoldList = (serverId = null, currentPage = null) => {
        setParamsPreOrderGoldListParams("");
        setParamsPreOrderGoldListParams({
            status: 0,
            model_id: 999999,
            start: "2024-01-01",
            server_id: serverId ? serverId : server.id,
            page: currentPage !== null ? currentPage : currentPreOrderListPage,
        });
    };

    const getGoldSellList = (data) => {
        const sellList = [];
        const sellOwnList = [];
        if (data?.length > 0) {
            data.forEach((data) => {
                const goldItem = {
                    id: data?.id,
                    item_id: data?.item_id,
                    quantity: data?.quantity ? formatPrice(data.quantity * 1000000) : 0,
                    price: data?.price ? formatPrice(data.price) : 0,
                    name: t1('gold'),
                    listedOn: data?.created_at ? dayjs(data.created_at).format('DD/MM/YYYY') : ''
                };

                if (data?.user?.id === profile?.profile?.id) {
                    sellOwnList.push(goldItem);
                } else {
                    sellList.push(goldItem);
                }
            })
        }
        setGoldSellList(sellList);
        setGoldSellOwnList(sellOwnList);
    };

    const getPreOrderGoldList = (data) => {
        let preOrderGold = [];
        if (data?.data?.length > 0) {
            preOrderGold = data.data.map((d) => ({
                id: d?.id,
                quantity: d?.quantity ? formatPrice(d.quantity * 1000000) : 0,
                price: d?.price ? formatPrice(d.price) : 0,
                name: t1("gold"),
                listedOn: d?.created_at ? dayjs(d.created_at).format("DD/MM/YYYY") : "",
            }));
        }
        setPreOrderGoldList(preOrderGold);
    };

    //api call start
    const [dataGoldBuyOrder, setEndpointGoldBuyOrder, setParamsGoldBuyOrder] = useFetch(
        endpoints.public_gold_market_buy,
        "",
        "GET",
        (result, params) => {
            if (result?.data) {
                setGoldBuyOrder(result?.data);
            }
        },
        (result, params) => {
            if (result?.data) {
                setGoldBuyOrder(result?.data);
            }
        }
    );

    const [dataGoldSellOrder, setEndpointGoldSellOrder, setParamsGoldSellOrder] = useFetch(
        endpoints.public_gold_market_sell,
        "",
        "GET",
        (result, params) => {
            if (result?.data) {
                setGoldSellOrder(result?.data);
            }
        },
        (result, params) => {
            if (result?.data) {
                setGoldSellOrder(result?.data);
            }
        }
    );

    const [dataCSellOrder, setEndpointCSellOrder, setParamsCSellOrder] = useFetch(
        endpoints.sell_gold_instant,
        "",
        "POST",
        (result, params) => {
            if (result?.data) {
                fetchGoldSellList();
                fetchOrder(setParamsGoldSellOrder, server, server.id);
            }
        },
        (result, params) => {
            if (result?.data) {
                fetchGoldSellList();
                fetchOrder(setParamsGoldSellOrder, server, server.id);
            }
        }
    );
    

    //api call end

    const [dataGoldSellList, setEndpointGoldSellList, setParamsGoldSellListParams] = useFetch(
		endpoints.search_item,
		'',
		"GET",
		(result, params) => {
            if (result?.data) {
                setGoldSellData(result?.data);
                // setCurrentSellGoldListPage(result?.data?.current_page || 1);
                // setSellGoldListPageSize(result?.data?.per_page || 10);
                // setSellGoldListTotal(result?.data?.total || 0);
            }
		},
		(result, params) => {
            if (result?.data) {
                setGoldSellData(result?.data);
                // setCurrentSellGoldListPage(result?.data?.current_page || 1);
                // setSellGoldListPageSize(result?.data?.per_page || 10);
                // setSellGoldListTotal(result?.data?.total || 0);
            }
		}
	);

    // const [dataGoldSell, setEndpointGoldSell, setGoldSellParams] = useFetch(
    //     endpoints.set_price,
    //     '',
    //     "POST",
    //     (result, params) => {
    //         setSellData({amount: 0, price: 0});
    //         fetchGoldSellList();
    //         setGoldModal(false);
    //         notification.success({
	// 			message: t1('success_message'),
	// 			description: t1('sell_%s_success', ['gold'])
	// 		});
    //     },
    //     (result, params) => {
    //         notification.warning({
	// 			message: t1('warning_message'),
	// 			description: result?.message
	// 		})
    //     }
    // );

    // const [dataCreateGold, setEndpointCreateGold, setParamsCreateGold] = useFetch(
    //     endpoints.create_gold_item,
    //     '',
    //     "POST",
    //     (result, params) => {
    //         if (profile?.reloadProfile){
    //             profile?.reloadProfile();
    //         }
    //         if (result?.success && result?.data?.item?.id) {
    //             setGoldSellParams('');
    //             setGoldSellParams({
    //                 item_id: result.data.item.id,
    //                 concurrency: 0,
    //                 price: sellData.price
    //             })
    //         }
    //     },
    //     (result, params) => {
    //         setGoldModal(false);
    //         notification.warning({
	// 			message: t1('warning_message'),
	// 			description: t1(result?.message)
	// 		});
    //     }
    // );

    const [dataCancelGold, setEndpointCancelGold, setParamsCancelGold] = useFetch(
        endpoints.cancel_gold_listing,
        "",
        "POST",
        (result, params) => {
            if (profile?.reloadProfile) {
                profile?.reloadProfile();
            }
            fetchGoldSellList();
            fetchOrder(setParamsGoldSellOrder, server);
            setModalDeleteGoldOpen({ status: false, selectedItemId: -1 });
        },
        (result, params) => {
            setModalDeleteGoldOpen({ status: false, selectedItemId: -1 });
            notification.warning({
                message: t1("warning_message"),
                description: result?.message,
            });
        }
    );

    const [dataPreOrderGold, setEndpointPreOrderGold, setPreOrderGoldParams] = useFetch(
        endpoints.pre_order,
        '',
        "POST",
        (result, params) => {
            setPreOrderGoldModal(false);
            fetPreOrderGoldList();
            fetchOrder(setParamsGoldBuyOrder, server);
            notification.success({
				message: t1('success_message'),
				description: t1('pre_order_%s_success', ['gold'])
			});
        },
        (result, params) => {
            notification.warning({
				message: t1('warning_message'),
				description: result?.message
			})
        },
        () => {},
        'raw'
    );

    const [dataPreOrderGoldList, setEndpointPreOrderGoldList, setParamsPreOrderGoldListParams] = useFetch(
		endpoints.list_pre_order,
		'',
		"GET",
		(result, params) => {
            if (result?.data) {
                getPreOrderGoldList(result?.data);
                setCurrentPreOrderListPage(result?.data?.current_page || 1);
                setPreOrderListPageSize(result?.data?.per_page || 10);
                setPreOrderListTotal(result?.data?.total || 0);
            }
		},
		(result, params) => {
            if (result?.data) {
                getPreOrderGoldList(result?.data);
                setCurrentPreOrderListPage(result?.data?.current_page || 1);
                setPreOrderListPageSize(result?.data?.per_page || 10);
                setPreOrderListTotal(result?.data?.total || 0);
            }
		}
	);

    const [dataCancelPreOrderGold, setEndpointCancelPreOrderGold, setParamsCancelPreOrderGold] = useFetch(
        endpoints.delete_pre_order,
        "",
        "DELETE",
        (result, params) => {
            setModalDeletePreOrderGoldOpen({status: false, selectedItemId: undefined});
            fetchOrder(setParamsGoldBuyOrder, server);
            fetPreOrderGoldList();
        },
        (result, params) => {}
    );

    const onSubmitCreateGold = (data) => {
        setParamsCSellOrder("");
        setParamsCSellOrder({
            price: data?.price,
            quantity: data?.amount,
            server_id: indexServer + 1,
        });
        setGoldModal(false);
    };

    const onSubmitBuyGold = (data) => {
        setPreOrderGoldParams('');
        setPreOrderGoldParams({
            model_id: 999999,
            price: parseFloat(data?.price),
            concurrency: 0,
            quantity: parseFloat(data?.amount),
            server_id: server.id,
            requirements: []
        });
    };

    const columns = [
        {
          title: t1('quantity'),
          dataIndex: 'quantity',
          key: 'quantity',
          render: (text) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {text}
                <img src="/goldicon.png" alt="sro money gold icon" style={{width:"14px", marginLeft: '5px'}}/>
            </div>
          )
        },
        {
          title: t1('price'),
          dataIndex: 'price',
          key: 'price',
          render: (text) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {text}
                <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px", marginLeft: '5px'}}/>
            </div>
        ),
        },
        // {
        //   title: t1('listed_on'),
        //   dataIndex: 'listedOn',
        //   key: 'listedOn',
        //   render: (text) => <a>{text}</a>,
        // },
        {
          title: t1('_'),
          render: (item) => (
            <div style={{textAlign: 'center'}}>
                <Button
                    type="primary"
                    size="small"
                    danger
                    onClick={(e) => {
                        if (item?.item_id) {
                            setModalDeleteGoldOpen({status: true, selectedItemId: item.item_id});
                        } else {
                            notification.error({
                                message: t1('error_message'),
                                description: t1('delete_%s_error', ['gold'])
                            });
                        }
                    }}
                >
                    {t1('remove')}
                </Button>
            </div>
          )
        }
    ];

    const preOrderColumns = [
        {
          title: t1('quantity'),
          dataIndex: 'quantity',
          key: 'quantity',
          render: (text) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {text}
                <img src="/goldicon.png" alt="sro money gold icon" style={{width:"14px", marginLeft: '5px'}}/>
            </div>
          )
        },
        {
          title: t1('price'),
          dataIndex: 'price',
          key: 'price',
          render: (text) => (
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                {text}
                <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px", marginLeft: '5px'}}/>
            </div>
        ),
        },
        {
            title: t1('_'),
            render: (item) => (
              <div style={{textAlign: 'center'}}>
                  <Button
                      type="primary"
                      size="small"
                      danger
                      onClick={(e) => {
                          if (item?.id) {
                              setModalDeletePreOrderGoldOpen({status: true, selectedItemId: item.id});
                          } else {
                              notification.error({
                                  message: t1('error_message'),
                                  description: t1('delete_%s_error', ['gold'])
                              });
                          }
                      }}
                  >
                      {t1('remove')}
                  </Button>
              </div>
            )
        }
    ];

	return (
		<PageLayout breadcrumList={[{ title: t1("gold_market_page"), link: '/dashboard' },{ title: t1("server_%s", [server.name]), link: location.pathname }]}>
            <Row gutter={[8, 8]}>
                <Col span={24} className="mt-2">
                    <Card title={null} style={{ borderRadius: "5px"}}>
                        <Row gutter={[16, 32]}>
                            <Col lg={16} md={12} sm={12} xs={24}>
                                <Typography>    
                                    <Title level={4}>{t1('gold_market_server_%s', [server.name])}</Title>
                                    <Paragraph>
                                        {t1('gold_market_server_%s_description', [server.name])}
                                    </Paragraph>
                                </Typography>
                            </Col>
                            <Col lg={8} md={12} sm={12} xs={24}>
                                <GoldAccountDetail filterServer={[server]} staticTextStyle={{display: 'flex'}}/>
                            </Col>
                        </Row>
                    </Card>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Card title={null} style={{ borderRadius: "5px" }} bodyStyle={{ padding: '10px 5px' }}>
                                <Row gutter={[16, 32]}>
                                    <Col span={24}>
                                        <DepthChart
                                            optionsData={optionGenerator(
                                                goldSellOrder,
                                                goldBuyOrder
                                            )}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={null} style={{ borderRadius: "5px"}} bodyStyle={{ padding: '10px' }}>
                                <Row gutter={[4, 4]}>
                                    <Col span={24} className="text-center">
                                        <p>
                                            <span className="fw-bold fs-5 me-1">
                                                {generateTotalQuantityAndPriceStart(goldBuyOrder)?.quantity}
                                            </span>
                                            {t1("mil_request_to_buy_at")}
                                            <span className="fw-bold fs-5 ms-1 me-1">
                                                {generateTotalQuantityAndPriceStart(goldBuyOrder)?.price}
                                            </span>
                                            {t1("or_lower")}
                                        </p>
                                    </Col>
                                    <Col span={24} className="text-center">
                                        <div
                                            className="fs-2"
                                            style={{
                                                backgroundColor: '#FF7F27',
                                                color: '#fff',
                                                borderRadius: '5px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setGoldModal(true)}
                                        >
                                            {t1('sell')}
                                        </div>
                                        <Divider />
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            columns={columnsBuy}
                                            dataSource={goldBuyOrder}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={null} style={{ borderRadius: "5px"}} bodyStyle={{ padding: '10px' }}>
                                <Row gutter={[4, 4]}>
                                    <Col span={24} className="text-center">
                                        <p>
                                            <span className="fw-bold fs-5 me-1">
                                                {
                                                    generateTotalQuantityAndPriceStart(
                                                        goldSellOrder
                                                    )?.quantity
                                                }
                                            </span>
                                            {t1("mil_gold_on_sale_starting_at")}
                                            <span className="fw-bold fs-5 ms-1 me-1">
                                                {t1("%s_point_per_mil", [
                                                    generateTotalQuantityAndPriceStart(
                                                        goldSellOrder
                                                    )?.price,
                                                ])}
                                            </span>
                                        </p>
                                    </Col>
                                    <Col span={24} className="text-center">
                                        <div
                                            className="fs-2"
                                            style={{
                                                backgroundColor: '#FF7F27',
                                                color: '#fff',
                                                borderRadius: '5px',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => setPreOrderGoldModal(true)}
                                        >
                                            {t1('buy')}
                                        </div>
                                        <Divider />
                                    </Col>
                                    <Col span={24}>
                                        <Table
                                            columns={columnsSell}
                                            dataSource={goldSellOrder}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[8, 8]}>
                        <Col span={24}>
                            <Card title={t1('my_sell_listing')} style={{ borderRadius: "5px"}}>
                                <Row gutter={[16, 32]}>
                                    <Col span={24}>
                                        <Table
                                            style={{ width: '100%' }}
                                            columns={columns}
                                            dataSource={goldSellOwnList}
                                            pagination={false}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24}>
                            <Card title={t1('my_buy_listing')} style={{ borderRadius: "5px"}}>
                                <Row gutter={[16, 32]}>
                                    <Col span={24}>
                                        <Table
                                            style={{ width: '100%' }}
                                            columns={preOrderColumns}
                                            dataSource={preOrderGoldList}
                                            pagination={{
                                                current: currentPreOrderListPage,
                                                pageSize: preOrderListPageSize,
                                                total: preOrderListTotal,
                                                // showTotal: (totalNumber,range) => <div>{t1('total_%s_pre_order',[totalNumber])}</div>,
                                                onChange: (pageNumber, pageSizeNumber) => {
                                                    fetPreOrderGoldList(server.id, pageNumber);
                                                },
                                                size: 'small',
                                            }}
                                        />
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24} className="d-none">
                            <Card title={t1('recent_activity')} style={{ borderRadius: "5px"}}>
                                <Row gutter={[16, 32]}>
                                    <Col span={24}>
                                        <p>User1 buy 1000 mil for 0.35$</p>
                                        <p>User1 buy 200 mil for 0.36$</p>
                                        <p>User2 buy 100 mil for 0.36$</p>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                        <Col span={24} className="d-none">
                            <Card title={null} style={{ borderRadius: "5px"}}>
                                <Row gutter={[16, 32]}>
                                    <Col span={24}>
                                        <PriceChart/>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Modal title={t1('create_gold_item')} open={goldModal} footer={null} onCancel={(e) => setGoldModal(false)}>
                <FormCreator
                    schema={createGoldItemMarketSchema(
                        profile?.account?.gold_accounts[indexServer],
                        1
                    )}
                    ui={createGoldItemMarketUI}
                    layout={createGoldItemMarketLayout}
                    onSubmit={onSubmitCreateGold}
                    initialValues={{amount: 0, price: 0, preViewAmount: 0}}
                    SubmitButton={(form) => {
                        return (
                            <Button onClick={(e) => form.submit()}>
                                {t1("click_to_place_your_buy_order")}
                            </Button>
                        );
                    }}
                />
            </Modal>
            <Modal
                title={t1('cancel_listing')}
                open={modalDeleteGoldOpen.status}
                footer={null}
                onCancel={(e) => setModalDeleteGoldOpen({status: false, selectedItemId: -1})}
            >
                <Row>
                    <Col span={24} className="text-center">
                        <p>{t1('do_you_want_to_cancel_listing_of_this_gold_item')}</p>
                        <Button
                            className="me-2"
                            onClick={(e) => {
                                setModalDeleteGoldOpen({status: false, selectedItemId: -1});
                            }}
                        >
                            {t1('click_to_close_modal')}
                        </Button>
                        <Button type="primary" danger onClick={(e) => {
                            setParamsCancelGold('');
                            setParamsCancelGold({
                                item_id: modalDeleteGoldOpen.selectedItemId
                            })
                        }}>{t1('click_to_cancel_listing')}</Button>
                    </Col>
                </Row>
            </Modal>
            <Modal title={t1('buy_gold_item')} open={preOrderGoldModal} footer={null} onCancel={(e) => setPreOrderGoldModal(false)}>
                <FormCreator
                    schema={preOrderGoldItemMarketSchema(
                        profile?.account?.gold_accounts[indexServer],
                        1
                    )}
                    ui={preOrderGoldItemMarketUI}
                    layout={preOrderGoldItemMarketLayout}
                    onSubmit={onSubmitBuyGold}
                    initialValues={{amount: 0, price: 0, preViewAmount: 0}}
                    SubmitButton={(form) => {
                        return <Button onClick={(e) => form.submit()}>{t1('click_to_create_gold_item_for_selling')}</Button>
                    }}
                    debugMode={false}
                />
            </Modal>
            <Modal
                title={t1('cancel_listing')}
                open={modalDeletePreOrderGoldOpen.status}
                footer={null}
                onCancel={(e) => setModalDeletePreOrderGoldOpen({status: false, selectedItemId: -1})}
            >
                <Row>
                    <Col span={24} className="text-center">
                        <p>{t1('do_you_want_to_cancel_listing_of_pre_order_gold_item')}</p>
                        <Button
                            className="me-2"
                            onClick={(e) => {
                                setModalDeletePreOrderGoldOpen({status: false, selectedItemId: -1});
                            }}
                        >
                            {t1('click_to_close_modal')}
                        </Button>
                        <Button
                            type="primary"
                            danger
                            onClick={(e) => {
                                setEndpointCancelPreOrderGold(endpoints.delete_pre_order + '/' + modalDeletePreOrderGoldOpen.selectedItemId);
                                setParamsCancelPreOrderGold({});
                            }}
                        >
                            {t1("remove")}
                        </Button>
                    </Col>
                </Row>
            </Modal>
		</PageLayout>
	);
}

const mapStateToProps = (state,props) => {
	return {
		profile: state?.profile
	}
}

export default connect(mapStateToProps)(MarketLayout);
