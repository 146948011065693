import OfferTradeLayout from "./index";
import { MENU_LINK_OFFER_TRADE } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_OFFER_TRADE,
        exact: true,
        component: <OfferTradeLayout />,
    }
];

export default routeSchema;
 