import React from 'react';
import { t1 } from 'translate';

const ItemStatus = ({itemData, style}) => {
    switch (itemData?.status){
        case 'lock':
            return <div className="status-block" style={style}><span style={{color: '#d3f261'}}>{t1(`status_${itemData?.status}`)}</span></div>
        case 'active':
            return <div className="status-block" style={style}><span style={{color: '#389e0d'}}>{t1(`status_${itemData?.status}`)}</span></div>
        case 'inactive':
            return <div className="status-block" style={style}><span style={{color: '#ff7875'}}>{t1(`status_${itemData?.status}`)}</span></div>
        case 'selling':
            return <div className="status-block" style={{...style, right: '40px'}}><span style={{color: '#85a5ff'}}>{t1(`status_${itemData?.status}`)}</span></div>
        default:
            return <></>
    }
}

export default ItemStatus;