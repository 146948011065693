import React, { useState } from 'react';
import Input from 'antd/lib/input';
import Typography from 'antd/lib/typography';
import Dropdown from 'antd/lib/dropdown';

const { Text } = Typography;

const DropdownFieldAntd = ({ input, meta, errorText = {}, items, dropdownRender, renderField, placement, customOpenButton, ...rest }) => {
    const [isOpen, setIsOpen] = useState(false);

    const closeDropdown = () => setIsOpen(false);

    return (
        <div>
            <Dropdown
                placement={placement}
                menu={{
                    items,
                    onClick: () => setIsOpen(false)
                }} 
                trigger={['contextMenu']}
                dropdownRender={(menu) => dropdownRender(menu, input, closeDropdown)}
                open={isOpen}
                onOpenChange={e => setIsOpen(e)}
            >
                <div>
                    {renderField(input?.value, rest?.schemaIn)}
                    {typeof(customOpenButton) === 'function' && (
                        <div style={{ cursor: 'pointer', marginTop: '20px' }} onClick={() => setIsOpen(true)}>
                            {customOpenButton()}
                        </div>
                    )}
                </div>
            </Dropdown>
            {meta.error && meta.touched ? (
                <Text type="danger" style={{ float: 'left' }}>
                {errorText[meta.error] ? errorText[meta.error] : meta.error}
                </Text>
            ) : (
                ''
            )}
        </div>
    );
};

export default DropdownFieldAntd;

