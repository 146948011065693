import { LOGIN_ACTION, LOGOUT_ACTION } from "redux/action-types";
import Cookies from 'js-cookie';
import { setAuthToken, deleteAuthToken } from 'commons/network/Request';

let initialState = {};

if (Cookies.get('access_token')){
    initialState = {token: Cookies.get('access_token')};
    setAuthToken(initialState);
}

const AuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_ACTION: {
            let authToken = action.payload;
            setAuthToken(action.payload);
            Cookies.set('access_token', authToken, { expires: 1});
            return {
                ...state || {},
                token: authToken
            };
        }
        case LOGOUT_ACTION: {
            Cookies.remove('access_token');
            deleteAuthToken();
            return {};
        }
        default:
            return state;
    }
}

export default AuthReducer;
