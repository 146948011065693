import React, { useState } from "react";
import Modal from "antd/lib/modal";
import { SERVER_LIST } from "constants/game";
import { t1 } from "translate";
import {
  getImageLinkFromItemModel,
  getItemType,
} from "./utils";
import styles from "./styles.module.scss";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Typography from "antd/lib/typography";
import { ITEM_RARITY, ITEM_RARE_SPECIAL } from "constants/item";
import ItemStatus from "./ItemStatus";
import Popover from 'antd/lib/popover';
import DetailContent from "./DetailContent";
import Button from 'antd/lib/button';
import { copyText } from 'helper/helper';
import notification from "antd/lib/notification";
import { CopyOutlined } from '@ant-design/icons';

const { Title, Paragraph, Text } = Typography;

const ItemDetail = ({isSmallPic = false, data, action = () => {}, isFocus, onClick=false, disabled = false}) => {
  const [showDetail, setShowDetail] = useState(false);

  const getServer = (serverId) =>
    SERVER_LIST.find(
      (server) => server.id === Number(serverId)
    )?.name || "";

  let itemType = getItemType(
    data?.item_model_info,
    data?.item_info
  );
  
  let rareType = "";
  if (itemType?.type == ITEM_RARITY.RARE){
    if (data?.item_model_info?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOS}_RARE`)){
      rareType = 'SOS';
    }else{
      if (data?.item_model_info?.code_name?.includes(`${ITEM_RARE_SPECIAL.SOM}_RARE`)){
        rareType = "SOM";
      }else{
        rareType = "SUN";
      }
    }
  }

  let isLock = data?.status == "lock";

  let fullItemName = data?.item_info?.plus && parseInt(data?.item_info?.plus) > 0 ? `${data?.item_model_info?.name?.vi} (+${data?.item_info?.plus})` : data?.item_model_info?.name?.vi;
  if (fullItemName?.length > 1){
    
  }else{
    fullItemName = t1('no_name_item');
  }

  if (itemType?.type == ITEM_RARITY.RARE){
    fullItemName = `[${rareType}] ${fullItemName}`;
  }

  let quantity = 1;

  if (data?.item_info?.count){
    quantity = data?.item_info?.count;
  }

  return (
    <>
      <div className={`hvr-box-shadow-outset ${styles.itemContainer} ${
          isFocus ? "item-selected" : ""
        } ${isLock ? "disabled-item" : "enabled-item"}`}
        style={{ position: 'relative' }}
      >
        {disabled && (
          <div className={styles.itemDisable} />
        )}
        <Row
          align="middle"
          gutter={8}
          onClick={(e) => {
            if (disabled) return;
            onClick && typeof(onClick) === 'function' ? onClick(data) : setShowDetail(true);
          }}
        >
          <ItemStatus itemData={data} style={{zIndex: 100}}/>
          <Col span="24">
            <Typography>
              <Title
                ellipsis={{ rows: 2}}
                level={5}
                className={`ps-2 m-0 item-name-${itemType?.type}`}
                style={{height: "50px", lineHeight: "25px"}}
              >
                {fullItemName}
              </Title>
            </Typography>
          </Col>
          <Col span={isSmallPic ? 4 : 8} className="text-center mt-1 mb-1">
            <img
              src={getImageLinkFromItemModel(
                data?.item_model_info
              )}
              alt={data?.item_model_info?.name?.vi}
              style={{ width: "120%" }}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                if (
                  currentTarget.src ==
                  getImageLinkFromItemModel(
                    data?.item_model_info
                  )
                ) {
                  currentTarget.src =
                    getImageLinkFromItemModel(
                      data?.item_model_info,
                      true
                    );
                }
              }}
            />
            {itemType?.type == ITEM_RARITY.RARE ? (
              <img
                className="rare-star ps-1"
                style={{ width: "90%" }}
                src="https://sro.money/images/rare_star.gif"
                alt={data?.item_model_info?.name?.vi}
              />
            ) : (
              <></>
            )}
          </Col>
          <Col span={isSmallPic ? 20 : 18}>
            <Typography>
              {itemType?.type == ITEM_RARITY.RARE ? <><Text className="item-name-rare">{rareType}</Text><br/></> : <></>}
              <Text className="text-white">
                {t1("server_:_%s", [
                  getServer(data?.server_id),
                ])}
              </Text>
              <br/>
              <Text className="text-white">
                {t1("quantity_:_%s", [
                  quantity,
                ])}
              </Text>
              <br/>
              <Text className="text-white">
                {t1("bot_id_:_%s", [
                  data?.bot_id,
                ])}
              </Text>
            </Typography>
          </Col>
        </Row>
        {data?.active_offer?.id ? (
          <div style={{
            position: 'absolute',
            bottom: '0px',
            left: '5px',
            color: 'white',
            backgroundColor: 'rgba(0,0,0,0.7)',
            padding: '1px 3px',
            borderRadius: '4px',
            border: '1px solid #fff'
          }}>
            <span className="me-1">
              {data?.active_offer?.concurrency == 0
                ? <img src="/cashicon.png" alt="sro money point icon" style={{width:"18px"}}/>
                : <img src="/goldicon.png" alt="sro money gold icon" style={{width:"15px"}}/>
              }
            </span>
            <span style={{fontSize: '12px'}} >{parseInt(data?.active_offer?.price)}</span>
          </div>
          ) : <></>
        }
        {data?.latest_open_price_id && (
          <Button
            className="ms-2"
            icon={<CopyOutlined />}
            style={{ position: 'absolute', bottom: '0', right: '0' }}
            onClick={() => {
              let itemName = data?.item_model_info?.name?.vi
                ? data.item_model_info.name.vi
                : data?.item_model_info?.name?.en
                  ? data.item_model_info.name.en
                  : '';
              itemName = itemName?.replaceAll(' ', '-');
              itemName = itemName?.toLowerCase();
              notification.success({
                message: '',
                description: t1('copy_ok')
              })
              copyText(`${window.SRO_MONEY}/offer/${data?.latest_open_price_id}-${itemName}`);
            }}
          />
        )}
      </div>
      <Modal
        centered
        title={null}
        open={showDetail}
        onCancel={() => setShowDetail(false)}
        footer={null}
      >
        <DetailContent
          itemType={itemType}
          itemModel={data?.item_model_info}
          itemInfo={data?.item_info}
          rareType={rareType}
          action={action(data, setShowDetail)}
        />
      </Modal>
    </>
  );
};

export default ItemDetail;
