import loginEndpoint from "./loginData";
import getProfileEndpoint from "./userProfileData";
import { t1 } from 'translate';

const MAPPING_FAKE_DATA_LIST = [
    loginEndpoint,
    getProfileEndpoint
];

const mappingFakeData = (endpoints, params) => {
    let output = {
        success: false,
        message: t1('default_message')
    }

    output = MAPPING_FAKE_DATA_LIST.find(fakeData => fakeData.url == endpoints)?.outputData(params) ||  {
        success: false,
        message: t1('default_message')
    };

    return output;
}

export default mappingFakeData;