import React from 'react';
import dayjs from 'dayjs';
import { TimePicker } from 'antd';
import Typography from 'antd/lib/typography';
import styles from './styles.module.scss';

const { Text } = Typography;
//rest here will need options value
//option should in this format: {label: A, value: B}
const TimePickerFieldAntd = ({ input, meta, timeFormat, errorText, ...rest }) => {
  return (
    <>
      <TimePicker
        {...input}
        {...rest}
        format={timeFormat}
        value={input.value ? (typeof input.value === 'object' ? input.value : dayjs(input.value)) : ''}
        onChange={(valueChange) => input.onChange(valueChange)}
        onSelect={(value) => input.onChange(value)}
      />
      {meta.error && meta.touched ? (
        <Text type="danger" className={styles.messageError}>
          {errorText[meta.error] ? errorText[meta.error] : meta.error}
        </Text>
      ) : null}
    </>
  );
};

export default TimePickerFieldAntd;
