export const ohlcdata = [
    [1059868800000,0.8866,0.8876,0.8857,0.8864],
    [1059955200000,0.8864,0.8883,0.879,0.8807],
    [1060041600000,0.8808,0.8846,0.8773,0.878],
    [1060128000000,0.878,0.8832,0.8751,0.8821],
    [1060214400000,0.8819,0.8837,0.876,0.88],
    [1060300800000,0.88,0.8862,0.8788,0.8846],
    [1060473600000,0.8846,0.8851,0.8846,0.8851],
    [1060560000000,0.8851,0.888,0.8787,0.8806],
    [1060646400000,0.8806,0.8876,0.8793,0.8871],
    [1060732800000,0.8871,0.8916,0.8825,0.8843],
    [1060819200000,0.8841,0.891,0.8819,0.888],
    [1060905600000,0.8877,0.8898,0.8858,0.888],
    [1061078400000,0.888,0.8893,0.8832,0.8889],
    [1061164800000,0.8903,0.8991,0.8874,0.8978],
    [1061251200000,0.8978,0.9048,0.897,0.8987],
    [1061337600000,0.8986,0.9027,0.8975,0.9003],
    [1061424000000,0.9003,0.9189,0.9001,0.9149],
    [1061510400000,0.9148,0.9231,0.9135,0.9187],
    [1061769600000,0.9187,0.9262,0.9158,0.9236],
    [1061856000000,0.9236,0.927,0.9166,0.9195],
    [1061942400000,0.9195,0.9234,0.9147,0.9213],
    [1062028800000,0.9213,0.9253,0.9163,0.9193],
    [1062115200000,0.9189,0.9201,0.9093,0.9107],
    [1062374400000,0.9107,0.9132,0.9078,0.9124],
    [1062460800000,0.9125,0.9262,0.9122,0.9254],
    [1062547200000,0.9254,0.9295,0.9197,0.9244],
    [1062633600000,0.9244,0.9253,0.9132,0.9146],
    [1062720000000,0.9146,0.9163,0.8998,0.9002],
    [1062979200000,0.9008,0.9055,0.8986,0.9037],
    [1063065600000,0.9036,0.905,0.8897,0.8913],
    [1063152000000,0.8911,0.8961,0.8898,0.8945],
    [1063238400000,0.8942,0.8968,0.8872,0.8942],
    [1063324800000,0.8942,0.8983,0.8831,0.8858],
    [1063584000000,0.8858,0.8898,0.8842,0.8868],
    [1063670400000,0.8868,0.8967,0.8835,0.8957],
    [1063756800000,0.896,0.898,0.885,0.8868],
    [1063843200000,0.8869,0.8909,0.8816,0.8891],
    [1063929600000,0.8891,0.8899,0.8779,0.8788],
    [1064188800000,0.8788,0.8788,0.8698,0.8717],
    [1064275200000,0.8717,0.8752,0.8675,0.8743],
    [1064361600000,0.8743,0.8753,0.8692,0.8709],
    [1064448000000,0.8708,0.873,0.8669,0.872],
    [1064534400000,0.872,0.8743,0.8696,0.8714],
    [1064793600000,0.8729,0.8778,0.8592,0.8638],
    [1064880000000,0.8639,0.8643,0.8522,0.8567],
    [1064966400000,0.8568,0.8582,0.8521,0.8536],
    [1065052800000,0.8536,0.8577,0.8498,0.8564],
    [1065139200000,0.8562,0.8659,0.8532,0.8639],
    [1065398400000,0.8639,0.8673,0.853,0.8538],
    [1065484800000,0.8538,0.8551,0.8466,0.8489],
    [1065571200000,0.849,0.8498,0.8444,0.8459],
    [1065657600000,0.8458,0.8559,0.843,0.8521],
    [1065744000000,0.852,0.8548,0.8439,0.8477],
    [1066003200000,0.8467,0.8583,0.8464,0.8554],
    [1066089600000,0.8554,0.8636,0.85,0.853],
    [1066176000000,0.8529,0.863,0.8514,0.8607],
    [1066262400000,0.8605,0.8645,0.855,0.8636],
    [1066348800000,0.8636,0.8663,0.8559,0.8565],
    [1066608000000,0.8565,0.8621,0.8527,0.86],
    [1066694400000,0.8599,0.861,0.8546,0.8583],
    [1066780800000,0.8581,0.8586,0.8454,0.8462],
    [1066867200000,0.8462,0.8508,0.8442,0.846],
    [1066953600000,0.846,0.8505,0.8435,0.8492],
    [1067212800000,0.85,0.8535,0.8486,0.8521],
    [1067299200000,0.8522,0.8581,0.8517,0.8573],
    [1067385600000,0.8573,0.8579,0.8533,0.8573],
    [1067472000000,0.8573,0.8614,0.8506,0.8603],
    [1067558400000,0.8602,0.865,0.8579,0.8632],
    [1067817600000,0.863,0.8752,0.8606,0.8729],
    [1067904000000,0.8728,0.8754,0.8686,0.8717],
    [1067990400000,0.8718,0.8763,0.8697,0.8753],
    [1068076800000,0.8754,0.8783,0.8722,0.8766],
    [1068163200000,0.8765,0.8792,0.8663,0.8675],
    [1068422400000,0.8686,0.8739,0.8669,0.8734],
    [1068508800000,0.8734,0.874,0.8672,0.869],
    [1068595200000,0.8691,0.8693,0.8576,0.8587],
    [1068681600000,0.8584,0.8598,0.8509,0.8519],
    [1068768000000,0.8519,0.8526,0.8472,0.8493],
    [1069027200000,0.8493,0.853,0.844,0.8512],
    [1069113600000,0.8514,0.8524,0.8351,0.8363],
    [1069200000000,0.8361,0.8425,0.8348,0.8405],
    [1069286400000,0.8407,0.8417,0.8357,0.8403],
    [1069372800000,0.8403,0.8436,0.8376,0.8393],
    [1069632000000,0.8393,0.8507,0.839,0.8489],
    [1069718400000,0.8488,0.8511,0.846,0.8492],
    [1069804800000,0.8489,0.8493,0.837,0.8386],
    [1069891200000,0.8385,0.8416,0.8377,0.8391],
    [1069977600000,0.8392,0.8406,0.8322,0.8338],
    [1070236800000,0.8349,0.8379,0.8305,0.8363],
    [1070323200000,0.8362,0.8377,0.8255,0.8273],
    [1070409600000,0.8273,0.8289,0.8245,0.8256],
    [1070496000000,0.8256,0.8315,0.8226,0.828],
    [1070582400000,0.8282,0.8293,0.8212,0.822],
    [1070841600000,0.822,0.8233,0.8171,0.8186],
    [1070928000000,0.8186,0.8208,0.8146,0.8167],
    [1071014400000,0.8167,0.823,0.8153,0.8207],
    [1071100800000,0.8205,0.8257,0.8174,0.8183],
    [1071187200000,0.8183,0.8193,0.8126,0.8146],
    [1071446400000,0.8156,0.8246,0.8115,0.8124],
    [1071532800000,0.8123,0.8131,0.8091,0.8118],
    [1071619200000,0.8119,0.8135,0.8051,0.8067],
    [1071705600000,0.8066,0.8093,0.8039,0.8055],
    [1071792000000,0.8054,0.8103,0.8043,0.8082],
    [1072051200000,0.8082,0.81,0.8035,0.807],
    [1072137600000,0.8069,0.8078,0.8048,0.8067],
    [1072224000000,0.8068,0.807,0.8019,0.8032],
    [1072310400000,0.8033,0.8042,0.8025,0.8036],
    [1072396800000,0.8036,0.8052,0.802,0.8046],
    [1072656000000,0.8046,0.805,0.7995,0.8012],
    [1072742400000,0.8012,0.8017,0.7961,0.7971],
    [1072828800000,0.797,0.7993,0.7907,0.794],
    [1072915200000,0.794,0.802,0.7934,0.7955],
    [1073001600000,0.7955,0.7988,0.7919,0.7948],
    [1073260800000,0.7948,0.795,0.7878,0.7885],
    [1073347200000,0.7885,0.7896,0.7806,0.7864],
    [1073433600000,0.7865,0.7926,0.7848,0.7914],
    [1073520000000,0.7914,0.7964,0.7821,0.7831],
    [1073606400000,0.7831,0.7873,0.7771,0.7805],
    [1073865600000,0.7805,0.7855,0.7753,0.7851],
    [1073952000000,0.7849,0.7868,0.7815,0.7823],
    [1074038400000,0.7822,0.792,0.7821,0.7903],
    [1074124800000,0.7902,0.7962,0.7891,0.794],
    [1074211200000,0.794,0.8098,0.7931,0.8067],
    [1074470400000,0.8067,0.8109,0.8049,0.8097],
    [1074556800000,0.8098,0.8104,0.7937,0.7948],
    [1074643200000,0.7948,0.7971,0.7891,0.7903],
    [1074729600000,0.7902,0.7916,0.7843,0.7862],
    [1074816000000,0.7862,0.7961,0.7828,0.795],
    [1075075200000,0.795,0.803,0.7928,0.8011],
    [1075161600000,0.8012,0.8041,0.7897,0.7917],
    [1075248000000,0.7915,0.8057,0.791,0.8042],
    [1075334400000,0.804,0.809,0.7982,0.806],
    [1075420800000,0.806,0.8098,0.8005,0.8015],
    [1075680000000,0.8015,0.8072,0.8008,0.8046],
    [1075766400000,0.8047,0.8057,0.7937,0.7973],
    [1075852800000,0.7973,0.8008,0.796,0.7977],
    [1075939200000,0.7979,0.799,0.7914,0.796],
    [1076025600000,0.7958,0.7994,0.7859,0.7874],
    [1076284800000,0.792,0.7942,0.7836,0.7881],
    [1076371200000,0.7879,0.7896,0.782,0.7886],
    [1076457600000,0.7886,0.7908,0.7785,0.7799],
    [1076544000000,0.7795,0.7822,0.7785,0.7806],
    [1076630400000,0.7806,0.7866,0.7756,0.7854],
    [1076889600000,0.7844,0.7866,0.7819,0.7838],
    [1076976000000,0.7838,0.7841,0.7766,0.7778],
    [1077062400000,0.7777,0.7901,0.7735,0.7859],
    [1077148800000,0.7859,0.7908,0.7833,0.784],
    [1077235200000,0.7841,0.8005,0.7836,0.7979],
    [1077494400000,0.7979,0.8033,0.794,0.7959],
    [1077580800000,0.7957,0.7987,0.7866,0.789],
    [1077667200000,0.789,0.8019,0.787,0.8003],
    [1077753600000,0.8002,0.8078,0.799,0.8038],
    [1077840000000,0.8037,0.8084,0.7995,0.8006],
    [1078099200000,0.8009,0.805,0.7974,0.8036],
    [1078185600000,0.8035,0.8201,0.8032,0.8197],
    [1078272000000,0.8197,0.8296,0.8185,0.8205],
    [1078358400000,0.8205,0.8244,0.8169,0.8197],
    [1078444800000,0.8198,0.8216,0.8044,0.8087],
    [1078704000000,0.8087,0.8107,0.8048,0.805],
    [1078790400000,0.8051,0.8149,0.8026,0.8121],
    [1078876800000,0.8119,0.82,0.8104,0.8179],
    [1078963200000,0.8179,0.8224,0.8073,0.8101],
    [1079049600000,0.81,0.8216,0.8095,0.8186],
    [1079308800000,0.8174,0.821,0.8119,0.8153],
    [1079395200000,0.8154,0.8177,0.8083,0.8161],
    [1079481600000,0.8162,0.8215,0.8138,0.8181],
    [1079568000000,0.8181,0.8185,0.8041,0.8074],
    [1079654400000,0.8076,0.8161,0.8059,0.8141],
    [1079913600000,0.8141,0.8184,0.8064,0.8102],
    [1080000000000,0.8103,0.8156,0.8084,0.8104],
    [1080086400000,0.8104,0.8264,0.8085,0.824],
    [1080172800000,0.8241,0.8269,0.8197,0.8242],
    [1080259200000,0.8242,0.8286,0.8188,0.8245],
    [1080518400000,0.8253,0.8302,0.8218,0.8228],
    [1080604800000,0.8227,0.8228,0.8178,0.8212],
    [1080691200000,0.8212,0.8216,0.8115,0.8129],
    [1080777600000,0.8129,0.8163,0.8072,0.8095],
    [1080864000000,0.8095,0.8267,0.8085,0.8241],
    [1081123200000,0.8257,0.8348,0.8244,0.8326],
    [1081209600000,0.8326,0.8349,0.8235,0.8252],
    [1081296000000,0.8252,0.8302,0.8198,0.822],
    [1081382400000,0.8221,0.8294,0.8183,0.8282],
    [1081468800000,0.8282,0.8292,0.8256,0.8273],
    [1081728000000,0.827,0.8299,0.8265,0.8284],
    [1081814400000,0.8284,0.8403,0.8276,0.8384],
    [1081900800000,0.8382,0.8427,0.8351,0.8375],
    [1081987200000,0.8375,0.8411,0.8338,0.8351],
    [1082073600000,0.8353,0.8389,0.8301,0.8336],
    [1082332800000,0.8336,0.8338,0.8279,0.833],
    [1082419200000,0.8329,0.8462,0.8326,0.8455],
    [1082505600000,0.8455,0.8492,0.8393,0.8477],
    [1082592000000,0.8479,0.8483,0.8374,0.8387],
    [1082678400000,0.839,0.8479,0.8384,0.8446],
    [1082937600000,0.8446,0.8507,0.8414,0.8432],
    [1083024000000,0.8432,0.8453,0.837,0.8388],
    [1083110400000,0.8387,0.846,0.8366,0.8456],
    [1083196800000,0.8457,0.8472,0.8344,0.8365],
    [1083283200000,0.8366,0.8391,0.8325,0.8344],
    [1083542400000,0.8347,0.839,0.8332,0.8379],
    [1083628800000,0.8382,0.8384,0.8251,0.8261],
    [1083715200000,0.8261,0.8265,0.821,0.8225],
    [1083801600000,0.8226,0.829,0.8214,0.8282],
    [1083888000000,0.8284,0.8422,0.8236,0.841],
    [1084147200000,0.841,0.8468,0.8407,0.8427],
    [1084233600000,0.8429,0.8485,0.841,0.842],
    [1084320000000,0.8419,0.8451,0.8373,0.8396],
    [1084406400000,0.8397,0.8496,0.8382,0.8474],
    [1084492800000,0.8474,0.849,0.8401,0.8412],
    [1084752000000,0.8412,0.8421,0.8292,0.831],
    [1084838400000,0.8309,0.8384,0.8307,0.8374],
    [1084924800000,0.8373,0.8378,0.8302,0.8334],
    [1085011200000,0.8334,0.8408,0.8324,0.8366],
    [1085097600000,0.8364,0.8367,0.8284,0.8349],
    [1085356800000,0.8347,0.8382,0.8316,0.8339],
    [1085443200000,0.8337,0.8342,0.8245,0.8258],
    [1085529600000,0.8258,0.828,0.8234,0.8266],
    [1085616000000,0.8263,0.8275,0.8137,0.8148],
    [1085702400000,0.8149,0.8197,0.8131,0.8182],
    [1085961600000,0.8175,0.8218,0.816,0.821],
    [1086048000000,0.8209,0.8228,0.8151,0.8168],
    [1086134400000,0.8168,0.8195,0.8126,0.8188],
    [1086220800000,0.8187,0.822,0.8161,0.8189],
    [1086307200000,0.8191,0.824,0.8109,0.8141],
    [1086566400000,0.8134,0.8149,0.8104,0.812],
    [1086652800000,0.8121,0.8168,0.8095,0.8151],
    [1086739200000,0.8151,0.8319,0.8148,0.831],
    [1086825600000,0.831,0.8316,0.8252,0.8273],
    [1086912000000,0.8272,0.8359,0.8271,0.8328],
    [1087171200000,0.8333,0.8368,0.8272,0.8288],
    [1087257600000,0.8289,0.8316,0.8216,0.8232],
    [1087344000000,0.8233,0.8349,0.8217,0.8331],
    [1087430400000,0.8333,0.8341,0.8279,0.831],
    [1087516800000,0.831,0.8354,0.8232,0.8238],
    [1087776000000,0.8241,0.8282,0.8232,0.8254],
    [1087862400000,0.8252,0.829,0.8241,0.826],
    [1087948800000,0.826,0.8291,0.8201,0.8281],
    [1088035200000,0.8278,0.8308,0.8203,0.8218],
    [1088121600000,0.8217,0.8261,0.8207,0.8211],
    [1088380800000,0.8211,0.825,0.8177,0.8203],
    [1088467200000,0.8205,0.8288,0.8205,0.8273],
    [1088553600000,0.8273,0.829,0.8189,0.8197],
    [1088640000000,0.8197,0.8243,0.8191,0.8222],
    [1088726400000,0.8222,0.8237,0.8112,0.8118],
    [1088985600000,0.8115,0.8153,0.8106,0.8127],
    [1089072000000,0.8128,0.8155,0.811,0.8133],
    [1089158400000,0.8133,0.8149,0.8071,0.8081],
    [1089244800000,0.808,0.8105,0.8059,0.807],
    [1089331200000,0.8071,0.8086,0.8049,0.8054],
    [1089590400000,0.8056,0.8078,0.8041,0.8068],
    [1089676800000,0.8068,0.8132,0.8066,0.8113],
    [1089763200000,0.8114,0.8119,0.805,0.8073],
    [1089849600000,0.8073,0.8114,0.8072,0.8096],
    [1089936000000,0.8096,0.8113,0.8026,0.8031],
    [1090195200000,0.8034,0.8067,0.8024,0.8046],
    [1090281600000,0.8047,0.8131,0.8029,0.8118],
    [1090368000000,0.8117,0.8192,0.8097,0.8157],
    [1090454400000,0.8159,0.8179,0.8134,0.8159],
    [1090540800000,0.8159,0.8276,0.8151,0.827],
    [1090800000000,0.8267,0.8275,0.8216,0.8241],
    [1090886400000,0.8242,0.831,0.8203,0.8288],
    [1090972800000,0.829,0.8335,0.8277,0.8299],
    [1091059200000,0.8298,0.8341,0.8267,0.8307],
    [1091145600000,0.8309,0.8333,0.825,0.8324],
    [1091318400000,0.8324,0.8325,0.8324,0.8325],
    [1091404800000,0.8325,0.8325,0.8269,0.8318],
    [1091491200000,0.8318,0.8339,0.828,0.8297],
    [1091577600000,0.8295,0.8355,0.8286,0.8294],
    [1091664000000,0.8295,0.832,0.8282,0.8294],
    [1091750400000,0.8295,0.8304,0.8135,0.8143],
    [1092009600000,0.814,0.8168,0.8136,0.8153],
    [1092096000000,0.8152,0.8183,0.8117,0.8173],
    [1092182400000,0.8174,0.8201,0.8163,0.8179],
    [1092268800000,0.8181,0.8184,0.8132,0.8173],
    [1092355200000,0.8173,0.8214,0.808,0.8081],
    [1092614400000,0.8082,0.812,0.8074,0.8093],
    [1092700800000,0.8093,0.8124,0.8072,0.8097],
    [1092787200000,0.8098,0.8141,0.8087,0.8104],
    [1092873600000,0.8108,0.811,0.8078,0.8089],
    [1092960000000,0.8088,0.8145,0.8076,0.8118],
    [1093219200000,0.8118,0.8245,0.8112,0.8231],
    [1093305600000,0.823,0.8293,0.8219,0.8285],
    [1093392000000,0.8284,0.8298,0.8252,0.8278],
    [1093478400000,0.828,0.8304,0.8249,0.8259],
    [1093564800000,0.826,0.8334,0.8239,0.8327],
    [1093824000000,0.8327,0.8344,0.8286,0.8301],
    [1093910400000,0.8302,0.8304,0.82,0.8212],
    [1093996800000,0.8213,0.8235,0.8183,0.8204],
    [1094083200000,0.8204,0.8238,0.8197,0.822],
    [1094169600000,0.8219,0.8306,0.82,0.8295],
    [1094428800000,0.8295,0.8303,0.8278,0.8289],
    [1094515200000,0.829,0.8298,0.8256,0.8274],
    [1094601600000,0.8275,0.8317,0.8201,0.821],
    [1094688000000,0.821,0.8226,0.8164,0.8175],
    [1094774400000,0.8175,0.8201,0.8122,0.8151],
    [1095033600000,0.8154,0.8183,0.8138,0.8153],
    [1095120000000,0.8153,0.8181,0.8133,0.8164],
    [1095206400000,0.8165,0.8242,0.8156,0.8232],
    [1095292800000,0.8232,0.8252,0.8198,0.8207],
    [1095379200000,0.8207,0.8227,0.8181,0.8207],
    [1095638400000,0.821,0.8249,0.8206,0.8215],
    [1095724800000,0.8216,0.822,0.8099,0.8114],
    [1095811200000,0.8113,0.818,0.8109,0.8152],
    [1095897600000,0.8151,0.8163,0.8108,0.8145],
    [1095984000000,0.8146,0.8173,0.8089,0.8147],
    [1096243200000,0.8145,0.8167,0.812,0.8129],
    [1096329600000,0.8129,0.8146,0.8098,0.8121],
    [1096416000000,0.8121,0.8139,0.8103,0.8112],
    [1096502400000,0.8112,0.8122,0.8036,0.8048],
    [1096588800000,0.8048,0.8076,0.8042,0.8066],
    [1096848000000,0.8065,0.816,0.8064,0.8142],
    [1096934400000,0.8141,0.8157,0.8111,0.8119],
    [1097020800000,0.812,0.8167,0.8115,0.814],
    [1097107200000,0.814,0.8151,0.8121,0.8133],
    [1097193600000,0.8133,0.8142,0.8043,0.8059],
    [1097452800000,0.8056,0.8086,0.805,0.8081],
    [1097539200000,0.8082,0.8139,0.8081,0.812],
    [1097625600000,0.812,0.8181,0.8093,0.8104],
    [1097712000000,0.8103,0.8118,0.8052,0.8074],
    [1097798400000,0.8076,0.808,0.7997,0.8013],
    [1098057600000,0.8013,0.8026,0.7978,0.8012],
    [1098144000000,0.8014,0.8033,0.7983,0.7992],
    [1098230400000,0.7995,0.801,0.7919,0.7949],
    [1098316800000,0.795,0.7955,0.7905,0.7929],
    [1098403200000,0.7929,0.7938,0.788,0.7881],
    [1098662400000,0.7879,0.7879,0.7794,0.782],
    [1098748800000,0.782,0.7859,0.7787,0.7845],
    [1098835200000,0.7845,0.788,0.7805,0.788],
    [1098921600000,0.7878,0.7917,0.7833,0.7845],
    [1099008000000,0.7846,0.7871,0.7813,0.782],
    [1099267200000,0.782,0.7864,0.7793,0.7847],
    [1099353600000,0.7848,0.7897,0.7844,0.7865],
    [1099440000000,0.7865,0.7901,0.7794,0.78],
    [1099526400000,0.7801,0.7819,0.7754,0.777],
    [1099612800000,0.777,0.7826,0.7708,0.7715],
    [1099872000000,0.7712,0.775,0.7701,0.775],
    [1099958400000,0.7749,0.7763,0.7729,0.7754],
    [1100044800000,0.7754,0.7784,0.7691,0.7765],
    [1100131200000,0.7763,0.7778,0.7741,0.7748],
    [1100217600000,0.7748,0.7768,0.7699,0.771],
    [1100476800000,0.771,0.7742,0.7693,0.7731],
    [1100563200000,0.773,0.7743,0.7695,0.7718],
    [1100649600000,0.7715,0.7723,0.7664,0.7673],
    [1100736000000,0.7672,0.773,0.7649,0.7726],
    [1100822400000,0.7726,0.773,0.7652,0.768],
    [1101081600000,0.7678,0.7688,0.7662,0.767],
    [1101168000000,0.7671,0.7708,0.7631,0.7637],
    [1101254400000,0.7637,0.7645,0.7582,0.7591],
    [1101340800000,0.759,0.7599,0.7528,0.7545],
    [1101427200000,0.7545,0.7586,0.7502,0.7521],
    [1101686400000,0.7523,0.756,0.7517,0.7541],
    [1101772800000,0.7541,0.7559,0.75,0.7522],
    [1101859200000,0.7523,0.7534,0.7485,0.7493],
    [1101945600000,0.7496,0.7555,0.747,0.7543],
    [1102032000000,0.7543,0.7547,0.7429,0.7435],
    [1102291200000,0.7435,0.7471,0.7432,0.7466],
    [1102377600000,0.7467,0.7469,0.7424,0.7447],
    [1102464000000,0.7447,0.7578,0.7445,0.7493],
    [1102550400000,0.7493,0.7547,0.7484,0.7518],
    [1102636800000,0.752,0.7612,0.7517,0.7563],
    [1102896000000,0.7569,0.7583,0.7503,0.751],
    [1102982400000,0.751,0.7543,0.7499,0.7516],
    [1103068800000,0.7517,0.7542,0.744,0.7463],
    [1103155200000,0.7463,0.7573,0.7447,0.7547],
    [1103241600000,0.7548,0.7561,0.751,0.7515],
    [1103500800000,0.7513,0.7522,0.7458,0.7468],
    [1103587200000,0.7469,0.7491,0.7458,0.7482],
    [1103673600000,0.7483,0.7496,0.746,0.7469],
    [1103760000000,0.7469,0.7475,0.7398,0.7404],
    [1103846400000,0.7405,0.7417,0.7381,0.739],
    [1104105600000,0.739,0.7401,0.7331,0.7342],
    [1104192000000,0.7342,0.736,0.733,0.7356],
    [1104278400000,0.7358,0.7377,0.7328,0.7349],
    [1104364800000,0.7351,0.7368,0.7317,0.7341],
    [1104451200000,0.7343,0.7394,0.732,0.7377],
    [1104710400000,0.7384,0.7472,0.7364,0.7426],
    [1104796800000,0.7427,0.7548,0.741,0.753],
    [1104883200000,0.753,0.7568,0.7517,0.7534],
    [1104969600000,0.7535,0.7603,0.7531,0.7588],
    [1105056000000,0.7587,0.7678,0.7547,0.7661],
    [1105315200000,0.7662,0.7665,0.7619,0.7631],
    [1105401600000,0.763,0.7635,0.7592,0.7625],
    [1105488000000,0.7627,0.7644,0.7523,0.7544],
    [1105574400000,0.7544,0.758,0.7538,0.7571],
    [1105660800000,0.7572,0.7659,0.7571,0.7628],
    [1105920000000,0.763,0.7672,0.7618,0.7665],
    [1106006400000,0.7665,0.7696,0.7648,0.7684],
    [1106092800000,0.7684,0.7714,0.7623,0.7694],
    [1106179200000,0.7693,0.7739,0.7678,0.7715],
    [1106265600000,0.7716,0.7734,0.7653,0.767],
    [1106438400000,0.767,0.767,0.7669,0.7669],
    [1106524800000,0.7666,0.7676,0.7634,0.767],
    [1106611200000,0.767,0.7726,0.7647,0.7712],
    [1106697600000,0.7713,0.7714,0.7629,0.7648],
    [1106784000000,0.7648,0.7688,0.7619,0.7669],
    [1106870400000,0.7669,0.7702,0.7644,0.7672],
    [1107043200000,0.7672,0.7673,0.7672,0.7673],
    [1107129600000,0.7673,0.7708,0.7658,0.7664],
    [1107216000000,0.7665,0.7695,0.7653,0.767],
    [1107302400000,0.7668,0.7688,0.7637,0.7675],
    [1107388800000,0.7676,0.7729,0.767,0.7712],
    [1107475200000,0.7711,0.7775,0.7669,0.7772],
    [1107648000000,0.7772,0.7774,0.7772,0.7774],
    [1107734400000,0.7777,0.7855,0.7773,0.7837],
    [1107820800000,0.7838,0.7856,0.7813,0.7832],
    [1107907200000,0.7833,0.7851,0.7804,0.7807],
    [1107993600000,0.7808,0.7851,0.7748,0.7768],
    [1108080000000,0.7767,0.7786,0.7754,0.7774],
    [1108339200000,0.7775,0.7776,0.7699,0.7711],
    [1108425600000,0.7711,0.7721,0.7662,0.7685],
    [1108512000000,0.7685,0.7717,0.7655,0.768],
    [1108598400000,0.7679,0.7685,0.764,0.7651],
    [1108684800000,0.7651,0.7685,0.7644,0.765],
    [1108944000000,0.7652,0.7674,0.7646,0.7656],
    [1109030400000,0.7656,0.7662,0.7537,0.7543],
    [1109116800000,0.7543,0.7586,0.7534,0.7576],
    [1109203200000,0.7577,0.759,0.7536,0.7588],
    [1109289600000,0.7589,0.7609,0.7548,0.7551],
    [1109548800000,0.7553,0.7572,0.7531,0.7567],
    [1109635200000,0.7567,0.7598,0.7561,0.7594],
    [1109721600000,0.7593,0.7641,0.7588,0.7613],
    [1109808000000,0.7614,0.7637,0.7595,0.7632],
    [1109894400000,0.7633,0.7638,0.7545,0.7556],
    [1110153600000,0.7553,0.759,0.7549,0.7566],
    [1110240000000,0.7566,0.7573,0.7483,0.7493],
    [1110326400000,0.7494,0.7505,0.745,0.7477],
    [1110412800000,0.7479,0.7481,0.7433,0.7442],
    [1110499200000,0.7442,0.7465,0.7418,0.7431],
    [1110758400000,0.7431,0.75,0.742,0.7484],
    [1110844800000,0.7487,0.7522,0.7458,0.7515],
    [1110931200000,0.7514,0.7525,0.744,0.7458],
    [1111017600000,0.7458,0.7496,0.7452,0.7475],
    [1111104000000,0.7476,0.7539,0.7471,0.7505],
    [1111363200000,0.7506,0.7611,0.7506,0.7601],
    [1111449600000,0.7601,0.7661,0.7565,0.7649],
    [1111536000000,0.7649,0.7718,0.7642,0.7698],
    [1111622400000,0.77,0.7736,0.7673,0.7735],
    [1111708800000,0.7735,0.7738,0.771,0.7718],
    [1111968000000,0.7718,0.778,0.7712,0.7762],
    [1112054400000,0.7763,0.7766,0.7718,0.7731],
    [1112140800000,0.7732,0.7748,0.77,0.7738],
    [1112227200000,0.7739,0.7747,0.768,0.7715],
    [1112313600000,0.7714,0.7772,0.7664,0.7745],
    [1112400000000,0.7745,0.7745,0.7742,0.7745],
    [1112572800000,0.7747,0.7802,0.7747,0.7784],
    [1112659200000,0.7785,0.7814,0.7766,0.7784],
    [1112745600000,0.7785,0.7791,0.7746,0.7769],
    [1112832000000,0.7769,0.7794,0.7727,0.7792],
    [1112918400000,0.7792,0.7809,0.7727,0.7732],
    [1113091200000,0.7732,0.7735,0.773,0.7732],
    [1113177600000,0.7734,0.7751,0.769,0.7706],
    [1113264000000,0.7708,0.7776,0.7687,0.7751],
    [1113350400000,0.7751,0.7784,0.7719,0.7748],
    [1113436800000,0.7749,0.7833,0.7746,0.7806],
    [1113523200000,0.7806,0.7827,0.7727,0.7739],
    [1113609600000,0.7739,0.7739,0.7738,0.7739],
    [1113782400000,0.774,0.7768,0.7666,0.7686],
    [1113868800000,0.7686,0.7704,0.7646,0.7657],
    [1113955200000,0.7656,0.7699,0.763,0.7634],
    [1114041600000,0.7634,0.7679,0.7619,0.767],
    [1114128000000,0.767,0.7682,0.7637,0.7658],
    [1114387200000,0.7655,0.772,0.7651,0.7701],
    [1114473600000,0.7701,0.7726,0.7683,0.7707],
    [1114560000000,0.7708,0.7751,0.7699,0.7736],
    [1114646400000,0.7736,0.7764,0.7727,0.7751],
    [1114732800000,0.7751,0.7782,0.7704,0.7769],
    [1114992000000,0.7771,0.7791,0.7767,0.778],
    [1115078400000,0.778,0.7797,0.7745,0.7766],
    [1115164800000,0.7766,0.7769,0.7708,0.7727],
    [1115251200000,0.7727,0.7738,0.7698,0.7724],
    [1115337600000,0.7726,0.7808,0.7714,0.7791],
    [1115596800000,0.7794,0.782,0.7766,0.7786],
    [1115683200000,0.7785,0.7805,0.7757,0.7767],
    [1115769600000,0.7768,0.7828,0.7744,0.7811],
    [1115856000000,0.7811,0.7897,0.7807,0.7886],
    [1115942400000,0.7886,0.7931,0.7881,0.7916],
    [1116115200000,0.7916,0.7916,0.7915,0.7916],
    [1116201600000,0.7918,0.7951,0.7905,0.7921],
    [1116288000000,0.7921,0.7945,0.7896,0.7942],
    [1116374400000,0.7945,0.7947,0.788,0.7893],
    [1116460800000,0.7894,0.7935,0.788,0.7917],
    [1116547200000,0.792,0.7979,0.7902,0.7969],
    [1116806400000,0.7969,0.7981,0.7939,0.7954],
    [1116892800000,0.7955,0.7967,0.792,0.7962],
    [1116979200000,0.7962,0.797,0.7925,0.7934],
    [1117065600000,0.7935,0.8006,0.7928,0.7995],
    [1117152000000,0.7995,0.7997,0.7945,0.7948],
    [1117411200000,0.795,0.8028,0.795,0.8013],
    [1117497600000,0.8013,0.8137,0.8013,0.8131],
    [1117584000000,0.8131,0.8227,0.8104,0.82],
    [1117670400000,0.8199,0.8212,0.8133,0.8149],
    [1117756800000,0.8151,0.8197,0.8103,0.8174],
    [1118016000000,0.8177,0.8189,0.8135,0.8156],
    [1118102400000,0.8156,0.8174,0.8121,0.8142],
    [1118188800000,0.8143,0.8195,0.8097,0.8172],
    [1118275200000,0.8173,0.8215,0.816,0.8177],
    [1118361600000,0.818,0.8262,0.8165,0.8253],
    [1118620800000,0.8256,0.8317,0.8252,0.8264],
    [1118707200000,0.8262,0.8326,0.823,0.8315],
    [1118793600000,0.8314,0.8318,0.8243,0.8258],
    [1118880000000,0.8259,0.8296,0.8219,0.8262],
    [1118966400000,0.8262,0.8275,0.8137,0.8145],
    [1119225600000,0.8151,0.8251,0.8151,0.8245],
    [1119312000000,0.8245,0.8288,0.8192,0.8207],
    [1119398400000,0.821,0.8267,0.8197,0.8245],
    [1119484800000,0.8246,0.8325,0.8233,0.8318],
    [1119571200000,0.8319,0.8348,0.826,0.8273],
    [1119830400000,0.8272,0.8277,0.8207,0.822],
    [1119916800000,0.822,0.8302,0.8215,0.8287],
    [1120003200000,0.8288,0.8326,0.8256,0.8271],
    [1120089600000,0.8271,0.8306,0.8252,0.8274],
    [1120176000000,0.8273,0.8379,0.8262,0.8363],
    [1120435200000,0.8366,0.8418,0.8366,0.8396],
    [1120521600000,0.8399,0.843,0.8377,0.8389],
    [1120608000000,0.8389,0.8413,0.8368,0.8384],
    [1120694400000,0.8385,0.8401,0.8307,0.8381],
    [1120780800000,0.8384,0.8426,0.8344,0.8356],
    [1121040000000,0.8356,0.8367,0.8275,0.8294],
    [1121126400000,0.8297,0.8302,0.8161,0.8188],
    [1121212800000,0.8188,0.8293,0.8182,0.8281],
    [1121299200000,0.8282,0.8298,0.8249,0.8271],
    [1121385600000,0.8269,0.8321,0.8239,0.831],
    [1121644800000,0.8313,0.8323,0.8273,0.8292],
    [1121731200000,0.8293,0.8369,0.8291,0.8315],
    [1121817600000,0.8315,0.8318,0.8206,0.8237],
    [1121904000000,0.8237,0.8267,0.8164,0.8227],
    [1121990400000,0.8227,0.83,0.8205,0.829],
    [1122249600000,0.829,0.8318,0.8273,0.8304],
    [1122336000000,0.8302,0.8349,0.829,0.8323],
    [1122422400000,0.8323,0.8361,0.8276,0.8288],
    [1122508800000,0.8286,0.8311,0.8232,0.8245],
    [1122595200000,0.8247,0.8284,0.8224,0.825],
    [1122854400000,0.825,0.8254,0.8165,0.8203],
    [1122940800000,0.8203,0.821,0.8163,0.8203],
    [1123027200000,0.8205,0.8235,0.8102,0.8112],
    [1123113600000,0.8112,0.8133,0.8064,0.8075],
    [1123200000000,0.8075,0.8125,0.8068,0.8085],
    [1123459200000,0.8085,0.8123,0.8072,0.8091],
    [1123545600000,0.809,0.8114,0.8055,0.8094],
    [1123632000000,0.8095,0.8109,0.8048,0.8078],
    [1123718400000,0.8079,0.8083,0.8014,0.8028],
    [1123804800000,0.803,0.8079,0.8008,0.8039],
    [1124064000000,0.8039,0.8103,0.8022,0.8099],
    [1124150400000,0.81,0.8135,0.8083,0.8091],
    [1124236800000,0.8091,0.8161,0.8085,0.8156],
    [1124323200000,0.8156,0.823,0.8134,0.8208],
    [1124409600000,0.8209,0.8249,0.8201,0.823],
    [1124668800000,0.823,0.823,0.8169,0.8179],
    [1124755200000,0.8178,0.8207,0.8159,0.8184],
    [1124841600000,0.8184,0.8228,0.8141,0.8158],
    [1124928000000,0.8158,0.8161,0.811,0.8141],
    [1125014400000,0.8141,0.8149,0.81,0.8142],
    [1125273600000,0.8142,0.8188,0.8098,0.818],
    [1125360000000,0.8181,0.822,0.8172,0.8189],
    [1125446400000,0.8187,0.8207,0.8093,0.8106],
    [1125532800000,0.8105,0.8117,0.7985,0.8015],
    [1125619200000,0.8019,0.8025,0.7943,0.7993],
    [1125878400000,0.7993,0.8001,0.7947,0.7998],
    [1125964800000,0.7997,0.8038,0.799,0.8026],
    [1126051200000,0.8027,0.8063,0.7976,0.8052],
    [1126137600000,0.8053,0.8078,0.803,0.8062],
    [1126224000000,0.8063,0.8083,0.8026,0.8058],
    [1126483200000,0.8061,0.8153,0.8055,0.8135],
    [1126569600000,0.8135,0.8169,0.812,0.8141],
    [1126656000000,0.8143,0.8158,0.8108,0.8149],
    [1126742400000,0.8151,0.8202,0.8147,0.8176],
    [1126828800000,0.8177,0.8197,0.8122,0.8173],
    [1127088000000,0.8175,0.8267,0.8175,0.8236],
    [1127174400000,0.8236,0.8261,0.8201,0.8254],
    [1127260800000,0.8258,0.826,0.8151,0.8158],
    [1127347200000,0.8159,0.8245,0.8151,0.8237],
    [1127433600000,0.8238,0.8319,0.8221,0.8306],
    [1127692800000,0.8309,0.8329,0.8279,0.8284],
    [1127779200000,0.8287,0.8349,0.8277,0.8324],
    [1127865600000,0.8324,0.8342,0.8285,0.8297],
    [1127952000000,0.8297,0.8331,0.8282,0.8319],
    [1128038400000,0.8319,0.8338,0.8269,0.8288],
    [1128297600000,0.8291,0.8407,0.829,0.8392],
    [1128384000000,0.8393,0.8403,0.837,0.8389],
    [1128470400000,0.8389,0.8395,0.8319,0.8324],
    [1128556800000,0.8325,0.8326,0.8193,0.8224],
    [1128643200000,0.8224,0.8269,0.8212,0.8245],
    [1128729600000,0.8245,0.8273,0.8245,0.8245],
    [1128902400000,0.8247,0.831,0.8229,0.8299],
    [1128988800000,0.8299,0.8362,0.8295,0.8358],
    [1129075200000,0.8357,0.837,0.8297,0.8318],
    [1129161600000,0.8318,0.8398,0.8305,0.8324],
    [1129248000000,0.8324,0.8351,0.8258,0.8281],
    [1129507200000,0.828,0.8339,0.825,0.8331],
    [1129593600000,0.8329,0.8393,0.8324,0.8384],
    [1129680000000,0.8383,0.8423,0.8329,0.8345],
    [1129766400000,0.8345,0.8373,0.8304,0.8307],
    [1129852800000,0.8306,0.8384,0.8279,0.8361],
    [1130112000000,0.8369,0.8393,0.8319,0.8363],
    [1130198400000,0.8365,0.8382,0.8252,0.8267],
    [1130284800000,0.8269,0.8305,0.8238,0.8297],
    [1130371200000,0.83,0.83,0.8214,0.8237],
    [1130457600000,0.8237,0.8302,0.8215,0.8289],
    [1130716800000,0.8289,0.8358,0.8273,0.8342],
    [1130803200000,0.8342,0.8354,0.8304,0.8319],
    [1130889600000,0.8319,0.8346,0.8274,0.8288],
    [1130976000000,0.8288,0.8382,0.8275,0.837],
    [1131062400000,0.837,0.8477,0.8338,0.8467],
    [1131321600000,0.8467,0.8493,0.845,0.8486],
    [1131408000000,0.8488,0.8541,0.8479,0.8501],
    [1131494400000,0.8501,0.8537,0.848,0.8495],
    [1131580800000,0.8495,0.8573,0.8477,0.8569],
    [1131667200000,0.8569,0.8572,0.8522,0.8523],
    [1131926400000,0.8523,0.858,0.8494,0.8557],
    [1132012800000,0.8556,0.8595,0.852,0.8527],
    [1132099200000,0.8527,0.8589,0.8524,0.8567],
    [1132185600000,0.8568,0.8595,0.8502,0.8515],
    [1132272000000,0.8515,0.8576,0.8479,0.8495],
    [1132531200000,0.8496,0.8538,0.8448,0.8525],
    [1132617600000,0.8527,0.8561,0.8457,0.8465],
    [1132704000000,0.8465,0.8501,0.8428,0.8468],
    [1132790400000,0.8468,0.8501,0.8455,0.8491],
    [1132876800000,0.8493,0.8543,0.849,0.8531],
    [1133136000000,0.8531,0.8565,0.8402,0.8442],
    [1133222400000,0.8446,0.8522,0.8427,0.8501],
    [1133308800000,0.8502,0.8511,0.8473,0.8479],
    [1133395200000,0.8478,0.8558,0.8473,0.8521],
    [1133481600000,0.8521,0.8579,0.8511,0.8535],
    [1133740800000,0.8544,0.8562,0.8461,0.8485],
    [1133827200000,0.8485,0.851,0.8467,0.8475],
    [1133913600000,0.8475,0.8548,0.8474,0.8538],
    [1134000000000,0.854,0.855,0.8436,0.8465],
    [1134086400000,0.8465,0.8499,0.8446,0.8466],
    [1134345600000,0.8467,0.8493,0.8343,0.8365],
    [1134432000000,0.8365,0.8399,0.834,0.837],
    [1134518400000,0.8373,0.8374,0.8292,0.8354],
    [1134604800000,0.8356,0.8381,0.8307,0.836],
    [1134691200000,0.8359,0.8382,0.8313,0.8326],
    [1134950400000,0.8324,0.8352,0.8306,0.8329],
    [1135036800000,0.8329,0.845,0.8324,0.8425],
    [1135123200000,0.8424,0.8478,0.8396,0.8453],
    [1135209600000,0.8455,0.8471,0.8401,0.8428],
    [1135296000000,0.8428,0.8457,0.8412,0.8427],
    [1135555200000,0.8427,0.8456,0.8407,0.8452],
    [1135641600000,0.8453,0.846,0.842,0.8437],
    [1135728000000,0.8437,0.847,0.8379,0.8444],
    [1136160000000,0.8442,0.8468,0.8442,0.8465],
    [1136505600000,0.8268,0.8272,0.821,0.8228],
    [1136764800000,0.8228,0.8299,0.8226,0.8284],
    [1136851200000,0.8283,0.831,0.8258,0.8292],
    [1136937600000,0.8292,0.8308,0.8232,0.8242],
    [1137024000000,0.8242,0.833,0.822,0.8304],
    [1137110400000,0.8304,0.8317,0.8235,0.8238],
    [1137369600000,0.8238,0.8264,0.8214,0.8256],
    [1137456000000,0.8258,0.8304,0.8237,0.8262],
    [1137542400000,0.8264,0.8287,0.8224,0.8262],
    [1137628800000,0.8261,0.8297,0.8248,0.8274],
    [1137715200000,0.8274,0.8307,0.8237,0.8242],
    [1137974400000,0.8241,0.8243,0.8114,0.8127],
    [1138060800000,0.8127,0.8159,0.8121,0.8153],
    [1138147200000,0.8153,0.8176,0.8113,0.8169],
    [1138233600000,0.8168,0.8201,0.8151,0.8191],
    [1138320000000,0.8189,0.8276,0.8173,0.8271],
    [1138579200000,0.8271,0.8293,0.8255,0.8279],
    [1138665600000,0.8277,0.8282,0.8202,0.8233],
    [1138752000000,0.8233,0.8303,0.8219,0.829],
    [1138838400000,0.829,0.8314,0.8258,0.8277],
    [1138924800000,0.8277,0.8354,0.8256,0.8318],
    [1139184000000,0.8318,0.8376,0.8301,0.8363],
    [1139270400000,0.8362,0.8376,0.8324,0.8342],
    [1139356800000,0.8342,0.839,0.8339,0.8361],
    [1139443200000,0.8361,0.8372,0.8335,0.8339],
    [1139788800000,0.8399,0.842,0.8389,0.8398],
    [1139875200000,0.8398,0.8432,0.8382,0.8396],
    [1139961600000,0.8396,0.8427,0.8363,0.8417],
    [1140048000000,0.8415,0.8443,0.8392,0.8402],
    [1140134400000,0.8402,0.8435,0.8371,0.8377],
    [1140393600000,0.8377,0.839,0.8351,0.8382],
    [1140480000000,0.8382,0.8413,0.8378,0.8395],
    [1140566400000,0.8394,0.8432,0.8383,0.8393],
    [1140652800000,0.8393,0.8413,0.8356,0.8391],
    [1140739200000,0.8391,0.8432,0.838,0.8422],
    [1140998400000,0.8422,0.846,0.8418,0.8442],
    [1141084800000,0.8442,0.8453,0.8375,0.8384],
    [1141171200000,0.8384,0.8413,0.8352,0.8394],
    [1141257600000,0.8393,0.8398,0.8303,0.831],
    [1141344000000,0.831,0.834,0.8297,0.8304],
    [1141603200000,0.8304,0.8344,0.8269,0.8326],
    [1141689600000,0.8326,0.8429,0.8319,0.8414],
    [1141776000000,0.8413,0.8427,0.8375,0.8391],
    [1141862400000,0.839,0.8408,0.8368,0.8405],
    [1141948800000,0.8405,0.8435,0.8385,0.8398],
    [1142208000000,0.8398,0.84,0.8356,0.836],
    [1142294400000,0.8361,0.838,0.8312,0.8324],
    [1142380800000,0.8324,0.8333,0.8283,0.8288],
    [1142467200000,0.8288,0.8313,0.8205,0.8218],
    [1142553600000,0.8217,0.8239,0.8192,0.8205],
    [1142812800000,0.8205,0.8235,0.8199,0.8225],
    [1142899200000,0.8224,0.8284,0.822,0.8261],
    [1142985600000,0.8262,0.8292,0.826,0.8284],
    [1143072000000,0.8283,0.8368,0.8278,0.8356],
    [1143158400000,0.8356,0.837,0.8302,0.831],
    [1143417600000,0.831,0.8335,0.8294,0.8326],
    [1143504000000,0.8326,0.8342,0.826,0.833],
    [1143590400000,0.8329,0.8351,0.8299,0.8314],
    [1143676800000,0.8314,0.832,0.8216,0.8219],
    [1143763200000,0.822,0.828,0.8214,0.8254],
    [1144022400000,0.8256,0.8315,0.8229,0.8236],
    [1144108800000,0.8235,0.8254,0.8147,0.8163],
    [1144195200000,0.8164,0.8171,0.8127,0.8145],
    [1144281600000,0.8144,0.8206,0.811,0.8195],
    [1144368000000,0.8195,0.8279,0.818,0.8273],
    [1144627200000,0.8271,0.8284,0.8245,0.8263],
    [1144713600000,0.8263,0.8275,0.8222,0.8228],
    [1144800000000,0.8228,0.829,0.8219,0.8264],
    [1144886400000,0.8264,0.8288,0.8243,0.826],
    [1144972800000,0.8261,0.8274,0.8253,0.826],
    [1145232000000,0.8253,0.8254,0.8139,0.816],
    [1145318400000,0.8159,0.8184,0.8085,0.8094],
    [1145404800000,0.8094,0.8141,0.8068,0.8085],
    [1145491200000,0.8084,0.8153,0.8081,0.8134],
    [1145577600000,0.8133,0.8155,0.8092,0.8103],
    [1145836800000,0.8097,0.8111,0.8055,0.808],
    [1145923200000,0.808,0.8091,0.804,0.8049],
    [1146009600000,0.8049,0.8077,0.802,0.8035],
    [1146096000000,0.8035,0.8065,0.797,0.7976],
    [1146182400000,0.7976,0.799,0.7911,0.7918],
    [1146441600000,0.792,0.7967,0.7881,0.796],
    [1146528000000,0.796,0.7966,0.7895,0.7933],
    [1146614400000,0.7932,0.7945,0.7894,0.7918],
    [1146700800000,0.7918,0.7958,0.786,0.7878],
    [1146787200000,0.7877,0.7893,0.7835,0.7861],
    [1147046400000,0.7859,0.7882,0.7821,0.7875],
    [1147132800000,0.7876,0.7901,0.7824,0.7838],
    [1147219200000,0.7839,0.7846,0.7793,0.7841],
    [1147305600000,0.7839,0.7886,0.7769,0.7785],
    [1147392000000,0.7785,0.7791,0.7718,0.7738],
    [1147651200000,0.7732,0.7829,0.771,0.782],
    [1147737600000,0.7819,0.7833,0.7772,0.7783],
    [1147824000000,0.7782,0.7875,0.7741,0.7849],
    [1147910400000,0.7849,0.7855,0.7769,0.7778],
    [1147996800000,0.7777,0.788,0.7774,0.7834],
    [1148256000000,0.7836,0.788,0.7761,0.7783],
    [1148342400000,0.7785,0.7836,0.7765,0.7819],
    [1148428800000,0.7819,0.7857,0.776,0.7834],
    [1148515200000,0.7834,0.7848,0.7796,0.7813],
    [1148601600000,0.7813,0.7875,0.7794,0.7854],
    [1148860800000,0.7854,0.7866,0.7832,0.7846],
    [1148947200000,0.7846,0.7851,0.7748,0.778],
    [1149033600000,0.7779,0.7813,0.7751,0.7805],
    [1149120000000,0.7805,0.7863,0.7798,0.7803],
    [1149206400000,0.7803,0.7819,0.7729,0.7742],
    [1149465600000,0.7735,0.776,0.7705,0.7757],
    [1149552000000,0.7757,0.7813,0.7732,0.7798],
    [1149638400000,0.7798,0.7839,0.7791,0.7813],
    [1149724800000,0.7813,0.7923,0.781,0.7911],
    [1149811200000,0.7911,0.7942,0.7885,0.7916],
    [1150070400000,0.7915,0.7961,0.7905,0.795],
    [1150156800000,0.795,0.7985,0.7928,0.7973],
    [1150243200000,0.7972,0.7981,0.7908,0.7935],
    [1150329600000,0.7933,0.795,0.7901,0.7909],
    [1150416000000,0.7909,0.7926,0.789,0.7908],
    [1150675200000,0.7907,0.797,0.7903,0.7952],
    [1150761600000,0.7952,0.7977,0.7926,0.7939],
    [1150848000000,0.7938,0.7947,0.7888,0.7896],
    [1150934400000,0.7895,0.7972,0.7888,0.7954],
    [1151020800000,0.7954,0.8017,0.7945,0.7996],
    [1151280000000,0.7994,0.8003,0.7931,0.7933],
    [1151366400000,0.7935,0.7964,0.7925,0.7957],
    [1151452800000,0.7959,0.7994,0.7947,0.7974],
    [1151539200000,0.7974,0.7991,0.7893,0.7898],
    [1151625600000,0.7898,0.7902,0.7816,0.782],
    [1151884800000,0.7823,0.7839,0.7798,0.7813],
    [1151971200000,0.7813,0.783,0.7798,0.7826],
    [1152057600000,0.7827,0.7872,0.7788,0.7857],
    [1152144000000,0.7858,0.7864,0.7822,0.7829],
    [1152230400000,0.7829,0.7836,0.7775,0.7808],
    [1152489600000,0.7805,0.7864,0.7801,0.7852],
    [1152576000000,0.7852,0.7872,0.7825,0.7836],
    [1152662400000,0.7836,0.789,0.7825,0.7875],
    [1152748800000,0.7875,0.7898,0.7855,0.788],
    [1152835200000,0.7879,0.7922,0.7877,0.7906],
    [1153094400000,0.7906,0.7996,0.79,0.7986],
    [1153180800000,0.7988,0.8017,0.7962,0.8001],
    [1153267200000,0.8002,0.8028,0.793,0.7939],
    [1153353600000,0.7939,0.7946,0.7902,0.7916],
    [1153440000000,0.7918,0.7921,0.787,0.7875],
    [1153699200000,0.7873,0.7933,0.7867,0.7924],
    [1153785600000,0.7923,0.7962,0.7891,0.7951],
    [1153872000000,0.7951,0.7964,0.7858,0.7868],
    [1153958400000,0.7868,0.7888,0.7829,0.7883],
    [1154044800000,0.7881,0.7902,0.783,0.7838],
    [1154304000000,0.7838,0.7851,0.7822,0.7835],
    [1154390400000,0.7834,0.7864,0.7794,0.7798],
    [1154476800000,0.7798,0.7828,0.7791,0.7817],
    [1154563200000,0.7817,0.785,0.7791,0.7813],
    [1154649600000,0.7813,0.7829,0.7745,0.777],
    [1154908800000,0.7769,0.7811,0.7756,0.7801],
    [1154995200000,0.78,0.7836,0.7755,0.7819],
    [1155081600000,0.7819,0.7833,0.775,0.777],
    [1155168000000,0.7772,0.7847,0.7745,0.7818],
    [1155254400000,0.7818,0.7871,0.7814,0.7859],
    [1155513600000,0.7859,0.7872,0.7834,0.7862],
    [1155600000000,0.7863,0.7879,0.7809,0.7822],
    [1155686400000,0.7821,0.7833,0.7772,0.778],
    [1155772800000,0.7782,0.7806,0.7759,0.7793],
    [1155859200000,0.7793,0.7827,0.7784,0.7798],
    [1156118400000,0.7798,0.7802,0.7728,0.7774],
    [1156204800000,0.7773,0.7824,0.776,0.7815],
    [1156291200000,0.7816,0.783,0.778,0.7825],
    [1156377600000,0.7825,0.7844,0.7786,0.7838],
    [1156464000000,0.7838,0.786,0.7822,0.7842],
    [1156723200000,0.7842,0.7843,0.7799,0.7818],
    [1156809600000,0.7818,0.7846,0.7788,0.7795],
    [1156896000000,0.7795,0.7808,0.778,0.7793],
    [1156982400000,0.7794,0.7823,0.7764,0.7808],
    [1157068800000,0.7806,0.7841,0.7783,0.7792],
    [1157328000000,0.7792,0.7792,0.7767,0.7778],
    [1157414400000,0.7777,0.7817,0.7774,0.7803],
    [1157500800000,0.7803,0.7833,0.7791,0.7804],
    [1157587200000,0.7805,0.7872,0.7791,0.7859],
    [1157673600000,0.7856,0.7906,0.7853,0.789],
    [1157932800000,0.7892,0.7908,0.7849,0.7875],
    [1158019200000,0.7873,0.7893,0.7854,0.7887],
    [1158105600000,0.7885,0.7897,0.7862,0.7876],
    [1158192000000,0.7876,0.7892,0.7841,0.7863],
    [1158278400000,0.7862,0.792,0.7851,0.79],
    [1158537600000,0.79,0.7917,0.7867,0.7872],
    [1158624000000,0.787,0.7908,0.7857,0.7889],
    [1158710400000,0.789,0.7901,0.7856,0.7878],
    [1158796800000,0.7879,0.7882,0.7813,0.7822],
    [1158883200000,0.7822,0.7839,0.7793,0.7827],
    [1159142400000,0.7827,0.7857,0.78,0.7841],
    [1159228800000,0.7841,0.79,0.7835,0.7877],
    [1159315200000,0.7878,0.7895,0.7862,0.7867],
    [1159401600000,0.7865,0.789,0.7853,0.787],
    [1159488000000,0.787,0.7914,0.7865,0.7892],
    [1159747200000,0.7891,0.79,0.7838,0.7847],
    [1159833600000,0.7847,0.7865,0.7833,0.7854],
    [1159920000000,0.7854,0.7897,0.7849,0.7863],
    [1160006400000,0.7864,0.7893,0.7857,0.788],
    [1160092800000,0.7878,0.7956,0.7867,0.7937],
    [1160352000000,0.7939,0.7949,0.7927,0.7941],
    [1160438400000,0.7943,0.799,0.7926,0.7979],
    [1160524800000,0.7979,0.8,0.7963,0.7987],
    [1160611200000,0.7988,0.7992,0.7957,0.7965],
    [1160697600000,0.7964,0.8013,0.795,0.7982],
    [1160956800000,0.7982,0.801,0.7972,0.7982],
    [1161043200000,0.7981,0.7996,0.7957,0.7976],
    [1161129600000,0.7976,0.8002,0.7962,0.7974],
    [1161216000000,0.7974,0.7981,0.791,0.792],
    [1161302400000,0.792,0.794,0.791,0.7928],
    [1161561600000,0.7928,0.798,0.7922,0.7971],
    [1161648000000,0.7971,0.7987,0.795,0.7964],
    [1161734400000,0.7967,0.7967,0.7922,0.7928],
    [1161820800000,0.7928,0.7932,0.7872,0.7881],
    [1161907200000,0.7881,0.79,0.7841,0.7852],
    [1162166400000,0.7852,0.7877,0.7851,0.7865],
    [1162252800000,0.7865,0.7888,0.7823,0.7835],
    [1162339200000,0.7835,0.7852,0.7813,0.7842],
    [1162425600000,0.7841,0.7854,0.782,0.7827],
    [1162512000000,0.7829,0.7887,0.7819,0.7863],
    [1162771200000,0.7864,0.7883,0.7852,0.786],
    [1162857600000,0.7858,0.7862,0.78,0.7826],
    [1162944000000,0.7827,0.7847,0.7808,0.7838],
    [1163030400000,0.7838,0.7846,0.7783,0.7792],
    [1163116800000,0.7792,0.7796,0.775,0.7788],
    [1163376000000,0.7788,0.7818,0.7765,0.78],
    [1163462400000,0.7801,0.7819,0.7769,0.7806],
    [1163548800000,0.7806,0.7831,0.7791,0.78],
    [1163635200000,0.7798,0.7824,0.7787,0.7819],
    [1163721600000,0.782,0.7838,0.7785,0.7797],
    [1163980800000,0.7797,0.7813,0.778,0.7804],
    [1164067200000,0.7806,0.7816,0.7779,0.7788],
    [1164153600000,0.7788,0.7791,0.7717,0.7729],
    [1164240000000,0.7731,0.7738,0.7707,0.7722],
    [1164326400000,0.7722,0.7726,0.7628,0.7638],
    [1164585600000,0.7635,0.7642,0.7591,0.7617],
    [1164672000000,0.7616,0.7625,0.7565,0.7576],
    [1164758400000,0.7575,0.7617,0.7565,0.761],
    [1164844800000,0.7607,0.7612,0.7532,0.7547],
    [1164931200000,0.7548,0.7567,0.7491,0.7498],
    [1165190400000,0.7498,0.7531,0.7479,0.75],
    [1165276800000,0.7499,0.7528,0.7482,0.751],
    [1165363200000,0.7511,0.7546,0.7495,0.7527],
    [1165449600000,0.7528,0.7535,0.7504,0.7528],
    [1165536000000,0.7528,0.7582,0.7482,0.7575],
    [1165795200000,0.7577,0.7618,0.7539,0.7548],
    [1165881600000,0.7548,0.7569,0.7523,0.753],
    [1165968000000,0.753,0.7582,0.7526,0.7567],
    [1166054400000,0.7567,0.7612,0.7544,0.7603],
    [1166140800000,0.7603,0.7659,0.7583,0.7645],
    [1166400000000,0.7648,0.7663,0.7623,0.7632],
    [1166486400000,0.7632,0.7646,0.7567,0.7574],
    [1166572800000,0.7572,0.7601,0.7549,0.7585],
    [1166659200000,0.7585,0.7612,0.7567,0.7588],
    [1166745600000,0.7587,0.763,0.7568,0.7618],
    [1167004800000,0.7618,0.7636,0.7594,0.7614],
    [1167091200000,0.7614,0.7641,0.7609,0.7629],
    [1167177600000,0.7628,0.7636,0.7588,0.7621],
    [1167264000000,0.7623,0.7632,0.7574,0.7608],
    [1167350400000,0.7608,0.7614,0.7571,0.7579],
    [1167609600000,0.7579,0.7594,0.7564,0.7568],
    [1167696000000,0.7568,0.7569,0.752,0.7535],
    [1167782400000,0.7534,0.7609,0.7523,0.7594],
    [1167868800000,0.7594,0.765,0.7587,0.7642],
    [1167955200000,0.7643,0.7705,0.763,0.7692],
    [1168214400000,0.7692,0.771,0.7668,0.7671],
    [1168300800000,0.7671,0.7702,0.766,0.7696],
    [1168387200000,0.7698,0.7736,0.7691,0.773],
    [1168473600000,0.7731,0.7765,0.7682,0.7757],
    [1168560000000,0.7756,0.7773,0.7725,0.774],
    [1168819200000,0.774,0.7745,0.7717,0.7733],
    [1168905600000,0.7733,0.7748,0.7698,0.7742],
    [1168992000000,0.7741,0.7754,0.7721,0.773],
    [1169078400000,0.773,0.7756,0.7705,0.7717],
    [1169164800000,0.7716,0.7747,0.7691,0.7723],
    [1169424000000,0.7722,0.7741,0.7704,0.7724],
    [1169510400000,0.7723,0.7737,0.7666,0.768],
    [1169596800000,0.768,0.7725,0.767,0.7715],
    [1169683200000,0.7715,0.7745,0.7691,0.7732],
    [1169769600000,0.7733,0.7767,0.7727,0.7743],
    [1170028800000,0.7743,0.7756,0.7711,0.7716],
    [1170115200000,0.7715,0.7727,0.7703,0.7714],
    [1170201600000,0.7717,0.7739,0.767,0.7679],
    [1170288000000,0.7679,0.7693,0.7669,0.7685],
    [1170374400000,0.7684,0.7724,0.7653,0.7715],
    [1170633600000,0.7715,0.7745,0.7711,0.7733],
    [1170720000000,0.7733,0.7747,0.7696,0.7702],
    [1170806400000,0.7703,0.7713,0.7678,0.7679],
    [1170892800000,0.7679,0.7709,0.7666,0.7672],
    [1170979200000,0.7672,0.7704,0.7665,0.7688],
    [1171238400000,0.7688,0.7729,0.767,0.7718],
    [1171324800000,0.7718,0.7723,0.7666,0.7676],
    [1171411200000,0.7677,0.7679,0.7604,0.7612],
    [1171497600000,0.7612,0.7625,0.7591,0.7614],
    [1171584000000,0.7614,0.7639,0.7607,0.7612],
    [1171843200000,0.7613,0.7623,0.7596,0.7605],
    [1171929600000,0.7603,0.7618,0.7581,0.7607],
    [1172016000000,0.7606,0.7628,0.7595,0.7612],
    [1172102400000,0.7612,0.7648,0.7609,0.7621],
    [1172188800000,0.762,0.7635,0.7582,0.7596],
    [1172448000000,0.7595,0.7606,0.7576,0.758],
    [1172534400000,0.7579,0.7601,0.7541,0.756],
    [1172620800000,0.7559,0.759,0.7553,0.756],
    [1172707200000,0.7561,0.7603,0.7552,0.7596],
    [1172793600000,0.7594,0.7611,0.7575,0.7582],
    [1173052800000,0.7582,0.7652,0.7568,0.7642],
    [1173139200000,0.7644,0.7648,0.7609,0.7613],
    [1173225600000,0.7613,0.7631,0.7584,0.759],
    [1173312000000,0.7589,0.7625,0.7585,0.7612],
    [1173398400000,0.7613,0.7644,0.7598,0.7625],
    [1173657600000,0.7623,0.7631,0.7575,0.7583],
    [1173744000000,0.7584,0.7603,0.7563,0.7583],
    [1173830400000,0.7583,0.759,0.7549,0.7557],
    [1173916800000,0.7557,0.7582,0.7529,0.753],
    [1174003200000,0.753,0.7536,0.7496,0.7511],
    [1174262400000,0.7511,0.7532,0.7504,0.7522],
    [1174348800000,0.7523,0.7537,0.7505,0.7511],
    [1174435200000,0.7511,0.7528,0.7456,0.7464],
    [1174521600000,0.7467,0.7515,0.7461,0.7503],
    [1174867200000,0.7526,0.7548,0.7492,0.7504],
    [1174953600000,0.7519,0.7519,0.7517,0.7518],
    [1175040000000,0.7518,0.7518,0.7476,0.7515],
    [1175126400000,0.7514,0.752,0.7488,0.7502],
    [1175212800000,0.7504,0.7528,0.7468,0.7488],
    [1175472000000,0.7489,0.7502,0.7472,0.7481],
    [1175558400000,0.7481,0.751,0.7472,0.7506],
    [1175644800000,0.7506,0.7509,0.7473,0.7478],
    [1175731200000,0.7479,0.7491,0.7439,0.745],
    [1175817600000,0.745,0.7485,0.7447,0.7472],
    [1176076800000,0.7472,0.7499,0.7472,0.7489],
    [1176163200000,0.7489,0.7493,0.7432,0.7447],
    [1176249600000,0.7448,0.746,0.7439,0.7443],
    [1176336000000,0.7443,0.7447,0.7404,0.7416],
    [1176422400000,0.7416,0.742,0.7378,0.7394],
    [1176681600000,0.7394,0.7397,0.7372,0.7393],
    [1176768000000,0.7393,0.7396,0.7355,0.7369],
    [1176854400000,0.7366,0.7378,0.7342,0.7345],
    [1176940800000,0.7346,0.7376,0.7337,0.734],
    [1177027200000,0.7341,0.7363,0.7332,0.7361],
    [1177286400000,0.7354,0.7386,0.7349,0.7365],
    [1177372800000,0.7365,0.7383,0.7328,0.7335],
    [1177459200000,0.7335,0.7344,0.7317,0.7325],
    [1177545600000,0.7325,0.7362,0.7324,0.7357],
    [1177632000000,0.7357,0.7363,0.7309,0.7323],
    [1177891200000,0.7325,0.7361,0.731,0.7325],
    [1177977600000,0.7324,0.736,0.7313,0.7351],
    [1178064000000,0.7351,0.7377,0.7344,0.7356],
    [1178150400000,0.7357,0.7384,0.7341,0.7379],
    [1178236800000,0.738,0.7391,0.7347,0.7362],
    [1178496000000,0.7362,0.7362,0.7338,0.7355],
    [1178582400000,0.7355,0.74,0.7341,0.7386],
    [1178668800000,0.7386,0.7398,0.7371,0.7389],
    [1178755200000,0.7389,0.7427,0.7372,0.742],
    [1178841600000,0.7421,0.7429,0.739,0.7395],
    [1179100800000,0.7393,0.7396,0.7375,0.7384],
    [1179187200000,0.7385,0.7394,0.7348,0.7358],
    [1179273600000,0.7358,0.7406,0.7347,0.7396],
    [1179360000000,0.7396,0.7421,0.7383,0.741],
    [1179446400000,0.741,0.7429,0.7395,0.7404],
    [1179705600000,0.7401,0.7444,0.739,0.7429],
    [1179792000000,0.7428,0.7443,0.7419,0.7436],
    [1179878400000,0.7437,0.7457,0.7406,0.7432],
    [1179964800000,0.7432,0.7457,0.7428,0.745],
    [1180051200000,0.7451,0.7458,0.7421,0.7435],
    [1180310400000,0.7435,0.744,0.7428,0.7434],
    [1180396800000,0.7434,0.7454,0.7396,0.7434],
    [1180483200000,0.7433,0.746,0.7431,0.7446],
    [1180569600000,0.7445,0.7452,0.742,0.7432],
    [1180656000000,0.7433,0.7468,0.7427,0.7439],
    [1180915200000,0.7439,0.7447,0.7406,0.7412],
    [1181001600000,0.7412,0.7416,0.7377,0.7397],
    [1181088000000,0.7397,0.7417,0.7386,0.741],
    [1181174400000,0.7409,0.7452,0.74,0.7449],
    [1181260800000,0.7449,0.7508,0.7445,0.748],
    [1181520000000,0.748,0.7503,0.7477,0.7491],
    [1181606400000,0.7491,0.752,0.7479,0.7518],
    [1181692800000,0.7519,0.7542,0.7509,0.7515],
    [1181779200000,0.7515,0.7532,0.7504,0.7515],
    [1181865600000,0.7515,0.7517,0.7468,0.7476],
    [1182124800000,0.7476,0.7478,0.7451,0.7454],
    [1182211200000,0.7454,0.7474,0.7442,0.7449],
    [1182297600000,0.7451,0.747,0.7441,0.7463],
    [1182384000000,0.7465,0.748,0.7459,0.7469],
    [1182470400000,0.7469,0.7476,0.7423,0.7427],
    [1182729600000,0.7427,0.7443,0.7421,0.7427],
    [1182816000000,0.7428,0.7447,0.7419,0.7435],
    [1182902400000,0.7435,0.7456,0.7431,0.7433],
    [1182988800000,0.7433,0.7451,0.7417,0.7444],
    [1183075200000,0.7442,0.7448,0.7381,0.7384],
    [1183334400000,0.7386,0.7394,0.7331,0.7342],
    [1183420800000,0.7343,0.7363,0.7334,0.7349],
    [1183507200000,0.735,0.7353,0.7336,0.7343],
    [1183593600000,0.7343,0.7366,0.732,0.7356],
    [1183680000000,0.7356,0.7371,0.733,0.734],
    [1183939200000,0.734,0.735,0.7332,0.7342],
    [1184025600000,0.7342,0.7358,0.7254,0.7276],
    [1184112000000,0.7276,0.7285,0.7256,0.7275],
    [1184198400000,0.7275,0.7279,0.7247,0.7254],
    [1184284800000,0.7254,0.7267,0.7239,0.7254],
    [1184544000000,0.7254,0.7269,0.7244,0.726],
    [1184630400000,0.726,0.727,0.7246,0.725],
    [1184716800000,0.725,0.7272,0.7229,0.7243],
    [1184803200000,0.7243,0.7256,0.723,0.7248],
    [1184889600000,0.7247,0.7258,0.7223,0.7229],
    [1185148800000,0.7229,0.7251,0.7222,0.7247],
    [1185235200000,0.7246,0.7248,0.7219,0.7235],
    [1185321600000,0.7236,0.7302,0.7232,0.7288],
    [1185408000000,0.7288,0.7306,0.7261,0.7272],
    [1185494400000,0.7272,0.7342,0.7271,0.7335],
    [1185753600000,0.7341,0.735,0.7284,0.7289],
    [1185840000000,0.7288,0.7319,0.7285,0.7314],
    [1185926400000,0.7314,0.7334,0.7293,0.7318],
    [1186012800000,0.7319,0.7327,0.7295,0.7298],
    [1186099200000,0.7298,0.7309,0.7236,0.7256],
    [1186358400000,0.7254,0.7258,0.7225,0.7242],
    [1186444800000,0.7241,0.7283,0.7239,0.7273],
    [1186531200000,0.7271,0.7289,0.7232,0.7246],
    [1186617600000,0.7247,0.7324,0.7237,0.7319],
    [1186704000000,0.7317,0.7331,0.7296,0.7303],
    [1186963200000,0.7305,0.7354,0.7294,0.7349],
    [1187049600000,0.7349,0.7394,0.7339,0.7391],
    [1187136000000,0.739,0.7455,0.7389,0.7447],
    [1187222400000,0.7446,0.7486,0.7436,0.7446],
    [1187308800000,0.7448,0.7481,0.7381,0.7423],
    [1187568000000,0.7421,0.7431,0.7402,0.7423],
    [1187654400000,0.7423,0.7435,0.7395,0.7426],
    [1187740800000,0.7427,0.7437,0.7375,0.7382],
    [1187827200000,0.7381,0.739,0.7359,0.7374],
    [1187913600000,0.7374,0.7381,0.7308,0.731],
    [1188172800000,0.7313,0.7337,0.7306,0.7334],
    [1188259200000,0.7334,0.7369,0.7309,0.7366],
    [1188345600000,0.7365,0.7376,0.7309,0.7316],
    [1188432000000,0.7316,0.7359,0.7314,0.7316],
    [1188518400000,0.7316,0.7344,0.7289,0.7337],
    [1188777600000,0.7338,0.7352,0.7323,0.7346],
    [1188864000000,0.7348,0.7381,0.7338,0.7341],
    [1188950400000,0.7341,0.7371,0.7314,0.7325],
    [1189036800000,0.7325,0.7336,0.7293,0.7304],
    [1189123200000,0.7304,0.7321,0.7247,0.7265],
    [1189382400000,0.7263,0.7265,0.7238,0.7251],
    [1189468800000,0.7251,0.726,0.7221,0.7226],
    [1189555200000,0.7228,0.7235,0.7186,0.7198],
    [1189641600000,0.7197,0.7215,0.718,0.7209],
    [1189728000000,0.7209,0.7224,0.7196,0.7208],
    [1189987200000,0.721,0.7222,0.7199,0.7212],
    [1190073600000,0.7212,0.7232,0.7148,0.7155],
    [1190160000000,0.7157,0.7178,0.7149,0.7156],
    [1190246400000,0.7157,0.7161,0.7092,0.7108],
    [1190332800000,0.7108,0.7123,0.7082,0.7097],
    [1190592000000,0.7097,0.711,0.7077,0.7107],
    [1190678400000,0.7106,0.7114,0.706,0.7069],
    [1190764800000,0.7069,0.7088,0.7064,0.7079],
    [1190851200000,0.7078,0.7083,0.7047,0.7065],
    [1190937600000,0.7065,0.7069,0.7003,0.7007],
    [1191196800000,0.7006,0.7039,0.7001,0.7027],
    [1191283200000,0.7025,0.7075,0.7022,0.7066],
    [1191369600000,0.7066,0.7102,0.7041,0.7094],
    [1191456000000,0.7095,0.7111,0.7067,0.7075],
    [1191542400000,0.7075,0.7127,0.7063,0.7077],
    [1191801600000,0.7075,0.7127,0.7065,0.712],
    [1191888000000,0.712,0.7138,0.7084,0.7089],
    [1191974400000,0.7088,0.7097,0.7056,0.7073],
    [1192060800000,0.7073,0.7077,0.7021,0.7043],
    [1192147200000,0.7043,0.7066,0.7035,0.7057],
    [1192406400000,0.7055,0.7064,0.702,0.7043],
    [1192492800000,0.7039,0.7072,0.7028,0.7055],
    [1192579200000,0.7054,0.7064,0.7027,0.704],
    [1192665600000,0.704,0.7046,0.6988,0.6998],
    [1192752000000,0.6997,0.7021,0.6983,0.6993],
    [1193011200000,0.6991,0.7081,0.6968,0.7054],
    [1193097600000,0.7055,0.7058,0.7003,0.7015],
    [1193184000000,0.7015,0.7049,0.7007,0.7012],
    [1193270400000,0.7013,0.702,0.6971,0.6981],
    [1193356800000,0.6982,0.6986,0.6944,0.6949],
    [1193616000000,0.6937,0.6957,0.6926,0.6936],
    [1193702400000,0.6938,0.6958,0.6923,0.6926],
    [1193788800000,0.6926,0.6936,0.6894,0.691],
    [1193875200000,0.6911,0.6945,0.6906,0.6933],
    [1193961600000,0.6933,0.6936,0.6883,0.6898],
    [1194220800000,0.689,0.6925,0.6885,0.6908],
    [1194307200000,0.6908,0.6913,0.6862,0.6868],
    [1194393600000,0.6866,0.6873,0.6788,0.6839],
    [1194480000000,0.6839,0.6842,0.6801,0.6813],
    [1194566400000,0.6813,0.6836,0.6778,0.6815],
    [1194825600000,0.6815,0.6888,0.6812,0.6888],
    [1194912000000,0.6886,0.6888,0.6833,0.684],
    [1194998400000,0.6841,0.6845,0.6791,0.6825],
    [1195084800000,0.6826,0.6849,0.68,0.6841],
    [1195171200000,0.684,0.686,0.6815,0.6822],
    [1195430400000,0.6817,0.6841,0.6807,0.6818],
    [1195516800000,0.6818,0.6835,0.6732,0.6743],
    [1195603200000,0.6743,0.677,0.6724,0.6731],
    [1195689600000,0.6731,0.6749,0.6723,0.6734],
    [1195776000000,0.6734,0.6765,0.6681,0.674],
    [1196035200000,0.6744,0.6756,0.6717,0.6724],
    [1196121600000,0.6724,0.6754,0.6707,0.6741],
    [1196208000000,0.6741,0.6799,0.673,0.6739],
    [1196294400000,0.6739,0.6794,0.6736,0.6776],
    [1196380800000,0.6777,0.6838,0.6763,0.6834],
    [1196640000000,0.6834,0.6841,0.6799,0.6817],
    [1196726400000,0.6818,0.6835,0.677,0.6774],
    [1196812800000,0.6775,0.6854,0.677,0.6852],
    [1196899200000,0.6851,0.6886,0.6821,0.6823],
    [1196985600000,0.6823,0.6852,0.6815,0.6824],
    [1197244800000,0.6824,0.6832,0.6786,0.6799],
    [1197331200000,0.6799,0.6832,0.6779,0.6822],
    [1197417600000,0.682,0.6827,0.678,0.6797],
    [1197504000000,0.6797,0.6862,0.6785,0.6837],
    [1197590400000,0.6838,0.6941,0.6822,0.693],
    [1197849600000,0.6949,0.698,0.6919,0.694],
    [1197936000000,0.6939,0.6962,0.6927,0.6936],
    [1198022400000,0.6936,0.6982,0.6932,0.6954],
    [1198108800000,0.6953,0.699,0.6948,0.6978],
    [1198195200000,0.6978,0.6982,0.6939,0.6955],
    [1198454400000,0.6958,0.6962,0.6938,0.6947],
    [1198540800000,0.6947,0.6953,0.6933,0.6947],
    [1198627200000,0.6947,0.6951,0.6893,0.6905],
    [1198713600000,0.6903,0.6914,0.683,0.6845],
    [1198800000000,0.6845,0.6855,0.6789,0.6793],
    [1199059200000,0.6793,0.6866,0.6779,0.6856],
    [1199145600000,0.6856,0.6862,0.6835,0.6841],
    [1199232000000,0.6841,0.6853,0.6779,0.6796],
    [1199318400000,0.6796,0.681,0.6765,0.6783],
    [1199404800000,0.6784,0.6807,0.6745,0.6784],
    [1199664000000,0.6779,0.6824,0.6776,0.6812],
    [1199750400000,0.6811,0.6814,0.6782,0.68],
    [1199836800000,0.68,0.6832,0.6782,0.6822],
    [1199923200000,0.6822,0.6832,0.675,0.6757],
    [1200009600000,0.6758,0.6776,0.6748,0.6769],
    [1200268800000,0.6769,0.6769,0.6704,0.6727],
    [1200355200000,0.6727,0.677,0.6701,0.6757],
    [1200441600000,0.6757,0.6854,0.6729,0.6821],
    [1200528000000,0.6821,0.6856,0.6795,0.6832],
    [1200614400000,0.6832,0.685,0.6805,0.684],
    [1200787200000,0.684,0.6854,0.684,0.6851],
    [1200873600000,0.6851,0.6941,0.6848,0.6936],
    [1200960000000,0.6937,0.6963,0.6809,0.6829],
    [1201046400000,0.6829,0.6894,0.6825,0.6837],
    [1201132800000,0.6837,0.6855,0.6766,0.677],
    [1201219200000,0.6771,0.6823,0.6768,0.6812],
    [1201392000000,0.6812,0.6815,0.6805,0.6807],
    [1201478400000,0.6808,0.6822,0.6757,0.6763],
    [1201564800000,0.6764,0.6787,0.6762,0.6772],
    [1201651200000,0.6771,0.6779,0.6707,0.6745],
    [1201737600000,0.6745,0.6757,0.6705,0.6728],
    [1201824000000,0.6729,0.6765,0.6689,0.6756],
    [1201996800000,0.6756,0.6761,0.6751,0.6758],
    [1202083200000,0.6757,0.6762,0.6734,0.6745],
    [1202169600000,0.6746,0.6841,0.6742,0.6833],
    [1202256000000,0.6834,0.6855,0.6816,0.6844],
    [1202342400000,0.6843,0.6927,0.6825,0.6908],
    [1202428800000,0.6908,0.6922,0.6873,0.6894],
    [1202601600000,0.6894,0.6894,0.6883,0.6886],
    [1202688000000,0.6885,0.6907,0.6859,0.6898],
    [1202774400000,0.6898,0.69,0.6842,0.6859],
    [1202860800000,0.6859,0.6882,0.6849,0.6871],
    [1202947200000,0.6871,0.6875,0.6826,0.6831],
    [1203033600000,0.683,0.6837,0.6798,0.6812],
    [1203206400000,0.6812,0.6818,0.6811,0.6816],
    [1203292800000,0.6817,0.6846,0.6806,0.6826],
    [1203379200000,0.6827,0.6832,0.6776,0.679],
    [1203465600000,0.6788,0.6844,0.6787,0.6793],
    [1203552000000,0.6793,0.6804,0.6739,0.6751],
    [1203638400000,0.6752,0.6764,0.6728,0.6745],
    [1203811200000,0.6745,0.6745,0.6738,0.6741],
    [1203897600000,0.674,0.6761,0.6736,0.6743],
    [1203984000000,0.6742,0.6768,0.6645,0.6668],
    [1204070400000,0.6668,0.6677,0.6603,0.6616],
    [1204156800000,0.6616,0.6636,0.6566,0.6582],
    [1204243200000,0.6582,0.6605,0.6562,0.6588],
    [1204416000000,0.6588,0.6588,0.6575,0.6576],
    [1204502400000,0.6576,0.6598,0.6547,0.658],
    [1204588800000,0.658,0.6592,0.6558,0.6577],
    [1204675200000,0.6577,0.6604,0.6535,0.6546],
    [1204761600000,0.6545,0.655,0.6495,0.6502],
    [1204848000000,0.6501,0.6531,0.6467,0.6513],
    [1205020800000,0.6513,0.6513,0.6491,0.6494],
    [1205107200000,0.6495,0.6531,0.6491,0.6521],
    [1205193600000,0.6521,0.6544,0.6453,0.6521],
    [1205280000000,0.652,0.6521,0.6421,0.6435],
    [1205366400000,0.6435,0.6444,0.6391,0.6402],
    [1205452800000,0.6402,0.644,0.6374,0.6377],
    [1205625600000,0.6377,0.6381,0.6352,0.6369],
    [1205712000000,0.6369,0.6378,0.6287,0.6352],
    [1205798400000,0.6352,0.6407,0.6316,0.6388],
    [1205884800000,0.6389,0.6418,0.6334,0.6395],
    [1205971200000,0.6395,0.6497,0.6393,0.6478],
    [1206057600000,0.648,0.6493,0.6462,0.648],
    [1206316800000,0.6479,0.652,0.647,0.648],
    [1206403200000,0.648,0.649,0.6386,0.6406],
    [1206489600000,0.6406,0.6419,0.6305,0.6321],
    [1206576000000,0.6321,0.636,0.631,0.6325],
    [1206662400000,0.6325,0.6354,0.6313,0.633],
    [1206921600000,0.633,0.6351,0.629,0.6346],
    [1207008000000,0.6347,0.6427,0.6334,0.6403],
    [1207094400000,0.6403,0.6439,0.6368,0.638],
    [1207180800000,0.638,0.6449,0.6375,0.6385],
    [1207267200000,0.6387,0.6396,0.6339,0.6358],
    [1207526400000,0.6357,0.64,0.6354,0.6367],
    [1207612800000,0.6366,0.6381,0.6329,0.6371],
    [1207699200000,0.6371,0.6376,0.6303,0.6322],
    [1207785600000,0.6322,0.6361,0.6283,0.6346],
    [1207872000000,0.6347,0.635,0.6306,0.6333],
    [1208131200000,0.6335,0.6382,0.6294,0.6319],
    [1208217600000,0.632,0.635,0.6298,0.6335],
    [1208304000000,0.6335,0.634,0.6258,0.6275],
    [1208390400000,0.6274,0.6312,0.6256,0.6287],
    [1208476800000,0.6287,0.6366,0.6266,0.6323],
    [1208736000000,0.6325,0.6334,0.627,0.6285],
    [1208822400000,0.6284,0.6317,0.6242,0.6261],
    [1208908800000,0.6261,0.6306,0.625,0.6297],
    [1208995200000,0.6297,0.6397,0.6295,0.638],
    [1209081600000,0.6378,0.6431,0.6366,0.6398],
    [1209340800000,0.6399,0.6414,0.6372,0.6388],
    [1209427200000,0.6388,0.6436,0.6385,0.642],
    [1209513600000,0.6419,0.6445,0.6393,0.64],
    [1209600000000,0.64,0.6482,0.6392,0.6461],
    [1209686400000,0.6461,0.6511,0.6452,0.6483],
    [1209945600000,0.6482,0.6485,0.6443,0.6451],
    [1210032000000,0.6451,0.6473,0.6412,0.6445],
    [1210118400000,0.6445,0.6523,0.6442,0.6518],
    [1210204800000,0.6518,0.6544,0.6476,0.6492],
    [1210291200000,0.6491,0.6498,0.6455,0.6456],
    [1210550400000,0.6458,0.651,0.6422,0.6439],
    [1210636800000,0.6439,0.6482,0.6424,0.6465],
    [1210723200000,0.6463,0.6497,0.6457,0.6466],
    [1210809600000,0.6466,0.6486,0.6432,0.647],
    [1210896000000,0.6469,0.6474,0.6409,0.6421],
    [1211155200000,0.6419,0.646,0.6396,0.644],
    [1211241600000,0.644,0.645,0.6377,0.6391],
    [1211328000000,0.6391,0.6399,0.633,0.6337],
    [1211414400000,0.6337,0.6374,0.6323,0.6357],
    [1211500800000,0.6356,0.6372,0.6331,0.6344],
    [1211760000000,0.6342,0.6354,0.6332,0.6338],
    [1211846400000,0.6338,0.6382,0.6322,0.6374],
    [1211932800000,0.6375,0.6408,0.6344,0.6387],
    [1212019200000,0.6387,0.646,0.6384,0.6445],
    [1212105600000,0.6446,0.6469,0.6423,0.643],
    [1212364800000,0.643,0.6458,0.6414,0.6435],
    [1212451200000,0.6435,0.649,0.6398,0.6472],
    [1212537600000,0.6473,0.6487,0.6458,0.648],
    [1212624000000,0.648,0.6509,0.6408,0.6412],
    [1212710400000,0.6413,0.6423,0.6336,0.6337],
    [1212969600000,0.6335,0.6404,0.6311,0.6391],
    [1213056000000,0.6389,0.6478,0.6389,0.6469],
    [1213142400000,0.6469,0.6472,0.6415,0.6428],
    [1213228800000,0.6427,0.6504,0.6425,0.6474],
    [1213315200000,0.6474,0.6536,0.6467,0.6502],
    [1213574400000,0.6501,0.6518,0.6443,0.6466],
    [1213660800000,0.6467,0.647,0.643,0.6445],
    [1213747200000,0.6445,0.6469,0.6429,0.6433],
    [1213833600000,0.6433,0.6467,0.6415,0.6453],
    [1213920000000,0.6452,0.6457,0.6389,0.6406],
    [1214179200000,0.6404,0.6466,0.6395,0.6443],
    [1214265600000,0.6443,0.6457,0.6401,0.6421],
    [1214352000000,0.6421,0.6438,0.6374,0.6384],
    [1214438400000,0.6383,0.64,0.6342,0.6345],
    [1214524800000,0.6345,0.6363,0.633,0.6335],
    [1214784000000,0.6337,0.6358,0.6314,0.6345],
    [1214870400000,0.6345,0.6361,0.6318,0.6334],
    [1214956800000,0.6334,0.634,0.6292,0.6298],
    [1215043200000,0.6298,0.6381,0.6284,0.6376],
    [1215129600000,0.6376,0.6389,0.6358,0.6367],
    [1215388800000,0.6369,0.6407,0.6347,0.636],
    [1215475200000,0.636,0.6397,0.6353,0.6384],
    [1215561600000,0.6384,0.6392,0.6349,0.6358],
    [1215648000000,0.6356,0.6375,0.6328,0.6335],
    [1215734400000,0.6332,0.6345,0.627,0.6272],
    [1215993600000,0.627,0.6314,0.6261,0.6294],
    [1216080000000,0.6293,0.6304,0.6234,0.6283],
    [1216166400000,0.6283,0.633,0.627,0.6319],
    [1216252800000,0.632,0.6337,0.6292,0.6309],
    [1216339200000,0.6309,0.6328,0.6294,0.631],
    [1216598400000,0.6312,0.6319,0.6276,0.6285],
    [1216684800000,0.6284,0.6348,0.6271,0.6338],
    [1216771200000,0.6338,0.6383,0.6329,0.6377],
    [1216857600000,0.6377,0.64,0.6363,0.6376],
    [1216944000000,0.6376,0.6386,0.6347,0.6365],
    [1217203200000,0.6367,0.6378,0.6341,0.6352],
    [1217289600000,0.6352,0.6431,0.6346,0.6415],
    [1217376000000,0.6415,0.6444,0.6403,0.6418],
    [1217462400000,0.6418,0.6425,0.6369,0.6418],
    [1217548800000,0.6417,0.6446,0.6414,0.6425],
    [1217808000000,0.6425,0.643,0.6397,0.6425],
    [1217894400000,0.6425,0.6475,0.6424,0.6466],
    [1217980800000,0.6466,0.6496,0.6444,0.6485],
    [1218067200000,0.6484,0.6533,0.645,0.6531],
    [1218153600000,0.653,0.667,0.653,0.6663],
    [1218412800000,0.6665,0.6721,0.6629,0.6706],
    [1218499200000,0.6706,0.6752,0.6682,0.6715],
    [1218585600000,0.6716,0.6739,0.6675,0.6713],
    [1218672000000,0.6712,0.6776,0.6688,0.6769],
    [1218758400000,0.6769,0.6824,0.6759,0.6803],
    [1219017600000,0.6803,0.6819,0.6771,0.6805],
    [1219104000000,0.6805,0.6837,0.6754,0.676],
    [1219190400000,0.676,0.6818,0.6756,0.6779],
    [1219276800000,0.678,0.6785,0.6707,0.6717],
    [1219363200000,0.6717,0.6777,0.6713,0.6764],
    [1219622400000,0.6765,0.6806,0.6753,0.6791],
    [1219708800000,0.6791,0.6866,0.6789,0.6828],
    [1219795200000,0.6828,0.683,0.6767,0.6782],
    [1219881600000,0.6781,0.6817,0.6751,0.6801],
    [1219968000000,0.6801,0.6832,0.6771,0.6816],
    [1220227200000,0.6814,0.6871,0.6792,0.6858],
    [1220313600000,0.6858,0.6915,0.685,0.6903],
    [1220400000000,0.6902,0.6954,0.6885,0.689],
    [1220486400000,0.689,0.7038,0.6875,0.7014],
    [1220572800000,0.7014,0.7045,0.697,0.7013],
    [1220832000000,0.7011,0.7117,0.693,0.7075],
    [1220918400000,0.7075,0.7121,0.7028,0.7096],
    [1221004800000,0.7096,0.7178,0.7051,0.7175],
    [1221091200000,0.7176,0.7206,0.7122,0.7139],
    [1221177600000,0.7138,0.7159,0.7022,0.7033],
    [1221436800000,0.7031,0.7102,0.6904,0.7021],
    [1221523200000,0.7021,0.7108,0.7001,0.7058],
    [1221609600000,0.7058,0.7094,0.6952,0.6975],
    [1221696000000,0.6975,0.7005,0.6877,0.6993],
    [1221782400000,0.6993,0.7068,0.6897,0.6913],
    [1222041600000,0.6911,0.6929,0.6726,0.6756],
    [1222128000000,0.6756,0.684,0.6744,0.681],
    [1222214400000,0.6811,0.6849,0.6781,0.6825],
    [1222300800000,0.6825,0.687,0.6771,0.6819],
    [1222387200000,0.6819,0.6871,0.6812,0.6855],
    [1222646400000,0.6857,0.6993,0.6857,0.6961],
    [1222732800000,0.6959,0.714,0.6932,0.7083],
    [1222819200000,0.7083,0.7157,0.7055,0.7149],
    [1222905600000,0.7148,0.7276,0.7134,0.7254],
    [1222992000000,0.7254,0.7299,0.7191,0.7255],
    [1223251200000,0.7257,0.744,0.7257,0.74],
    [1223337600000,0.74,0.7419,0.7276,0.737],
    [1223424000000,0.737,0.7384,0.727,0.7348],
    [1223510400000,0.7349,0.7381,0.7254,0.7374],
    [1223596800000,0.7375,0.7544,0.7324,0.7465],
    [1223856000000,0.7463,0.7463,0.7307,0.7323],
    [1223942400000,0.7323,0.7375,0.7262,0.7374],
    [1224028800000,0.7374,0.7452,0.7305,0.7443],
    [1224115200000,0.7443,0.7488,0.7386,0.7414],
    [1224201600000,0.7414,0.7472,0.7397,0.7456],
    [1224460800000,0.7454,0.7527,0.739,0.7497],
    [1224547200000,0.7497,0.7694,0.7488,0.7688],
    [1224633600000,0.7688,0.786,0.7672,0.783],
    [1224720000000,0.783,0.7856,0.7689,0.7753],
    [1224806400000,0.7752,0.8004,0.7751,0.7923],
    [1225065600000,0.7925,0.8111,0.7883,0.8022],
    [1225152000000,0.8022,0.8112,0.7785,0.7809],
    [1225238400000,0.781,0.7922,0.7697,0.7719],
    [1225324800000,0.7719,0.7813,0.7522,0.7769],
    [1225411200000,0.777,0.7896,0.7761,0.7855],
    [1225670400000,0.7854,0.7954,0.7753,0.7928],
    [1225756800000,0.7928,0.7986,0.7663,0.7703],
    [1225843200000,0.7704,0.7819,0.7624,0.7744],
    [1225929600000,0.7745,0.7892,0.7732,0.7881],
    [1226016000000,0.7881,0.7905,0.7781,0.7864],
    [1226275200000,0.7864,0.7865,0.7735,0.7846],
    [1226361600000,0.7846,0.8017,0.7812,0.7993],
    [1226448000000,0.7993,0.8042,0.7916,0.8028],
    [1226534400000,0.8028,0.8034,0.7777,0.7821],
    [1226620800000,0.7821,0.7956,0.7812,0.7933],
    [1226880000000,0.7936,0.7994,0.7847,0.7911],
    [1226966400000,0.791,0.7959,0.7873,0.7915],
    [1227052800000,0.7915,0.8019,0.7804,0.7999],
    [1227139200000,0.7999,0.8052,0.794,0.8041],
    [1227225600000,0.8041,0.8046,0.7911,0.7945],
    [1227484800000,0.7943,0.7961,0.7716,0.7744],
    [1227571200000,0.7744,0.7812,0.7644,0.7676],
    [1227657600000,0.7676,0.7802,0.7672,0.7756],
    [1227744000000,0.7756,0.7778,0.7711,0.7754],
    [1227830400000,0.7754,0.791,0.7717,0.7881],
    [1228089600000,0.7879,0.7954,0.7863,0.794],
    [1228176000000,0.7939,0.7962,0.7833,0.787],
    [1228262400000,0.7868,0.7937,0.7847,0.7872],
    [1228348800000,0.7871,0.7971,0.7783,0.783],
    [1228435200000,0.7829,0.7921,0.7813,0.7868],
    [1228694400000,0.7867,0.7868,0.7711,0.7744],
    [1228780800000,0.7745,0.7816,0.7691,0.7736],
    [1228867200000,0.7736,0.7753,0.765,0.7685],
    [1228953600000,0.7685,0.7692,0.7459,0.7504],
    [1229040000000,0.7504,0.7549,0.7454,0.7481],
    [1229299200000,0.748,0.7486,0.7284,0.7302],
    [1229385600000,0.7302,0.7338,0.7067,0.711],
    [1229472000000,0.7111,0.714,0.6926,0.6942],
    [1229558400000,0.6942,0.7053,0.6793,0.6996],
    [1229644800000,0.6996,0.7235,0.6988,0.7189],
    [1229904000000,0.7189,0.7195,0.7079,0.7166],
    [1229990400000,0.7166,0.7189,0.7131,0.7177],
    [1230076800000,0.7177,0.7178,0.7133,0.7142],
    [1230163200000,0.7142,0.7155,0.7118,0.7136],
    [1230249600000,0.7136,0.7151,0.7083,0.7129],
    [1230508800000,0.7127,0.7188,0.6962,0.7127],
    [1230595200000,0.7127,0.7151,0.7031,0.7073],
    [1230681600000,0.7072,0.7221,0.7069,0.7156],
    [1230768000000,0.7156,0.7164,0.7107,0.7143],
    [1230854400000,0.7143,0.7228,0.7128,0.7184],
    [1231113600000,0.7186,0.7382,0.7161,0.7334],
    [1231200000000,0.7334,0.7514,0.7333,0.7406],
    [1231286400000,0.7405,0.7447,0.7274,0.7346],
    [1231372800000,0.7345,0.739,0.7246,0.7301],
    [1231459200000,0.73,0.7457,0.7272,0.7421],
    [1231718400000,0.7423,0.7527,0.7418,0.7487],
    [1231804800000,0.7487,0.761,0.7486,0.7576],
    [1231891200000,0.7576,0.764,0.7497,0.7569],
    [1231977600000,0.7569,0.7679,0.7553,0.76],
    [1232064000000,0.76,0.761,0.7494,0.7538],
    [1232323200000,0.7536,0.766,0.747,0.764],
    [1232409600000,0.764,0.7787,0.7632,0.7762],
    [1232496000000,0.7764,0.78,0.7641,0.7692],
    [1232582400000,0.7693,0.7748,0.7642,0.7691],
    [1232668800000,0.7691,0.7836,0.7671,0.7708],
    [1232928000000,0.7706,0.7778,0.7554,0.7584],
    [1233014400000,0.7584,0.7624,0.7501,0.7569],
    [1233100800000,0.7569,0.7634,0.7502,0.7603],
    [1233187200000,0.7603,0.7744,0.7587,0.7731],
    [1233273600000,0.7731,0.7836,0.7727,0.7805],
    [1233532800000,0.7808,0.7872,0.7752,0.7805],
    [1233619200000,0.7805,0.7813,0.7658,0.7686],
    [1233705600000,0.7686,0.7807,0.765,0.778],
    [1233792000000,0.778,0.7838,0.7748,0.7819],
    [1233878400000,0.782,0.7847,0.7696,0.7729],
    [1234137600000,0.7727,0.777,0.7637,0.7717],
    [1234224000000,0.7717,0.7808,0.7649,0.7746],
    [1234310400000,0.7746,0.7794,0.7693,0.7773],
    [1234396800000,0.7772,0.7862,0.7726,0.7762],
    [1234483200000,0.7763,0.7802,0.7726,0.7775],
    [1234742400000,0.7778,0.786,0.7778,0.7815],
    [1234828800000,0.7815,0.7965,0.7813,0.7948],
    [1234915200000,0.7947,0.7994,0.7911,0.7959],
    [1235001600000,0.7959,0.7966,0.7836,0.7901],
    [1235088000000,0.7901,0.7966,0.7761,0.7797],
    [1235347200000,0.78,0.7898,0.7696,0.7893],
    [1235433600000,0.7893,0.79,0.7765,0.777],
    [1235520000000,0.777,0.7882,0.7751,0.7864],
    [1235606400000,0.7864,0.7887,0.7805,0.7865],
    [1235692800000,0.7865,0.7935,0.7842,0.7896],
    [1235952000000,0.7898,0.7978,0.7898,0.7962],
    [1236038400000,0.7962,0.7987,0.7886,0.7982],
    [1236124800000,0.7981,0.803,0.7896,0.7916],
    [1236211200000,0.7916,0.8017,0.7911,0.7972],
    [1236297600000,0.7972,0.7977,0.784,0.7904],
    [1236556800000,0.7904,0.7967,0.7857,0.7925],
    [1236643200000,0.7925,0.7932,0.7798,0.7865],
    [1236729600000,0.7865,0.7928,0.7773,0.781],
    [1236816000000,0.781,0.7856,0.7724,0.7754],
    [1236902400000,0.7755,0.7776,0.7718,0.7735],
    [1237161600000,0.7737,0.7792,0.765,0.7709],
    [1237248000000,0.7709,0.7734,0.7667,0.7674],
    [1237334400000,0.7674,0.7704,0.7387,0.7415],
    [1237420800000,0.7415,0.7455,0.7279,0.7321],
    [1237507200000,0.7321,0.7399,0.7285,0.7364],
    [1237766400000,0.7362,0.7417,0.728,0.7343],
    [1237852800000,0.7344,0.7447,0.731,0.7418],
    [1237939200000,0.7418,0.7454,0.7325,0.7368],
    [1238025600000,0.7367,0.7413,0.7331,0.7393],
    [1238112000000,0.7393,0.7546,0.7357,0.7527],
    [1238371200000,0.7527,0.7628,0.7526,0.7583],
    [1238457600000,0.7583,0.7592,0.7495,0.7541],
    [1238544000000,0.7544,0.7597,0.7526,0.7553],
    [1238630400000,0.7553,0.7555,0.7398,0.7423],
    [1238716800000,0.7423,0.7483,0.7409,0.7418],
    [1238976000000,0.7416,0.7508,0.7363,0.7506],
    [1239062400000,0.7506,0.7562,0.7463,0.7537],
    [1239148800000,0.7537,0.7608,0.7513,0.7549],
    [1239235200000,0.7549,0.7641,0.7498,0.7623],
    [1239321600000,0.7623,0.7641,0.7581,0.7583],
    [1239580800000,0.7585,0.762,0.7466,0.7477],
    [1239667200000,0.7477,0.7563,0.7475,0.7539],
    [1239753600000,0.7539,0.7608,0.752,0.7563],
    [1239840000000,0.7563,0.7621,0.7536,0.7585],
    [1239926400000,0.7584,0.7683,0.7579,0.7668],
    [1240185600000,0.7666,0.7762,0.7658,0.775],
    [1240272000000,0.775,0.7756,0.7696,0.773],
    [1240358400000,0.7731,0.7762,0.7669,0.7688],
    [1240444800000,0.7688,0.7707,0.7598,0.762],
    [1240531200000,0.7619,0.7628,0.7518,0.7552],
    [1240790400000,0.755,0.7694,0.7548,0.7679],
    [1240876800000,0.7679,0.7715,0.7595,0.7616],
    [1240963200000,0.7616,0.7624,0.7496,0.7539],
    [1241049600000,0.7539,0.7582,0.747,0.7552],
    [1241136000000,0.7552,0.7562,0.7502,0.7534],
    [1241395200000,0.7534,0.7571,0.7441,0.7454],
    [1241481600000,0.7454,0.7531,0.7442,0.7517],
    [1241568000000,0.7516,0.7551,0.7477,0.7519],
    [1241654400000,0.7519,0.7549,0.7424,0.747],
    [1241740800000,0.747,0.7497,0.7324,0.7335],
    [1242000000000,0.7332,0.7378,0.7315,0.7362],
    [1242086400000,0.7362,0.7376,0.7295,0.7312],
    [1242172800000,0.7312,0.739,0.7287,0.7386],
    [1242259200000,0.7386,0.7395,0.7317,0.7336],
    [1242345600000,0.7336,0.743,0.7325,0.7411],
    [1242604800000,0.7413,0.7452,0.7372,0.738],
    [1242691200000,0.738,0.7393,0.7316,0.733],
    [1242777600000,0.733,0.7364,0.723,0.7256],
    [1242864000000,0.7256,0.7287,0.7173,0.7174],
    [1242950400000,0.7173,0.7196,0.7116,0.7145],
    [1243209600000,0.7143,0.7165,0.7118,0.7139],
    [1243296000000,0.7139,0.7217,0.7131,0.7147],
    [1243382400000,0.7147,0.7236,0.7144,0.723],
    [1243468800000,0.723,0.7252,0.7151,0.7171],
    [1243555200000,0.7172,0.7174,0.7057,0.7065],
    [1243814400000,0.7067,0.7094,0.7019,0.7052],
    [1243900800000,0.7052,0.7093,0.6976,0.6999],
    [1243987200000,0.6999,0.709,0.6974,0.7072],
    [1244073600000,0.7072,0.711,0.7021,0.7059],
    [1244160000000,0.7059,0.718,0.7009,0.7163],
    [1244419200000,0.7161,0.7245,0.7142,0.718],
    [1244505600000,0.7181,0.7221,0.7091,0.7112],
    [1244592000000,0.7112,0.7188,0.707,0.7151],
    [1244678400000,0.7151,0.7173,0.7053,0.708],
    [1244764800000,0.708,0.7177,0.7078,0.7136],
    [1244937600000,0.7136,0.7161,0.7136,0.7155],
    [1245024000000,0.7155,0.7271,0.7149,0.7261],
    [1245110400000,0.726,0.7275,0.7177,0.7239],
    [1245196800000,0.7239,0.7243,0.7149,0.716],
    [1245283200000,0.716,0.7211,0.7141,0.7193],
    [1245369600000,0.7193,0.7205,0.7136,0.7145],
    [1245628800000,0.717,0.7234,0.7169,0.7221],
    [1245715200000,0.7221,0.7233,0.7088,0.7108],
    [1245801600000,0.7108,0.7203,0.7073,0.7173],
    [1245974400000,0.7176,0.718,0.7082,0.7114],
    [1246233600000,0.7112,0.7154,0.7085,0.7093],
    [1246320000000,0.7093,0.7143,0.7065,0.7124],
    [1246406400000,0.7124,0.7144,0.7042,0.707],
    [1246492800000,0.707,0.7181,0.7067,0.7164],
    [1246579200000,0.7163,0.7169,0.7128,0.7161],
    [1246838400000,0.7163,0.7208,0.7143,0.7147],
    [1246924800000,0.7147,0.7202,0.7116,0.7196],
    [1247011200000,0.7197,0.7232,0.7173,0.7214],
    [1247097600000,0.7214,0.7218,0.7105,0.7138],
    [1247184000000,0.7138,0.7209,0.7136,0.7178],
    [1247443200000,0.7176,0.7198,0.7139,0.7141],
    [1247529600000,0.7141,0.719,0.7135,0.7154],
    [1247616000000,0.7154,0.716,0.7074,0.7096],
    [1247702400000,0.7096,0.7116,0.7059,0.7076],
    [1247788800000,0.7076,0.7113,0.7067,0.7095],
    [1248048000000,0.7094,0.7097,0.7017,0.7038],
    [1248134400000,0.7038,0.7062,0.7004,0.7044],
    [1248220800000,0.7044,0.7066,0.7014,0.7041],
    [1248307200000,0.7041,0.7084,0.6997,0.7062],
    [1248393600000,0.7061,0.7077,0.7016,0.7041],
    [1248652800000,0.7039,0.7058,0.6993,0.702],
    [1248739200000,0.702,0.7079,0.6991,0.7069],
    [1248825600000,0.7069,0.7141,0.7044,0.7135],
    [1248912000000,0.7135,0.7137,0.7094,0.71],
    [1248998400000,0.71,0.7105,0.7002,0.7015],
    [1249257600000,0.7016,0.7041,0.6922,0.6941],
    [1249344000000,0.6941,0.6962,0.6929,0.6938],
    [1249430400000,0.6938,0.6967,0.6921,0.694],
    [1249516800000,0.694,0.698,0.6929,0.6959],
    [1249603200000,0.6959,0.7066,0.6938,0.7048],
    [1249862400000,0.705,0.7092,0.7033,0.7078],
    [1249948800000,0.7078,0.7089,0.7049,0.7067],
    [1250035200000,0.7068,0.7101,0.7019,0.7034],
    [1250121600000,0.7034,0.7039,0.6979,0.7006],
    [1250208000000,0.7006,0.7064,0.699,0.7041],
    [1250467200000,0.7043,0.7121,0.7043,0.7089],
    [1250640000000,0.7072,0.7102,0.7009,0.7019],
    [1250726400000,0.7018,0.7043,0.7005,0.7016],
    [1250812800000,0.7016,0.704,0.6955,0.6983],
    [1251072000000,0.6981,0.7004,0.6964,0.6997],
    [1251158400000,0.6995,0.7018,0.6962,0.6999],
    [1251244800000,0.7001,0.7041,0.6967,0.7024],
    [1251331200000,0.7024,0.7034,0.694,0.6958],
    [1251417600000,0.6958,0.7004,0.6949,0.6994],
    [1251676800000,0.6993,0.7016,0.6959,0.6975],
    [1251763200000,0.6976,0.7055,0.6954,0.7039],
    [1251849600000,0.7039,0.7049,0.6995,0.7008],
    [1251936000000,0.7007,0.7026,0.6969,0.7015],
    [1252022400000,0.7015,0.7048,0.698,0.6996],
    [1252281600000,0.6994,0.6995,0.6962,0.6976],
    [1252368000000,0.6977,0.6981,0.6879,0.6896],
    [1252454400000,0.6897,0.6915,0.685,0.6874],
    [1252540800000,0.6874,0.6897,0.6843,0.6858],
    [1252627200000,0.6858,0.6872,0.6833,0.6866],
    [1252886400000,0.6864,0.6891,0.6825,0.6832],
    [1252972800000,0.6832,0.6869,0.6809,0.6817],
    [1253059200000,0.6818,0.683,0.6785,0.6799],
    [1253145600000,0.6799,0.681,0.6772,0.6788],
    [1253232000000,0.6788,0.6828,0.6785,0.6796],
    [1253491200000,0.6798,0.6846,0.6796,0.6811],
    [1253577600000,0.6811,0.6812,0.6737,0.6753],
    [1253664000000,0.6753,0.6811,0.6736,0.679],
    [1253750400000,0.679,0.684,0.6755,0.6826],
    [1253836800000,0.6826,0.6845,0.6791,0.6806],
    [1254096000000,0.6805,0.6869,0.6793,0.684],
    [1254182400000,0.684,0.6885,0.6827,0.6853],
    [1254268800000,0.6853,0.6862,0.6815,0.6825],
    [1254355200000,0.6825,0.6893,0.6817,0.689],
    [1254441600000,0.689,0.6907,0.6827,0.6863],
    [1254700800000,0.6861,0.6862,0.6817,0.6823],
    [1254787200000,0.6823,0.6825,0.6774,0.6793],
    [1254873600000,0.6793,0.6827,0.6785,0.6797],
    [1254960000000,0.6797,0.6802,0.6749,0.677],
    [1255046400000,0.677,0.6816,0.6766,0.6789],
    [1255305600000,0.6791,0.6815,0.675,0.676],
    [1255392000000,0.676,0.6776,0.6722,0.6734],
    [1255478400000,0.6735,0.674,0.669,0.6697],
    [1255564800000,0.6697,0.6739,0.668,0.6687],
    [1255651200000,0.6687,0.6736,0.6681,0.6709],
    [1255910400000,0.6709,0.6745,0.6675,0.6681],
    [1255996800000,0.6682,0.672,0.6669,0.6698],
    [1256083200000,0.6698,0.6719,0.6646,0.6661],
    [1256169600000,0.666,0.6693,0.6647,0.665],
    [1256256000000,0.665,0.6675,0.664,0.6668],
    [1256515200000,0.6669,0.6738,0.6638,0.6731],
    [1256601600000,0.6731,0.6772,0.6699,0.6749],
    [1256688000000,0.6748,0.6808,0.6738,0.6796],
    [1256774400000,0.6796,0.6812,0.6729,0.6739],
    [1256860800000,0.6739,0.6803,0.6729,0.6789],
    [1257120000000,0.6791,0.6812,0.6736,0.677],
    [1257206400000,0.677,0.6839,0.6752,0.6789],
    [1257292800000,0.6789,0.6803,0.6708,0.6727],
    [1257379200000,0.6727,0.6754,0.6703,0.6722],
    [1257465600000,0.6722,0.6753,0.6705,0.6736],
    [1257724800000,0.6734,0.6734,0.6657,0.6665],
    [1257811200000,0.6665,0.6696,0.6657,0.6671],
    [1257897600000,0.6671,0.669,0.6644,0.6675],
    [1257984000000,0.6675,0.6748,0.6659,0.6732],
    [1258070400000,0.6732,0.6747,0.6694,0.6712],
    [1258329600000,0.671,0.6721,0.666,0.6676],
    [1258416000000,0.6675,0.6755,0.6667,0.6723],
    [1258502400000,0.6724,0.6727,0.6671,0.6687],
    [1258588800000,0.6687,0.6739,0.6686,0.6706],
    [1258675200000,0.6706,0.6757,0.6695,0.6729],
    [1258934400000,0.6728,0.6744,0.6667,0.6687],
    [1259020800000,0.6687,0.6718,0.6671,0.6682],
    [1259107200000,0.6682,0.6685,0.6602,0.6612],
    [1259193600000,0.6611,0.6697,0.6605,0.6682],
    [1259280000000,0.6682,0.6745,0.6665,0.6673],
    [1259539200000,0.6671,0.6684,0.663,0.6651],
    [1259625600000,0.6651,0.668,0.6613,0.6627],
    [1259712000000,0.6627,0.6654,0.6618,0.6638],
    [1259798400000,0.6638,0.6652,0.6604,0.6639],
    [1259884800000,0.6639,0.6749,0.6626,0.6731],
    [1260144000000,0.6732,0.6778,0.6709,0.674],
    [1260230400000,0.674,0.6819,0.6726,0.6807],
    [1260316800000,0.6807,0.6816,0.6765,0.6786],
    [1260403200000,0.6786,0.6811,0.6775,0.6789],
    [1260489600000,0.6789,0.6857,0.6767,0.6846],
    [1260748800000,0.6844,0.6853,0.6809,0.6823],
    [1260835200000,0.6823,0.6897,0.6822,0.6878],
    [1260921600000,0.6878,0.6896,0.6853,0.6886],
    [1261008000000,0.6887,0.6992,0.6881,0.6972],
    [1261094400000,0.6972,0.7014,0.6939,0.6975],
    [1261353600000,0.6977,0.7011,0.6957,0.7001],
    [1261440000000,0.7001,0.7035,0.6977,0.7017],
    [1261526400000,0.7017,0.7027,0.696,0.6977],
    [1261612800000,0.6977,0.698,0.6935,0.6956],
    [1261699200000,0.6956,0.6967,0.6926,0.6955],
    [1261958400000,0.6955,0.6969,0.6937,0.6958],
    [1262044800000,0.6957,0.6979,0.6916,0.6974],
    [1262131200000,0.6974,0.7008,0.6963,0.6974],
    [1262217600000,0.6974,0.6992,0.6925,0.6979],
    [1262304000000,0.6979,0.6985,0.6975,0.6976],
    [1262563200000,0.6978,0.7016,0.6917,0.6932],
    [1262649600000,0.6933,0.6972,0.6904,0.6962],
    [1262736000000,0.6962,0.7003,0.6927,0.6944],
    [1262822400000,0.6944,0.6994,0.6921,0.6985],
    [1262908800000,0.6985,0.7012,0.6925,0.694],
    [1263168000000,0.6938,0.6942,0.687,0.6893],
    [1263254400000,0.6893,0.692,0.6873,0.6908],
    [1263340800000,0.6908,0.6919,0.6858,0.6886],
    [1263427200000,0.6886,0.6923,0.687,0.6897],
    [1263513600000,0.6898,0.6977,0.6895,0.6951],
    [1263772800000,0.6953,0.6978,0.694,0.6943],
    [1263859200000,0.6943,0.7018,0.6937,0.7003],
    [1263945600000,0.7003,0.7104,0.6998,0.7086],
    [1264032000000,0.7086,0.7131,0.707,0.7093],
    [1264118400000,0.7093,0.7101,0.7051,0.7074],
    [1264377600000,0.7072,0.708,0.7045,0.7069],
    [1264464000000,0.7069,0.7123,0.7052,0.7101],
    [1264550400000,0.7102,0.7147,0.7094,0.7128],
    [1264636800000,0.7129,0.7179,0.7116,0.7162],
    [1264723200000,0.7163,0.7216,0.7149,0.7214],
    [1264982400000,0.7217,0.722,0.7174,0.7184],
    [1265068800000,0.7183,0.7203,0.7154,0.7156],
    [1265155200000,0.7156,0.7205,0.7129,0.7195],
    [1265241600000,0.7196,0.7293,0.7192,0.7278],
    [1265328000000,0.7277,0.7363,0.7274,0.7312],
    [1265587200000,0.7309,0.7343,0.7292,0.7324],
    [1265673600000,0.7324,0.7329,0.7225,0.7256],
    [1265760000000,0.7256,0.7313,0.724,0.7274],
    [1265846400000,0.7275,0.7357,0.7245,0.7307],
    [1265932800000,0.7306,0.7393,0.7305,0.7336],
    [1266192000000,0.7338,0.7366,0.7335,0.7355],
    [1266278400000,0.7355,0.7361,0.7257,0.7267],
    [1266364800000,0.7267,0.7363,0.7251,0.7349],
    [1266451200000,0.7349,0.744,0.7323,0.7431],
    [1266537600000,0.7428,0.7435,0.7343,0.7348],
    [1266796800000,0.7346,0.7368,0.7323,0.735],
    [1266883200000,0.735,0.7411,0.7303,0.7395],
    [1266969600000,0.7395,0.7409,0.7338,0.7386],
    [1267056000000,0.7386,0.7436,0.7369,0.7388],
    [1267142400000,0.7388,0.7391,0.7308,0.7337],
    [1267401600000,0.7335,0.7431,0.7323,0.7375],
    [1267488000000,0.7375,0.7444,0.7341,0.7343],
    [1267574400000,0.7343,0.7359,0.728,0.7299],
    [1267660800000,0.7299,0.738,0.7292,0.7363],
    [1267747200000,0.7363,0.7392,0.7336,0.7341],
    [1268006400000,0.7341,0.7351,0.7297,0.7345],
    [1268092800000,0.7345,0.7389,0.7334,0.735],
    [1268179200000,0.735,0.7385,0.7309,0.7331],
    [1268265600000,0.733,0.7344,0.7306,0.7309],
    [1268352000000,0.7309,0.7317,0.7246,0.7264],
    [1268611200000,0.7262,0.7332,0.7257,0.7313],
    [1268697600000,0.7313,0.7324,0.7256,0.7264],
    [1268784000000,0.7264,0.7287,0.7236,0.7281],
    [1268870400000,0.728,0.7363,0.7278,0.7348],
    [1268956800000,0.7348,0.7408,0.7338,0.7391],
    [1269216000000,0.7389,0.7429,0.7369,0.7371],
    [1269302400000,0.7371,0.7432,0.737,0.7425],
    [1269388800000,0.7425,0.7518,0.7415,0.7503],
    [1269475200000,0.7502,0.7539,0.7468,0.7516],
    [1269561600000,0.7516,0.752,0.7448,0.7458],
    [1269820800000,0.7456,0.7456,0.7404,0.7413],
    [1269907200000,0.7413,0.7467,0.7387,0.7444],
    [1269993600000,0.7444,0.7473,0.7381,0.7393],
    [1270080000000,0.7393,0.7431,0.7357,0.736],
    [1270166400000,0.736,0.7423,0.7358,0.7406],
    [1270425600000,0.7409,0.7432,0.7386,0.7424],
    [1270512000000,0.7424,0.749,0.7424,0.7473],
    [1270598400000,0.7473,0.7508,0.7461,0.7501],
    [1270684800000,0.7502,0.7531,0.7472,0.7477],
    [1270771200000,0.7477,0.7499,0.7406,0.7408],
    [1271030400000,0.7406,0.7406,0.7303,0.7361],
    [1271116800000,0.7361,0.7384,0.7338,0.7345],
    [1271203200000,0.7345,0.7357,0.7309,0.7324],
    [1271289600000,0.7324,0.7399,0.7317,0.7373],
    [1271376000000,0.7373,0.7424,0.7371,0.7406],
    [1271635200000,0.7408,0.7456,0.7408,0.7416],
    [1271721600000,0.7416,0.7455,0.7394,0.745],
    [1271808000000,0.745,0.7488,0.7434,0.747],
    [1271894400000,0.747,0.7577,0.745,0.7563],
    [1271980800000,0.7563,0.7575,0.7462,0.7472],
    [1272240000000,0.7474,0.7526,0.7453,0.7467],
    [1272326400000,0.7466,0.761,0.7465,0.7589],
    [1272412800000,0.7589,0.7627,0.7537,0.7578],
    [1272499200000,0.7578,0.7587,0.7531,0.7547],
    [1272585600000,0.7547,0.7563,0.7495,0.7524],
    [1272844800000,0.7522,0.7605,0.7484,0.7579],
    [1272931200000,0.7579,0.7717,0.7567,0.7706],
    [1273017600000,0.7706,0.7822,0.7694,0.7806],
    [1273104000000,0.7806,0.7989,0.7778,0.7903],
    [1273190400000,0.7903,0.7948,0.7813,0.7842],
    [1273449600000,0.7839,0.7862,0.7637,0.7852],
    [1273536000000,0.7852,0.7928,0.7811,0.7912],
    [1273622400000,0.7912,0.7935,0.7849,0.7909],
    [1273708800000,0.7909,0.7992,0.7883,0.7982],
    [1273795200000,0.7982,0.8097,0.7951,0.8091],
    [1274054400000,0.8094,0.8176,0.8055,0.8076],
    [1274140800000,0.8076,0.8237,0.8035,0.8235],
    [1274227200000,0.8235,0.8235,0.8044,0.8075],
    [1274313600000,0.8075,0.8134,0.7937,0.7974],
    [1274400000000,0.7975,0.8021,0.7891,0.7954],
    [1274659200000,0.7956,0.8112,0.7956,0.8105],
    [1274745600000,0.8105,0.8214,0.8072,0.8109],
    [1274832000000,0.811,0.8231,0.81,0.821],
    [1274918400000,0.8209,0.8212,0.8067,0.8087],
    [1275004800000,0.8087,0.8156,0.803,0.8143],
    [1275264000000,0.8146,0.816,0.8107,0.8149],
    [1275350400000,0.8149,0.826,0.8095,0.8167],
    [1275436800000,0.8167,0.8215,0.8147,0.8163],
    [1275523200000,0.8163,0.8231,0.8112,0.8214],
    [1275609600000,0.8212,0.8366,0.8186,0.8357],
    [1275868800000,0.836,0.8422,0.8339,0.8389],
    [1275955200000,0.8389,0.8405,0.8326,0.8364],
    [1276041600000,0.8364,0.839,0.8282,0.8342],
    [1276128000000,0.8342,0.8366,0.8235,0.8244],
    [1276214400000,0.8244,0.8304,0.8228,0.8257],
    [1276473600000,0.8254,0.8257,0.8129,0.819],
    [1276560000000,0.8191,0.8222,0.8097,0.8121],
    [1276646400000,0.8121,0.8163,0.8095,0.8131],
    [1276732800000,0.8131,0.8171,0.8054,0.8074],
    [1276819200000,0.8074,0.8097,0.8053,0.8072],
    [1277078400000,0.8031,0.8129,0.8025,0.8116],
    [1277164800000,0.8118,0.8164,0.8094,0.8148],
    [1277251200000,0.8153,0.8273,0.81,0.8129],
    [1277337600000,0.8126,0.8158,0.8072,0.8107],
    [1277424000000,0.8107,0.8163,0.8067,0.808],
    [1277683200000,0.8083,0.8155,0.8065,0.8141],
    [1277769600000,0.8139,0.8232,0.8135,0.8207],
    [1277856000000,0.8207,0.8222,0.8127,0.8179],
    [1277942400000,0.8179,0.8203,0.7974,0.7993],
    [1278028800000,0.7992,0.8013,0.7929,0.7967],
    [1278288000000,0.7965,0.8001,0.7957,0.7995],
    [1278374400000,0.7995,0.8015,0.7897,0.7923],
    [1278460800000,0.7923,0.7967,0.7896,0.7921],
    [1278547200000,0.7921,0.7923,0.7867,0.7881],
    [1278633600000,0.7881,0.7932,0.786,0.7911],
    [1278892800000,0.7914,0.797,0.7906,0.7935],
    [1278979200000,0.7935,0.7987,0.785,0.7862],
    [1279065600000,0.7862,0.7886,0.7825,0.7854],
    [1279152000000,0.7854,0.7872,0.7718,0.7741],
    [1279238400000,0.7741,0.776,0.7688,0.7735],
    [1279497600000,0.7738,0.7772,0.7697,0.773],
    [1279584000000,0.773,0.779,0.7675,0.7749],
    [1279670400000,0.7749,0.7857,0.7744,0.7838],
    [1279756800000,0.7837,0.7853,0.7732,0.7745],
    [1279843200000,0.7745,0.7818,0.7712,0.775],
    [1280102400000,0.7753,0.7768,0.7688,0.7703],
    [1280188800000,0.7705,0.7723,0.7665,0.7699],
    [1280275200000,0.7699,0.7714,0.7667,0.7694],
    [1280361600000,0.7693,0.7707,0.7629,0.7653],
    [1280448000000,0.7653,0.7706,0.7637,0.7663],
    [1280707200000,0.7661,0.7663,0.7579,0.7592],
    [1280793600000,0.7594,0.7609,0.754,0.7564],
    [1280880000000,0.7565,0.7617,0.7552,0.7603],
    [1280966400000,0.7602,0.7624,0.7555,0.7588],
    [1281052800000,0.7588,0.7602,0.7499,0.753],
    [1281312000000,0.7527,0.7574,0.7514,0.7567],
    [1281398400000,0.7567,0.765,0.7559,0.7602],
    [1281484800000,0.7602,0.7797,0.7598,0.7785],
    [1281571200000,0.7785,0.7825,0.7732,0.7787],
    [1281657600000,0.7786,0.7846,0.7748,0.7843],
    [1281916800000,0.784,0.7855,0.7769,0.7806],
    [1282003200000,0.7807,0.7812,0.7742,0.7771],
    [1282089600000,0.7771,0.7819,0.7739,0.7816],
    [1282176000000,0.7816,0.7831,0.775,0.7808],
    [1282262400000,0.7809,0.7899,0.7792,0.787],
    [1282521600000,0.7872,0.7924,0.7855,0.7918],
    [1282608000000,0.7918,0.7947,0.7862,0.7914],
    [1282694400000,0.7914,0.7934,0.7857,0.7891],
    [1282780800000,0.7892,0.7908,0.7834,0.7872],
    [1282867200000,0.7872,0.789,0.7825,0.7836],
    [1283126400000,0.7838,0.7903,0.7829,0.7896],
    [1283212800000,0.7897,0.7923,0.7847,0.7891],
    [1283299200000,0.7891,0.79,0.7778,0.7816],
    [1283385600000,0.7816,0.7829,0.7783,0.7804],
    [1283472000000,0.7805,0.7809,0.7753,0.7755],
    [1283731200000,0.7757,0.7815,0.774,0.7802],
    [1283817600000,0.7802,0.7891,0.7801,0.788],
    [1283904000000,0.7879,0.7901,0.7835,0.7859],
    [1283990400000,0.7857,0.7898,0.7833,0.7888],
    [1284076800000,0.7888,0.7911,0.7844,0.7892],
    [1284336000000,0.789,0.789,0.7756,0.7774],
    [1284422400000,0.7774,0.7797,0.7671,0.7695],
    [1284508800000,0.7695,0.7721,0.7669,0.7686],
    [1284595200000,0.7689,0.771,0.7624,0.7654],
    [1284681600000,0.7653,0.7682,0.7599,0.7662],
    [1284940800000,0.766,0.7675,0.7621,0.7656],
    [1285027200000,0.7658,0.766,0.7524,0.7528],
    [1285113600000,0.753,0.7539,0.744,0.7467],
    [1285200000000,0.7467,0.7526,0.7455,0.7523],
    [1285286400000,0.7523,0.7528,0.741,0.7415],
    [1285545600000,0.7416,0.745,0.7403,0.7447],
    [1285632000000,0.7446,0.7476,0.7355,0.7368],
    [1285718400000,0.7368,0.7373,0.7328,0.734],
    [1285804800000,0.734,0.7376,0.7307,0.7337],
    [1285891200000,0.7337,0.7338,0.7249,0.7253],
    [1286150400000,0.7251,0.7331,0.7243,0.7328],
    [1286236800000,0.7328,0.7335,0.7215,0.7219],
    [1286323200000,0.7219,0.7248,0.7169,0.7186],
    [1286409600000,0.7186,0.7219,0.7128,0.7182],
    [1286496000000,0.7182,0.7231,0.7151,0.7173],
    [1286755200000,0.7171,0.7219,0.7136,0.7212],
    [1286841600000,0.7213,0.7261,0.7175,0.7184],
    [1286928000000,0.7184,0.719,0.7142,0.7152],
    [1287014400000,0.7152,0.7153,0.7081,0.7131],
    [1287100800000,0.7131,0.7176,0.7062,0.7155],
    [1287360000000,0.7153,0.7232,0.7143,0.7162],
    [1287446400000,0.7162,0.7302,0.714,0.73],
    [1287532800000,0.73,0.7301,0.7147,0.7156],
    [1287619200000,0.7156,0.721,0.7117,0.7189],
    [1287705600000,0.7188,0.7218,0.7157,0.7167],
    [1287964800000,0.7165,0.7192,0.7102,0.7183],
    [1288051200000,0.7183,0.7235,0.7152,0.7222],
    [1288137600000,0.7223,0.7283,0.7205,0.725],
    [1288224000000,0.7249,0.7261,0.7167,0.7172],
    [1288310400000,0.7172,0.7245,0.7168,0.7171],
    [1288569600000,0.7168,0.7214,0.7137,0.719],
    [1288656000000,0.719,0.7206,0.7113,0.7127],
    [1288742400000,0.7127,0.7149,0.7066,0.7079],
    [1288828800000,0.708,0.7093,0.7001,0.7029],
    [1288915200000,0.7028,0.7133,0.7027,0.7128],
    [1289174400000,0.7126,0.7217,0.7099,0.7213],
    [1289260800000,0.7214,0.7276,0.7156,0.7263],
    [1289347200000,0.7263,0.7316,0.7233,0.726],
    [1289433600000,0.726,0.7352,0.7235,0.7342],
    [1289520000000,0.7342,0.7369,0.7258,0.7305],
    [1289779200000,0.7302,0.7375,0.7272,0.7369],
    [1289865600000,0.7368,0.7438,0.7322,0.7411],
    [1289952000000,0.7411,0.7431,0.737,0.7379],
    [1290038400000,0.7379,0.8273,0.7316,0.7323],
    [1290124800000,0.7323,0.7351,0.7282,0.7315],
    [1290384000000,0.7313,0.7366,0.7253,0.7354],
    [1290470400000,0.7353,0.7487,0.7345,0.747],
    [1290556800000,0.747,0.753,0.745,0.7487],
    [1290643200000,0.7487,0.7528,0.7469,0.7496],
    [1290729600000,0.7499,0.7577,0.749,0.7553],
    [1290988800000,0.7551,0.7656,0.7517,0.7634],
    [1291075200000,0.7634,0.7713,0.7604,0.7695],
    [1291161600000,0.7695,0.7712,0.7586,0.762],
    [1291248000000,0.762,0.766,0.7547,0.7569],
    [1291334400000,0.7569,0.7582,0.7441,0.7455],
    [1291593600000,0.7457,0.7551,0.745,0.7526],
    [1291680000000,0.7526,0.7557,0.7462,0.7552],
    [1291766400000,0.7548,0.759,0.753,0.7549],
    [1291852800000,0.7549,0.7598,0.7506,0.7553],
    [1291939200000,0.7553,0.759,0.7528,0.7561],
    [1292198400000,0.7564,0.759,0.7444,0.7466],
    [1292284800000,0.7466,0.749,0.7409,0.7487],
    [1292371200000,0.7487,0.7573,0.7475,0.7561],
    [1292457600000,0.7561,0.7588,0.7537,0.7553],
    [1292544000000,0.7553,0.7616,0.7485,0.7584],
    [1292803200000,0.7586,0.7639,0.7583,0.7624],
    [1292889600000,0.7626,0.7651,0.7574,0.7639],
    [1292976000000,0.764,0.7649,0.7586,0.7626],
    [1293062400000,0.7626,0.7663,0.7603,0.7621],
    [1293148800000,0.7621,0.7639,0.7605,0.7622],
    [1293408000000,0.7624,0.7652,0.7581,0.7583],
    [1293494400000,0.7584,0.7646,0.7532,0.7642],
    [1293580800000,0.7642,0.7645,0.7553,0.7562],
    [1293667200000,0.7564,0.7568,0.751,0.7519],
    [1293753600000,0.7519,0.7528,0.7449,0.7473],
    [1294012800000,0.7476,0.7549,0.7465,0.7488],
    [1294099200000,0.7487,0.7525,0.7443,0.7509],
    [1294185600000,0.7508,0.7621,0.7505,0.7602],
    [1294272000000,0.7602,0.7712,0.7592,0.7705],
    [1294358400000,0.7705,0.7751,0.7679,0.7748],
    [1294617600000,0.7751,0.7771,0.7712,0.7714],
    [1294704000000,0.7715,0.7747,0.7698,0.7736],
    [1294790400000,0.7736,0.7737,0.7607,0.7618],
    [1294876800000,0.7618,0.7642,0.7472,0.7491],
    [1294963200000,0.749,0.7513,0.7431,0.747],
    [1295222400000,0.7468,0.7552,0.7462,0.753],
    [1295308800000,0.753,0.7547,0.7426,0.7475],
    [1295395200000,0.7475,0.7477,0.7386,0.7434],
    [1295481600000,0.7434,0.7467,0.7394,0.7427],
    [1295568000000,0.7427,0.7438,0.7339,0.7343],
    [1295827200000,0.7345,0.7388,0.7307,0.733],
    [1295913600000,0.733,0.737,0.7297,0.7308],
    [1296000000000,0.7308,0.7332,0.7287,0.7294],
    [1296086400000,0.7294,0.7335,0.7268,0.7288],
    [1296172800000,0.7288,0.7363,0.7274,0.7349],
    [1296432000000,0.7351,0.7371,0.7277,0.7292],
    [1296518400000,0.7293,0.7301,0.7224,0.7232],
    [1296604800000,0.7232,0.7265,0.7213,0.7239],
    [1296691200000,0.7238,0.735,0.7233,0.7341],
    [1296777600000,0.7341,0.7386,0.731,0.7364],
    [1297036800000,0.7366,0.7405,0.7338,0.7357],
    [1297123200000,0.7357,0.737,0.7305,0.734],
    [1297209600000,0.734,0.735,0.7275,0.7289],
    [1297296000000,0.729,0.7367,0.7284,0.7351],
    [1297382400000,0.7352,0.7411,0.7341,0.7377],
    [1297641600000,0.738,0.7449,0.7375,0.7419],
    [1297728000000,0.7419,0.7431,0.7379,0.7409],
    [1297814400000,0.7409,0.743,0.7359,0.7367],
    [1297900800000,0.7368,0.739,0.7338,0.7341],
    [1297987200000,0.7341,0.7385,0.7291,0.7304]];
