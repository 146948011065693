import React, { useEffect, useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from "translate";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";
import Spin from "antd/lib/spin";
import Message from "antd/lib/message";
import FormCreator from "commons/final-form/core/FormCreator";
import { endpoints } from "constants/endpoints";
import DataFetchHook from "commons/hook/fetchDataHook";
import connectCharacterSchema from "schema-form/schema/connectCharacterSchema";
import connectCharacterViewLayout from "schema-form/layout/connectCharacterViewLayout";
import connectCharacterUI from "schema-form/ui/connectCharacterUI";
import SearchCharacter from "components/ConnectCharacter/SearchCharacter";
import VerifyLinkAccountToken from "components/ConnectCharacter/VerifyLinkAccountToken";
import CharacterList from "components/ConnectCharacter/CharacterList";
import avatarExample from "../../../assets/images/imageExample/avatarExample.png";
import { connect, useSelector } from "react-redux";
import Progress from "antd/lib/progress";
import Tabs from "antd/lib/tabs";
import {
    generateServerGameCharacterListTab,
    generateCharacterEachServer,
} from "commons/game";
import Alert from "antd/lib/alert";
import { CloseOutlined } from "@ant-design/icons";

import UserInfoBlock from "components/UserInfo/UserInfoBlock";
import styles from "./styles.module.scss";
import AccountLimit from "components/UserInfo/AccountLimit";
import ModalConnectCharacter from "../components/ModalConnectCharacter";

//This page will show all Character and all queue that user will use to link thier game character to the system
const CharacterInfoLayout = ({ userProfile }) => {
    const [gameCharacterList, setGameCharacterList] =
        useState([]);
    const [currentServer, setCurrentServer] =
        useState(1);
    const [visible, setVisible] = useState(false);

    const [
        dataGameCharacter,
        setEndpointGameCharacter,
        setParamsGameCharacter,
    ] = DataFetchHook(
        endpoints.game_character,
        { submit: 1 },
        "GET",
        (result, params) => {
            setGameCharacterList(result?.data || []);
        },
        (result, params) => {
            console.log({ result });
        }
    );

    const [
        unlinkGameCharacter,
        setEndpointUnlink,
        setParamsUnlink,
    ] = DataFetchHook(
        endpoints.unlink_game_character,
        "",
        "POST",
        (result, params) => {
            if (result?.data) {
                setParamsGameCharacter("");
                setParamsGameCharacter({ submit: 1 });
            }
        },
        (result, params) => {
            console.log({ result });
        }
    );

    const unlinkCharacterWithAccount = (
        gameCharacterId
    ) => {
        setParamsUnlink("");
        setParamsUnlink({
            game_character_id: gameCharacterId,
        });
    };

    return (
        <PageLayout className={styles.characterContainer}>
            <Row gutter={[16, 16]} className="mt-3">
                <Col span={24}>
                    <Alert
                        className="mb-2"
                        message={t1(
                            "welcome_to_sro_money_connect_game_character_screen"
                        )}
                        type="success"
                    />
                </Col>
                {/* <Col span={24} md={8} lg={8}>
                    <UserInfoBlock />
                    <AccountLimit />
                </Col> */}
                <Col span={24} md={16} lg={16}>
                    <Tabs
                        defaultActiveKey="1"
                        onChange={(e) =>
                            setCurrentServer(e)
                        }
                        type="card"
                        items={generateServerGameCharacterListTab(
                            {
                                serverListInfo:
                                    generateCharacterEachServer(
                                        gameCharacterList
                                    ),
                                onRemoveCharacter:
                                    unlinkCharacterWithAccount,
                                onSelectCharacter: (e) =>
                                    console.log(e),
                                currentSelected: false,
                                isAbleToAddCharacter: true,
                                onClickAddCharacter: (e) => setVisible(true)
                            }
                        )}
                    />
                </Col>
            </Row>
            <ModalConnectCharacter
                visible={visible}
                setVisible={setVisible}
                serverId={currentServer}
                refreshSearch={(e) => {
                  setParamsGameCharacter('');
                  setParamsGameCharacter({submit: 1});
                }}
            />
        </PageLayout>
    );
};

const mapStateToProps = (state, props) => {
    return {
        userProfile: state?.profile?.profile,
    };
};

export default connect(mapStateToProps)(
    CharacterInfoLayout
);
