import SupportTicketLayout from "./ticket";
import SupportQALayout from './qa';
import { MENU_LINK_QA, MENU_LINK_TICKET_SUPPORT } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_QA,
        exact: true,
        component: <SupportQALayout />,
    },
    {
        path: MENU_LINK_TICKET_SUPPORT,
        exact: true,
        component: <SupportTicketLayout />,
    }
];

export default routeSchema;