import React from "react";
import { t1 } from "translate";
import Typography from "antd/lib/typography";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import Button from "antd/lib/button";

const { Title, Paragraph, Text } = Typography;

/**
 * This components will work like description below:
 * 
 * 1/ Show text: You need to put enough item here then click accept - list of item that eligible to deposit (countdown 3 min)
 * 2/ Bot check that then show successful and stop countdown, show text to screen
 * We found these items in the trade => show item (cross item if not eligible) => if have not eligible item => cancel trade => block this user from trade for 2 mins
 * show link to item eligible to deposit
 * If number of item also not match => cancel trade => show message: wrong amount of items found - trade cancelled => block this user from trade for 2 mins
 * 
 * 3/ If all item eligible and number of item are correct => show promt to user: Is that correct => user confirm (countdown 3 mins) - if cancel => block this user from
 * trade for 3 mins
 * 
 * 4/ user confirm => move to step 4
 * Any step fail will resulted in block from deposit function for 5 mins
 */
const Step3 = ({nextStep, setCurrentPercent}) => {
  return (
    <Row gutter={[8,8]}>
      <Col span={24}>
        <Typography>
          <Title>{t1("end_of_step_3")}</Title>
          <Paragraph>
            {t1("click_button_below_to_confirm_that_you_arE_ready_to_transfer_item_to_our_bot")}
          </Paragraph>

          <Text type="danger">
            {t1(
              "failed_to_confirm_in_time_will_make_you_blocked_from_deposit_function_for_at_least_5_mins"
            )}
          </Text>
        </Typography>
      </Col>
      <Col span={24} className="text-center">
        <Button className="me-2">{t1("i_cancelled_the_deal")}</Button>
        <Button onClick={(e) => {
          setCurrentPercent(0)
          nextStep()
          }}>{t1("i_am_ready")}</Button>
      </Col>
    </Row>
  );
};

export default Step3;
