import React, { useState } from "react";
import PageLayout from "components/PageLayout";
import { t1 } from "translate";
import Card from "antd/lib/card";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import Alert from "antd/lib/alert";
import RefundPointInput from "../components/RefundPointInput";
import useFetch from "commons/hook/fetchDataHook";
import { endpoints } from "constants/endpoints";
import { SmileOutlined, QrcodeOutlined, PlusOutlined } from "@ant-design/icons";
import Steps from 'antd/lib/steps';
import { formatPrice } from "helper/helper";

const stepList = ({ setCurrentStep, setGetParamsWithdraw }) => {
    return [
        {
            title: t1("choose_amount_to_refund"),
            icon: <PlusOutlined />,
            content: <RefundPointInput setParams={setGetParamsWithdraw} setCurrentStep={setCurrentStep} nextStepId={1}/>,
        },
        {
            title: t1("wait_for_offer_to_finished"),
            icon: <SmileOutlined />,
            content: (
                <h5 className="mt-5 text-center">
                    {t1(
                        "your_refund_request_inprogress,_time_for_transaction_to_complete_could_take_up_to_maximun_%s_hours_if_bank_system_not_undermaintain",
                        [24]
                    )}
                </h5>
            ),
        },
    ];
};

//This page will be use to refund point to money in system
const PointRefundLayout = ({ userProfile, account, bank }) => {
    const [currentStep, setCurrentStep] = useState(0);
    const navigate = useNavigate();

    const [data, UpEnpoints, setParams] = useFetch(
        endpoints.create_refund_request,
        "",
        "post",
        (result, params) => {
        }
    );

    return (
        <PageLayout>
            <h5>{t1("point_refund_layout")}</h5>
            <Alert
                className="mb-2"
                message={
                    <div>
                        {t1("your_current_point_number_is_%s", [formatPrice(Number(account?.balance))])}
                        <img src="/cashicon.png" alt="sro money point icon" style={{ width:"20px", marginLeft: '3px'}}/>
                    </div>
                }
                type="success"
            />
            <Row>
                <Col span={24}>
                    {!userProfile?.identify_card_number || bank?.length == 0 ? (
                        <Alert
                            onClick={(e) => {
                                navigate("/user-info");
                            }}
                            message={t1(
                                "you_have_to_click_here_to_confirm_your_identity_and_add_your_bank_account_first"
                            )}
                            type="warning"
                            style={{ cursor: "pointer" }}
                        />
                    ) : (
                        <Card title={null} bordered={true}>
                            <Row gutter={[32, 16]}>
                                <Col span={24}>
                                    <Steps
                                        current={currentStep}
                                        items={stepList({
                                            setCurrentStep,
                                            setGetParamsWithdraw: setParams,
                                        })}
                                    />
                                </Col>
                                <Col span={24}>
                                    {
                                        stepList({
                                            setCurrentStep,
                                            setGetParamsWithdraw: setParams,
                                        })[currentStep]?.content
                                    }
                                </Col>
                            </Row>
                        </Card>
                    )}
                </Col>
            </Row>
        </PageLayout>
    );
};

const mapStateToProps = (state, props) => {
    return {
        userProfile: state?.profile?.profile,
        account: state?.profile?.account,
        bank: state?.profile?.banks,
    };
};

export default connect(mapStateToProps)(PointRefundLayout);
