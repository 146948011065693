import React from 'react';
import dayjs from 'dayjs';
import { TIMEFORMAT, DATEFORMAT2, DATEFORMAT3 } from 'constants';
import Checkbox from 'antd/lib/checkbox';

const ReviewDataFieldAntd = ({ input, meta, errorText = {}, ...rest }) => {
  if (typeof rest?.reviewRender == 'function') {
    return <>{rest.reviewRender(input?.value, rest?.schemaIn)}</>;
  } else {
    switch (rest?.oldType) {
      case 'radio':
      case 'select':
        const selectValue = (rest?.schemaIn?.options || []).find((opt) => opt.value == input?.value);
        return <>{selectValue ? selectValue.label : ''}</>;
      case 'checkbox':
        if (Object.keys(rest?.schemaIn?.options.option).length === 0) {
          const checkedValue = (rest?.schemaIn?.options || []).filter((opt) => input?.value?.includes(opt.value));
          return checkedValue.map((item) => {
            return (
              <div>
                <Checkbox checked={true}>{item?.label}</Checkbox>
              </div>
            );
          });
        }
        const checked =
          rest?.schemaIn?.options && rest?.schemaIn?.options?.option?.value == input?.value
            ? true
            : input?.value == true
            ? true
            : false;
        return <Checkbox checked={checked}>{rest?.schemaIn?.options.option?.label}</Checkbox>;
      case 'upload':
        return input?.value;
      case 'monthpicker':
        return input?.value ? dayjs(input?.value).format(DATEFORMAT3) : '';
      case 'rangepicker':
        return input?.value ? input?.value.map((date) => dayjs(date).format(DATEFORMAT2)).join(' ~ ') : '';
      case 'timepicker':
        return input?.value ? dayjs(input?.value).format(TIMEFORMAT) : '';
      case 'time_rangepicker':
        return input?.value ? input?.value.map((date) => dayjs(date).format(TIMEFORMAT)).join(' ~ ') : '';
      case 'datepicker':
        return input?.value ? dayjs(input?.value).format(DATEFORMAT2) : '';
      default:
        return <>{input?.value}</>;
    }
  }
};

export default ReviewDataFieldAntd;
