export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined);
  
export const required = value => (value ? undefined : 'required');

export const emailValidator = value => (
  String(value)
    .toLowerCase()
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
      ? undefined
      : 'emailInvalid'
);

export const maxLength = (value, maxValue) => value?.length > maxValue ? 'invalidValue' : undefined;

export const minValue = (value, minValue) => Number(value) < Number(minValue) ? 'invalidValue' : undefined;
