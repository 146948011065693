import React, { useState } from 'react';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';
import lGet from 'lodash/get';
import FormElementRender from './FormElementRender';
import DefaultLayout from './DefaultLayout';
import { Button } from 'antd';
import { modifySchemaToReviewField } from '../utils';
import { cloneDeep } from 'lodash';

const FormCreator = ({
  schema,
  ui,
  layout,
  formatBeforeSubmit,
  SubmitButton,
  onSubmit = () => {},
  renderFields,
  initialValues = {},
  uiId = 'default',
  styleLibrary = 'antd',
  debugMode = false,
  mode = 'edit'
}) => {
  if (!schema || !ui) {
    return <>schema_or_ui_havent_declare</>;
  }

  const handleOnSubmitWithFormat = (data) => {
    if (typeof formatBeforeSubmit === 'function') {
      onSubmit(formatBeforeSubmit(data));
    } else {
      onSubmit(data);
    }
  };
  //const [componentList, setComponentList] = useState({});

  if (mode == 'view'){
    schema = modifySchemaToReviewField(cloneDeep(schema));
  }

  return (
    <Form
      onSubmit={handleOnSubmitWithFormat}
      initialValues={initialValues}
      mutators={{
        ...arrayMutators,
      }}
      render={({
        handleSubmit,
        form: {
          mutators: { push, pop },
        }, // injected from final-form-arrays above
        pristine,
        form,
        submitting,
        values,
      }) => {
        var ComponentsObject = {};

        lGet(lGet(ui, uiId, {}), 'fields', []).map((field) => {
          let fieldData = schema[field];
          fieldData['name'] = field;
          if (renderFields) {
            fieldData['renderFields'] = renderFields;
          }
          let FieldComponent = FormElementRender({
            form,
            fieldData: fieldData,
            style: styleLibrary,
            arrayMutators: { push, pop },
            values: values,
          });
          
          ComponentsObject[field] = FieldComponent;
          //return FieldComponent;
        });

        if (SubmitButton) {
          if (typeof SubmitButton === 'function') {
            ComponentsObject['SubmitButton'] = SubmitButton(form);
          } else {
            ComponentsObject['SubmitButton'] = SubmitButton;
          }
        } else {
          ComponentsObject['SubmitButton'] = (
            <div className="buttons" style={{ textAlign: 'center' }}>
              <button type="submit">Submit</button>
              <button type="button" onClick={form.reset} disabled={submitting || pristine}>
                Reset
              </button>
            </div>
          );
        }

        return (
          <form onSubmit={handleSubmit}>
            {typeof layout === 'function' ? layout(ComponentsObject) : DefaultLayout(ComponentsObject)}
            {debugMode ? <pre>{JSON.stringify(values, 0, 2)}</pre> : <></>}
          </form>
        );
      }}
    />
  );
};

export default FormCreator;
