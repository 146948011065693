import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

const DefaultLayoutReview = (fields) => {
  return (layoutComponents) => {
    return (
      <Row gutter={[2,2]} style={{
        backgroundColor: '#F5F5F5',
        borderRadius: '5px',
        padding: '15px 0px 10px 10px'
      }}>
        <Col span={24}>
          <Row gutter={[20, 20]}>
            {Object.keys(fields?.children).map((fieldName) => {
              if (layoutComponents[fieldName]) {
                return <Col span={24}>{layoutComponents[fieldName]}</Col>;
              }
            })}
          </Row>
        </Col>
      </Row>
    );
  };
};

export default DefaultLayoutReview;
