import { SET_PARAMS_HOOK_ACTION, SET_URL_HOOK_ACTION, SET_CALLBACK_SUCCESS_ACTION, SET_CALLBACK_FAILED_ACTION, SET_CHANGE_METHOD_HOOK } from "redux/action-types";
//import lGet from 'lodash/get';

const initialState = {
    setUrl: false,//(e) => console.log(e),
    setParams: false,//(e) => console.log(e),
    setMethod: false,//(e) => console.log(e),
    callBackSuccess: (result,params,message) => console.log({result,params,message}),
    callBackFailed: (result,params,message) => console.log({result,params,message})
};

const RequestReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PARAMS_HOOK_ACTION: {
            return Object.assign({},state,{ setParams: action.payload });
        }
        case SET_URL_HOOK_ACTION: {
            return Object.assign({},state,{ setUrl: action.payload });
        }
        case SET_CALLBACK_SUCCESS_ACTION: {
            return Object.assign({},state,{ callBackSuccess: action.payload });
        }
        case SET_CALLBACK_FAILED_ACTION: {
            return Object.assign({},state,{ callBackFailed: action.payload });
        }
        case SET_CHANGE_METHOD_HOOK: {
            return Object.assign({},state,{ setMethod: action.payload });
        }
        default:
            return state;
    }
}

export default RequestReducer;
