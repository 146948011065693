import React from "react";
import PageLayout from "components/PageLayout";
import { t1 } from 'translate';

//This page will list all settings notification that user can modify, or some secret keyword that this user want to config
const SettingsNotificationLayout = (props) => {
    return <PageLayout>
        {t1('settings_notification_layout')}
    </PageLayout>
}

export default SettingsNotificationLayout;