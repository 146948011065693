import React, {useState} from 'react';
import { t1 } from "translate";
import Row from "antd/lib/row";
import Col from "antd/lib/col";
import { endpoints } from 'constants/endpoints';
import DataFetchHook from 'commons/hook/fetchDataHook';
import { SERVER_LIST } from 'constants/game';
import { getCharacterImageFromModelId } from 'commons/game';
import Typography from "antd/lib/typography";

const { Title, Paragraph, Text } = Typography;

const CharacterDetail = (props) => {
  const {characters, styles} = props;
  const [characterList,setCharacterList] = useState([]);

  const [
    dataGameCharacter,
    setEndpointGameCharacter,
    setParamsGameCharacter,
  ] = DataFetchHook(
    endpoints.game_character,
    { submit: 1 },
    "GET",
    (result, params) => {
      if (result?.data) {
        const newCharacterList = (result?.data || [])?.map(character => {
          const getServer = SERVER_LIST.find(server => server.id === Number(character?.server_id))?.name || '';
          const avatar = (
            <img
              src={getCharacterImageFromModelId(
                character?.char_id
              )}
              alt={character?.name}
              style={{ width: "90%" }}
            />
          );

          return {
            server: getServer,
            value: character?.id,
            name: character?.name,
            icon: avatar
          }
        });

        setCharacterList([...newCharacterList, ...newCharacterList]);
      }
    },
    (result, params) => {
      console.log({ result });
    }
  );

  return (
    <Row gutter={[24, 24]}>
      {characterList.map((character, index) => (
        <Col key={index} span={24}>
          <Row gutter={[16, 4]}>
            <Col span={5}>
              {character?.icon}
            </Col>
            <Col span={19}>
              <Paragraph>
                <b className="fs-6">{character?.name}</b>
                <br/>
                {t1('server_%s',[character?.server])}
              </Paragraph>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default CharacterDetail;
