import BlogGuidanceVideoLayout from "./guidance";
import BlogAboutUsLayout from './about-us';
import { MENU_LINK_ABOUT_US, MENU_LINK_GUIDANCE_VIDEO } from 'constants/menu-link'

const routeSchema = [
    {
        path: MENU_LINK_GUIDANCE_VIDEO,
        exact: true,
        component: <BlogGuidanceVideoLayout />,
    },
    {
        path: MENU_LINK_ABOUT_US,
        exact: true,
        component: <BlogAboutUsLayout />,
    }
];

export default routeSchema;