import { t1} from 'translate';

export const MAPPING_CODE_WITH_IMAGE_MONSTER = {
    MOB_TQ_TOMBSOLDIER_CLON2: 'mob_tq/cnd_01.jpg',
    MOB_TQ_TOMBSOLDIER_CLON: 'mob_tq/cnd_03.jpg',
    MOB_TQ_TOMBSOLDIER: 'mob_tq/cnd_05.jpg',

    MOB_TQ_QINSHITOMBSTONE_CLON2: 'mob_tq/cnd_02.jpg',
    MOB_TQ_QINSHITOMBSTONE_CLON: 'mob_tq/cnd_04.jpg',
    MOB_TQ_QINSHITOMBSTONE: 'mob_tq/cnd_07.jpg',
    
    MOB_TQ_TOMBARCHER_CLON2: 'mob_tq/cnd_06.jpg',
    MOB_TQ_TOMBARCHER_CLON: 'mob_tq/cnd_08.jpg',
    MOB_TQ_TOMBARCHER: 'mob_tq/cnd_11.jpg',

    MOB_TQ_TOMBSTONEGHOST_CLON2: 'mob_tq/cnd_10.jpg',
    MOB_TQ_TOMBSTONEGHOST_CLON: 'mob_tq/cnd_12.jpg',
    MOB_TQ_TOMBSTONEGHOST: 'mob_tq/cnd_14.jpg',
    
    MOB_TQ_TOMBGENERAL:'mob_tq/cnd_09.jpg',
    MOB_TQ_TOMBGENERAL_CLON: 'mob_tq/cnd_15.jpg',
    MOB_TQ_TOMBGENERAL_CLON2: 'mob_tq/cnd_17.jpg',
    MOB_TQ_TOMBGENERAL_CLON3: 'mob_tq/cnd_20.jpg',
    
    MOB_TQ_ROYALSOLDIER_CLON2: 'mob_tq/cnd_13.jpg',
    MOB_TQ_ROYALSOLDIER_CLON: 'mob_tq/cnd_16.jpg',
    MOB_TQ_ROYALSOLDIER: 'mob_tq/cnd_18.jpg',

    MOB_TQ_TOMBBUGGHOST_CLON2: 'mob_tq/cnd_19.jpg',
    MOB_TQ_TOMBBUGGHOST_CLON: 'mob_tq/cnd_22.jpg',
    MOB_TQ_TOMBBUGGHOST: 'mob_tq/cnd_24.jpg',

    MOB_TQ_SNAKESLAVE_CLON2: 'mob_tq/cnd_21.jpg',
    MOB_TQ_SNAKESLAVE_CLON: 'mob_tq/cnd_25.jpg',
    MOB_TQ_SNAKESLAVE: 'mob_tq/cnd_28.jpg',

    MOB_TQ_SNAKEWOMAN_CLON2: 'mob_tq/cnd_23.jpg',
    MOB_TQ_SNAKEWOMAN_CLON: 'mob_tq/cnd_26.jpg',
    MOB_TQ_SNAKEWOMAN: 'mob_tq/cnd_35.jpg',

    MOB_TQ_SNAKEDEMON_CLON2: 'mob_tq/cnd_27.jpg',
    MOB_TQ_SNAKEDEMON_CLON: 'mob_tq/cnd_33.jpg',
    MOB_TQ_SNAKEDEMON: 'mob_tq/cnd_37.jpg',

    MOB_TQ_SNAKEGENERAL: 'mob_tq/cnd_29.jpg',
    MOB_TQ_SNAKEGENERAL_CLON: 'mob_tq/cnd_30.jpg',
    MOB_TQ_SNAKEGENERAL_CLON2: 'mob_tq/cnd_31.jpg',
    MOB_TQ_SNAKEGENERAL_CLON3: 'mob_tq/cnd_32.jpg',

    MOB_TQ_SNAKEMAN_CLON2: 'mob_tq/cnd_34.jpg',
    MOB_TQ_SNAKEMAN_CLON: 'mob_tq/cnd_36.jpg',
    MOB_TQ_SNAKEMAN: 'mob_tq/cnd_40.jpg',

    MOB_TQ_NORTHGUARDIAN: 'mob_tq/cnd_38.jpg',
    MOB_TQ_SOUTHGUARDIAN: 'mob_tq/cnd_39.jpg',
    MOB_TQ_EASTGUARDIAN: 'mob_tq/cnd_41.jpg',
    MOB_TQ_WESTGUARDIAN: 'mob_tq/cnd_42.jpg',

    MOB_TQ_BLACKSNAKE: 'mob_tq/cnd_43.jpg',
    MOB_TQ_BLACKSNAKE_L3: 'mob_tq/cnd_43.jpg',
    MOB_TQ_BLACKSNAKE_L2: 'mob_tq/cnd_43.jpg',

    MOB_TQ_FLAMEMASTER: 'mob_tq/cnd_44.jpg',
    MOB_TQ_WHITESNAKE: 'mob_tq/cnd_45.jpg',
}

export const MAPPING_SKILL_TYPE = {
    '5': t1('physic'),
    '10': t1('magic'),
}